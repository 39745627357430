import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";
import { ALL_EMPLOYEE_JOB_TITLES_QUERY } from "../../graphql";

const SupervisorTitleSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  getValue,
  setValue,
  showBlank,
  showAll,
}) => {
  const { data: EmployeeTitleData, loading: EmployeeTitleLoading } =
    useQuery(ALL_EMPLOYEE_JOB_TITLES_QUERY, {
      variables: {
        where: {},
        distinct: "jobTitle",
        orderBy: [
          {
            jobTitle: "asc",
          },
        ],
      },
    });

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded size={size}>
      {label && <Label size={size}>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={
          EmployeeTitleLoading || Array.isArray(EmployeeTitleData?.users)
            ? ""
            : "loading"
        }
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {showAll && <Select.Option value="">All</Select.Option>}
          {Array.isArray(EmployeeTitleData?.users) &&
            EmployeeTitleData.users.map(({ jobTitle }) => (
              <Select.Option key={jobTitle} value={jobTitle}>
                {jobTitle}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

SupervisorTitleSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
  showAll: PropTypes.bool,
};

SupervisorTitleSelect.defaultProps = {
  size: "normal",
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  showBlank: true,
  showAll: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};

export default SupervisorTitleSelect;
