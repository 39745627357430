import { uppercaseFirstLetter } from "../../utils";

const PROJECT_COLUMNS = [
  {
    Header: "Project Number",
    id: "project_num",
    accessor: "project_num",
  },
  {
    Header: "Project Name",
    id: "name",
    accessor: "name",
  },
  {
    Header: "Manager",
    id: "manager",
    accessor: "manager",
    Cell: ({ cell }) => uppercaseFirstLetter(cell?.value) || "N/a",
  },
  {
    Header: "Supervisor",
    id: "supervisor",
    accessor: "supervisor",
    Cell: ({ cell }) => uppercaseFirstLetter(cell?.value) || "N/a",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Address",
    id: "address",
    accessor: "address",
    Cell: ({ row }) => {
      const address = row?.original?.address || "Not Entered";
      const state = row?.original?.state || "Not Entered";
      const city = row?.original?.city || "Not Entered";
      const zip = row?.original?.zip || "Not Entered";

      return `${address}, ${state}, ${city}, ${zip}`;
    },
  },
];

export default PROJECT_COLUMNS;
