import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_REVIEW = gql`
  mutation UpdateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      id
      firstName
      lastName
      reviews {
        dateCompleted
        dateToCompleteBy
        manager
        status
        title
        year
        evaluations {
          dateCompleted
          dateToCompleteBy
          status
          author
          manager
          owner
          formAuthor
          formAuthorEmployeeNumber
          sections {
            instructions
            questions {
              answerOptions {
                value
                name
              }
              answer
              answerType
              text
            }
            title
          }
          title
        }
      }
    }
  }
`;
