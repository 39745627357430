import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useReactToPrint } from 'react-to-print';
import { useLocation } from "react-router-dom";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { Loader, Title, Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { GET_EVALUATION_TEMPLATES } from "./graphql";
import EvaluationSection from "../Reviews/components/EvaluationSection";
import { GET_EVALUATIONS, GET_REVIEW_TITLE, SEND_FOLLOW_UP_EMAIL } from "../AllEvaluations/graphql";
import formatDate from "../../utils/formatDate";
import capitalizeEveryWord from "../../utils/capitalizeEveryWord";
import "./Details.scss";
// import { useAuth } from "../../context";
// import { usePermissions } from "../../hooks";

const Details = ({ keyType, evaluationId, isModalView, refetchEvals }) => {
  const client = useApolloClient();
  const location = useLocation();
  const [sendFollowUpEmail] = useMutation(
    SEND_FOLLOW_UP_EMAIL
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });


  const handlePrintClick = async (e) => {
    e.preventDefault();
    await handlePrint();
   };

  const search = location.search.split("?")[1];
  const key = keyType.length ? keyType : search?.split("=")[0];
  const id = evaluationId.length ? evaluationId : search?.split("=")[1];

    const handleSendFollowUpEmail = async () => {
      try {
        await sendFollowUpEmail({
          variables: {
            evaluationId: id,
          },
        });
        toast.success("Reminder Email Sent to Current Author!");
      } catch (error) {
        toast.error(error.message);
      } finally {
        refetchEvals();
      }
    };


  const detailsContainerClassName = isModalView
    ? "details-container-modal"
    : "details-container";

  const detailsClassName = isModalView ? "details-modal" : "details";

  const [detailsData, setDetailsData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (key === "evaluation-template") {
        try {
          const { data: EvaluationData } = await client.query({
            query: GET_EVALUATION_TEMPLATES,
            variables: {
              where: {
                id: {
                  equals: id,
                },
              },
              sectionsOrderBy: [{ order: "asc" }],
              questionsOrderBy: [{ order: "asc" }],
            },
          });

          setDetailsData(EvaluationData?.evaluationTemplates?.[0]);
        } catch (err) {
          toast.error("Error: Please contact support.", err);
        } finally {
          setLoading(false);
        }
      }

      if (key === "evaluation") {
        try {
          // get evaluation data:
          const { data: EvaluationData } = await client.query({
            query: GET_EVALUATIONS,
            variables: {
              where: {
                id: {
                  equals: id,
                },
              },
            },
          });
          setDetailsData(EvaluationData?.evaluations?.[0]);

          // get review name:
          const { data: ReviewData } = await client.query({
            query: GET_REVIEW_TITLE,
            variables: {
              where: {
                evaluations: {
                  some: {
                    id: {
                      equals: id,
                    },
                  },
                },
              },
            },
          });

          setDetailsData((prev) => ({
            ...prev,
            reviewTitle: ReviewData?.reviews?.[0]?.title,
          }));
        } catch (err) {
          toast.error("Error: Please contact support.", err);
        } finally {
          setLoading(false);
        }
      }
    };

    if (id && key) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, key]);

  if (loading) {
    return (
      <div className={detailsContainerClassName}>
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (key === "evaluation-template") {
    return (
      <div className={detailsContainerClassName}>
        <div className={detailsClassName}>
          <Title size={4}>{detailsData?.title} Template</Title>
          <EvaluationSection
            isEvaluationCompleted
            handleInputChange={() => {}}
            inputs={detailsData}
          />
        </div>
      </div>
    );
  }

  if (key === "evaluation") {
    return (
      <div className={detailsContainerClassName} ref={componentRef}>
        <div className={detailsClassName}>
          <div className="details-modal-header2">
            <Title size={4}>
              {detailsData?.reviewTitle} Of{" "}
              {capitalizeEveryWord(detailsData?.owner)}
            </Title>
            <div className="export-container">
              <Button
                className="print-button fa-print"
                size="medium"
                style={{ marginBottom: "1rem", marginLeft: "1rem" }}
                onClick={handlePrintClick}
              >
                <Icon size="small">
                  <FontAwesomeIcon icon={faPrint} size="small"/>
                </Icon>
              </Button>
              <Button
                className="button is-primary"
                size="small-medium"
                style={{ marginBottom: "1rem", marginLeft: "1rem" }}
                onClick={handleSendFollowUpEmail}
              >
               Send Reminder Email
              </Button>
            </div>
          </div>
          <div className="eval-details-container">
            <p>Form Name: {detailsData?.title}</p>
            <p>Writer: {capitalizeEveryWord(detailsData?.formAuthor)}</p>
            <p>Subject: {capitalizeEveryWord(detailsData?.owner)}</p>
            <p>Manager: {capitalizeEveryWord(detailsData?.manager)}</p>
            <p>Due Date: {formatDate(detailsData?.dateToCompleteBy)}</p>
            <p>
              Signed On:{" "}
              {detailsData?.signedDate
                ? formatDate(detailsData?.signedDate)
                : ""}
            </p>
            <p>Signed By: {capitalizeEveryWord(detailsData?.signerName)}</p>
          </div>
          <EvaluationSection
            isEvaluationCompleted
            readOnly
            handleInputChange={() => {}}
            inputs={detailsData}
          />
        </div>
      </div>
    );
  }

  return (
    <div className={detailsContainerClassName}>
      <div className={detailsClassName}>No Details Found</div>
    </div>
  );
};

Details.propTypes = {
  keyType: PropTypes.string,
  evaluationId: PropTypes.string,
  isModalView: PropTypes.bool,
  refetchEvals: PropTypes.func,
};

Details.defaultProps = {
  keyType: "",
  evaluationId: "",
  isModalView: false,
  refetchEvals: () => {},
};

export default Details;
