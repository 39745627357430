import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import IncompleteGoalsList from "../IncompleteGoalsList";
import CompletedGoalsList from "../CompletedGoalsList";
import { useCurrentUser } from "../../../../hooks";

const GoalsListContainer = ({
  goals,
  isCompletedSection,
  sectionType,
  handleClick,
  setGoals,
  handleOpenGoalAdd,
  isUsersOwnGoals,
  showManagerReviews,
}) => {
  const user = useCurrentUser();
  // eslint-disable-next-line no-unused-vars
  const activeEmployeeNumber = user?.employeeNumber;

  const [incompleteGoals, setIncompleteGoals] = useState([]);
  const [completedGoals, setCompletedGoals] = useState([]);

  useEffect(() => {
    const incompleteGoalsFiltered = goals.filter(
      (goal) =>
        goal.status === "Active"
    ).sort((a, b) => new Date(a?.dueDate) - new Date(b?.dueDate));

    const completedGoalsFiltered = goals?.filter(
      (goal) =>
        goal.status === "COMPLETED"
    ).sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    setIncompleteGoals(incompleteGoalsFiltered);
    setCompletedGoals(completedGoalsFiltered);
  }, [goals]);

  if (isCompletedSection) {
    return (
      <ul>
        <div className="list-container">
          {goals.legnth ? (
            <div style={{ margin: "1rem" }}>No results found...</div>
          ) : (
            <React.Fragment>
              <CompletedGoalsList
                completedGoals={completedGoals}
                goalsLength={completedGoals?.length}
                handleClick={handleClick}
                sectionType={sectionType}
                setGoals={setGoals}
              />
            </React.Fragment>
          )}
        </div>
      </ul>
    );
  }


    return (
      <ul>
        <div className="list-container" style={{overflowY: "visible"}}>
          {!incompleteGoals.length ? (
            <div style={{ margin: "1rem" }}>No results found...</div>
          ) : (
            <React.Fragment>
              <IncompleteGoalsList
                goalsLength={incompleteGoals?.length}
                handleClick={handleClick}
                handleOpenGoalAdd={handleOpenGoalAdd}
                incompleteGoals={incompleteGoals}
                isUsersOwnGoals={isUsersOwnGoals}
                setGoals={setGoals}
                showManagerReviews={showManagerReviews}
              />
            </React.Fragment>
          )}
        </div>
      </ul>
    );
};

GoalsListContainer.propTypes = {
  goals: PropTypes.array.isRequired,
  sectionType: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  setGoals: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool,
  handleOpenGoalAdd: PropTypes.func.isRequired,
  isUsersOwnGoals: PropTypes.bool,
  showManagerReviews: PropTypes.bool
};

GoalsListContainer.defaultProps = {
  sectionType: "",
  isCompletedSection: false,
  isUsersOwnGoals: false,
  showManagerReviews: false
};

export default GoalsListContainer;
