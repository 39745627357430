/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import format from "date-fns/format";

import { FIND_MANY_CAL_EVENTS_QUERY } from "../../graphql/calendar/calendar.queries";
import {
  VerticalDivider,
  Resources,
  DashboardCard,
  SystemCodes,
} from "../../components";
import urlLinks from "./constants";

import { usePermissions, useFoundationQuery } from "../../hooks";
import {
  YearsOfServiceTable,
  LicensesTable,
  CertificationsTable,
  PointsTable,
  TshirtTable,
  EmployeePointsTable,
  EmployeePermissionsTable 
} from "./components";

import { ALL_BIRTHDAYS_QUERY } from "../../graphql";
import AlphaListTable from "./components/AlphaListTable";
import QualificationsTable from "./components/QualReportTable";

export const HEADER_TABS = [
  "Alpha List",
  "Employee Points",
  "Employee Permissions",
  "Expiring Licenses",
  "Expiring Certifications",
  "Points",
  "Qualifications",
  "T Shirts",
  "Years of Service",
];

const currentDate = new Date();

const Dashboard = () => {
  const [report, setReport] = useState("Alpha List");
  const [tabs, setTabs] = useState([]);
  const [birthdays, setBirthdays] = useState([]);
  const [events, setEvents] = useState([]);

  const permissionLevels = usePermissions();

  useEffect(() => {
    if (permissionLevels?.webEditor) {
      setTabs(HEADER_TABS);
      setReport("Alpha List");
    } else if (permissionLevels?.superAdmin) {
      setTabs(HEADER_TABS.filter(
        (tab) =>
          tab !== "Employee Points" && tab !== "Employee Permissions"
      ))
      setReport("Alpha List");
    } else if (permissionLevels?.admin) {
      setTabs(
        HEADER_TABS.filter(
          (tab) =>
            tab !== "Qualifications" &&
            tab !== "T Shirts" &&
            tab !== "Points" &&
            tab !== "Expiring Certifications" &&
            tab !== "Expiring Licenses" &&
            tab !== "Employee Points" &&
            tab !== "Employee Permissions"
        )
      );
      setReport("Alpha List");
    } else if (permissionLevels?.fieldSupervisor) {
      setTabs(["Qualifications"]);
      setReport("Qualifications");
    } else {
      setTabs([]);
    }
  }, [permissionLevels]);


  // ONE SPOT WE CONTINUE TO QUERY FOUNDATION.. is not part of sync apparently
  const { data: { birthdays: birthdayData = [] } = {} } = useFoundationQuery(
    ALL_BIRTHDAYS_QUERY,
    {
      variables: { where: { days: 30 } },
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: { findManyCalEvents: eventsData = [] } = {} } = useQuery(
    FIND_MANY_CAL_EVENTS_QUERY,
    {
      variables: {
        orderBy: [
          {
            date: "asc",
          },
        ],
        where: {
          date: {
            gte: currentDate,
          },
        },
      },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (birthdayData?.length && !birthdays?.length) {
      const dashboardCardFormat = birthdayData.map((item) => ({
        name: item.name,
        description: item.birthday,
        id: item.name + item.birthday,
      }));

      setBirthdays(dashboardCardFormat);
    }
  }, [birthdayData, birthdayData?.length, birthdays?.length]);

  useEffect(() => {
    if (eventsData?.length && !events?.length) {
      const dashboardCardFormat = eventsData.map((item) => ({
        name: item.title,
        description: format(new Date(item.date), "MMM do '-' hh:mm aa"),
        id: item.id,
      }));

      setEvents(dashboardCardFormat);
    }
  }, [eventsData, eventsData?.length, events?.length]);

  return (
    <div className="dashboard-container">
      <div className="left-side">
        {(permissionLevels.admin ||
          permissionLevels.superAdmin ||
          permissionLevels.webEditor) &&
          report === "Years of Service" && (
            <YearsOfServiceTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}

        {(permissionLevels.superAdmin || permissionLevels.webEditor) &&
          report === "Expiring Licenses" && (
            <LicensesTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}
        {(permissionLevels.superAdmin || permissionLevels.webEditor) &&
          report === "Expiring Certifications" && (
            <CertificationsTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}
        {(permissionLevels.superAdmin || permissionLevels.webEditor) &&
          report === "Points" && (
            <PointsTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}
        {(permissionLevels.webEditor || permissionLevels.superAdmin) &&
          report === "T Shirts" && (
            <TshirtTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}
        {(permissionLevels.admin ||
          permissionLevels.superAdmin ||
          permissionLevels.webEditor) &&
          report === "Alpha List" && (
            <AlphaListTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}

        {(permissionLevels.webEditor) &&
          report === "Employee Points" && (
            <EmployeePointsTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}

        {(permissionLevels.webEditor) &&
          report === "Employee Permissions" && (
            <EmployeePermissionsTable
              permissions={permissionLevels}
              report={report}
              tabs={tabs}
              onReportChange={setReport}
            />
          )}

        {(permissionLevels.fieldSupervisor ||
          permissionLevels.superAdmin ||
          permissionLevels.webEditor) &&
          report === "Qualifications" && (
            <React.Fragment>
              <QualificationsTable
                permissions={permissionLevels}
                report={report}
                tabs={tabs}
                onReportChange={setReport}
              />
              <VerticalDivider />
            </React.Fragment>
          )}
        <br />
        <br />
        <SystemCodes isAdmin isReadOnly />
        <br />
        <Resources readOnly />
        <br />
      </div>

      <div className="right-side">
        <DashboardCard data={urlLinks} paginationSize={5} title="Quick Links" />
        <VerticalDivider />
        <DashboardCard data={events} paginationSize={5} title="Events" />
        <VerticalDivider />
        <DashboardCard data={birthdays} paginationSize={5} title="Birthdays" />
      </div>
    </div>
  );
};

export default Dashboard;
