import React from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const LinkCell = ({ to, value }) => <Link to={to}>{value}</Link>;

LinkCell.propTypes = {
  to: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
};

LinkCell.defaultProps = {
  to: ""
}

export default LinkCell;
