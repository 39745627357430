import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Title } from "rbx";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { addYears, startOfMonth } from "date-fns";
import EvaluationQuestions from "../EvaluationQuestions";
import { GET_USERS, UPDATE_ONE_USER_GOAL } from "../../../Goals/graphql";
import GoalEvalListItem from "../../../Goals/components/GoalEvalListItem";
import { useAuth } from "../../../../context";
import { Loader } from "../../../../components";

const EvaluationSection = ({
  isEvaluationCompleted,
  inputs,
  handleInputChange,
  readOnly
}) => {
  const auth = useAuth();
  const activeUserEmployeeNumber = auth?.state?.user?.employeeNumber;
  const activeUserFullName = `${auth?.state?.user?.firstName} ${auth?.state?.user?.lastName}`;
  // from the owner of the evaluation
  const employeeNumber = inputs?.ownerEmployeeNumber;

  const {
    data: userData,
    loading,
    refetch,
  } = useQuery(GET_USERS, {
    variables: {
      where: {
        employeeNumber: { contains: employeeNumber },
      },
    },
    fetchPolicy: "network-only",
  });

  const [goals, setGoals] = useState([]);
  const [updateOneUserGoal, { loading: loadingGoal }] =
    useMutation(UPDATE_ONE_USER_GOAL);

  const onComplete = async () => {
    await refetch();
  };

  const handleGoalCompletion = async (e, goalId, evaluationSectionId) => {
    try {
      e.preventDefault();

      const timeStamp = new Date().toISOString();
      // completing decco leadership -> Molly for signing
      await updateOneUserGoal({
        variables: {
          where: {
            id: goalId,
          },
          data: {
            status: {
              set: "COMPLETED",
            },
            updatedBy: {
              set: activeUserEmployeeNumber,
            },
            updatedByFullName: {
              set: activeUserFullName,
            },
            updatedAt: {
              set: timeStamp,
            },
            evaluationSection: {
              connect: {
                id: evaluationSectionId,
              },
            },
          },
        },
      });

      toast.success("Successfully updated goal");
      onComplete();
    } catch (err) {
      toast.error("Error saving goal, please contact support.");
    }
  };

  const handleReassignGoal = async (e, goalId) => {
    try {
      e.preventDefault();

      const yearLater = addYears(new Date(), 1);
      const timeStamp = new Date().toISOString();
      // reassign for 6 months in future
      await updateOneUserGoal({
        variables: {
          where: {
            id: goalId,
          },
          data: {
            updatedBy: {
              set: activeUserEmployeeNumber,
            },
            updatedByFullName: {
              set: activeUserFullName,
            },
            updatedAt: {
              set: timeStamp,
            },
            dueDate: {
              set: startOfMonth(yearLater).toISOString(),
            },
          },
        },
      });

      toast.success("Successfully updated goal");
      onComplete();
    } catch (err) {
      toast.error("Error saving goal, please contact support.");
    }
  };

  useEffect(() => {
    if (userData?.users[0]?.goals) {
      const filteredGoals = userData?.users[0]?.goals.filter(
        (goal) =>
          goal.status === "Active" && Date.parse(goal?.dueDate) <= new Date()
      );
      setGoals(filteredGoals);
    }
  }, [userData, employeeNumber]);

  if (loading || loadingGoal) {
    return (
      <div style={{ minHeight: "10rem" }}>
        <Loader />
      </div>
    );
  }

  return (
    <React.Fragment>
      {inputs?.sections?.map((section, i) => {
        if (section?.title?.includes("Goals") && !isEvaluationCompleted) {
          return (
            <div
              key={`${section?.name}-${section?.instructions}-${section?.id}`}
            >
              <Title size={6}>
                {section?.title}
                {section?.instructions && ` - ${section?.instructions}`}
              </Title>
              {goals?.length === 0 ? (
                <div className="centered-title">
                  <Title size={6}>No active goals found</Title>
                </div>
              ) : (
                <div
                  className="centered-title"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Title size={6}>Active Goals</Title>
                  <span style={{ fontSize: "1rem", color: "red" }}>
                    **Important: Make sure to repeat or complete all goals
                    before submiting this form. Repeating goals will remove them
                    from this evaluation and reassign them to this employee for
                    completion 1 year in the future. Completing a goal will
                    complete it and also remove it from this evaluation.
                  </span>
                </div>
              )}
              {goals?.length > 0 && !isEvaluationCompleted && (
                <div style={{ listStyle: "none" }}>
                  {goals?.map((goal, ind) => (
                    <GoalEvalListItem
                      evaluationSectionId={section?.id}
                      goal={goal}
                      goalIndex={ind}
                      handleClick={() => {}}
                      handleComplete={handleGoalCompletion}
                      handleReassign={handleReassignGoal}
                      isCompletedSection={isEvaluationCompleted}
                      readOnlyParent={readOnly}
                    />
                  ))}
                </div>
              )}
              <EvaluationQuestions
                handleInputChange={handleInputChange}
                isEvaluationCompleted={isEvaluationCompleted}
                section={section}
                sectionIndex={i}
              />
            </div>
          );
        }
        return (
          <div key={`${section?.name}-${section?.instructions}-${section?.id}`}>
            <Title size={6}>
              {section?.title}
              {section?.instructions && ` - ${section?.instructions}`}
            </Title>
            <EvaluationQuestions
              handleInputChange={handleInputChange}
              isEvaluationCompleted={isEvaluationCompleted}
              section={section}
              sectionIndex={i}
            />
          </div>
        );
      })}
    </React.Fragment>
  );
};

EvaluationSection.propTypes = {
  isEvaluationCompleted: PropTypes.bool.isRequired,
  inputs: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

EvaluationSection.defaultProps = {
  readOnly: false,
};

export default EvaluationSection;
