import { Control, Field, Label } from "rbx";
import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import {
  DateInput,
  GroupCycleSelect,
  ReviewTemplateSelect,
} from "../../../../components";
import checkMobile from "../../../../utils/checkForMobile";

const ReviewCycleForm = ({ inputs, onChange, readOnly }) => {
  const handleDateInputChange = (e) => {
    const { name, value } = e?.target;
    onChange(name, value?.toISOString());
  };

  const handleChange = (name, value) => {
    onChange(name, value);
  };
  const isMobile = checkMobile();

  return (
    <Field className={isMobile? "grid-override-mobile" :"grid-override is-two"} kind="group">
      <Control>
        <Label>Cycle Name</Label>
        <TextField
          fullWidth
          disabled={readOnly}
          inputProps={{
            sx: {
              height: "0.8rem",
              fontSize: "1.25rem",
            },
          }}
          name="name"
          value={inputs.name}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
      </Control>
      <Control>
        <DateInput
          disabled={readOnly}
          label="Start Date"
          name="startDate"
          size="medium"
          type="date"
          value={inputs.startDate}
          onChange={handleDateInputChange}
        />
      </Control>
      <Control>
        <ReviewTemplateSelect
          showBlank
          disabled={readOnly}
          label="Select Review Template to Use"
          name="templateId"
          size="medium"
          value={inputs.templateId || ""}
          onChange={handleChange}
        />
      </Control>
      <Control>
        <GroupCycleSelect
          showBlank
          disabled={readOnly}
          label="Group Specific Cycle (optional)"
          name="groupCycle"
          size="medium"
          value={inputs.groupCycle || ""}
          onChange={handleChange}
        />
      </Control>
    </Field>
  );
};

ReviewCycleForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ReviewCycleForm.defaultProps = {
  readOnly: false,
};

export default ReviewCycleForm;
