import React from "react";
import PropTypes from "prop-types";
import { Title } from "rbx";
import GoalsListItem from "../GoalListItem";

;

const CompletedGoalsList = ({
  goalsLength,
  handleClick,
  completedGoals,
  setGoals,
  isCompletedSection
}) => (
    <React.Fragment>
      {completedGoals?.map((goal, index) => (
        <React.Fragment key={goal?.id}>
          <GoalsListItem
            isCompletedSection
            goal={goal}
            goalIndex={index}
            handleClick={handleClick}
          />
        </React.Fragment>
      ))}
      {!completedGoals.length  && (
        <div className="centered-title">
          <Title size={6}>
            No Current Completed Goals
          </Title>
        </div>
      )}
    </React.Fragment>
  );

CompletedGoalsList.propTypes = {
  goalsLength: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  completedGoals: PropTypes.array.isRequired,
  setGoals: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool
};

CompletedGoalsList.defaultProps = {
  isCompletedSection: true
};

export default CompletedGoalsList;
