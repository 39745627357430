/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useMemo, useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Image, Navbar as RBXNavbar } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useApolloClient } from "@apollo/client";
import { useAuth } from "../../context";
import "./Navbar.scss";
import { usePermissions } from "../../hooks";
import checkforMobile from "../../utils/checkForMobile";
import { AuthContext } from "../../context/AuthContext";
import {
  SIGNED_FILE_URL_QUERY
} from "../../graphql";

// const { REACT_APP_ENV } = process.env;

// const isProduction =
//   REACT_APP_ENV === "production";
function Navbar() {
  const location = useLocation();

  const path = location.pathname.split("/")[1];

  const isMobile = useMemo(() => checkforMobile(), []);

  const [navBarActive, setNavBarActive] = useState(false);

  const highlight = () => {
    switch (path) {
      case "dashboard":
        return 1;
      case "baseball-card":
        return 2;
      case "partner-services":
        return 3;
      case "partner-assignments":
        return 3;
      case "contractor-assignments":
        return 3;
      case "projects":
        return 4;
      case "reviews":
        return 5;
      case "workshop":
        return 5;
      case "navigator":
        return 5;
      case "review-cycle":
        return 5;
      case "goals":
        return 6;
      case "partner-goals":
        return 6;
      case "allUsersGoals":
        return 6;
      case "settings":
        return 7;
      default:
        return 0;
    }
  }




  // eslint-disable-next-line no-unused-vars
  const [workshopPermissions, setWorkshopPermissions] = useState(false);
  const [partnerServicesPermissions, setPartnerServicesPermissions] =
    useState(false);
  const [assignmentsPermissions, setAssignmentsPermissions] = useState(false);
  const [projectPermissions, setProjectPermissions] = useState(false);
  const [settingPermissions, setSettingPermissions] = useState(false);
  const [allGoalsPermissions, setAllGoalsPermissions] = useState(false);
  const [profileImageS3, setProfileImageS3] = useState("");

  const client = useApolloClient();
  const {
    state: { user },
  } = useContext(AuthContext);

  const { handleLogout } = useAuth();

  const employeeNumber = user?.employeeNumber || "";

  const permissionLevels = usePermissions();

   useEffect(() => {
    if (user?.profileImage) {
      const handleDownloadProfilePicture = async (data) => {
        const { profileImage } = data;
        const picPath = profileImage.split("/")[3];
        const { data: signedFileUrlData } = await client.query({
          query: SIGNED_FILE_URL_QUERY,
          variables: {
            input: {
              objectKey: picPath,
            },
            fetchPolicy: "network-only",
          },
        });
        const { signedFileUrl } = signedFileUrlData;
        setProfileImageS3(signedFileUrl);
      };
     handleDownloadProfilePicture(user);
    }
  }, [user, client]);

  // Permissions
  useEffect(() => {
    if (permissionLevels.webEditor) {
      setWorkshopPermissions(true);
    }

    if (
      permissionLevels.fieldSupervisor ||
      permissionLevels.admin ||
      permissionLevels.superAdmin ||
      permissionLevels.webEditor
    ) {
      setPartnerServicesPermissions(true);
    }

    if (permissionLevels.superAdmin || permissionLevels.webEditor) {
      setAssignmentsPermissions(true);
    }
    if (permissionLevels.webEditor || permissionLevels.superAdmin) {
      setProjectPermissions(true);
    }

    if (permissionLevels.webEditor || permissionLevels.superAdmin) {
      setSettingPermissions(true);
    }
     if (permissionLevels.webEditor) {
      setAllGoalsPermissions(true);
    }
  }, [permissionLevels]);

  const dropdownOptions = useMemo(
    () =>
      [
        settingPermissions && { id: 1, label: "Settings", to: "/settings" },
        { id: 2, label: "Logout", logout: true, to: "/" },
      ].filter(Boolean),
    [settingPermissions]
  );

  const noNavbarRoutes = [
    "login",
    "forgot-password",
    "password-confirmation",
    "reset",
  ];

  if (!noNavbarRoutes.every((p) => !location.pathname.includes(p))) {
    return null;
  }

  return (
    <RBXNavbar active={navBarActive} managed={isMobile}>
      <RBXNavbar.Brand>
        <RBXNavbar.Item as={Link} managed={isMobile} to="/">
          <Image alt="DECCO" className="decco-logo" src="/logo-white.png" />
        </RBXNavbar.Item>
        <RBXNavbar.Item as="div" managed={isMobile} style={{ textDecoration: "none" }}>
          <div
            style={{
              fontSize: "2rem",
              marginLeft: "10px",
            }}
          >
            iDECCO
          </div>
        </RBXNavbar.Item>
        <RBXNavbar.Burger onClick={() => setNavBarActive(!navBarActive)}/>
      </RBXNavbar.Brand>
      <RBXNavbar.Menu>
        <RBXNavbar.Segment align="end" style={{ fontSize: "1rem" }}>
          <RBXNavbar.Item as={Link} className={highlight() === 1 ? "nav-subtitle highlight2" : "nav-subtitle"} managed={isMobile} to="/dashboard" onClick={() => setNavBarActive(false)}>
            Dashboard
          </RBXNavbar.Item>
          <RBXNavbar.Item
            as={Link}
            className={highlight() === 2 ? "nav-subtitle highlight2" : "nav-subtitle"}
            managed={isMobile}
            to={`/baseball-card/${employeeNumber?.trim()}`}
            onClick={() => setNavBarActive(false)}
          >
            Baseball Card
          </RBXNavbar.Item>

          {partnerServicesPermissions && (
            <RBXNavbar.Item
              dropdown
              hoverable
              className={highlight() === 3 ? "nav-subtitle highlight2" : "nav-subtitle"}
              id="hideWhenNotHovered"
              managed={isMobile}
              style={{ marginLeft: "10px", marginRight: "10px" }}
              onClick={() => setNavBarActive(false)}
            >
              Partner Services
              <RBXNavbar.Dropdown>
                <RBXNavbar.Item as={Link} managed={isMobile} to="/partner-services" onClick={() => setNavBarActive(false)}>
                  Partner Services
                </RBXNavbar.Item>
                {assignmentsPermissions && (
                  <React.Fragment>
                    <RBXNavbar.Item as={Link} managed={isMobile} to="/partner-assignments" onClick={() => setNavBarActive(false)}>
                      Partner Assignments
                    </RBXNavbar.Item>
                    <RBXNavbar.Item as={Link} managed={isMobile} to="/contractor-assignments" onClick={() => setNavBarActive(false)}>
                      Contractor Assignments
                    </RBXNavbar.Item>
                  </React.Fragment>
                )}
              </RBXNavbar.Dropdown>
            </RBXNavbar.Item>
          )}
          {projectPermissions && (
            <RBXNavbar.Item as={Link} className={highlight() === 4 ? "nav-subtitle highlight2" : "nav-subtitle"} managed={isMobile} to="/projects" onClick={() => setNavBarActive(false)}>
              Projects
            </RBXNavbar.Item>
          )}
          <RBXNavbar.Item dropdown hoverable className={highlight() === 5 ? "nav-subtitle highlight2" : "nav-subtitle"} id="hideWhenNotHovered">
            Reviews
            <RBXNavbar.Dropdown>
              <RBXNavbar.Item as={Link} managed={isMobile} to="/reviews"onClick={() => setNavBarActive(false)}>
                My Reviews
              </RBXNavbar.Item>
              {workshopPermissions && (
                <React.Fragment>
                  <RBXNavbar.Item as={Link} managed={isMobile} to="/workshop"onClick={() => setNavBarActive(false)}>
                    Workshop
                  </RBXNavbar.Item>
                  <RBXNavbar.Item as={Link} managed={isMobile} to="/navigator/reviews"onClick={() => setNavBarActive(false)}>
                    All Reviews
                  </RBXNavbar.Item>
                  <RBXNavbar.Item as={Link}managed={isMobile} to="/navigator/forms"onClick={() => setNavBarActive(false)}>
                    All Forms
                  </RBXNavbar.Item>
                  <RBXNavbar.Item as={Link} managed={isMobile} to="/review-cycle"onClick={() => setNavBarActive(false)}>
                    Review Cycles
                  </RBXNavbar.Item>
                </React.Fragment>
              )}
            </RBXNavbar.Dropdown>
          </RBXNavbar.Item>
          <RBXNavbar.Item dropdown hoverable className={highlight() === 6 ? "nav-subtitle highlight2" : "nav-subtitle"} id="hideWhenNotHovered">
            Goals
            <RBXNavbar.Dropdown>
              <RBXNavbar.Item
                as={Link}
                managed={isMobile} to={`/goals/${employeeNumber?.trim()}`}
                onClick={() => setNavBarActive(false)}
              >
                My Goals
              </RBXNavbar.Item>
              {/* table with any partners you are the manager of in table and view button that goes to goals/employeeId */}
              {partnerServicesPermissions && (
                <React.Fragment>
                  <RBXNavbar.Item as={Link} managed={isMobile} to={`/partner-goals/${employeeNumber?.trim()}`} onClick={() => setNavBarActive(false)}>
                    Partner Goals
                  </RBXNavbar.Item>
                </React.Fragment>
              )}
               {allGoalsPermissions && (
                <React.Fragment>
                  <RBXNavbar.Item as={Link} managed={isMobile} to="/allUsersGoals" onClick={() => setNavBarActive(false)}>
                    All User Goals
                  </RBXNavbar.Item>
                </React.Fragment>
              )}
            </RBXNavbar.Dropdown>
          </RBXNavbar.Item>
          <RBXNavbar.Item
            className="nav-subtitle"
            href="https://decco.myabsorb.com/#/login"
          >
            DECCO U
          </RBXNavbar.Item>
          <RBXNavbar.Item dropdown hoverable className={highlight() === 7 ? "nav-subtitle highlight2" : "nav-subtitle"} id="hideWhenNotHovered">
            <div className="profile-button">
              {user?.profileImage ? (
                <Image src={profileImageS3} />
              ) : (
                <FontAwesomeIcon icon={faUser} />
              )}
            </div>
            <RBXNavbar.Dropdown align="right">
              {dropdownOptions.map((item) => (
                <RBXNavbar.Item
                  as={Link}
                  className={item.id === 2 ? "logout-item" : ""}
                  key={item.id}
                  managed={isMobile} to={item.to}
                  onClick={() => item.id === 2 ? handleLogout() : setNavBarActive(false)}
                >
                  {item.label}
                </RBXNavbar.Item>
              ))}
            </RBXNavbar.Dropdown>
          </RBXNavbar.Item>
        </RBXNavbar.Segment>
      </RBXNavbar.Menu>
    </RBXNavbar>
  );
}

Navbar.propTypes = {};

export default Navbar;