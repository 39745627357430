import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const QualificationSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  getValue,
  setValue,
  options,
}) => {
  const [formattedOptions, setFormattedOptions] = useState([]);

  useEffect(() => {
    if (options && options.length > 0) {
      const fOptions = options
        .sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }
          if (a.title > b.title) {
            return 1;
          }
          return 0;
        })
        .map((formattedOption) => {
          const formattedTitle =
            formattedOption?.entity === "licenses"
              ? `Title: ${formattedOption?.title} | Level: ${
                  formattedOption?.level || "None"
                } | State: ${formattedOption?.state || "None"}`
              : formattedOption?.title;

          return {
            ...formattedOption,
            formattedTitle,
          };
        });

      const blank = {
        id: "",
        title: "",
        formattedTitle: "",
      };

      fOptions.unshift(blank);

      setFormattedOptions(fOptions);
    }
  }, [options]);

  useEffect(() => {
    if (value === "" && formattedOptions.length > 0) {
      setValue(formattedOptions[0].id);
    }
  }, [setValue, value, formattedOptions, formattedOptions.length]);

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.value, setValue(e.target.value));
  };

  return (
    <Control expanded size={size}>
      {label && <Label>{label}</Label>}
      <Select.Container fullwidth={fullWidth} size={size}>
        <Select
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {formattedOptions.map((option) => (
            <Select.Option key={option?.id} value={option?.id}>
              {option?.formattedTitle}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

QualificationSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  options: PropTypes.array,
};

QualificationSelect.defaultProps = {
  size: "",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  id: null,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
  options: [],
};

export default QualificationSelect;
