import React from "react";
import PropTypes from "prop-types";

import { Control, Field, Input, Textarea, Label } from "rbx";
import { PhoneNumberInput, EmployeeSelect } from "../../../../components";

import "./ProjectForm.scss";

const ProjectForm = ({ project, onChange }) => {
  const handleInputChange = (item) => {
    if (item.target.name === "zip") {
      const value = item.target.value.replace(/\D/g, "");
      onChange(item.target.name, value);
    } else if (item.target.name === "state") {
      const value = item.target.value.replace(/[0-9]/g, "");
      onChange(item.target.name, value);
    } else {
      onChange(item.target.name, item.target.value);
    }
  };

  const handleInputPhoneChange = (name, phoneNumber) =>
    onChange(name, phoneNumber);

  const handleEmployeeSelectChange = (name, employeeName) =>
    onChange(name, employeeName);

  return (
    <React.Fragment>
      <Field className="grid-override is-two" kind="group">
        <Control expanded>
          <Label>Project Number*</Label>
          <Input
            required
            autoComplete="off"
            maxLength={100}
            name="project_num"
            placeholder="Project Number"
            type="text"
            value={project.project_num || ""}
            onChange={handleInputChange}
          />
        </Control>
        <Control expanded>
          <Label>Project Name*</Label>
          <Input
            required
            autoComplete="off"
            maxLength={100}
            name="name"
            placeholder="Project Name"
            type="text"
            value={project.name || ""}
            onChange={handleInputChange}
          />
        </Control>
        <Control expanded>
          <EmployeeSelect
            required
            label="Manager"
            name="manager"
            placeholder="Manager"
            value={project.manager || ""}
            onChange={handleEmployeeSelectChange}
          />
        </Control>
        <Control expanded>
          <EmployeeSelect
            required
            label="Supervisor"
            name="supervisor"
            placeholder="Supervisor"
            value={project.supervisor || ""}
            onChange={handleEmployeeSelectChange}
          />
        </Control>
        <Control expanded>
          <Label>Address*</Label>
          <Input
            required
            autoComplete="off"
            maxLength={100}
            name="address"
            placeholder="Address"
            type="text"
            value={project.address || ""}
            onChange={handleInputChange}
          />
        </Control>
        <Control expanded>
          <Label>City*</Label>
          <Input
            required
            autoComplete="off"
            maxLength={100}
            name="city"
            placeholder="City"
            type="text"
            value={project.city || ""}
            onChange={handleInputChange}
          />
        </Control>
        <Control expanded>
          <Label>State*</Label>
          <Input
            required
            autoComplete="off"
            maxLength={2}
            name="state"
            placeholder="State"
            type="text"
            value={project.state || ""}
            onChange={handleInputChange}
          />
        </Control>
        <Control expanded>
          <Label>Zip*</Label>
          <Input
            required
            autoComplete="off"
            maxLength={5}
            name="zip"
            placeholder="Zip"
            type="text"
            value={project.zip || ""}
            onChange={handleInputChange}
          />
        </Control>
        <Control expanded>
          <PhoneNumberInput
            required
            autoComplete="off"
            name="phone"
            placeholder="Phone"
            type="text"
            value={project.phone || ""}
            onChange={handleInputPhoneChange}
          />
        </Control>
      </Field>
      <Control expanded>
        <Label>Notes</Label>
        <Textarea
          autoComplete="off"
          name="notes"
          placeholder="Notes"
          type="text"
          value={project.notes || ""}
          onChange={handleInputChange}
        />
      </Control>
    </React.Fragment>
  );
};

ProjectForm.propTypes = {
  project: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

ProjectForm.defaultProps = {
  project: {},
};

export default ProjectForm;
