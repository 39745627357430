import { gql } from "@apollo/client/core";

// eslint-disable-next-line import/prefer-default-export
export const GET_MANY_EMPLOYEE_ASSIGNMENTS = gql`
  query GetManyEmployeeAssignments($where: CombinedEmployeeWhereInput!) {
    getManyEmployeeAssignments(where: $where) {
      firstName
      lastName
      jobTitle
      employeeNumber
      projectId
      projectName
      supervisor
      manager
      id
      address
      city
      state
      zip
      distanceToProject
      timeToProject
      distanceValue
    }
  }
`;
