import React from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";

const SwitchInput = ({
  name,
  label,
  size,
  value,
  onChange,
  readOnly,
  disabled,
}) => {
  const handleChange = (e) => {
    onChange(name, e);
  };

  return (
    <Switch
      checked={!!value}
      className="react-switch"
      disabled={disabled}
      handleDiameter={16}
      height={20}
      id={name}
      label={label}
      name={name}
      readOnly={readOnly}
      size="small"
      width={45}
      onChange={handleChange}
    />
  );
};

SwitchInput.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

SwitchInput.defaultProps = {
  name: "",
  size: "",
  label: "",
  value: "",
  onChange: (e) => e,
  readOnly: false,
  disabled: false,
};

export default SwitchInput;
