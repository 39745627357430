import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Box,
  LinearProgress,
  Collapse,
  Divider,
  useTheme,
  Typography,
} from "@mui/material";
import { FileDownload, FilterAlt, FilterAltOff } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { AccordionItem, AccordionDetailsItem } from "../../components";
import PartnerFilters from "./PartnerFilters";

import MobilePartnerServicesCard from "./MobilePartnerServicesCard";
import BoxLoader from "../../components/DetailedDataTable/BoxLoader/BoxLoader";

import { GET_EMPLOYEES_MOBILE } from "../../graphql/employeeDirectory";

const MobilePartnerServices = ({ onExportClick, totalResults }) => {
  const [data, setData] = useState([]);
  // const [take, setTake] = useState(20);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [extraFlags, setExtraFlags] = useState(false);
  const [where, setWhere] = useState({
    employmentStatus: {
      equals: "active",
    },
  });

  const { data: { users: employeesData = [] } = {}, loading } = useQuery(
    GET_EMPLOYEES_MOBILE,
    {
      variables: {
        orderBy: [{ firstName: "asc" }, { lastName: "asc" }],
        where: {
          AND: [where],
        },
      },
    }
  );

  useEffect(() => {
    if (employeesData?.length) {
      setExtraFlags(true);
      setData(employeesData);
      const timout = async () => {
        await setTimeout(() => {
          setExtraFlags(false);
        }, 5000);
      }
      timout();
    }
  }, [employeesData]);


  // const handleScrollChange = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

  //   if (bottom && take < totalResults) {
  //     setTake(take + 20);
  //   }
  // };

  const theme = useTheme();

  return (
    <React.Fragment>
      <Box padding="90px 0px 0px 0px" width="100%">
        {loading && <LinearProgress color="secondary" />}
      </Box>

      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100%"
        overflow="hidden"
        padding="20px 0px 0px 0px"
        width="100svw"
      >
        <Box display="inline-block" width="100%">
          <Box
            display="flex"
            gap={2}
            id="filters-container"
            justifyContent="center"
            mb={2}
            pl={2}
            pr={2}
            sx={{
              overflowX: "hidden",
            }}
            width="100%"
          >
            <Box pl={2} width="100%">
              <Button
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => onExportClick()}
              >
                <FileDownload />
              </Button>
            </Box>
            <Box pr={2} width="100%">
              <Button
                color="primary"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              >
                {isFiltersOpen ? <FilterAltOff /> : <FilterAlt />}
              </Button>
            </Box>
          </Box>
          <Collapse in={isFiltersOpen}>
            <Box mb={4}>
              <PartnerFilters
                loading={loading}
                setWhere={setWhere}
                where={where}
                onExportClick={onExportClick}
              />
            </Box>
          </Collapse>
          {(data?.length === 0 && employeesData?.length === 0 && !loading) && (
            <Box
              alignItems="center"
              bgcolor={theme.palette.grey[400]}
              display="flex"
              height="15rem"
              justifyContent="center"
              mt={2}
              width="100%"
            >
              <Typography variant="h7">No Partners Found</Typography>
            </Box>
          )}
          {((data?.length === 0 || !data) && (loading || extraFlags)) && (
            <Box
              alignItems="center"
              bgcolor={theme.palette.grey[400]}
              display="flex"
              height="15rem"
              justifyContent="center"
              mt={2}
              width="100%"
            >
              <BoxLoader />
            </Box>
          )}
          <Collapse in={!isFiltersOpen}>
            <Box
              display="inline-block"
              height="80svh"
              id="employees-container"
              maxHeight="38rem"
              pr={2}
              sx={{
                textTransform: "uppercase",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              width="100%"
            >
              {data?.map((item) => (
                <AccordionItem
                  img={item?.profileImage?.length > 0 ? item?.profileImage : "none"}
                  key={item?.email}
                  title={`${item?.firstName} ${item?.lastName}`}
                >
                  <AccordionDetailsItem
                    label="Employee #"
                    value={item?.employeeNumber}
                  />
                  <AccordionDetailsItem
                    label="Job Title"
                    value={item?.title || item?.jobTitle}
                  />
                  <AccordionDetailsItem label="Email" value={item?.email} />
                  <Divider
                    sx={{
                      marginTop: "1rem",
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  />

                  <MobilePartnerServicesCard data={item} key={item.email} />
                </AccordionItem>
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </React.Fragment>
  );
};

MobilePartnerServices.propTypes = {
  onExportClick: PropTypes.func,
  totalResults: PropTypes.number.isRequired,
};

MobilePartnerServices.defaultProps = {
  onExportClick: () => {},
};

export default MobilePartnerServices;
