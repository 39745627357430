import React, { useEffect, useState } from "react";
import { Title, Button } from "rbx";
import { useQuery, useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GET_REVIEW_TEMPLATES, CREATE_REVIEW_TEMPLATE } from "./graphql";
import { customToast as toast } from "../../utils";
import "../Reviews/Reviews.scss";
import { useModal } from "../../context";
// import EvaluationForm from "../Reviews/components/EvaluationForm";
import AssignReview from "./AssignReviewModal/AssignReview";
import { Loader, DetailedDataTable, ModalWrapper } from "../../components";
// import ReviewSection from "../Reviews/components/ReviewSection/ReviewSection";
import { REVIEW_TEMPLATE_COLUMNS } from "./columns";
import Details from "../Details/Details";
import FilterComponent from "../../components/DetailedDataTable/SearchFiltersModal";
import ReviewSummaryList from "../ReviewSummaryList/ReviewSummaryList";
import checkMobile from "../../utils/checkForMobile";

const Workshop = () => {
  const { data: ReviewData, loading } = useQuery(GET_REVIEW_TEMPLATES, {
    variables: {
      where: {},
      sectionsOrderBy: [{ order: "asc" }],
      questionsOrderBy: [{ order: "asc" }],
    },
    fetchPolicy: "cache-and-network",
  });
  const { setModalOpen } = useModal();
  const [createReviewTemplate] = useMutation(CREATE_REVIEW_TEMPLATE);
  const [reviews, setReviews] = useState([]);
  const location = useLocation();

  const isMobile = checkMobile();

  useEffect(() => {
    if (ReviewData?.reviewTemplates?.length > 0) {
      setReviews(ReviewData.reviewTemplates);
    }
  }, [ReviewData]);

  const handleSubmit = async () => {
    try {
      await createReviewTemplate({
        variables: {
          data: {
            title: "Test Review Template2",
            year: "2023",
          },
        },
      });
      toast.success("Review Template Created");
    } catch (err) {
      toast.error("An error has occured, please try again later");
    }
  };

  const handleAssignReview = () => {
    setModalOpen(
      true,
      <AssignReview where={{}} onComplete={() => setModalOpen(false, "")} />
    );
  };

  useEffect(() => {
    if (ReviewData?.reviewTemplates?.length > 0) {
      setReviews(ReviewData.reviewTemplates);
    }
  }, [ReviewData]);

  const handleOpenNewWindow = (evaluationId) => {
    const defaultPath = location.pathname.split("/workshop")[0];
    window.open(`${defaultPath}/workshop/?evaluation-template=${evaluationId}`);
  };

  const handleRowClick = (review) => {
    const formattedReview = {
      ...review,
      evaluations: review?.evaluationTemplates,
    };

    setModalOpen(
      true,
      <ModalWrapper
        details={review.title}
        title="Evaluation Templates"
        onComplete={() => setModalOpen(false)}
      >
        <ReviewSummaryList
          isTemplate
          handleClick={handleOpenNewWindow}
          review={formattedReview}
        />
      </ModalWrapper>
    );
  };

  if (loading) {
    return (
      <div className="workshop-container">
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (location.search.includes("evaluation")) {
    return <Details />;
  }

  if (!reviews?.length > 0) {
    return (
      <div
        className={
          isMobile ? "workshop-container-mobile" : "workshop-container"
        }
      >
        <div className="workshop">
          <Title>No Reviews Found</Title>
          <Button onClick={handleSubmit} />
        </div>
      </div>
    );
  }

  return (
    <div
      className={isMobile ? "workshop-container-mobile" : "workshop-container"}
    >
      <div className="workshop">
        <div className="workshop-header">
          <Title>Workshop</Title>
          <Button className="assign-btn" onClick={() => handleAssignReview()}>
            <Title size={6} textColor="white">
              Assign Review Template
            </Title>
          </Button>
        </div>
        <DetailedDataTable
          FilterComponent={FilterComponent}
          aggregateName="aggregateReviewTemplate"
          columns={REVIEW_TEMPLATE_COLUMNS}
          dataExtractor={(data) => data?.reviewTemplates}
          defaultSorting={[{ id: "year", desc: false }]}
          location="workshop"
          name="workshop"
          query={GET_REVIEW_TEMPLATES}
          searchTransformer={(search) => ({})}
          totalExtractor={(total) => total}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

export default Workshop;
