import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";
import { GET_EMPLOYEES_WITH_GROUP } from "../../graphql";
import { uppercaseFirstLetter, employeeNameSort } from "../../utils";

const EmployeeSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  getValue,
  setValue,
  showBlank,
  showAll,
  useName,
  useEmployeeNumber,
}) => {
  // query is already getting only active employees
  const { data: employeesData, loading: employeeDataLoading } =
    useQuery(GET_EMPLOYEES_WITH_GROUP, {
      variables: {},
    });

  const [sortedEmployees, setSortedEmployees] = useState([])
  const [sortedEmployeesByEmployeeNumber, setSortedEmployeesByEmployeeNumber] = useState([])

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const employees = employeesData?.getEmployeesWithGroup;

  useEffect(() => {
    if (
      employees &&
      Array.isArray(employees) &&
      employees?.length > 0){
        if(useEmployeeNumber){
          setSortedEmployeesByEmployeeNumber([...employees].sort((a, b) => parseInt(a.employeeNumber.trim(), 10) - parseInt(b.employeeNumber.trim(), 10)));
        }
        else {
          setSortedEmployees(employeeNameSort([...employees]));
        }
      }
  }, [employees, useEmployeeNumber]);
   

  const handleChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <Control size={size}>
      {label && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        state={employeeDataLoading || Array.isArray(employees) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            width: (useName || useEmployeeNumber) ?  "200px": "", // for filters
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={value}
          onChange={(e) => handleChange(e)}
        >
          {showBlank && <Select.Option value="" />}
          {showAll && <Select.Option value="">All</Select.Option>}
          {(Array.isArray(sortedEmployees) && sortedEmployees.length > 0) || (Array.isArray(sortedEmployeesByEmployeeNumber) && sortedEmployeesByEmployeeNumber.length > 0) &&
            !useEmployeeNumber ? 
             (employeeNameSort(sortedEmployees).map(({ employeeNumber, firstName, lastName }) => (
              <Select.Option
                key={employeeNumber}
                value={useName ? `${firstName} ${lastName}` : employeeNumber}
              >
                {useEmployeeNumber ? employeeNumber : `${uppercaseFirstLetter(firstName)} ${uppercaseFirstLetter(
                  lastName)}`
                }
              </Select.Option>
             )
            ))
            :
            sortedEmployeesByEmployeeNumber.map(({ employeeNumber, firstName, lastName }) => (
              <Select.Option
                key={employeeNumber}
                value={useName ? `${firstName} ${lastName}` : employeeNumber}
              >
                {useEmployeeNumber ? employeeNumber : `${uppercaseFirstLetter(firstName)} ${uppercaseFirstLetter(
                  lastName)}`
                }
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

EmployeeSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
  showAll: PropTypes.bool,
  useName: PropTypes.bool,
  useEmployeeNumber: PropTypes.bool,
};

EmployeeSelect.defaultProps = {
  size: "normal",
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  showBlank: false,
  showAll: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
  useName: false,
  useEmployeeNumber: false,
};

export default EmployeeSelect;
