import React from "react";
import PropTypes from "prop-types";
import { Image } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



const Avatar = ({ employeeData, profileImageS3 }) => (
    <div className="bball-card-avatar-container">
      <div className="bball-card-image-box">
        {profileImageS3?.length > 0 || employeeData?.profileImage?.length > 0 ? (
          <Image src={profileImageS3} />
        ) : (
          <FontAwesomeIcon icon="user" />
        )}
      </div>
    </div>
  )


Avatar.propTypes = {
  employeeData: PropTypes.object,
  profileImageS3: PropTypes.string,
};

Avatar.defaultProps = {
  employeeData: {},
  profileImageS3: "",
};

export default Avatar;
