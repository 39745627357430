import { gql } from "@apollo/client";

export const CREATE_EMPLOYEE_RELATION_MUTATION = gql`
  mutation CreateOneEmployeeRelations($data: EmployeeRelationsCreateInput!) {
    createOneEmployeeRelations(data: $data) {
      id
    }
  }
`;

export const UPDATE_EMPLOYEE_RELATION_MUTATION = gql`
  mutation UpdateEmployeeRelations(
    $jobTitle: String!
    $supervisor: String!
    $group: String!
  ) {
    updateEmployeeRelations(
      jobTitle: $jobTitle
      supervisor: $supervisor
      group: $group
    )
  }
`;

export const DELETE_EMPLOYEE_RELATION_MUTATION = gql`
  mutation DeleteEmployeeRelations($jobTitle: String!) {
    deleteEmployeeRelations(jobTitle: $jobTitle)
  }
`;
