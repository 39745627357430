import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_REVIEW_CYCLE = gql`
  mutation CreateOneReviewCycle($data: ReviewCycleCreateInput!) {
    createOneReviewCycle(data: $data) {
      groupCycle
      id
      name
      startDate
      templateId
    }
  }
`;

export const UPDATE_REVIEW_CYCLE = gql`
  mutation UpdateOneReviewCycle(
    $data: ReviewCycleUpdateInput!
    $where: ReviewCycleWhereUniqueInput!
  ) {
    updateOneReviewCycle(data: $data, where: $where) {
      id
      name
    }
  }
`;

export const DELETE_REVIEW_CYCLE = gql`
  mutation DeleteOneReviewCycle($where: ReviewCycleWhereUniqueInput!) {
    deleteOneReviewCycle(where: $where) {
      id
      name
    }
  }
`;
