import React, { useState, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { useTheme, useMediaQuery } from "@mui/material";
import { Loader, FiltersContainer } from "../../components";
import { EmployeeAssignmentsTable } from "./components";
import generateColumns from "./columns";
import {
  GET_MANY_EMPLOYEE_ASSIGNMENTS,
  ASSIGN_PROJECT,
  CREATE_PROJECT_HISTORY_ENTRY,
} from "../../graphql";
import { downloadLocalCsv, uppercaseFirstLetter } from "../../utils";

import { GET_USER_FULL_NAME } from "./graphql";
import { useCurrentUser } from "../../hooks";

import MobileEmployeeAssignments from "./MobileEmployeeAssignments";
import EmployeeAssignmentFilters from "./EmployeeAssignmentFilters";

const DEFAULT_WHERE = {
  fullName: "",
  jobTitle: "",
  employeeNumber: "",
  projectId: "",
  isFilterEmployee: false,
  isGetDistanceQuery: false,
};

const EmployeeAssignments = () => {
  const [inputs, setInputs] = useState(DEFAULT_WHERE);
  const user = useCurrentUser();

  const {
    data: { getManyEmployeeAssignments: employeeAssignmentData = [] } = {},
    loading: employeeLoading,
    refetch: refetchEmployeeAssignments,
  } = useQuery(GET_MANY_EMPLOYEE_ASSIGNMENTS, {
    variables: {
      where: {
        fullName: inputs.fullName,
        jobTitle: inputs.jobTitle,
        employeeNumber: inputs.employeeNumber,
        projectId: inputs.projectId,
        isFilterEmployee: inputs.isFilterEmployee,
        certifications: inputs.certifications,
        skills: inputs.skills,
        deccoUCourses: inputs.deccoUCourses,
        licenses: inputs.licenses,
        licenseState: inputs.licenseState,
        licenseLevel: inputs.licenseLevel,
        isGetDistanceQuery: inputs.isGetDistanceQuery,
      },
    },
    fetchPolicy: "network-only",
    awaitRefetchQueries: true,
  });

  const [assignProject] = useMutation(ASSIGN_PROJECT, {
    onCompleted: async () => {
      await refetchEmployeeAssignments();

      toast.success("Project assigned successfully");
    },
    onError: () => {
      toast.error("Something went wrong. Please contact support ");
    },
  });

  const { data: UserData } = useQuery(GET_USER_FULL_NAME, {
    variables: {
      where: {
        id: user?.id,
      },
    },
  });

  const [createProjectHistoryEntry] = useMutation(CREATE_PROJECT_HISTORY_ENTRY);

  const handleProjectChange = async (empNum, projId, projName) => {
    await createProjectHistoryEntry({
      variables: {
        data: {
          projectId: projId,
          projectName: projName,
          updatedAt: new Date().toISOString(),
          updatedBy: user?.employeeNumber,
          updatedByFullName: `${UserData?.user?.firstName} ${UserData?.user?.lastName}`,
          user: {
            connect: {
              employeeNumber: empNum,
            },
          },
        },
      },
    });

    await assignProject({
      variables: {
        projectId: projId,
        employeeNumber: empNum,
      },
    });
  };

  const COLUMNS = useMemo(
    () =>
      generateColumns(
        (empNum, projId, projName) =>
          handleProjectChange(empNum, projId, projName),
        inputs.isGetDistanceQuery
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignProject, employeeAssignmentData, inputs.isGetDistanceQuery]
  );

  const handleFiltersChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const exportCSV = async () => {
    const headers = [
      {
        Header: "Employee Number",
        accessor: "employeeNumber",
      },
      {
        Header: "First Name",
        accessor: "firstName",
        Cell: ({ cell }) => uppercaseFirstLetter(cell?.value),
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        Cell: ({ cell }) => uppercaseFirstLetter(cell?.value),
      },
      {
        Header: "Job Title",
        accessor: "jobTitle",
      },
      {
        Header: "Project Name",
        accessor: "projectName",
      },
      {
        Header: "Supervisor",
        accessor: "supervisor",
      },
      {
        Header: "Manager",
        accessor: "manager",
      },
      {
        Header: "Project Distance",
        accessor: "projectDistance",
      },
    ];

    const getCleanJobTitle = (jobTitle) => {
      if (jobTitle.includes("-")) {
        return jobTitle.split("-")[0];
      }
      // U+2013 "–" can be confused with the ASCII character U+002d ("-")
      // but they are different
      if (jobTitle.includes("–")) {
        return jobTitle.split("–")[0];
      }
      return jobTitle;
    };

    const dataToExport = employeeAssignmentData.map((emp) => ({
      employeeNumber: emp.employeeNumber,
      firstName: `${emp.firstName}`,
      lastName: `${emp.lastName}`,
      jobTitle: `${getCleanJobTitle(emp.jobTitle)}`,
      projectName: emp.projectName,
      supervisor: emp?.supervisor,
      manager: emp?.manager,
      projectDistance:
        emp.distanceToProject && emp.timeToProject
          ? `${emp.distanceToProject}, ${emp.timeToProject}`
          : "Not Available",
    }));

    try {
      const headersWithoutProjectdistance = headers.filter(
        (header) => header.accessor !== "projectDistance"
      );

      downloadLocalCsv(
        dataToExport,
        "Employee Assignments",
        inputs?.isGetDistanceQuery ? headers : headersWithoutProjectdistance
      );
    } catch (err) {
      toast.error("Error exporting CSV");
    }
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm && employeeAssignmentData) {
    return (
      <MobileEmployeeAssignments
        employeeAssignmentData={employeeAssignmentData}
        handleFiltersChange={handleFiltersChange}
        handleProjectChange={handleProjectChange}
        inputs={inputs}
        loading={employeeLoading}
        onExportClick={exportCSV}
      />
    );
  }

  return (
    <div className="emp-assignments-container">
      <div className="emp-assignments-wrapper">
        <FiltersContainer onExportClick={exportCSV}>
          <EmployeeAssignmentFilters
            handleFiltersChange={handleFiltersChange}
            inputs={inputs}
          />
        </FiltersContainer>

        {employeeLoading ? (
          <div style={{ position: "absolute", top: "40vh", left: "45vw" }}>
            <Loader />
          </div>
        ) : (
          <EmployeeAssignmentsTable
            columns={COLUMNS}
            data={employeeAssignmentData}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeeAssignments;
