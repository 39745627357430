import React from "react";
import PropTypes from "prop-types";
import "./Loader.scss";

const Loader = ({ size }) => (
  <div className="loader-wrapper">
    <div className="logo-wrapper">
      <span className="logo-text">DECCO</span>
      <div className="lds-spinner">
        {[...Array(19)].map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i} />
        ))}
      </div>
      <div className="logo-text">Loading...</div>
    </div>

  </div>
);

Loader.propTypes = {
  size: PropTypes.string,
};

Loader.defaultProps = {
  size: "",
};

export default Loader;
