import React from "react";

const EmailConfirmation = () => (
  <div className="confirmation-container">
    <div className="confirmation-wrapper">
      <div className="logo">
        <img alt="DECCO" className="decco-logo" src="/logo-white.png" />
      </div>
      <div className="text-wrapper">
        <h1>Thank You!</h1>
        <p>We sent an email to your account!</p>
      </div>
    </div>
  </div>
);

export default EmailConfirmation;
