/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useAuth } from "../../context";

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const {
    state: { user },
  } = useAuth();
  const { pathname } = useLocation();

  const permissionLevel = user?.userSecurities[0] || "user";
  
  const checkPermissions = () => {
    if (allowedRoles.includes(permissionLevel)) {
      return true;
    }
    // user level permissions can only access their own baseball card
    if (
      permissionLevel === "user" &&
      pathname.includes("baseball-card") &&
      user.employeeNumber === pathname.split("/").at(-1)
    ) {
      return true;
    }
    return false;
  };

  const isAuth = checkPermissions();

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

export default ProtectedRoute;

ProtectedRoute.propTypes = {
  component: PropTypes.any.isRequired,
  allowedRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};
