/* eslint-disable react/prop-types */
import format from "date-fns/format";
import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValid } from "date-fns";

export const DEFAULT_CERTIFICATION_COLUMNS = [
  {
    accessor: "codes.title",
    Header: "Title",
  },

  {
    accessor: "codes.points",
    Header: "Points",
  },
  {
    accessor: "issueDate",
    Header: "Issue Date",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell?.value), "MM/dd/yy") : "--",
  },
  {
    id: "expDate",
    Header: "Expiration Date",
    accessor: "expDate",
    accessorFn: (row) => {
      if (!row?.expDate || row?.codes?.expirationType === "none") return "--";

      if (row?.expDate && isValid(row.expDate) && row.expDate < new Date()) {
        return (
          <span style={{ color: "red" }}>
            {format(new Date(row?.expDate), "MM/dd/yy")}
          </span>
        );
      }

      return format(new Date(row?.expDate), "MM/dd/yy");
    },
  },

  {
    accessor: "certificationNumber",
    Header: "Certifcation Number",
  },
];

export const DEFAULT_CERTIFICATION_COLUMNS_2 = [
  {
    accessor: "codes.title",
    Header: "Title",
  },

  {
    accessor: "codes.points",
    Header: "Points",
  },
  {
    accessor: "issueDate",
    Header: "Issue Date",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell?.value), "MM/dd/yy") : "--",
  },
  {
    id: "expDate",
    Header: "Expiration Date",
    accessor: "expDate",
    Cell: ({ cell, row }) => {
      if (!row?.original?.expDate || row?.original?.codes?.expirationType === "none") return "--";

      if (row?.original?.expDate && isValid(row?.original?.expDate) && row?.original?.expDate < new Date()) {
        return (
          <span style={{ color: "red" }}>
            {format(new Date(row?.original?.expDate), "MM/dd/yy")}
          </span>
        );
      }

      return format(new Date(row?.original?.expDate), "MM/dd/yy");
    },
  },

  {
    accessor: "certificationNumber",
    Header: "Certifcation Number",
  },
];

export const DEFAULT_LICENSE_COLUMNS = [
  {
    accessor: "codes.title",
    Header: "Title",
  },
  {
    accessor: "codes.level",
    Header: "Level",
  },
  {
    accessor: "codes.state",
    Header: "State",
  },

  {
    accessor: "codes.points",
    Header: "Points",
  },
  {
    accessor: "issueDate",
    Header: "Issue Date",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell?.value), "MM/dd/yy") : "--",
  },
  {
    accessor: "expDate",
    Header: "Expiration Date",
    Cell: ({ cell }) => {
      if (!cell?.value) return "--";

      if (cell?.value && isValid(cell.value) && cell.value < new Date()) {
        return (
          <span style={{ color: "red" }}>
            {format(new Date(cell?.value), "MM/dd/yy")}
          </span>
        );
      }

      return format(new Date(cell?.value), "MM/dd/yy");
    },
  },
  {
    accessor: "licenseNumber",
    Header: "License Number",
  },
];

export const DEFAULT_SKILLS_COLUMNS = [
  {
    accessor: "codes.title",
    Header: "Title",
  },
  {
    accessor: "codes.points",
    Header: "Points",
  },
  {
    accessor: "issueDate",
    Header: "Issue Date",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell?.value), "MM/dd/yy") : "--",
  },
];

export const DEFAULT_DECCO_U_COLUMNS = [
  {
    Header: "Title",
    accessor: "codes.title",
  },

  {
    Header: "Completed",
    accessor: "issueDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell?.value), "MM/dd/yy") : "--",
  },
];

export const generateColumns = (handleDelete, refetch, columns) => {
  const isDeccoU = columns?.find((c) => c.Header === "Completed");
  return [
    {
      Header: "",
      accessor: "id",
      Cell: ({ cell, row }) => (
        <Generic
          style={{ textAlign: "center", cursor: "pointer" }}
          title="Delete"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            const qualificationType = row.original.codes.entity;
            handleDelete(cell.value, qualificationType);
            refetch();
          }}
        >
          <Icon color={isDeccoU ? "primary" : "danger"}>
            <FontAwesomeIcon icon="times" />
          </Icon>
        </Generic>
      ),
    },
    ...columns,
  ];
};
