import React, { useEffect, useMemo, useState } from "react";
import { Title } from "rbx";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Button as MuiButton, Typography } from "@mui/material";
import { GET_ALL_REVIEW_CYCLES } from "./graphql";
import "../Reviews/Reviews.scss";
import { useModal } from "../../context";

import { Loader, DetailedDataTable } from "../../components";

import { REVIEW_CYCLE_COLUMNS } from "./columns";
import Details from "../Details/Details";
import ReviewCycleModal from "./components/ReviewCycleModal/ReviewCycleModal";
// import { omit } from "../../utils";
import FilterComponent from "../../components/DetailedDataTable/SearchFiltersModal";
import checkMobile from "../../utils/checkForMobile";

const ReviewCycle = () => {
  const { setModalOpen } = useModal();

  const [paginationWhere, setPaginationWhere] = useState({}); // e

  // const { data: ReviewCycleData, loading } = useQuery(GET_ALL_REVIEW_CYCLES, {
  //   variables: {},
  //   fetchPolicy: "network-only",
  // });

  const {
    data: ReviewCycleData,
    loading,
    refetch,
  } = useQuery(GET_ALL_REVIEW_CYCLES, {
    variables: {
      where: paginationWhere,
    },
    fetchPolicy: "cache-and-network",
  });

  const [reviewCycles, setReviewCycles] = useState([]);
  // const [searchValue, setSearchValue] = useState("");

  const location = useLocation();

  const memoizedColumns = useMemo(() => REVIEW_CYCLE_COLUMNS, []);
  const isMobile = checkMobile();

  useEffect(() => {
    if (ReviewCycleData?.reviewCycles) {
      setReviewCycles(ReviewCycleData.reviewCycles);
    }
  }, [ReviewCycleData]);

  // const searchTransformer = useCallback((where, searchTerm) => {
  //   setSearchValue(searchTerm);
  //   const result = omit(where, ["OR"]);

  //   if (searchTerm) {
  //     result.OR = [
  //       {
  //         name: { contains: searchTerm },
  //       },
  //     ];
  //   }

  //   return result;
  // }, []);

  const handleOpenReviewCycleModal = (reviewCycle) => {
    const isReadOnly = reviewCycle.name && true;

    setModalOpen(
      true,
      <ReviewCycleModal
        readOnly={isReadOnly}
        refetchReviewCycleList={refetch}
        reviewCycle={reviewCycle || {}}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  if (loading) {
    return (
      <div className="review-cycle-container">
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (location.search.includes("evaluation")) {
    return <Details />;
  }

  if (!reviewCycles?.length > 0) {
    return (
      <div
        className={
          isMobile ? "review-cycle-container-mobile" : "review-cycle-container"
        }
      >
        <div className="review-cycle">
          <Title>No Review Cycles Found</Title>
          <MuiButton
            className="review-cycle-btn"
            sx={{ padding: "0.5rem" }}
            variant="contained"
            onClick={handleOpenReviewCycleModal}
          >
            <Typography color="common.white" variant="h8">
              Add Review Cycle
            </Typography>
          </MuiButton>
        </div>
      </div>
    );
  }

  return (
    <div
      className={
        isMobile ? "review-cycle-container-mobile" : "review-cycle-container"
      }
    >
      <div className="review-cycle">
        <div className="review-cycle-header">
          <Title>Review Cycle</Title>
          <MuiButton
            className="review-cycle-btn"
            sx={{ padding: "0.5rem" }}
            variant="contained"
            onClick={handleOpenReviewCycleModal}
          >
            <Typography color="common.white" variant="h8">
              Add Review Cycle
            </Typography>
          </MuiButton>
        </div>

        <DetailedDataTable
          FilterComponent={FilterComponent}
          aggregateName="aggregateReviewCycle"
          columns={memoizedColumns}
          dataExtractor={(data) => data?.reviewCycles}
          defaultSorting={[{ id: "name", desc: false }]}
          location="reviewCycles"
          name="review-cycles"
          query={GET_ALL_REVIEW_CYCLES}
          searchTransformer={(search) => ({})}
          setPaginationWhere={setPaginationWhere}
          totalExtractor={(total) => total}
          onRowClick={(row) => handleOpenReviewCycleModal(row)}
        />
      </div>
    </div>
  );
};

export default ReviewCycle;
