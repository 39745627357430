import React from "react";
import { Stack } from "@mui/material";
import PropTypes from "prop-types";

const ActionComponentWrapper = ({ children }) => (
  <Stack
    alignItems="center"
    bgcolor="#052e6f"
    borderRadius="1rem"
    boxShadow={2}
    direction="row"
    height="100%"
    paddingX={2}
    position="absolute"
    spacing={1}
    width="100%"
    zIndex={1}
  >
    {children}
  </Stack>
);

ActionComponentWrapper.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ActionComponentWrapper;
