import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_EMPLOYEES = gql`
  query GET_EMPLOYEES(
    $where: EmployeeWhereInput
    $orderBy: [EmployeeOrderByWithRelationInput!]
  ) {
    employees(where: $where, orderBy: $orderBy) {
      employeeNumber
      firstName
      lastName
    }
  }
`;

export const GET_COMBINED_EMPLOYEE = gql`
  query GET_COMBINED_EMPLOYEE($where: UserWhereInput) {
    getCombinedEmployee(where: $where) {
      id
      employeeNumber
      firstName
      lastName
      emailAddress
      jobTitle
      middleInitial
      dateOfBirth
      points
      licenses {
        expDate
        id
        issueDate
        licenseNumber
        codes {
          id
          entity
          points
          title
          level
          state
        }
      }
      certifications {
        id
        expDate
        issueDate
        certificationNumber
        codes {
          points
          entity
          expirationType
          title
          id
          level
          state
        }
      }
      skills {
        id
        issueDate
        codes {
          points
          entity
          expirationType
          id
          title
          level
          state
        }
      }
      deccoUCourses {
        id
        issueDate
        codes {
          points
          entity
          title
          expirationType
          id
          level
          state
        }
      }
      employeeRelations {
        id
        group
        title
        supervisor
      }
      profileImage
      projectId
      tshirtSize
      userType
      yearsOfServiceNote
      address
      city
      state
      zip
      yearsOfService
      averageCompanyPoints {
        averageCompanyPoints
      }
    }
  }
`;

export const GET_BASEBALL_CARD_EMPLOYEE = gql`
  query getEmployeeBaseballCard($where: UserWhereInput) {
    getEmployeeBaseballCard(where: $where) {
      id
      employeeNumber
      firstName
      lastName
      email
      projectId
      jobTitle
      middleInitial
      dateOfBirth
      points
      group
      supervisorObj {
        employeeNumber
        name
        jobTitle
      }
      licenses {
        expDate
        id
        issueDate
        licenseNumber
        codes {
          id
          entity
          points
          title
          level
          state
        }
      }
      certifications {
        id
        expDate
        issueDate
        certificationNumber
        codes {
          points
          entity
          expirationType
          title
          id
          level
          state
        }
      }
      skills {
        id
        issueDate
        codes {
          points
          entity
          expirationType
          id
          title
          level
          state
        }
      }
      deccoUCourses {
        id
        issueDate
        codes {
          points
          entity
          title
          expirationType
          id
          level
          state
        }
      }
      profileImage
      tshirtSize
      userType
      yearsOfServiceNote
      totalYearsOfService
    }
  }
`;

export const GET_ALL_BASEBALL_CARD_EMPLOYEES = gql`
  query getAllEmployeeBaseballCards($where: UserWhereInput) {
    getAllEmployeeBaseballCards(where: $where) {
      id
      employeeNumber
      firstName
      lastName
      points
      cerPoints
      licPoints
      skillPoints
      group
    }
  }
`;

export const GET_AVERAGE_COMPANY_POINTS = gql`
  query AverageCompanyPoints {
    averageCompanyPoints {
      averageCompanyPoints
    }
  }
`;

export const GET_EMPLOYEES_WITH_GROUP = gql`
  query GetEmployeesWithGroup {
    getEmployeesWithGroup {
      employeeNumber
      firstName
      group
      costCode
      id
      jobTitle
      lastName
      totalYearsOfService
      yearsOfServiceNote
      email
    }
  }
`;

export const GET_ALPHA_LIST = gql`
  query GET_ALPHA_LIST($orderBy: [UserOrderByWithRelationInput!], $where: UserWhereInput) {
    users(orderBy: $orderBy, where: $where) {
      id
      employeeNumber
      firstName
      lastName
      costCode
      jobTitle
      group
      userType
    }
  }
`;

export const FIND_FIRST_AVERAGE_POINTS = gql`
  query FIND_FIRST_AVERAGE_POINTS(
    $orderBy: [AveragePointsOrderByWithRelationInput!]
  ) {
    findFirstAveragePoints(orderBy: $orderBy) {
      id
      points
      timeStamp
    }
  }
`;

export const GET_EMPLOYEE_PROJECT_ASSIGNMENT_NAME = gql`
  query GetManyEmployeeAssignments($where: CombinedEmployeeWhereInput!) {
    getManyEmployeeAssignments(where: $where) {
      projectName
      projectId
    }
  }
`;

export const SIGNED_FILE_URL_QUERY = gql`
  query SIGNED_FILE_URL_QUERY($input: SignedFileUrlInput!) {
    signedFileUrl(input: $input)
  }
`;