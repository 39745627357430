/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
const omitBy = (obj, fn) =>
  Object.keys(obj)
    .filter((k) => !fn(obj[k], k))
    .reduce(
      (acc, key) => ((acc[key] = obj[key]), acc),
      {},
    );

export default omitBy;
