import React, { useState, useEffect } from "react";

import Select from "react-select";

import PropTypes from "prop-types";

import { format } from "date-fns";

import { useQuery } from "@apollo/client";

import { Title, Button, Field, Control, Label, Content } from "rbx";
import { initialValue } from "rbx/components/dropdown/dropdown-context";

import { DateInput, Loader } from "../../../../components";
import { FIND_MANY_CODES } from "../../../../graphql/codes/codes.queries";

const SkillsForm = ({ onComplete, onCancel, initialInputs = {}, employeeData = {}, mobile }) => {
  const [inputs, setInputs] = useState({});
  const [employeeSkills, setEmployeeSkills] = useState([]);
  const [repeatSkill, setRepeatSkill] = useState(null);
  const { data: { findManyCodes: options } = {}, loading } = useQuery(
    FIND_MANY_CODES,
    {
      variables: {
        where: {
          entity: {
            equals: "skills",
          },
        },
        orderBy: [
          {
            title: "asc",
          },
        ],
      },
    }
  );

  useEffect(() => {
    if (employeeData?.skills?.length > 0) {
      const skillIds = employeeData?.skills?.map((s) => ({skillId: s?.id, codeId: s?.codes?.id}));
      setEmployeeSkills(skillIds);
    }
  }, [employeeData]);

  useEffect(() => {
    if (inputs?.value && employeeSkills?.length > 0) {
      const oldSkill = employeeSkills.find((s) => (s?.codeId === inputs?.value));
      if (oldSkill) {
        setRepeatSkill(oldSkill?.skillId);
      } else {
        setRepeatSkill(null);
      }
    }
  }, [inputs, inputs.value, employeeSkills]);

  useEffect(() => {
    if (initialInputs?.id) {
      setInputs({
        expDate: initialInputs?.expDate || format(new Date(), "yyyy-MM-dd"),
        issueDate: initialInputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
        title: initialInputs?.title || "",
        expirationType: initialInputs?.expirationType || "manual",
      });
    }
  }, [initialInputs]);

  const handleChange = (e) => {
    const name = e?.target?.name || e?.name;
    const value = e?.target?.value || e?.value;
    setInputs({ ...inputs, [name]: value });
  };

  const handleSkillChange = (e) => {
    setInputs({ ...inputs, ...e });
  };

  const handleBuildNewSkill = () => {
    if(repeatSkill) {
        // treat as update
      const updateData = {
        skills: {
          update: [
            {
              data: {
                issueDate: {
                  set: inputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
                },
              },
              where: {
                id: repeatSkill,
              },
            },
          ],
        },
      };
      if (inputs?.expirationType === "manual") {
        updateData.skills.update[0].data.expDate = {
          set: inputs?.expDate || format(new Date(), "yyyy-MM-dd"),
        };
      }
      return updateData;
    } 
    const data = {
      skills: {
        create: [
          {
            codes: {
              connect: {
                id: inputs?.value,
              },
            },
            issueDate: inputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
          },
        ],
      },
    };

    if (inputs?.expirationType === "manual") {
      data.skills.create[0].expDate = 
        inputs?.expDate || format(new Date(), "yyyy-MM-dd");
    }
    return data;
  
  };

  const handleUpdateSkill = () => {
    const d = {
      skills: {
        update: [
          {
            data: {
              issueDate: {
                set: inputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
              },
            },
            where: {
              id: initialInputs?.id,
            },
          },
        ],
      },
    };

    if (inputs?.expirationType === "manual") {
      d.skills.update[0].data.expDate = {
        set: inputs?.expDate || format(new Date(), "yyyy-MM-dd"),
      };
    }

    return d;
  };

  const handleComplete = () => {
    if (initialInputs?.id) {
      onComplete(handleUpdateSkill());
    } else {
      onComplete(handleBuildNewSkill());
    }
  };

  if (loading && options?.length) return <Loader />;

  return (
    <div
      id="SkillsForm"
      style={{
        minHeight: "60vh",
        overflow: "auto",
      }}
    >
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>
            {initialInputs?.id ? "Update" : "Create"} Skill
          </Title>
        </div>
         {mobile && (
          <br />
        )}
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={() => onCancel()}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!inputs?.value && !initialInputs?.id}
              form="company-form"
              size="small"
              onClick={handleComplete}
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field
        style={{
          display: "flex",
          flexDirection: "column",
          justifContent: "stretch",
          alignItems: "stretch",
        }}
      >
        <Control>
          <Label textSize={5}>Title:</Label>
          {initialInputs?.id ? (
            <Content size="medium">{`${inputs?.title}`}</Content>
          ) : (
            <div style={{ width: "100%" }}>
              {repeatSkill && (
                <Content size="medium" textColor="danger">
                  This skill is already assigned to this employee. Adding another will replace the old skill.
                </Content>
              )}
              <Select
                options={options?.map((o) => ({
                  value: o?.id,
                  label: `${o?.title}`,
                  ...o,
                }))}
                value={inputs || initialValue}
                onChange={(e) => {
                  handleSkillChange(e);
                }}
              />
            </div>
          )}
        </Control>
        <Field
          style={{
            display: "flex",
            flexDirection: mobile ? "column": "row",
            justifyContent: "flex-start",
            gap: "1rem",
          }}
        >
          <Control>
            <Label textSize={5}>Issue Date:</Label>
            <DateInput
              name="issueDate"
              type="date"
              value={inputs?.issueDate || format(new Date(), "yyyy-MM-dd")}
              onChange={(e) => handleChange(e)}
            />
          </Control>

          {(inputs?.expirationType === "manual" ||
            inputs?.expirationType === "manual") && (
            <Control>
              <Label textSize={5}>Expiration Date:</Label>
              <DateInput
                name="expDate"
                type="date"
                value={inputs.expDate || format(new Date(), "yyyy-MM-dd")}
                onChange={(e) => handleChange(e)}
              />
            </Control>
          )}
        </Field>
      </Field>
    </div>
  );
};

SkillsForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialInputs: PropTypes.object,
  employeeData: PropTypes.object,
  mobile: PropTypes.bool,
};

SkillsForm.defaultProps = {
  initialInputs: {},
  employeeData: {},
  mobile: false,
};

export default SkillsForm;
