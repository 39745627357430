import React, { useEffect, useState, useRef } from "react";
import { Button, Title, Icon } from "rbx";
import PropTypes from "prop-types";
import { useReactToPrint } from 'react-to-print';
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import EvaluationSection from "../EvaluationSection";
import {
  GET_USER_REVIEWS,
  UPDATE_ONE_REVIEW,
  UPDATE_ONE_EVALUATION,
  UPDATE_ONE_EVALUATION_QUESTION,
  GET_USER,
  GET_REVIEW
} from "../../graphql";

import { Loader } from "../../../../components";
import formatDate from "../../../../utils/formatDate";
import { useAuth } from "../../../../context";
import capitalizeEveryWord from "../../../../utils/capitalizeEveryWord";
// import PrintEvaluation from "../PrintEvaluation";
import "./EvaluationForm.scss";

const EvaluationForm = ({
  employeeNumber,
  evaluation,
  evaluations,
  currentReviewId,
  onComplete,
  loading,
  isSigning,
  onCanceled,
  refetchMyReviews,
  key
}) => {
  const [inputs, setInputs] = useState(evaluation);

  const [signature, setSignature] = useState("");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });
  // passed down evals seem inconsistent to non existent, so we are refetching the review to get the evals. Will give more up to date info anyways
  const [reviewEvaluations, setReviewEvaluations] = useState([]);

  const auth = useAuth();

  const activeUserEmployeeNumber = auth?.state?.user?.employeeNumber;
  const activeUserFullName = `${auth?.state?.user?.firstName} ${auth?.state?.user?.lastName}`;
  // const [printMode, setPrintMode] = useState(false);
  const [updateOneReview, { loading: loadingReview }] =
    useMutation(UPDATE_ONE_REVIEW);
  const [updateOneEvaluation, { loading: loadingEvaluation }] = useMutation(
    UPDATE_ONE_EVALUATION
  );
  const [updateOneEvaluationQuestion] = useMutation(
    UPDATE_ONE_EVALUATION_QUESTION
  );

  const { data: UserData, loadingUserData } = useQuery(GET_USER, {
    variables: {
      where: {
        employeeNumber: evaluation?.signedUserId || "",
      },
    },
  });

  const { data: ReviewData, loadingReviewData } = useQuery(GET_REVIEW, {
    variables: {
      where: {
        id: currentReviewId,
      },
    },
  });

  useEffect(() => {
    if (ReviewData) {
      setReviewEvaluations(ReviewData?.review?.evaluations);
    }
  }, [ReviewData]);

  const approversFullName = `${UserData?.user?.firstName} ${UserData?.user?.lastName}`;

  const isEvaluationCompleted = evaluation?.status === "COMPLETED";

  const handleInputChange = (e, value) => {
    const n = e?.target?.name || e;
    const v = e?.target?.value || value;

    const sectionIndex = n?.split("-")[0];
    const questionIndex = n?.split("-")[1];

    const updatedQuestion = {
      ...inputs?.sections[sectionIndex]?.questions[questionIndex],
      answer: v instanceof Date ? v.toISOString() : v,
    };

    const newQuestions = inputs?.sections[sectionIndex]?.questions?.map(
      (question, i) =>
        i === Number(questionIndex) ? updatedQuestion : question
    );

    const updatedSection = {
      ...inputs?.sections[sectionIndex],
      questions: newQuestions,
    };

    const newSections = inputs?.sections?.map((section, i) =>
      i === Number(sectionIndex) ? updatedSection : section
    );

    setInputs({
      ...inputs,
      sections: newSections,
    });
  };

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const timeStamp = new Date().toISOString();

      const questions = inputs?.sections
        ?.map((section) => section.questions)
        .flat();

      await Promise.all(
        questions.map((question) =>
          updateOneEvaluationQuestion({
            variables: {
              where: {
                id: question.id,
              },
              data: {
                answer: {
                  set: question.answer || "",
                },
              },
            },
          })
        )
      );

      await updateOneEvaluation({
        variables: {
          where: {
            id: inputs.id,
          },
          data: {
            status: {
              set: "SAVE_FOR_LATER",
            },
            updatedAt: {
              set: timeStamp,
            },
          },
        },
      });

      toast.success("Successfully saved evaluation");
      onComplete();
    } catch (err) {
      toast.error("Error saving evaluation, please contact support.");
    }
  };

  const handleSubmit = async (e, isSaveOnly) => {
    try {
      e.preventDefault();

      const timeStamp = new Date().toISOString();

      const questions = inputs?.sections
        ?.map((section) => section.questions)
        .flat();

      await Promise.all(
        questions.map((question) =>
          updateOneEvaluationQuestion({
            variables: {
              where: {
                id: question.id,
              },
              data: {
                answer: {
                  set: question.answer,
                },
              },
            },
          })
        )
      );
      // Signing
      if (isSigning) {
        await updateOneEvaluation({
          variables: {
            where: {
              id: inputs.id,
            },
            data: {
              signedDate: {
                set: new Date().toISOString(),
              },
              signedUserId: {
                set: activeUserEmployeeNumber,
              },
              signerName: {
                set: activeUserFullName,
              },
              needsSignature: {
                set: false,
              },
            },
          },
        });

        // completing decco leadership -> Molly for signing
      } else if (inputs.title.includes("DECCO Leadership")) {
        await updateOneEvaluation({
          variables: {
            where: {
              id: inputs.id,
            },
            data: {
              status: {
                set: "COMPLETED",
              },
              author: {
                set: "MOLLY REAGAN",
              },
              authorEmployeeNumber: {
                set: "     11888",
              },
              dateCompleted: {
                set: timeStamp,
              },
              needsSignature: {
                set: true,
              },
              formAuthor: {
                set: inputs?.author,
              },
              formAuthorEmployeeNumber: {
                set: inputs?.authorEmployeeNumber,
              },
            },
          },
        });
        // annual performance review -> employee for signing
      } else if (inputs.title.includes("Annual Performance Review")) {
        await updateOneEvaluation({
          variables: {
            where: {
              id: inputs.id,
            },
            data: {
              status: {
                set: "COMPLETED",
              },
              author: {
                set: inputs?.owner,
              },
              authorEmployeeNumber: {
                set: inputs?.ownerEmployeeNumber,
              },
              dateCompleted: {
                set: timeStamp,
              },
              needsSignature: {
                set: true,
              },
              formAuthor: {
                set: inputs?.author,
              },
              formAuthorEmployeeNumber: {
                set: inputs?.authorEmployeeNumber,
              },
            },
          },
        });
        // all other forms -> manager for signing
      } else {
        await updateOneEvaluation({
          variables: {
            where: {
              id: inputs.id,
            },
            data: {
              status: {
                set: "COMPLETED",
              },
              author: {
                set: inputs?.manager, // who has the next action
              },
              authorEmployeeNumber: {
                set: inputs.managerEmployeeNumber,
              },
              dateCompleted: {
                set: timeStamp,
              },
              needsSignature: {
                set: true,
              }
        
            },
          },
        });
              // UPDATED IN PRIOR STEP
              // formAuthor: { // who actually wrote the eval
              //   set: inputs?.author,
              // },
              // formAuthorEmployeeNumber: {
              //   set: inputs?.authorEmployeeNumber,
              // },
      }

      const completeEvals = reviewEvaluations?.filter(
        (evalItem) => evalItem?.status === "COMPLETED"
      );
      
      // set review status to completed if all evaluations are completed and further check at sign off
      const isAllEvalsCompleted =
        completeEvals?.length + 1 === reviewEvaluations?.length || (completeEvals?.length === reviewEvaluations?.length && isSigning);

      // checks for above and that the review status is not already completed, should be a good failsafe
      if (isAllEvalsCompleted && ReviewData?.review?.status !== "COMPLETED") {
        await updateOneReview({
          variables: {
            where: {
              id: currentReviewId,
            },
            data: {
              status: {
                set: isAllEvalsCompleted ? "COMPLETED" : "INCOMPLETE",
              },
              dateCompleted: {
                set: isAllEvalsCompleted ? timeStamp : null,
              },
            },
          },
          refetchQueries: [
            {
              query: GET_USER_REVIEWS,
              variables: {
                where: {
                  employeeNumber,
                },
              },
              fetchPolicy: "cache-and-network",
              awaitRefetchQueries: true,
            },
          ],
        });
      }

      toast.success("Successfully submitted evaluation");
      onComplete();
    } catch (err) {
      toast.error("Error saving evaluation, please contact support.");
    }
  };

  const handlePrintClick = async (e) => {
    e.preventDefault();
    await handlePrint();
   };




    // if (printMode) {
    // return (
    //   <PrintEvaluation
    //     approversFullName={approversFullName}
    //     evaluation={evaluation}
    //     handleInputChange={handleInputChange}
    //     inputs={inputs}
    //     isEvaluationCompleted={isEvaluationCompleted}
    //     onComplete={() => {
    //       window.print();
    //       setPrintMode(false);
    //     }}
    //   />
    // );
  // }

  if (loading || loadingUserData || loadingReview || loadingEvaluation || loadingReviewData) {
    return (
      <div style={{ minHeight: "10rem" }}>
        <Loader />
      </div>
    );
  }

  return (
    <form id="evaluation-form"  ref={componentRef} onSubmit={handleSubmit}>
      <div className="evaluation-container">
        <header className="modal-head">
          <div className="modal-head">
            <Title size={5}>{evaluation.title}</Title>
          </div>
          <div className="modal-head">
            <Button.Group hasAddons>
              <Button
                size="small"
                style={{ marginBottom: "0.5rem" }}
                onClick={onCanceled}
              >
                <span>Cancel</span>
              </Button>
        
              {!isEvaluationCompleted && (
                <Button
                  color="primary"
                  form="evaluation-form"
                  size="small"
                  style={{ marginBottom: "0.5rem" }}
                  onClick={handleSave}
                >
                  <span>Save</span>
                </Button>
              )}
              {!isEvaluationCompleted && (
                <Button
                  color="primary"
                  form="evaluation-form"
                  size="small"
                  style={{ marginBottom: "0.5rem" }}
                  type="submit"
                >
                  <span>Submit Review</span>
                </Button>
              )}
              {isEvaluationCompleted &&
                evaluation.needsSignature &&
                evaluation.authorEmployeeNumber ===
                  activeUserEmployeeNumber && (
                  <Button
                    color="primary"
                    disabled={
                      signature.toLowerCase() !==
                      evaluation.author.toLowerCase()
                    }
                    form="evaluation-form"
                    size="small"
                    style={{ marginBottom: "0.5rem" }}
                    type="submit"
                  >
                    <span>Sign Off</span>
                  </Button>
                )}
                {isEvaluationCompleted && (
                  <div className="export-container">
                    <Button
                      className="print-button fa-print"
                      size="medium"
                      style={{ marginLeft: ".5rem", backgroundColor: "transparent", color: "#fb7753"}}
                      onClick={handlePrintClick}
                    >
                      <Icon size="small">
                        <FontAwesomeIcon icon={faPrint} size="small"/>
                      </Icon>
                    </Button>
                  </div>
                )}
                
            </Button.Group>
          </div>
        </header>
        <div className="modal-head-details">
          <p>
            Writer:{" "}
            {capitalizeEveryWord(evaluation?.formAuthor) || "N/A"}
          </p>
          <p>Subject: {capitalizeEveryWord(evaluation?.owner)}</p>
          <p>Manager: {capitalizeEveryWord(evaluation?.manager)}</p>
          <p>Assigned On: {formatDate(evaluation?.assignmentDate)}</p>

          <p>Complete By: {formatDate(evaluation?.dateToCompleteBy)}</p>
          {evaluation.status === "COMPLETED" && (
            <p className="highlighted-details-text">
              Completed On: {formatDate(evaluation?.dateCompleted)}
            </p>
          )}
          {evaluation.signedDate && (
            <p>{`Signed On: ${formatDate(evaluation?.signedDate)}`}</p>
          )}

          {evaluation.signedUserId && (
            <p>{`Signed By: ${capitalizeEveryWord(approversFullName)}`}</p>
          )}
        </div>

        {isSigning && (
          <div id="sign-off-container">
            <p className="important-text">
              Please sign your full name to sign this evaluation after you
              have reviewed all the answers.
            </p>
            <TextField
              fullWidth
              size="small"
              value={signature}
              onChange={(e) => setSignature(e?.target?.value)}
            />
          </div>
        )}

        <hr />
      </div>
      <div className="modal-section">
        <EvaluationSection
          handleInputChange={handleInputChange}
          inputs={inputs}
          isEvaluationCompleted={isEvaluationCompleted}
        />
      </div>
    </form>
  );
};

EvaluationForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  employeeNumber: PropTypes.string.isRequired,
  evaluation: PropTypes.object.isRequired,
  evaluations: PropTypes.array.isRequired,
  currentReviewId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  isSigning: PropTypes.bool,
  onCanceled: PropTypes.func.isRequired,
  refetchMyReviews: PropTypes.func.isRequired,
  key: PropTypes.string.isRequired,
};

EvaluationForm.defaultProps = {
  isSigning: false,
};

export default EvaluationForm;
