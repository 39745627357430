import React, { useState } from "react";
import { Table, Title, Button } from "rbx";
import PropTypes from "prop-types";

import Dropdown from "../Dropdown";
import Pagination from "../Pagination";

const dropdownOptions = [
  { id: 1, label: "Next 60 Days" },
  { id: 2, label: "Next 90 Days" },
];

const ExpiringLicensesTable = ({
  data,
  onPaginationChange,
  pageCount,
  recordCount,
}) => {
  const [activeDropdownItem, setActiveDropdownItem] = useState(
    dropdownOptions[0]
  );

  const [currentPage, setCurrentPage] = useState(0);

  const handleDropdownOptionClick = (item) => {
    setActiveDropdownItem(item);
  };

  return (
    <div className="lic-table-container">
      <Title>Expiring Licenses</Title>
      <div className="lic-table-wrapper">
        <Table fullwidth className="exp-lic-table">
          <Table.Head>
            <Table.Row>
              <Table.Heading>Expiring</Table.Heading>
              <Table.Heading>Partner</Table.Heading>
              <Table.Heading>License Title</Table.Heading>
              <Table.Heading>Email</Table.Heading>
              <Table.Heading>Supervisor</Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((item, index) => (
              <Table.Row key={item.id} selected={item.selected}>
                <Table.Cell>{item.expiring}</Table.Cell>
                <Table.Cell>{item.partner}</Table.Cell>
                <Table.Cell>{item.license_title}</Table.Cell>
                <Table.Cell>{item.email}</Table.Cell>
                <Table.Cell>{item.supervisor}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        {recordCount > 10 && (
          <Pagination
            canNextPage={currentPage + 1 < pageCount}
            canPreviousPage={currentPage + 1 > 1}
            page={currentPage + 1}
            pageSize={10}
            pages={pageCount}
            recordCount={recordCount}
            setPageSize={() => null}
            onClickNext={() => {
              onPaginationChange(currentPage + 1);
              setCurrentPage((prev) => prev + 1);
            }}
            onClickPrev={() => {
              onPaginationChange(currentPage - 1);
              setCurrentPage((prev) => prev - 1);
            }}
          />
        )}
      </div>
      <div className="table-footer">
        <Button>Full Report</Button>
        <Dropdown
          activeItem={activeDropdownItem}
          handleOptionClick={handleDropdownOptionClick}
          options={dropdownOptions}
        />
      </div>
    </div>
  );
};

ExpiringLicensesTable.propTypes = {
  data: PropTypes.array,
  onPaginationChange: PropTypes.any,
  pageCount: PropTypes.number,
  recordCount: PropTypes.number,
};

ExpiringLicensesTable.defaultProps = {
  data: [],
  onPaginationChange: () => null,
  pageCount: 1,
  recordCount: 0,
};

export default ExpiringLicensesTable;
