import React, { useState, useEffect } from "react";
import { Field } from "rbx";
import PropTypes from "prop-types";
import FilterButton from "./FilterButton";
import ExportButton from "./ExportButton";

import { usePermissions } from "../../hooks";

const FiltersWrapper = ({
  children,
  buttonChildren,
  loading,
  onExportClick,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [exportPermissions, setExportPermissions] = useState(false);

  const permissionLevels = usePermissions();

  useEffect(() => {
    if (
      permissionLevels.admin ||
      permissionLevels.superAdmin ||
      permissionLevels.webEditor
    ) {
      setExportPermissions(true);
    }
  }, [permissionLevels]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  return (
    <div className="Filters-container">
      <div className="filter-btn-container">
        {buttonChildren}
        <FilterButton loading={loading} onClick={toggleFilters} />
        {exportPermissions && (
          <ExportButton loading={loading} onClick={onExportClick} />
        )}
      </div>

      <Field className={!showFilters && "hide"}>{children}</Field>
    </div>
  );
};
FiltersWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  buttonChildren: PropTypes.node,
  loading: PropTypes.bool.isRequired,
  onExportClick: PropTypes.func,
};

FiltersWrapper.defaultProps = {
  onExportClick: () => {},
  buttonChildren: null,
};

export default FiltersWrapper;
