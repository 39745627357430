import { useQuery, useMutation } from "@apollo/client";
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { useTheme, useMediaQuery } from "@mui/material";
import { Button } from "rbx";
import ContractorTable from "./components/ContractorTable/ContractorTable";
import { useModal } from "../../context";

import { GET_CONTRACTORS } from "../../graphql/contractor/contractor.queries";
import { generateColumns } from "./columns";

import { UPDATE_CONTRACTOR } from "../../graphql";

import {
  downloadLocalCsv,
  uppercaseFirstLetter,
  customToast as toast,
} from "../../utils";

import "./ContractorList.scss";
import ContractorFilters from "./components/ContractorFilters";
import ContractorModal from "./components/ContractorModal/ContractorModal";
import MobileContractorList from "./MobileContractorList";
import { FiltersContainer } from "../../components";

const DEFAULT_CONTRACTOR_WHERE = { status: 1 };

const ContractorList = ({ onComplete }) => {
  const [contractorWhere, setContractorWhere] = useState(
    DEFAULT_CONTRACTOR_WHERE
  );
  const [contractorList, setContractorList] = useState([]);
  const [loading, setLoading] = useState(false);

  const { setModalOpen } = useModal();

  useQuery(GET_CONTRACTORS, {
    variables: {
      orderBy: [{ firstName: "asc" }, { lastName: "asc" }],
      where: {
        AND: [
          contractorWhere?.fullName && {
            OR: [
              {
                firstName: {
                  contains: contractorWhere?.fullName,
                  mode: "insensitive",
                },
              },
              {
                lastName: {
                  contains: contractorWhere?.fullName,
                  mode: "insensitive",
                },
              },
            ],
          },
          contractorWhere?.task && {
            task: {
              contains: contractorWhere.task,
              mode: "insensitive",
            },
          },
          contractorWhere?.evaluation && {
            evaluation: {
              contains: contractorWhere.evaluation,
              mode: "insensitive",
            },
          },
          contractorWhere?.staffingAgency && {
            staffingAgency: {
              contains: contractorWhere.staffingAgency,
              mode: "insensitive",
            },
          },
          (contractorWhere?.status || !contractorWhere?.status) &&
            contractorWhere?.status !== 2 && {
              status: {
                equals: contractorWhere.status === 1,
              },
            },
          contractorWhere?.status &&
            contractorWhere?.status === 2 && {
              OR: [
                {
                  status: {
                    equals: false,
                  },
                },
                {
                  status: {
                    equals: true,
                  },
                },
              ],
            },
          contractorWhere?.project && {
            projectId: {
              equals: contractorWhere.project,
            },
          },
        ].filter(Boolean),
      },
    },
    fetchPolicy: "network-only",
    onCompleted: (d) => {
      const withFullNames = d?.contractors?.map((contractor) => ({
        ...contractor,
        fullName: `${uppercaseFirstLetter(
          contractor.firstName
        )} ${uppercaseFirstLetter(contractor.lastName)}`,
        status: contractor?.status ? "Active" : "Inactive",
      }));

      setContractorList(withFullNames);
    },
  });

  const [assignProject] = useMutation(UPDATE_CONTRACTOR, {
    onCompleted: () => {
      toast.success("Project assigned successfully");
    },
    onError: () => {
      toast.error("Something went wrong. Please contact support ");
    },
  });

  const handleProjectChange = async (contractorId, projId) => {
    await assignProject({
      variables: {
        data: {
          project: {
            connect: {
              id: projId,
            },
          },
        },

        where: {
          id: contractorId,
        },
      },
    });
  };

  const COLUMNS = useMemo(
    () =>
      generateColumns((contractorId, projId) =>
        handleProjectChange(contractorId, projId)
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [assignProject]
  );

  const handleAdd = () => {
    setModalOpen(
      true,
      <ContractorModal
        where={contractorWhere}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const handleFiltersChange = (name, value) => {
    setContractorWhere((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const exportCSV = async () => {
    const contractorListExport = contractorList.map((contractor) => ({
      fullName: `${contractor.firstName} ${contractor.lastName}`,
      task: contractor.task?.trim(),
      evaluation: contractor.evaluation?.trim(),
      staffingAgency: contractor.staffingAgency?.trim(),
      status: contractor.status?.trim(),
      notes: contractor.notes?.trim(),
      project: contractor.project?.name?.trim(),
    }));

    const headers = [
      {
        Header: "Full Name",
        accessor: "fullName",
      },
      {
        Header: "Task",
        accessor: "task",
      },
      {
        Header: "Evaluation",
        accessor: "evaluation",
      },
      {
        Header: "Staffing Agency",
        accessor: "staffingAgency",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Notes",
        accessor: "notes",
      },
      {
        Header: "Project",
        accessor: "project",
      },
    ];

    try {
      setLoading(true);
      await downloadLocalCsv(contractorListExport, "contractors", headers);
    } catch (error) {
      toast.error("Error exporting CSV");
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = (row) => {
    setModalOpen(
      true,
      <ContractorModal
        id={row?.original?.id || row?.id}
        where={contractorWhere}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileContractorList
        data={contractorList}
        handleAddClick={handleAdd}
        handleDetailsClick={handleRowClick}
        handleFiltersChange={handleFiltersChange}
        handleProjectChange={handleProjectChange}
        inputs={contractorWhere}
        loading={loading}
        onExportClick={exportCSV}
      />
    );
  }

  return (
    <div className="contractor-list-container">
      <FiltersContainer
        buttonChildren={
          <Button className="add-btn" disabled={loading} onClick={handleAdd}>
            + Add
          </Button>
        }
        onExportClick={exportCSV}
      >
        <ContractorFilters
          inputs={contractorWhere}
          onChange={handleFiltersChange}
          onExport={exportCSV}
        />
      </FiltersContainer>

      <ContractorTable
        columns={COLUMNS}
        data={contractorList}
        onRowClick={handleRowClick}
      />
    </div>
  );
};

ContractorList.propTypes = {
  onComplete: PropTypes.func,
};

ContractorList.defaultProps = {
  onComplete: () => {},
};

export default ContractorList;
