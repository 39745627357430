import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_EMPLOYEE_MUTATION = gql`
  mutation UpdateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      id
    }
  }
`;
