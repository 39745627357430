import React, { useState } from "react";
import PropTypes from "prop-types";

import { Content, Button, Control, Field } from "rbx";

import { useModal } from "../../context/ModalContext";
import Loader from "../Loader";

const Confirmation = ({ message, onConfirm, onCancel }) => {
  const { setModalOpen } = useModal();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    setModalOpen(false, "");
    if (onCancel && typeof onCancel === "function") {
      onCancel();
    }
  };

  const handleConfirm = () => {
    setLoading(true);
    onConfirm();
  };

  if (loading) {
    return (
       <div className="loader-container">
          <Loader />
        </div>
    );
  }

  return (
    <div>
      <Content size="medium" textAlign="centered">
        <p>{message}</p>
      </Content>

      <Field align="centered" kind="group">
        <Control>
          <Button
            className="btn-width"
            id="danger-btn"
            type="button"
            onClick={handleConfirm}
          >
            <span>YES</span>
          </Button>
        </Control>
        <Control>
          <Button
            className="btn-width"
            color="success"
            type="button"
            onClick={handleCancel}
          >
            <span>NO</span>
          </Button>
        </Control>
      </Field>
    </div>
  );
};

Confirmation.propTypes = {
  message: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

Confirmation.defaultProps = {
  onCancel: () => null,
};

export default Confirmation;
