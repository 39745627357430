import { gql } from "@apollo/client";

export const CREATE_CAL_EVENT_MUTATION = gql`
  mutation CreateOneCalEvents($data: CalEventsCreateInput!) {
    createOneCalEvents(data: $data) {
      date
      title
      id
    }
  }
`;

export const DELETE_CAL_EVENT_MUTATION = gql`
  mutation DeleteOneCalEvents($where: CalEventsWhereUniqueInput!) {
    deleteOneCalEvents(where: $where) {
      id
    }
  }
`;

export const UPDATE_CAL_EVENT_MUTATION = gql`
  mutation UpdateOneCalEvents(
    $data: CalEventsUpdateInput!
    $where: CalEventsWhereUniqueInput!
  ) {
    updateOneCalEvents(data: $data, where: $where) {
      id
    }
  }
`;
