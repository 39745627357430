import React from "react";
import PropTypes from "prop-types";
import EvaluationsListContainer from "../EvaluationsListContainer";

const ReviewSection = ({
  sectionType,
  reviews,
  isCompletedSection,
  handleClick,
  setReviews,
  refetchMyReviews
}) => (
  <EvaluationsListContainer
    handleClick={handleClick}
    isCompletedSection={isCompletedSection}
    refetchMyReviews={refetchMyReviews}
    reviews={reviews}
    sectionType={sectionType}
    setReviews={setReviews}
  />
);

ReviewSection.propTypes = {
  handleClick: PropTypes.func.isRequired,
  reviews: PropTypes.array,
  sectionType: PropTypes.string,
  setReviews: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool,
  refetchMyReviews: PropTypes.func,
};

ReviewSection.defaultProps = {
  reviews: [],
  sectionType: "",
  isCompletedSection: false,
  refetchMyReviews: () => {},
};

export default ReviewSection;
