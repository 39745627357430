import React from "react";
import PropTypes from "prop-types";
import { Title } from "rbx";
import "./PageHeader.scss";

const PageHeader = ({ title, style, children }) => (
  <div style={style || { paddingLeft: "1.5rem" }}>
    <div className="page-head">
      <div className="page-head-start">
        <Title size={3}>{title}</Title>
      </div>
      <div className="page-head-end">{children}</div>
    </div>

    <hr
      style={{
        width: "2rem",
        backgroundColor: "#059e42",
        margin: "0 0 1rem 0",
      }}
    />
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
};

PageHeader.defaultProps = {
  title: "",
  children: null,
  style: null,
};

export default PageHeader;
