import React, { useState } from "react";
import PropTypes from "prop-types";

import { useApolloClient } from "@apollo/client";

import { Title, Button, Control, Input, Label, Field } from "rbx";

import Loader from "../../../../components/Loader";

import { UPDATE_USER_MUTATION } from "../../../../graphql/user";
import { GenericSelect } from "../../../../components";

const tshirtOptions = [
  { id: "notAssigned", title: "Not Assigned" },
  { id: "SM", title: "SM" },
  { id: "MD", title: "MD" },
  { id: "LG", title: "LG" },
  { id: "XL", title: "XL" },
  { id: "XL2", title: "2XL" },
  { id: "XL3", title: "3XL" },
  { id: "XL4", title: "4XL" },
];

const accessLevelOptions = [
  { id: "1", title: "user" },
  { id: "2", title: "fieldSupervisor" },
  { id: "3", title: "admin" },
  { id: "4", title: "superAdmin" },
  { id: "5", title: "webEditor" },
];

const INITIAL_STATE = {
  yearsOfServiceNote: "",
  tshirtSize: "",
  userType: "user",
  id: "",
};

const UserForm = ({ employeeData, onComplete, permissionLevels, mobile }) => {
  const client = useApolloClient();

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    ...INITIAL_STATE,
    yearsOfServiceNote: employeeData?.yearsOfServiceNote,
    tshirtSize: employeeData?.tshirtSize,
    userType: employeeData?.userType,
    id: employeeData?.id,
  });

  const handleInputChange = (e, value) => {
    const n = e?.target?.name || e;
    const v = e?.target?.value || value;
    setInputs({ ...inputs, [n]: v });
  };

  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    // updateUser and refetch all queries
    await client.mutate({
      mutation: UPDATE_USER_MUTATION,
      variables: {
        where: {
          id: employeeData?.id,
        },
        data: {
          tshirtSize: { set: inputs.tshirtSize },
          yearsOfServiceNote: { set: inputs.yearsOfServiceNote || "" },
          userType: { set: inputs.userType },
        },
      },
      refetchQueries: ["getEmployeeBaseballCard"],
      awaitRefetchQueries: true,
    });

    onComplete(false);
  };

  const containerClass = mobile ? "baseball-form-container-mobile" : "baseball-form-container";
  const gridClass = mobile ? "baseball-form-container-mobile" : "grid-override is-three";

  if (loading) return <Loader />;

  return (
    <form id="project-form" onSubmit={handleSubmit}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>Employee Information</Title>
        </div>
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={!inputs?.userType || !inputs?.tshirtSize}
              form="project-form"
              size="small"
              type="submit"
            >
              <span>Save and Close</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />

      <React.Fragment>
        {(permissionLevels.admin || permissionLevels.superAdmin || permissionLevels.webEditor) ? (
          <Field className={containerClass}>
            <Field className={gridClass} kind="group">
              {(permissionLevels.webEditor) && (
                <Control>
                  <GenericSelect
                    disabled={!inputs.id}
                    label="Access Level"
                    name="userType"
                    options={accessLevelOptions}
                    showBlank={false}
                    value={inputs?.userType}
                    onChange={handleInputChange}
                  />
                </Control>
              )}
              {(permissionLevels.webEditor) && (
                <Control>
                  <Label size="medium">Years of Service Note</Label>
                  <Input
                    autoComplete="off"
                    maxLength={100}
                    name="yearsOfServiceNote"
                    placeholder="Years of Service Note"
                    type="text"
                    value={inputs.yearsOfServiceNote || ""}
                    onChange={handleInputChange}
                  />
                </Control>
               )}
              <Control>
                <GenericSelect
                  addClass="is-fullwidth"
                  disabled={!inputs.id}
                  label="T-shirt Size"
                  name="tshirtSize"
                  options={tshirtOptions}
                  showBlank={false}
                  value={inputs?.tshirtSize || ""}
                  onChange={handleInputChange}
                />
              </Control>
            </Field>
          </Field>
        ) : (
          <Field className="grid-override is-two" kind="group">
            <Control expanded>
              <GenericSelect
                disabled={!inputs.id}
                label="T-shirt Size"
                name="tshirtSize"
                options={tshirtOptions}
                placeholder="userType"
                showBlank={false}
                value={inputs?.tshirtSize || ""}
                onChange={handleInputChange}
              />
            </Control>
          </Field>
        )}
      </React.Fragment>
    </form>
  );
};

UserForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  employeeData: PropTypes.object.isRequired,
  permissionLevels: PropTypes.object.isRequired,
  mobile: PropTypes.bool,
};

UserForm.defaultProps = {
  mobile: false,
};

export default UserForm;
