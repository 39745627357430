import React from "react";
import PropTypes from "prop-types";
import { Button } from "rbx";
import "./ModalWrapper.scss";

const ModalWrapper = ({ children, onComplete, title, details }) => (
  <React.Fragment>
    <div className="modal-header">
      <p className="modal-title">{title}</p>
      <Button color="primary" size="small" onClick={onComplete}>
        Cancel
      </Button>
    </div>
    <div className="modal-details">
      <p>{details}</p>
    </div>
    <div>{children}</div>
  </React.Fragment>
);

ModalWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  onComplete: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  details: PropTypes.string,
};

ModalWrapper.defaultProps = {
  details: "",
};

export default ModalWrapper;
