import { gql } from "@apollo/client";

export const CREATE_CONTRACTOR = gql`
  mutation CreateOneContractor($data: ContractorCreateInput!) {
    createOneContractor(data: $data) {
      id
    }
  }
`;

export const UPDATE_CONTRACTOR = gql`
  mutation UpdateOneContractor(
    $data: ContractorUpdateInput!
    $where: ContractorWhereUniqueInput!
  ) {
    updateOneContractor(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_CONTRACTOR = gql`
  mutation DeleteOneContractor($where: ContractorWhereUniqueInput!) {
    deleteOneContractor(where: $where) {
      id
    }
  }
`;
