import React from "react";

import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from "prop-types";

const FilterButton = ({ onClick, size, loading }) => (
  <Button className="filter-btn" disabled={loading} onClick={onClick}>
    <Icon>
      <FontAwesomeIcon icon="filter" size={size} />
    </Icon>
  </Button>
);

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  loading: PropTypes.bool,
};

FilterButton.defaultProps = {
  size: "lg",
  loading: false,
};

export default FilterButton;
