/* eslint-disable react/prop-types */

export const LICENSE_COLUMNS = [
  {
    accessor: "title",
    Header: "Title",
  },
  {
    accessor: "level",
    Header: "Level",
  },
  {
    accessor: "state",
    Header: "State",
  },
  {
    accessor: "points",
    Header: "Points",
  },
  {
    accessor: "expirationType",
    Header: "Expiration Type",
  },
  {
    accessor: "lookupCode",
    Header: "Lookup Code",
  },
  {
    accessor: "description",
    Header: "Description",
  },
];

export const CERTS_COLUMNS = [
  {
    accessor: "title",
    Header: "Title",
  },
  {
    accessor: "points",
    Header: "Points",
  },
  {
    accessor: "expirationType",
    Header: "Expiration Type",
  },
  {
    accessor: "lookupCode",
    Header: "Lookup Code",
  },
  {
    accessor: "description",
    Header: "Description",
  },
];

export const generateLicenseColumns = () => [...LICENSE_COLUMNS];

export const generateCertificationColumns = () => [...CERTS_COLUMNS];

export const SKILLS_COURSES_COLUMNS = [
  {
    accessor: "title",
    Header: "Title",
  },
  {
    accessor: "points",
    Header: "Points",
  },
  {
    accessor: "description",
    Header: "Description",
  },
];

export const generateSkillCoursesColumns = () => [...SKILLS_COURSES_COLUMNS];
