import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Divider, useTheme } from "@mui/material";

import formatDate from "../../utils/formatDate";

const PartnerServicesRowDetails = ({ columns, data, title }) => {
  const theme = useTheme();
  if (data?.length === 0 || !data) {
    return (
      <Box display="flex" justifyContent="center">
        <Typography>No {title} found</Typography>
      </Box>
    );
  }

  const generateUUID = () =>
  `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`.replace(/[018]/g, (c) =>
    // eslint-disable-next-line no-bitwise
    (c ^ (crypto.randomBytes(1)[0] & (15 >> (c / 4)))).toString(16)
  );


  const formatValue = (item, accessor) => {
    if (accessor.includes(".")) {
      const value = accessor.split(".").reduce((o, i) => o[i], item);
      return value;
    }
    if (!isNaN(new Date(item[accessor]))) {
      return formatDate(item[accessor]);
    }
    return item[accessor];
  };

  return (
    <Box key={ () => generateUUID()} maxHeight="15rem" sx={{ overflowY: "auto" }}>
      {data?.map((item, i) => (
        <React.Fragment>
          {columns.map((col) => (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              key={col.Header}
            >
              <Typography variant="body2">{col.Header}:</Typography>
              <Typography variant={col.Header === "Title" ? "body3" : "body2"}>
                {formatValue(item, col.accessor)}
              </Typography>
            </Box>
          ))}

          {i !== data?.length - 1 && (
            <Divider
              sx={{
                marginTop: "0.5rem",
                marginBottom: "0.5rem",
                height: "1px",
                backgroundColor: theme.palette.primary.main,
              }}
            />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

PartnerServicesRowDetails.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  title: PropTypes.string,
};

PartnerServicesRowDetails.defaultProps = {
  title: "Items",
};

export default PartnerServicesRowDetails;
