import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Control, Field, File, Button, Icon } from "rbx";
import { useMutation, useApolloClient } from "@apollo/client";

import { toast } from "react-toastify";
import {
  UPLOAD_FILE_MUTATION,
  UPLOAD_IMAGE_MUTATION,
  SIGNED_FILE_URL_QUERY
} from "../../graphql/upload";

const FileUploader = ({
  label,
  disabled,
  value,
  onChange,
  showDownloadButton,
  buttonLabel,
  filePath,
  type,
  contactInfo
}) => {
  const inputRef = useRef();

  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  const handleDownload = async (data) => {
    const path = data.split("/")[3];
    const { data: signedFileUrlData } = await client.query({
      query: SIGNED_FILE_URL_QUERY,
      variables: {
        input: {
          objectKey: path,
        },
      },
    });
    const { signedFileUrl } = signedFileUrlData;
    window.open(signedFileUrl);
  };

  const downloadFile = () => {
    handleDownload(filePath);
  };

  const [uploadFile, { loading: uploadFileLoading }] =
    useMutation(UPLOAD_FILE_MUTATION);

  const [uploadImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE_MUTATION
  );

  const handleChange = async (e) => {
    try {
      const file = e.target?.files?.[0];
      if (file) {
        setLoading(true);
        if (type === "image") {
          const {
            data: {
              uploadImage: { filename, filepath },
            },
          } = await uploadImage({ variables: { file } });
          onChange({ filename, filepath, name: file.name });
        } else {
          const {
            data: {
              uploadFile: { filename, filepath },
            },
          } = await uploadFile({ variables: { file, contactInfo } });
          onChange({ filename, filepath, name: file.name });
        }
      }
    } catch (err) {
      toast.error("Error uploading file.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileCtaClick = (e) => {
    if (value) {
      e.preventDefault();
      downloadFile();
    }
  };

  return (
    <Field kind="addons">
      <Control>
        <File fullwidth hasName color="primary" size="small">
          <File.Label>
            <File.Input
              disabled={disabled || uploadFileLoading || uploadImageLoading}
              name="file"
              ref={inputRef}
              onChange={handleChange}
            />

            <File.CTA>
              <File.Label as="span" disabled={loading}>
                {buttonLabel}
              </File.Label>
            </File.CTA>
          </File.Label>
        </File>
      </Control>

      {showDownloadButton && value && (
        <Control>
          <Button
            color="primary"
            disabled={!value || uploadFileLoading || uploadImageLoading}
            size="small"
            state={uploadFileLoading || uploadImageLoading ? "loading" : ""}
            title="View"
            onClick={handleFileCtaClick}
          >
            <Icon>
              <FontAwesomeIcon icon="eye" />
            </Icon>
            {/* <span>View</span> */}
          </Button>
        </Control>
      )}
    </Field>
  );
};
FileUploader.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  showDownloadButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  filePath: PropTypes.string,
  type: PropTypes.string,
  contactInfo: PropTypes.bool
};

FileUploader.defaultProps = {
  label: "",
  value: "",
  disabled: false,
  onChange: () => {},
  showDownloadButton: false,
  buttonLabel: "Upload Avatar",
  filePath: "",
  type: "image",
  contactInfo: false
};

export default FileUploader;
