import React from "react";
import PropTypes from "prop-types";
import EvaluationQuestion from "../EvaluationQuestion";

const EvaluationQuestions = ({
  isEvaluationCompleted,
  section,
  sectionIndex,
  handleInputChange,
}) => (
  <React.Fragment>
    {section?.questions?.map((question, questionIndex) => (
      <EvaluationQuestion
        handleInputChange={handleInputChange}
        isEvaluationCompleted={isEvaluationCompleted}
        key={question.id}
        question={question}
        questionIndex={questionIndex}
        sectionIndex={sectionIndex}
      />
    ))}
  </React.Fragment>
);

EvaluationQuestions.propTypes = {
  isEvaluationCompleted: PropTypes.bool.isRequired,
  section: PropTypes.object.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  sectionIndex: PropTypes.number.isRequired,
};

export default EvaluationQuestions;
