import React, { useEffect, useState, useMemo } from "react";
import { useQuery } from "@apollo/client";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  Button as MuiButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { GET_ALL_BASEBALL_CARD_EMPLOYEES} from "../../../../graphql";
import { 
  ReportsTable, 
  AccordionItem,
  AccordionDetailsItem,
  ReportSelectMUI
} from "../../../../components";


import { EMPLOYEE_POINTS_COLUMNS } from "../../columns";

import ReportHeader from "../ReportHeader";

import { downloadLocalCsv, customToast as toast } from "../../../../utils";

import MobileTable from "../../../../components/ReportsTable/MobileTable";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";

const EmployeePointsTable = ({ report, onReportChange, tabs, permissions }) => {


  const { data, loading } = useQuery(
    GET_ALL_BASEBALL_CARD_EMPLOYEES,
    {
      variables: {
        where: {
          employmentStatus: {
            equals: "active",
          },
        },
        orderBy: [
          {
            lastName: "asc",
          },
        ],
      },
    }
  );

  const employees = useMemo( () => data?.getAllEmployeeBaseballCards || [], [data]);
  const [combinedEmployees, setCombinedEmployees] = useState([]);

  useEffect(() => {
    if (employees.length) {
      setCombinedEmployees(employees);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees]);

  const exportCSV = async () => {
    try {
      downloadLocalCsv(combinedEmployees, "Employee Points Report", EMPLOYEE_POINTS_COLUMNS);
    } catch (err) {
      toast.error("Error exporting CSV");
    }
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileTable
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <ReportSelectMUI value={report} onChange={onReportChange} />
            </Box>

            <Box mt={1} width="20%">
              <MuiButton
                color="secondary"
                sx={{
                  color: "white",
                  width: "100%",
                }}
                variant="contained"
                onClick={() => exportCSV()}
              >
                <FileDownload />
              </MuiButton>
            </Box>
          </React.Fragment>
        }
        loading={loading}
        title="Reports"
      >
        {loading ? (
          <BoxLoader />
        ) : (
          combinedEmployees?.map((item) => (
            <AccordionItem
              info={item?.points}
              key={item?.employeeNumber}
              title={`${item?.firstName} ${item?.lastName} `}
            >
              <AccordionDetailsItem
                label="Employee #"
                value={item?.employeeNumber}
              />
              <AccordionDetailsItem label="Group" value={item?.group} />
              <AccordionDetailsItem
                label="Lic"
                value={item?.licPoints}
              />
              <AccordionDetailsItem label="Cert" value={item?.cerPoints} />
              <AccordionDetailsItem label="Skill" value={item?.skillPoints} />
              <AccordionDetailsItem
                label="Total"
                value={item?.points}
              />
            </AccordionItem>
          ))
        )}
      </MobileTable>
    );
  }

  return (
    <ReportsTable
      columns={EMPLOYEE_POINTS_COLUMNS}
      data={combinedEmployees}
      loading={loading || !combinedEmployees.length}
    >
      {(permissions.webEditor ||
        permissions.superAdmin ||
        permissions.admin) && (
        <div className="table-utils">
          <Button
            className="download-btn"
            disabled={loading}
            size="medium"
            title="Download CSV"
            onClick={exportCSV}
          >
            <Icon size="small">
              <FontAwesomeIcon icon="download" />
            </Icon>
          </Button>
        </div>
      )}
      <ReportHeader
        report={report}
        tabs={tabs}
        onReportChange={onReportChange}
      />
    </ReportsTable>
  );
};

EmployeePointsTable.propTypes = {
  onReportChange: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default EmployeePointsTable;
