/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField, Autocomplete } from "@mui/material";
import { usePermissions } from "../../../../hooks";

const TABLE_OPTIONS = ["Certifications", "Skills"];

const QualificationCodeTableSelectMUI = ({
  name,
  value,
  label,
  placeholder,
  disabled,
  required,
  getValue,
  setValue,
  onChange,
}) => {
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissions = usePermissions();
  const isAdmin =
    permissions.admin || permissions.superAdmin || permissions.webEditor;

  const handleChange = (val) => {
    onChange(setValue(val));
  };

  useEffect(() => {
    if (TABLE_OPTIONS) {
      setLoading(true);

      if (isAdmin) {
        TABLE_OPTIONS.push("Licenses");
        TABLE_OPTIONS.sort();
      }

      const options = TABLE_OPTIONS.map((opt) => ({
        value: opt.toLowerCase(),
        label: opt,
      }));
      setFormattedOptions(options);
      setLoading(false);
    }
  }, [isAdmin]);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) =>
        option.label ??
        formattedOptions.find((opt) => opt.value === value)?.label
      }
      isOptionEqualToValue={(o, v) => o.value === v}
      isRequired={required}
      loading={loading}
      name={name}
      options={formattedOptions}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      size="small"
      value={getValue(value)}
      onChange={(_, option) => {
        handleChange(option?.value);
      }}
    />
  );
};

QualificationCodeTableSelectMUI.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
};

QualificationCodeTableSelectMUI.defaultProps = {
  name: "qualification",
  placeholder: "Select a Qualification",
  label: "Qualification",
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};

export default QualificationCodeTableSelectMUI;
