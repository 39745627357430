import React from "react";
import PropTypes from "prop-types";

// components
import { Field, Control, Input, Label, Content, Textarea } from "rbx";
import GenericSelect from "../../../GenericSelect";
import StateSelect from "../../../StateSelect";
import LevelSelect from "../../../LevelSelect";

const expOptions = [
  { id: 1, title: "manual" },
  { id: 2, title: "none" },
];

const QualificationForm = ({
  inputs,
  disabled,
  onChange,
  qualification,
  empsUsingCode,
  isAdding,
}) => (
  <Field className="QualificationsForms">
    <Control>
      <Label className="qual-form-input" size="medium">
        Name
      </Label>
      <Input
        required
        autoComplete="off"
        disabled={disabled}
        maxLength={100}
        name="title"
        placeholder="Name"
        type="text"
        value={inputs.title || ""}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
    </Control>

    <Control style={{ marginTop: "1rem" }}>
      <Label className="qual-form-input" size="medium">
        Description
      </Label>
      <Textarea
        fullWidth
        disabled={disabled}
        name="description"
        rows={3}
        type="textarea"
        value={inputs.description || ""}
        onChange={(e) => onChange(e.target.name, e.target.value)}
      />
    </Control>
    <Field
      is-group
      className="is-grouped-multiline "
      kind="group"
      style={{ marginTop: "1rem" }}
    >
      <Control>
        <Label className="qual-form-input" size="medium">
          Points
        </Label>
        <Input
          required
          autoComplete="off"
          disabled={disabled}
          maxLength={3}
          name="points"
          placeholder="Points"
          type="number"
          value={inputs.points || ""}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </Control>

      {qualification === "licenses" && (
        <React.Fragment>
          <Control>
            <Label className="qual-form-input" size="medium">
              State
            </Label>
            <StateSelect
              disabled={disabled}
              name="state"
              size=""
              value={inputs.state || ""}
              onChange={(name, value) => onChange(name, value)}
            />
          </Control>
          <Control>
            <Label className="qual-form-input" size="medium">
              Level
            </Label>
            <LevelSelect
              disabled={disabled}
              name="level"
              value={inputs.level || ""}
              onChange={(name, value) => onChange(name, value)}
            />
          </Control>
          <Control>
            <Label className="qual-form-input" size="medium">
              State Lookup Code
            </Label>
            <Input
              disabled={disabled}
              name="lookupCode"
              placeholder="Lookup Code"
              type="text"
              value={inputs.lookupCode || ""}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </React.Fragment>
      )}

      {qualification === "certifications" && (
        <Control>
          <Label className="qual-form-input" size="medium">
            State Lookup Code
          </Label>
          <Input
            disabled={disabled}
            name="lookupCode"
            placeholder="Lookup Code"
            type="text"
            value={inputs.lookupCode || ""}
            onChange={(e) => onChange(e.target.name, e.target.value)}
          />
        </Control>
      )}

      <Control>
        {qualification !== "deccoUCourses" && qualification !== "skills" && (
          <GenericSelect
            required
            addLabelClass="qual-form-input"
            disabled={disabled || empsUsingCode.length > 0 || !isAdding}
            label="Expiration Type"
            name="expirationType"
            options={expOptions}
            value={inputs.expirationType || ""}
            onChange={onChange}
          />
        )}
      </Control>
    </Field>

    <Field style={{ marginTop: "1rem" }}>
      <Content>
        <p>Note: Expiration type cannot be changed once created.</p>
      </Content>
    </Field>
  </Field>
);

QualificationForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  qualification: PropTypes.string,
  empsUsingCode: PropTypes.array,
  isAdding: PropTypes.bool,
};

QualificationForm.defaultProps = {
  disabled: false,
  qualification: "",
  empsUsingCode: [],
  isAdding: false,
};

export default QualificationForm;
