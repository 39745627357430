import React, { useEffect, useState } from "react";
import { Button, Title } from "rbx";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { GET_REVIEWS } from "./graphql";

import { Loader } from "../../components";
import IncompleteReviews from "./IncompleteReviews";
import CompletedReviews from "./CompletedReviews";
import "./Reviews.scss";

const Reviews = ({ employeeNumber }) => {
  // brings back all reviews where at least one authorEmployeeNumber matches their employee number
  const {
    data: ReviewData,
    loading,
    refetch,
  } = useQuery(GET_REVIEWS, {
    variables: {
      where: {
        evaluations: {
          some: {
            authorEmployeeNumber: {
              contains: employeeNumber,
            },
          },
        },
      },
      orderBy: [
        {
          name: "asc",
        },
      ],
      sectionsOrderBy: [{ order: "asc" }],
      questionsOrderBy: [{ order: "asc" }],
    },
    fetchPolicy: "cache-and-network",
  });


  const [reviews, setReviews] = useState([]);
  const [isCompletedReviews, setIsCompletedReviews] = useState(false);

  useEffect(() => {
    if (ReviewData?.reviews?.length) {
      setReviews(ReviewData?.reviews);
    }
  }, [ReviewData, refetch]);

  if (loading) {
    return (
      <div className="inner-container">
        <Loader />
      </div>
    );
  }

  if (!reviews.length) {
    return (
      <div className="inner-container">
        <div className="reviews">
          <Title>No Reviews Found</Title>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="inner-container">
        <Button
          className={isCompletedReviews ? "unselectedBtn" : "selectedBtn"}
          type="button"
          onClick={() => setIsCompletedReviews(!isCompletedReviews)}
        >
          <p>Incomplete</p>
        </Button>
        <Button
          className={isCompletedReviews ? "selectedBtn" : "unselectedBtn"}
          type="button"
          onClick={() => setIsCompletedReviews(!isCompletedReviews)}
        >
          <p>Completed</p>
        </Button>
      </div>
      {isCompletedReviews ? (
        <CompletedReviews
          employeeNumber={employeeNumber}
          loading={loading}
          reviews={reviews}
          setReviews={setReviews}
        />
      ) : (
        <IncompleteReviews
          employeeNumber={employeeNumber}
          loading={loading}
          refetchMyReviews={refetch}
          reviews={reviews}
          setReviews={setReviews}
        />
      )}
    </div>
  );
};

Reviews.propTypes = {
  employeeNumber: PropTypes.string.isRequired,
};

export default Reviews;
