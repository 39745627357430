import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Button, Control, Icon, Label } from "rbx";
import PropTypes from "prop-types";
import Select from "react-select";
import { format, isValid } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button as MuiButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { uppercaseFirstLetter, downloadLocalCsv } from "../../../../utils";
import {
  GET_QUALIFICATION_CERTS,
  GET_QUALIFICATION_LICENSES,
  GET_QUALIFICATION_SKILLS,
  GET_QUALIFICATION_DECCOU,
} from "../../../../graphql/reports";

import {
  CERTIFICATION_COLS,
  LICENSE_COLS,
  SKILL_COLS,
  DECCOU_COLS,
} from "./columns";

import {
  ReportsTable,
  ReactSelect,
  ReportSelectMUI,
  AccordionItem,
  AccordionDetailsItem,
} from "../../../../components";

import ReportHeader from "../ReportHeader";

import { FIND_MANY_CODES_FOR_SELECT } from "../../../../graphql";
import MobileTable from "../../../../components/ReportsTable/MobileTable";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";

const qualOptions = [
  { value: "licenses", label: "Licenses" },
  { value: "certifications", label: "Certifications" },
  { value: "skills", label: "Skills" },
  { value: "deccoUCourses", label: "DeccoU Courses" },
];

const levelOptions = [
  { value: "", label: "All" },
  { value: "apprentice", label: "Apprentice" },
  { value: "journeyman", label: "Journeyman" },
  { value: "master", label: "Master" },
];

const stateOptions = [
  { value: "", label: "All" },
  { value: "ma", label: "MA" },
  { value: "nh", label: "NH" },
  { value: "ct", label: "CT" },
  { value: "ri", label: "RI" },
];

const QualificationsTable = ({ onReportChange, report, tabs, permissions }) => {
  const [qualType, setQualType] = useState(null);
  const [selects, setSelects] = useState([]);
  const [tableQuery, setTableQuery] = useState(GET_QUALIFICATION_CERTS);
  const [tableColumns, setTableColumns] = useState(CERTIFICATION_COLS);
  const [level, setLevel] = useState(null);
  const [state, setState] = useState(null);
  const [whereAnd, setWhereAnd] = useState([]);
  const [currentData, setCurrentData] = useState([]);

  const isAdmin = permissions?.superAdmin || permissions?.webEditor;

  const finalQualOptions = isAdmin
    ? qualOptions
    : [{ value: "skills", label: "Skills" }];

  const { data: inputOptionsData, inputOptionsLoading } = useQuery(
    FIND_MANY_CODES_FOR_SELECT,
    {
      variables: {
        where: {
          entity: {
            equals: qualType,
          },
        },
        orderBy: [{ title: "asc" }],
        distinct: ["title"],
      },
    }
  );

  const { loading } = useQuery(tableQuery, {
    variables: {
      where: {
        codes: {
          is: {
            AND: whereAnd,
          },
        },
        users: {
          is: {
            employmentStatus: {
              equals: "active",
            },
          },
        },
      },
      orderBy: [
        {
          users: {
            firstName: "asc",
          },
        },
      ],
    },
    skip: !qualType || !selects.length || !tableQuery,
    onCompleted: (d) => {
      const current =
        d?.findManyCertifications ||
        d?.findManyLicenses ||
        d?.findManySkills ||
        d?.findManyDeccoUCourses;

      const formatted = current.map((c) => ({
        fullName: `${uppercaseFirstLetter(
          c?.users?.firstName
        )} ${uppercaseFirstLetter(
          c?.users?.middleInitial
        )} ${uppercaseFirstLetter(c?.users?.lastName)}`,
        firstName: uppercaseFirstLetter(c?.users?.firstName),
        lastName: uppercaseFirstLetter(c?.users?.lastName),
        expDate:
          isValid(new Date(c?.expDate)) && c?.codes?.expirationType === "manual"
            ? format(new Date(c?.expDate), "MM/dd/yy").trim()
            : null,
        issueDate: isValid(new Date(c?.issueDate))
          ? format(new Date(c?.issueDate), "MM/dd/yy").trim()
          : "--",
        employeeNumber: c?.users?.employeeNumber?.trim(),
        title: c?.codes?.title,
        level:
          c?.codes?.level && c?.codes?.level !== "N/A" ? c?.codes?.level : "",
        state: c?.codes?.state,
        isExpired:
          new Date(c?.expDate) < new Date() &&
          c?.codes?.expirationType === "manual"
            ? "Expired"
            : "",

        certificationNumber: c?.certificationNumber,
        licenseNumber: c?.licenseNumber,
      }));

      setCurrentData(formatted);
    },
  });

  useEffect(() => {
    if (qualType === "skills") {
      setTableQuery(GET_QUALIFICATION_SKILLS);
      setTableColumns(SKILL_COLS);
    } else if (qualType === "certifications") {
      setTableQuery(GET_QUALIFICATION_CERTS);
      setTableColumns(CERTIFICATION_COLS);
    } else if (qualType === "licenses") {
      setTableQuery(GET_QUALIFICATION_LICENSES);
      setTableColumns(LICENSE_COLS);
    } else if (qualType === "deccoUCourses") {
      setTableQuery(GET_QUALIFICATION_DECCOU);
      setTableColumns(DECCOU_COLS);
    }
  }, [qualType]);

  useEffect(() => {
    const startingWhereAnd = [];

    if (selects?.length) {
      startingWhereAnd.push({
        title: {
          in: selects,
        },
      });
    }

    if (level?.value) {
      startingWhereAnd.push({
        level: {
          equals: level?.value,
          mode: "insensitive",
        },
      });
    }

    if (state?.value) {
      startingWhereAnd.push({
        state: {
          equals: state?.value,
          mode: "insensitive",
        },
      });
    }

    setWhereAnd(startingWhereAnd);
  }, [selects, level, state, selects.length]);

  useEffect(() => {
    if (currentData?.length && !selects?.length) {
      setCurrentData([]);
    }
  }, [currentData?.length, selects, selects.length]);

  const handleChange = (e, n) => {
    if (n === "level") {
      setLevel(e);
    } else if (n === "state") {
      setState(e);
    }
  };

  const handleSelectChange = (e) => {
    setSelects([]);
    setQualType(e?.value);
    setLevel(stateOptions[0]);
    setState(levelOptions[0]);
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileTable
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <ReportSelectMUI value={report} onChange={onReportChange} />
            </Box>
            <Box mt={1} width="20%">
              <MuiButton
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() =>
                  downloadLocalCsv(currentData, "qualifications", tableColumns)
                }
              >
                <FileDownload />
              </MuiButton>
            </Box>
          </React.Fragment>
        }
        loading={loading}
        maxHeight="100rem"
        title="Reports"
      >
        <Box height="100%" mb={2} pl={2} pr={2} width="100svw">
          <Control>
            <Label>Select Qualification Category</Label>
            <Select options={finalQualOptions} onChange={handleSelectChange} />
          </Control>
          {qualType &&
            inputOptionsData?.findManyCodes?.length &&
            !inputOptionsLoading && (
              <React.Fragment>
                <Control>
                  <Label>Select Qualification</Label>
                  <ReactSelect
                    isMulti
                    name="selects"
                    options={
                      // reduce to value and label but only return if title does not exist in acc
                      inputOptionsData?.findManyCodes?.reduce((acc, cur) => {
                        if (
                          !acc.find(
                            (a) => a?.label?.trim() === cur?.title?.trim()
                          )
                        ) {
                          acc.push({
                            value: cur?.title?.trim(),
                            label: cur?.title?.trim(),
                          });
                        }
                        return acc;
                      }, [])
                    }
                    value={selects?.map((s) => ({ value: s, label: s })) || []}
                    onChange={(e) => {
                      setSelects(e?.map((s) => s?.label));
                    }}
                  />
                </Control>

                <Control>
                  <Label>Select Level</Label>
                  <ReactSelect
                    name="level"
                    options={levelOptions}
                    value={level}
                    onChange={(e, n) => {
                      handleChange(e, n);
                    }}
                  />
                </Control>
                <Control>
                  <Label>Select State</Label>
                  <ReactSelect
                    name="state"
                    options={stateOptions}
                    value={state}
                    onChange={(e, n) => {
                      handleChange(e, n);
                    }}
                  />
                </Control>
              </React.Fragment>
            )}
        </Box>
        <Box maxHeight="40rem" minHeight="20rem" mt={2} sx={{ overflowY: "auto" }}>
          {loading ? (
            <BoxLoader />
          ) : (
            currentData?.map((item) => (
              <AccordionItem
                key={item?.employeeNumber}
                title={`${item?.firstName} ${item?.lastName}`}
              >
                <AccordionDetailsItem
                  label="Certification"
                  value={item?.title}
                />
                <AccordionDetailsItem
                  label="Certification #"
                  value={item?.certificationNumber || "N/A"}
                />
                <AccordionDetailsItem
                  label="Issue Date"
                  value={format(new Date(item?.issueDate), "MM-dd-yyyy")}
                />
                <AccordionDetailsItem
                  label="Expiration Date"
                  value={format(new Date(item?.expDate), "MM-dd-yyyy")}
                />
                <AccordionDetailsItem
                  label="Is Expired"
                  value={item?.isExpired}
                />
              </AccordionItem>
            ))
          )}
        </Box>
      </MobileTable>
    );
  }

  return (
    <ReportsTable columns={tableColumns} data={currentData} loading={loading}>
      <React.Fragment>
        {isAdmin && (
          <div className="table-utils">
            <Button
              className="download-btn"
              disabled={loading}
              size="medium"
              title="Download CSV"
              onClick={() => {
                downloadLocalCsv(currentData, "qualifications", tableColumns);
              }}
            >
              <Icon size="small">
                <FontAwesomeIcon icon="download" />
              </Icon>
            </Button>
          </div>
        )}
        <ReportHeader
          report={report}
          tabs={tabs}
          onReportChange={onReportChange}
        />
        <Control>
          <Label>Select Qualification Category</Label>
          <Select options={finalQualOptions} onChange={handleSelectChange} />
        </Control>
        {qualType &&
          inputOptionsData?.findManyCodes?.length &&
          !inputOptionsLoading && (
            <React.Fragment>
              <Control>
                <Label>Select Qualification</Label>
                <ReactSelect
                  isMulti
                  name="selects"
                  options={
                    // reduce to value and label but only return if title does not exist in acc
                    inputOptionsData?.findManyCodes?.reduce((acc, cur) => {
                      if (
                        !acc.find(
                          (a) => a?.label?.trim() === cur?.title?.trim()
                        )
                      ) {
                        acc.push({
                          value: cur?.title?.trim(),
                          label: cur?.title?.trim(),
                        });
                      }
                      return acc;
                    }, [])
                  }
                  value={selects?.map((s) => ({ value: s, label: s })) || []}
                  onChange={(e) => {
                    setSelects(e?.map((s) => s?.label));
                  }}
                />
              </Control>

              <Control>
                <Label>Select Level</Label>
                <ReactSelect
                  name="level"
                  options={levelOptions}
                  value={level}
                  onChange={(e, n) => {
                    handleChange(e, n);
                  }}
                />
              </Control>
              <Control>
                <Label>Select State</Label>
                <ReactSelect
                  name="state"
                  options={stateOptions}
                  value={state}
                  onChange={(e, n) => {
                    handleChange(e, n);
                  }}
                />
              </Control>
            </React.Fragment>
          )}
      </React.Fragment>
    </ReportsTable>
  );
};

QualificationsTable.propTypes = {
  onReportChange: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default QualificationsTable;
