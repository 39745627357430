import React from "react";
import PropTypes from "prop-types";
import { useCurrentUser } from "../../../../hooks";
import EvaluationListItem from "../EvaluationListItem";
import { evalEmployeeNameSort } from "../../../../utils";

const CompleteEvaluationsList = ({ evaluations, sectionType, handleClick }) => {
  const user = useCurrentUser();

  const activeEmployeeNumber = user?.employeeNumber;

  if (sectionType === "Peer") {
    const peerEvals = evaluations.filter(
      (evaluation) =>
        evaluation.status === "COMPLETED" &&
        (evaluation?.title?.includes("Internal Client") ||
          evaluation?.title?.includes("Periodic Performance")) &&
        evaluation.formAuthorEmployeeNumber === activeEmployeeNumber &&
        evaluation.ownerEmployeeNumber !== activeEmployeeNumber &&
        evaluation.managerEmployeeNumber !== activeEmployeeNumber // add in condition where manager does not see PPA or Intenal Client for those that they are the manager of in the completed peer eval section
    );

    return (
      <React.Fragment>
        {evalEmployeeNameSort(peerEvals)?.map((e) => (
          <React.Fragment key={e?.id}>
            <EvaluationListItem e={e} handleClick={handleClick} title="View" />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  if (sectionType === "Manager" && evaluations) {
    const managerEvals = evaluations?.filter(
      (evaluation) =>
        evaluation.status === "COMPLETED" &&
        evaluation.managerEmployeeNumber === activeEmployeeNumber
    );

    return (
      <React.Fragment>
        {evalEmployeeNameSort(managerEvals)?.map((e) => (
          <React.Fragment key={e?.id}>
            <EvaluationListItem e={e} handleClick={handleClick} title="View" />
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  const personalEvals = evaluations?.filter(
    (evaluation) =>
      evaluation.status === "COMPLETED" &&
      !evaluation?.title.includes("Internal") &&
      !evaluation?.title.includes("PPA") &&
      evaluation.ownerEmployeeNumber === activeEmployeeNumber
  );

  return (
    <React.Fragment>
      {evalEmployeeNameSort(personalEvals)?.map((e) => (
        <React.Fragment key={e?.id}>
          <EvaluationListItem
            isPersonalEval
            e={e}
            handleClick={handleClick}
            title="View"
          />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

CompleteEvaluationsList.propTypes = {
  evaluations: PropTypes.array.isRequired,
  sectionType: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
};

CompleteEvaluationsList.defaultProps = {
  sectionType: "",
};

export default CompleteEvaluationsList;
