import React, { useState, createContext, useContext } from "react";
import PropTypes from "prop-types";

import { Modal, Box } from "rbx";

import isMobile from "../utils/checkForMobile";

const initialState = {
  isModalOpen: false,
  modalContent: "",
};

export const ModalContext = createContext(initialState);

export const ModalConsumer = ({allowOverflow} ) => {
  const overlay = allowOverflow;
  const mobile = isMobile();
  
  return (
    <ModalContext.Consumer>
      {({ isModalOpen, modalContent, setModalOpen }) => (
        <Modal
          clipped
          closeOnEsc
          active={isModalOpen}
          onClose={() => setModalOpen(false, "")}
        >
          <Modal.Background />
          <Modal.Content style={{overflow: overlay && !mobile ? "visible": "auto"}}>
            <Box>{modalContent}</Box>
          </Modal.Content>
          <Modal.Close />
        </Modal>
      )}
    </ModalContext.Consumer>
  );
}
ModalConsumer.propTypes = {
  allowOverflow: PropTypes.bool,
};

ModalConsumer.defaultProps = {
  allowOverflow: false,
};

export const ModalProvider = ({ allowOverflow, children }) => {
  const [state, setState] = useState(initialState);

  const setModalOpen = (isModalOpen, modalContent) => {
    setState({ isModalOpen, modalContent });
  };

  return (
    <ModalContext.Provider value={{ ...state, setModalOpen }}>
      {children}
      <ModalConsumer allowOverflow/>
    </ModalContext.Provider>
  );
};

ModalProvider.propTypes = {
  children: PropTypes.node,
  allowOverflow: PropTypes.bool,
};

ModalProvider.defaultProps = {
  children: null,
  allowOverflow: false,
};
export const useModal = () => useContext(ModalContext);

export default ModalContext;
