import { gql } from "@apollo/client";

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateOneUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    updateOneUser(data: $data, where: $where) {
      profileImage
      id
      employeeNumber
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword(
    $data: UpdatePasswordInput!
    $where: UserWhereUniqueInput!
  ) {
    updatePassword(data: $data, where: $where)
  }
`;

export const EMAIL_NOTIFICATION_ACCOUNT_INQUIRY_MUTATION = gql`
  mutation EmailNotificationAccountInquiry($data: UserCreateInput!) {
    emailNotificationAccountInquiry(data: $data)
  }
`;

export const FORGOT_PASSWORD_RECOVERY_MUTATION = gql`
  mutation ForgotPasswordRecovery(
    $data: ForgotPasswordInput!
    $where: UserWhereInput!
  ) {
    forgotPasswordRecovery(data: $data, where: $where) {
      employeeId
    }
  }
`;

export const RESET_PASSWORD_RECOVERY_MUTATION = gql`
  mutation ResetPasswordRecovery(
    $data: UserUpdateInput!
    $where: UserWhereInput!
  ) {
    resetPasswordRecovery(data: $data, where: $where)
  }
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser($data: UserCreateInput!) {
    createUser(data: $data) {
      id
      employeeNumber
    }
  }
`;

export const UPDATE_CERTIFICATIONS = gql`
  mutation UpdateCertifications(
    $data: CertificationsUpdateInput!
    $where: CertificationsWhereUniqueInput!
  ) {
    updateCertifications(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_LICENSES = gql`
  mutation UpdateLicenses(
    $data: LicensesUpdateInput!
    $where: LicensesWhereUniqueInput!
  ) {
    updateLicenses(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_SKILLS = gql`
  mutation UpdateSkills(
    $data: SkillsUpdateInput!
    $where: SkillsWhereUniqueInput!
  ) {
    updateSkills(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_CERTIFICATIONS = gql`
  mutation DeleteCertifications($where: CertificationsWhereUniqueInput!) {
    deleteCertifications(where: $where) {
      id
    }
  }
`;

export const DELETE_LICENSES = gql`
  mutation DeleteLicenses($where: LicensesWhereUniqueInput!) {
    deleteLicenses(where: $where) {
      id
    }
  }
`;

export const DELETE_SKILLS = gql`
  mutation DeleteSkills($where: SkillsWhereUniqueInput!) {
    deleteSkills(where: $where) {
      id
    }
  }
`;

export const CREATE_CERTIFICATIONS = gql`
  mutation CreateCertificatinos($data: CertificationsCreateInput!) {
    createCertifications(data: $data) {
      id
    }
  }
`;

export const CREATE_LICENSES = gql`
  mutation CreateLicenses($data: LicensesCreateInput!) {
    createLicenses(data: $data) {
      id
    }
  }
`;

export const CREATE_SKILLS = gql`
  mutation CreateSkills($data: SkillsCreateInput!) {
    createSkills(data: $data) {
      id
    }
  }
`;

export const UPDATE_YOS_NOTE = gql`
  mutation UpdateUser($data: UserUpdateInput!, $where: UserWhereUniqueInput!) {
    updateUser(data: $data, where: $where) {
      id
    }
  }
`;

export const CREATE_TECH_COURSES = gql`
  mutation CreatedeccoUCourses($data: deccoUCoursesCreateInput!) {
    createdeccoUCourses(data: $data) {
      id
    }
  }
`;

export const UPDATE_TECH_COURSES = gql`
  mutation UpdatedeccoUCourses(
    $data: deccoUCoursesUpdateInput!
    $where: deccoUCoursesWhereUniqueInput!
  ) {
    updatedeccoUCourses(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_TECH_COURSES = gql`
  mutation UpdatedeccoUCourses($where: deccoUCoursesWhereUniqueInput!) {
    deletedeccoUCourses(where: $where) {
      id
    }
  }
`;
