import React from "react";
import PropTypes from "prop-types";

import { Button, Title } from "rbx";
import { Paper } from "@mui/material";
import capitalizeEveryWord from "../../../../utils/capitalizeEveryWord";
import formatDate from "../../../../utils/formatDate";
import checkMobile from "../../../../utils/checkForMobile";

const GoalListItem = ({
  goal,
  goalIndex,
  handleClick,
  handleOpenGoalAdd,
  isCompletedSection = false,
  isUsersOwnGoals,
  showManagerReviews,
}) => {
  const isMobile = checkMobile();
  const direction = isMobile ? "column" : "row";
  return (
    <li>
      <Paper elevation={4} style={{ padding: "1rem", marginBottom: "1rem", backgroundColor: isMobile ? "#f5f5f5" : "white" }}>
        <div
          className="review-item"
          key={goal.id}
          style={{ flexDirection: "column" }}
        >
          {isCompletedSection && goal?.updatedAt && (
            <div style={{ width: "100%" }}>
              <h3
                style={{
                  textAlign: isMobile ? "center" : "right",
                  marginBottom: "1rem",
                  color: "green",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <b>Completed: </b>
                {`${formatDate(new Date(goal?.updatedAt))}`}
              </h3>
            </div>
          )}
          {!isCompletedSection && goal?.dueDate && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: isMobile ? "center" : "right",
              }}
            >
              <h3
                style={{
                  marginBottom: "1rem",
                  color: new Date(goal?.dueDate) < new Date() ? "red" : "black",
                }}
              >
                <b>Due: </b> {`${formatDate(new Date(goal?.dueDate))}`}
              </h3>
            </div>
          )}
          <p>
            <h3>{`${capitalizeEveryWord(goal.title)}`}</h3>
          </p>
          <p style={{ textAlign: isMobile ? "center" : "left" }}>
            <b>Description: </b>
            {`${goal?.description}`}
          </p>
          <hr />
          <div
            style={{
              display: "flex",
              flexDirection: direction,
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!isCompletedSection && showManagerReviews && !isUsersOwnGoals && (
              <React.Fragment>
                <Button
                  className="review-option-primary-goal"
                  style={{
                    marginBottom: "0.5rem",
                    padding: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  type="button"
                  onClick={() => handleOpenGoalAdd(goal, "Edit")}
                >
                  <Title size={6} textColor="white">
                    Edit
                  </Title>
                </Button>
                <Button
                  className="review-option-tertiary-goal"
                  style={{
                    marginBottom: "0.5rem",
                    padding: "0.5rem",
                    marginRight: "0.5rem",
                  }}
                  type="button"
                  onClick={() =>
                    handleClick(goal, goalIndex, isCompletedSection, "delete")
                  }
                >
                  <Title size={6} textColor="white">
                    Complete | Delete
                  </Title>
                </Button>
              </React.Fragment>
            )}

            <Button
              className="review-option-secondary-goal"
              style={{ marginBottom: "0.5rem", padding: "0.5rem" }}
              type="button"
              onClick={() => handleClick(goal, goalIndex, isCompletedSection)}
            >
              <Title size={6} textColor="white">
                View
              </Title>
            </Button>
          </div>
        </div>
      </Paper>
    </li>
  );
};

GoalListItem.propTypes = {
  goal: PropTypes.object.isRequired,
  goalIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleOpenGoalAdd: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool,
  isUsersOwnGoals: PropTypes.bool,
  showManagerReviews: PropTypes.bool,
};

GoalListItem.defaultProps = {
  isCompletedSection: false,
  isUsersOwnGoals: false,
  showManagerReviews: false,
};

export default GoalListItem;
