import formatDateWithTime from "../../../utils/formateDateWithTime";
import capitalizeEveryWord from "../../../utils/capitalizeEveryWord";

// eslint-disable-next-line import/prefer-default-export
export const PROJECT_HISTORY_COLUMNS = [
  {
    header: "Project Id",
    id: "projectId",
    accessorKey: "projectId",
  },
  {
    header: "Project Name",
    id: "projectName",
    accessorKey: "projectName",
  },
  {
    header: "Updated By",
    id: "updatedBy",
    cell: ({ row }) => capitalizeEveryWord(row?.original?.updatedByFullName),
  },
  {
    header: "Updated At",
    id: "updatedAt",
    cell: ({ row }) =>
      row?.original?.updatedAt && formatDateWithTime(row?.original?.updatedAt),
  },
];
