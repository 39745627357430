import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";

import { Control, Input, Label } from "rbx";
import { stripTimeZone } from "../../utils";
import "./DateInput.scss";

const DateInput = ({
  id,
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  required,
  getValue,
  setValue,
}) => {
  const [date, setDate] = useState();

  useEffect(() => {
    if (value) {
      const formattedValue = stripTimeZone(value);
      setDate(format(new Date(formattedValue), "yyyy-MM-dd"));
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      onChange({
        target: {
          name,
          value: stripTimeZone(value),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    const val = e?.target?.value || e?.value;

    const strippedValue = stripTimeZone(val);

    setDate(format(new Date(strippedValue), "yyyy-MM-dd"));

    onChange({
      target: {
        name: e?.target?.name || e?.name,
        value: strippedValue,
      },
    });
  };

  return (
    <Control expanded>
      {label && <Label>{label}</Label>}
      <Input
        className={disabled && "disabled-background"}
        defaultValue={date}
        disabled={disabled}
        id={id}
        key={date}
        name={name}
        required={required}
        size={size}
        type="date"
        onChange={handleChange}
      />
    </Control>
  );
};

DateInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
};

DateInput.defaultProps = {
  value: "",
  name: "",
  label: "",
  size: undefined,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  id: "",
  required: false,
};

export default DateInput;
