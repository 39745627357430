import React from "react";
import PropTypes from "prop-types";
import { Button } from "rbx";
import ReactSelect from "react-select";

import { FileUploader } from "../../../../components";
import { UserForm } from "..";

const Buttons = ({
  employeeData,
  setModalOpen,
  setLoading,
  permissionLevels,
  handleDownloadCellPhoneList,
  editMode,
  openAssignmentHistory,
  onEditClick,
  handleAddClick,
  fileName,
  qualificationOptions,
  uploadProfilePermissions,
  handleUploadImageChange,
  mobile
}) => (
  <div className="buttons">
    <Button
      disabled={false}
      onClick={() =>
        setModalOpen(
          true,
          <UserForm
            mobile
            employeeData={employeeData}
            permissionLevels={permissionLevels}
            onComplete={() => {
              setLoading(false);
              setModalOpen(false, "");
            }}
          />
        )
      }
    >
      Update User Info
    </Button>
    <Button style={{ background: "#346bd7" }}>
      <a
        href="https://decco.myabsorb.com/#/transcript"
        rel="noopener noreferrer"
        style={{ color: "white" }}
        target="_blank"
      >
        DECCO U Transcript
      </a>
    </Button>
    <Button
      style={{ background: "#188735", color: "white" }}
      onClick={() => handleDownloadCellPhoneList()}
    >
      DECCO Cell Phone List
    </Button>

    <Button className="light-blue-btn" onClick={() => openAssignmentHistory()}>
      Assignment History
    </Button>
    {(permissionLevels.fieldSupervisor ||
      permissionLevels.superAdmin ||
      permissionLevels.webEditor) && (
      <React.Fragment>
        <Button
          className="edit-button"
          disabled={false}
          onClick={() => onEditClick()}
        >
          {mobile ? "Add Qualifications" : "Edit Qualifications"}
        </Button>
        {editMode && (
          <div
            style={{
              minWidth: "200px",
              width: "200px",
            }}
          >
            <ReactSelect
              options={qualificationOptions?.map((tab) => ({
                label: tab,
                value: tab,
              }))}
              placeholder="Select what to add"
              onChange={(e) => {
                handleAddClick(e.value);
              }}
            />
          </div>
        )}
        {!editMode && uploadProfilePermissions && (
          <FileUploader
            employeeId={employeeData?.id}
            fileName={fileName || ""}
            name="profile"
            value={fileName || ""}
            onChange={handleUploadImageChange}
          />
        )}
      </React.Fragment>
    )}
  </div>
);

Buttons.propTypes = {
  employeeData: PropTypes.object,
  editMode: PropTypes.bool,
  fileName: PropTypes.string,
  handleAddClick: PropTypes.func,
  handleDownloadCellPhoneList: PropTypes.func,
  openAssignmentHistory: PropTypes.func,
  permissionLevels: PropTypes.object,
  qualificationOptions: PropTypes.array,
  setLoading: PropTypes.func,
  setModalOpen: PropTypes.func,
  uploadProfilePermissions: PropTypes.bool,
  onEditClick: PropTypes.func,
  handleUploadImageChange: PropTypes.func,
  mobile: PropTypes.bool,
};

Buttons.defaultProps = {
  employeeData: {},
  editMode: false,
  fileName: "",
  handleAddClick: () => null,
  handleDownloadCellPhoneList: () => null,
  openAssignmentHistory: () => null,
  permissionLevels: PropTypes.object,
  qualificationOptions: PropTypes.array,
  setLoading: () => null,
  setModalOpen: () => null,
  uploadProfilePermissions: false,
  onEditClick: () => null,
  handleUploadImageChange: () => null,
  mobile: false,
};

export default Buttons;
