import { useQuery, useLazyQuery } from "@apollo/client";

import { FoundationApolloClient } from "../graphql/client";

export function useFoundationQuery(query, config) {
  return useQuery(query, { ...config, client: FoundationApolloClient });
}

export function useFoundationLazyQuery(query, config) {
  return useLazyQuery(query, { ...config, client: FoundationApolloClient });
}
