// import formatDate from "../../../utils/formatDate";

export const REVIEW_COLUMNS = [
  {
    header: "id",
    id: "id",
    accessorKey: "id",
  },
  {
    header: "Manager",
    id: "manager",
    accessorKey: "manager",
  },
  {
    header: "Title",
    id: "title",
    accessorKey: "title",
  },
  {
    header: "Year",
    id: "year",
    accessorKey: "year",
  },
  {
    header: "templateVersionId",
    id: "templateVersionId",
    accessorKey: "templateVersionId",
  },
  {
    header: "dateCompleted",
    id: "dateCompleted",
    accessorKey: "dateCompleted",
  },
  {
    header: "dateToCompleteBy",
    id: "dateToCompleteBy",
    accessorKey: "dateToCompleteBy",
  },
  {
    header: "status",
    id: "status",
    accessorKey: "status",
  },
  {
    header: "userId",
    id: "userId",
    accessorKey: "userIds",
  },
];

export const GOALS_COLUMNS = [
  {
    header: "Partner First Name",
    id: "firstName",
    accessorKey: "firstName",
  },
  {
    header: "Partner Last Name",
    id: "lastName",
    accessorKey: "lastName",
  },
  {
    header: "Group",
    id: "group",
    accessorKey: "group",
  },
  {
    header: "Partner ID",
    id: "employeeNumber",
    accessorKey: "employeeNumber",
  },
  {
    header: "Manager ID",
    id: "manager",
    accessorFn: ( row ) =>
      `${row?.supervisorObj?.employeeNumber}`
  },
];
