import React from "react";
import PropTypes from "prop-types";
import { useModal } from "../../../../context";
import { useCurrentUser } from "../../../../hooks";
import AssignEvaluationForm from "../AssignEvaluationForm/AssignEvaluationForm";
import EvaluationListItem from "../EvaluationListItem";
import getButtonType from "../../utils/getButtonType";
import { evalEmployeeNameSort } from "../../../../utils";

const IncompleteEvaluationsList = ({
  evaluations,
  sectionType,
  handleClick,
  refetchMyReviews,
}) => {
  const { setModalOpen } = useModal();

  const user = useCurrentUser();

  const activeEmployeeNumber = user?.employeeNumber;


  const handleComplete = async (type) => {
    if(type === "cancel") {
      setModalOpen(false);
      return;
    }
    await refetchMyReviews();
    setModalOpen(false);
  };

  const handleOpenAssignEvaluationModal = (event, evaluation) => {
    event.preventDefault();
    setModalOpen(
      true,
      <AssignEvaluationForm
        evaluation={evaluation}
        reviewId={evaluation?.reviewId}
        onComplete={(type) => handleComplete(type)}
      />
    );
  };

  if (sectionType === "Peer") {
    const peerEvals = evaluations?.filter(
      (evaluation) =>
        evaluation.ownerEmployeeNumber !== activeEmployeeNumber &&
        evaluation.authorEmployeeNumber === activeEmployeeNumber &&
        (evaluation?.title?.includes("Internal Client") ||
          evaluation?.title?.includes("Periodic Performance")) &&
          evaluation?.needsAssignment === false &&
        (evaluation?.status === "INCOMPLETE" ||
          evaluation.status === "SAVE_FOR_LATER")
    );


    return (
      <React.Fragment>
        {evalEmployeeNameSort(peerEvals)?.map((e) => (
          <React.Fragment key={e?.id}>
            {getButtonType(e, activeEmployeeNumber, sectionType) ===
              "Resume" && (
              <EvaluationListItem
                e={e}
                handleClick={handleClick}
                title="Resume"
              />
            )}
            {getButtonType(e, activeEmployeeNumber, sectionType) ===
              "Start" && (
              <EvaluationListItem
                e={e}
                handleClick={handleClick}
                refetchMyReviews={refetchMyReviews}
                title="Start"
              />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }



  if (sectionType === "Manager") {
    return (
      <React.Fragment>
        {evalEmployeeNameSort(evaluations)?.map((e) => (
          <React.Fragment key={e?.id}>
            {getButtonType(e, activeEmployeeNumber, sectionType) ===
              "Sign Off" && (
              <EvaluationListItem
                e={e}
                handleClick={handleClick}
                refetchMyReviews={refetchMyReviews}
                title="Sign Off"
              />
            )}
            {getButtonType(e, activeEmployeeNumber, sectionType) ===
              "Assign" && (
              <EvaluationListItem
                e={e}
                handleClick={handleOpenAssignEvaluationModal}
                refetchMyReviews={refetchMyReviews}
                title="Assign"
              />
            )}
            {getButtonType(e, activeEmployeeNumber, sectionType) ===
              "Resume" && (
              <EvaluationListItem
                e={e}
                handleClick={handleClick}
                title="Resume"
              />
            )}
            {getButtonType(e, activeEmployeeNumber, sectionType) ===
              "Start" && (
              <EvaluationListItem
                e={e}
                handleClick={handleClick}
                refetchMyReviews={refetchMyReviews}
                title="Start"
              />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    );
  }

  const personalEvals = evaluations?.filter(
    (evaluation) =>
      evaluation.ownerEmployeeNumber === activeEmployeeNumber &&
      evaluation.authorEmployeeNumber === activeEmployeeNumber &&
      (evaluation.status === "INCOMPLETE" ||
        evaluation.status === "SAVE_FOR_LATER" ||
        (evaluation.status === "COMPLETED" && evaluation.needsSignature))
  );

  return (
    <React.Fragment>
      {evalEmployeeNameSort(personalEvals)?.map((e) => (
        <React.Fragment key={e?.id}>
          {getButtonType(e, activeEmployeeNumber, sectionType) ===
            "Sign Off" && (
            <EvaluationListItem
              e={e}
              handleClick={handleClick}
              refetchMyReviews={refetchMyReviews}
              title="Sign Off"
            />
          )}
          {getButtonType(e, activeEmployeeNumber, sectionType) === "Resume" && (
            <EvaluationListItem
              isPersonalEval
              e={e}
              handleClick={handleClick}
              title="Resume"
            />
          )}
          {getButtonType(e, activeEmployeeNumber, sectionType) === "Start" && (
            <EvaluationListItem
              isPersonalEval
              e={e}
              handleClick={handleClick}
              refetchMyReviews={refetchMyReviews}
              title="Start"
            />
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

IncompleteEvaluationsList.propTypes = {
  evaluations: PropTypes.array.isRequired,
  sectionType: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  refetchMyReviews: PropTypes.func,
};

IncompleteEvaluationsList.defaultProps = {
  sectionType: "",
  refetchMyReviews: () => {},
};

export default IncompleteEvaluationsList;
