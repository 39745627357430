import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, TextField, useTheme } from "@mui/material";
import { StatusSelectMUI } from "../../../../components";

const ProjectFilters = ({ onChange, inputs }) => {
  const theme = useTheme();

  const handleChange = (name, value) => {
    onChange(name, value);
  };

  return (
    <React.Fragment>
      <Typography fullWidth mb={2} ml={2} mr={2} mt={1} variant="h5">
        Project Filters
      </Typography>
      <Box
        display="grid"
        gap={2}
        sx={{
          width: "100%",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "1fr 1fr",
          },
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
        }}
      >
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Project Number"
          name="project_num"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.project_num}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Project Name"
          name="name"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.name}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Manager"
          name="manager"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.manager}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Supervisor"
          name="supervisor"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.supervisor}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <StatusSelectMUI
          showAllOption
          label="Status"
          name="status"
          value={inputs?.status}
          onChange={handleChange}
        />
      </Box>
    </React.Fragment>
  );
};

ProjectFilters.propTypes = {
  onChange: PropTypes.func,
  inputs: PropTypes.object,
};

ProjectFilters.defaultProps = {
  onChange: () => null,
  inputs: {},
};

export default ProjectFilters;
