import React from "react";
import { Control, Input, Label } from "rbx";
import PropTypes from "prop-types";
import { debounce } from "../../utils";

const TextInput = ({ label, name, onChange, size, readOnly, placeholder }) => (
  <Control className="Text-Input">
    <Label className="text-input-label">{label}</Label>
    <Input
      name={name}
      placeholder={placeholder}
      readOnly={readOnly}
      size={size}
      type="text"
      onChange={debounce(({ target: { name: n, value: v } }) => {
        onChange(n, v);
      }, 500)}
    />
  </Control>
);

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
};

TextInput.defaultProps = {
  size: "medium",
  readOnly: false,
  placeholder: "",
};

export default TextInput;
