/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback } from "react";

import { useLazyQuery } from "@apollo/client";
import {
  AssignmentLate as AssignmentLateIcon,
  FilterAlt,
  Refresh as RefreshIcon,
} from "@mui/icons-material";
import {
  useTheme,
  useMediaQuery,
  TablePagination,
  TableContainer,
  Stack,
  IconButton,
  Box,
  Button,
} from "@mui/material";

import PropTypes from "prop-types";
import { format } from "date-fns";
import { useModal } from "../../context";

import { useLocalStorage, useEventListener } from "../../hooks";
import equals from "../../utils/equals";
import omitBy from "../../utils/omitBy";
import Loader from "../Loader";

import ActionComponentWrapper from "./ActionComponentWrapper";
import Table from "./Table";
import MobileTable from "../ReportsTable/MobileTable";
import AccordionItem from "../AccordionItem";
import AccordionDetailsItem from "../AccordionDetailsItem";
import BoxLoader from "./BoxLoader/BoxLoader";
import checkMobile from "../../utils/checkForMobile";

// THIS IS THE ONE WE ARE USING FOR MAIN TABLES
const DetailedDataTable = ({
  columns,
  query,
  dataExtractor,
  totalExtractor,
  searchTransformer,
  defaultSorting = [],
  onRowClick = () => {},
  onCellClick = () => {},
  fetchPolicy = "cache-and-network",
  where: initialWhere = {},
  getIsRowSelected = () => false,
  FilterComponent = null,
  ActionComponent = null,
  ViewsComponent = null,
  opVarStorageKey = null,
  location = "",
  fullWidth = false,
  isModalTable = false,
  aggregateName = "",
  name = "",
}) => {
  const { setModalOpen } = useModal();
  const [currentPage, setCurrentPage] = useState(0);
  const [sorting, setSorting] = useState(defaultSorting || []);
  const [where, setWhere] = useState(initialWhere);
  // const [search, setSearch] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const isMobile = checkMobile();

  const [runQuery, { data, loading }] = useLazyQuery(query, {
    fetchPolicy,
  });

  const [defaultPageSize, setDefaultPageSize] = useLocalStorage(
    "DEFAULT_PAGE_SIZE",
    10
  );

  // const [debouncedSearch] = useDebouncedValue(search, 1000);
  const isSomeRowsSelected = !!Object.keys(rowSelection).length;

  const executeQuery = useCallback(() => {
    const variables = {
      where: omitBy(where, (x, y) => !x || y === "overdue"),
      orderBy: sorting.map((x) => {
        const sortDirection = x.desc ? "desc" : "asc";
        if (x.id === "subject") {
          return {
            user: { firstName: sortDirection },
          };
        }
        if (x.id === "signer") {
          return {
            signerName: sortDirection,
          };
        }
        return {
          [x.id]: sortDirection,
        };
      }),
      take: isMobile ? 10000 : defaultPageSize,
      skip: currentPage * defaultPageSize,
      sectionsOrderBy: [{ order: "asc" }],
      questionsOrderBy: [{ order: "asc" }],
    };
    if (opVarStorageKey) {
      window.sessionStorage.setItem(opVarStorageKey, JSON.stringify(variables));
    }
    runQuery({
      variables,
    });
  }, [
    where,
    sorting,
    defaultPageSize,
    currentPage,
    isMobile,
    opVarStorageKey,
    runQuery,
  ]);

  useEffect(() => {
    executeQuery();
  }, [executeQuery]);

  const handleChangePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setDefaultPageSize(parseInt(e.target.value, 10));
    setCurrentPage(0);
  };

  const handleSortingChange = (fn) => {
    const nextSort = fn();
    if (nextSort[0].id !== "overdue") {
      setSorting(nextSort);
      setCurrentPage(0);
    }
  };

  const handleRowSelectionChange = (result) => {
    setRowSelection(result);
  };

  const [result, setResult] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const nextResult = dataExtractor(data);
    const nextTotal = totalExtractor(data?.[aggregateName]?._count?._all);
    if (nextResult) {
      setResult(nextResult);
    }
    if (nextTotal) {
      setTotal(nextTotal);
    }
  }, [data, dataExtractor, totalExtractor, aggregateName]);

  // useEffect(() => {
  //   const nextWhere = searchTransformer?.(where, debouncedSearch);
  //   const isWhereEqual = equals(where, nextWhere);
  //   if (!isWhereEqual) {
  //     // setWhere(nextWhere);
  //     setCurrentPage(0);
  //   }
  // }, [debouncedSearch, searchTransformer, where]);

  useEventListener("keydown", (e) => {
    if (e.key === "ArrowRight") {
      e.preventDefault();
      setCurrentPage((prev) => Math.min(prev + 1, total / defaultPageSize - 1));
    }
    if (e.key === "ArrowLeft") {
      e.preventDefault();
      setCurrentPage((prev) => Math.max(prev - 1, 0));
    }
  });

  const handleFilterComplete = (nextWhere) => {
    const updateWhereFormat = Object.keys(nextWhere).forEach((key) => {
      if (
        key === "dateCompleted" ||
        key === "dateToCompleteBy" ||
        key === "assignmentDate" ||
        key === "startDate"
      ) {
        if (key === "dateToCompleteBy" || key === "dateCompleted") {
          if (!nextWhere?.overdue) {
            nextWhere[key] = {
              gte: nextWhere[key]?.gte,
              lte: nextWhere[key]?.lte,
            };
          }
        } else {
          nextWhere[key] = {
            gte: nextWhere[key]?.gte,
            lte: nextWhere[key]?.lte,
          };
        }
      } else if (
        (key === "title" || key === "name") &&
        typeof nextWhere[key] === "string" &&
        nextWhere[key] !== ""
      ) {
        nextWhere[key] = { contains: nextWhere[key] };
      } else if (
        key === "overdue" &&
        nextWhere[key] !== "" &&
        nextWhere[key] !== where[key]
      ) {
        if (nextWhere[key] === "Yes") {
          // date is less than today and dateCompleted is null
          nextWhere.dateToCompleteBy = { lt: new Date().toISOString() };
          nextWhere.dateCompleted = { not: { contains: "+" } }; // basically a check for exists
        } else if (nextWhere[key] === "No") {
          // date is greater than today
          nextWhere.dateToCompleteBy = { gte: new Date().toISOString() };
          delete nextWhere.dateCompleted;
        }
      } else if (key === "needsAssignment") {
        const bool = nextWhere[key] === "Yes";
        nextWhere.needsAssignment = { equals: bool };
      } else if (typeof nextWhere[key] === "string" && nextWhere[key] !== "") {
        nextWhere[key] = { equals: nextWhere[key] };
      }
    });
    const isWhereEqual = equals(where, updateWhereFormat);
    if (!isWhereEqual) {
      setWhere(nextWhere);
      setCurrentPage(0);
    }
  };

  const handleFilterComponentClick = () => {
    if (typeof FilterComponent === "function") {
      setModalOpen(
        true,
        <FilterComponent
          inputs={where}
          location={location}
          onComplete={handleFilterComplete}
        />
      );
    }
  };

  const handleViewsComponentClick = () => {
    if (typeof ViewsComponent === "function") {
      setModalOpen(true, <ViewsComponent onComplete={handleFilterComplete} />);
    }
  };

  // const handleSearchChange = (e) => {
  //   setSearch(e.target.value);
  // };

  const handleClearRowSelection = () => {
    setRowSelection({});
  };

  // const handleScrollChange = (e) => {
  //   const bottom =
  //     e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

  //   if (bottom && take < total) {
  //     setTake(take + 20);
  //   }
  // };

  const getOverdueStatus = (dateToCompleteBy, dateCompleted) =>
    dateToCompleteBy &&
    new Date(dateToCompleteBy) < new Date() &&
    !dateCompleted
      ? "Yes"
      : "No";

  const getItemByTableName = (item) => {
    if (name === "all-reviews") {
      return (
        // prob want to move into seperate component ReviewsAccordionItem..
        <AccordionItem
          info={
            getOverdueStatus(item?.dateToCompleteBy, item?.dateCompleted) ===
            "Yes" ? (
              <AssignmentLateIcon />
            ) : null
          }
          key={item?.id}
          title={`${item?.user?.firstName} ${item?.user?.lastName}`}
        >
          <AccordionDetailsItem label="Status" value={item?.status} />
          <AccordionDetailsItem label="Title" value={item?.title} />
          <AccordionDetailsItem label="year" value={item?.year} />
          <AccordionDetailsItem label="Manager" value={item?.manager} />
          <AccordionDetailsItem
            label="Subject"
            value={`${item?.user?.firstName} ${item?.user?.lastName}`}
          />
          <AccordionDetailsItem
            label="Due Date"
            value={
              item?.dateToCompleteBy
                ? format(new Date(item?.dateToCompleteBy), "MM/dd/yyyy")
                : ""
            }
          />
          <AccordionDetailsItem
            label="Overdue"
            value={getOverdueStatus(
              item?.dateToCompleteBy,
              item?.dateCompleted
            )}
          />
          <AccordionDetailsItem
            label="Date Completed"
            value={format(new Date(item?.dateCompleted), "MM/dd/yyyy")}
          />
          <Button
            fullWidth
            color="secondary"
            sx={{ color: theme.palette.common.white }}
            variant="contained"
            onClick={() => onRowClick(item)}
          >
            Details
          </Button>
        </AccordionItem>
      );
    }
    if (name === "all-evals") {
      return (
        <AccordionItem
          info={
            getOverdueStatus(item?.dateToCompleteBy, item?.dateCompleted) ===
            "Yes" ? (
              <AssignmentLateIcon />
            ) : null
          }
          key={item?.id}
          title={item?.owner}
        >
          <AccordionDetailsItem label="Status" value={item?.status} />
          <AccordionDetailsItem label="Title" value={item?.title} />
          <AccordionDetailsItem label="Manager" value={item?.manager} />
          <AccordionDetailsItem label="Writer" value={item?.formAuthor} />
          <AccordionDetailsItem
            label="Subject"
            value={item?.owner}
          />
          <AccordionDetailsItem label="Current Author" value={item?.author} />
          <AccordionDetailsItem
            label="Needs Assignment"
            value={item?.needsAssignment ? "Yes" : "No"}
          />
          <AccordionDetailsItem
            label="Assignment Date"
            value={
              item?.assignmentDate
                ? format(new Date(item?.assignmentDate), "MM/dd/yyyy")
                : ""
            }
          />
          <AccordionDetailsItem
            label="Due Date"
            value={
              item?.dateToCompleteBy
                ? format(new Date(item?.dateToCompleteBy), "MM/dd/yyyy")
                : ""
            }
          />
          <AccordionDetailsItem
            label="Date Completed"
            value={format(new Date(item?.dateCompleted), "MM/dd/yyyy")}
          />
          <AccordionDetailsItem label="Signer" value={(item?.signedDate ? item?.signerName : "")} />
          <AccordionDetailsItem label="Signed Date" value = {format(new Date(item?.signedDate), "MM/dd/yyyy")} />
          <AccordionDetailsItem
            label="Overdue"
            value={getOverdueStatus(
              item?.dateToCompleteBy,
              item?.dateCompleted
            )}
          />
          <Button
            fullWidth
            color="secondary"
            sx={{ color: theme.palette.common.white }}
            variant="contained"
            onClick={() => onRowClick(item)}
          >
            Details
          </Button>
        </AccordionItem>
      );
    }
    if (name === "review-cycles") {
      return (
        <AccordionItem key={item?.id} title={item?.name}>
          <AccordionDetailsItem label="Group Cycle" value={item?.groupCycle} />
          <AccordionDetailsItem
            label="Start Date"
            value={format(new Date(item?.startDate), "MM/dd/yyyy")}
          />
        </AccordionItem>
      );
    }
    if (name === "workshop") {
      return (
        <AccordionItem info={item?.year} key={item?.id} title={item?.title}>
          <Button
            fullWidth
            color="secondary"
            sx={{ color: theme.palette.common.white }}
            variant="contained"
            onClick={() => onRowClick(item)}
          >
            Details
          </Button>
        </AccordionItem>
      );
    }
    if (name === "all-goals" || name === "web-editor-goals") {
      return (
        <AccordionItem info={item?.group} key={item?.id} title={`${`${item?.firstName  } ${  item?.lastName}`}`}>
          <AccordionDetailsItem label="Employee #" value={item?.employeeNumber} />
          <AccordionDetailsItem label="Manager Employee #" value={item?.supervisorObj?.employeeNumber} />
          <Button
            fullWidth
            color="secondary"
            sx={{ color: theme.palette.common.white }}
            variant="contained"
            onClick={() => onRowClick(item)}
          >
            Details
          </Button>
        </AccordionItem>
      );
    }
    if (name === "assignment-history") {
      return (
        <AccordionItem info={format(new Date(item?.updatedAt), "MM/dd/yyyy")} key={item?.id} title={item?.projectName}>
          <AccordionDetailsItem label="Project Id" value={item?.projectId} />
          <AccordionDetailsItem label="Updated By" value={item?.updatedByFullName} />
        </AccordionItem>
      );
    }
    return null;
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (!Array.isArray(result) || loading) {
    return <Loader />;
  }

  if (downSm) {
    return (
      <Stack
        alignItems="center"
        direction="column"
        justifyContent="space-between"
        sx={{
          position: "sticky",
          left: 0,
          top: 0,
          margin: 0,
          padding: 0,
          bgcolor: "background.default",
        }}
      >
        <MobileTable
          showLoadingBar
          // handleScrollChange={handleScrollChange}
          headerActions={
            <React.Fragment>
              <Button
                color="primary"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => executeQuery()}
              >
                <RefreshIcon
                  sx={{
                    animationName: "spin",
                    animationDuration: "1s",
                    animationIterationCount: "infinite",
                    animationFillMode: "forwards",
                    animationPlayState: loading ? "running" : "paused",
                    "@keyframes spin": {
                      "0%": {
                        transform: "rotate(0deg)",
                      },
                      "100%": {
                        transform: "rotate(360deg)",
                      },
                    },
                  }}
                />
              </Button>
              {name !== "all-goals" && name !== "web-editor-goals" && name !== "assignment-history" && (  
                <Button
                  color="primary"
                  sx={{ width: "100%" }}
                  variant="contained"
                  onClick={() => handleFilterComponentClick()}
                >
                  <FilterAlt />
                </Button>
              )}
            </React.Fragment>
          }
          loading={loading}
          maxHeight="35rem"
          showExportButton={false}
          total={total}
        >
          {loading ? (
            <BoxLoader />
          ) : (
            result?.map((item) => getItemByTableName(item))
          )}
        </MobileTable>
      </Stack>
    );
  }

  return (
    <React.Fragment>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          position: "sticky",
          left: 0,
          top: 0,
          bgcolor: "background.default",
        }}
      >
        {ActionComponent && isSomeRowsSelected && (
          <ActionComponentWrapper>
            <ActionComponent
              rowSelection={rowSelection}
              onClearRowSelection={handleClearRowSelection}
            />
          </ActionComponentWrapper>
        )}
        <TablePagination
          component="div"
          count={total}
          page={currentPage}
          rowsPerPage={defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Box>
          {/* <TextField
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            placeholder="Search..."
            sx={{ mr: 2 }}
            value={search}
            variant="standard"
            onChange={handleSearchChange}
          /> */}
          {FilterComponent && (
            <Button color="primary" onClick={handleFilterComponentClick}>
              Filters
            </Button>
          )}
          {ViewsComponent && (
            <Button color="primary" onClick={handleViewsComponentClick}>
              Views
            </Button>
          )}
          <IconButton color="primary" onClick={() => executeQuery()}>
            <RefreshIcon
              sx={{
                animationName: "spin",
                animationDuration: "1s",
                animationIterationCount: "infinite",
                animationFillMode: "forwards",
                animationPlayState: loading ? "running" : "paused",
                "@keyframes spin": {
                  "0%": {
                    transform: "rotate(0deg)",
                  },
                  "100%": {
                    transform: "rotate(360deg)",
                  },
                },
              }}
            />
          </IconButton>
        </Box>
      </Stack>
      <TableContainer>
        <Box
          sx={isModalTable && { height: "29rem", overflowY: "auto !important" }}
        >
          <Table
            columns={columns}
            data={result}
            fullWidth={fullWidth}
            getIsRowSelected={getIsRowSelected}
            rowSelection={rowSelection}
            sorting={sorting}
            onCellClick={onCellClick}
            onRowClick={onRowClick}
            onRowSelectionChange={handleRowSelectionChange}
            onSortingChange={handleSortingChange}
          />
        </Box>
      </TableContainer>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{ position: "sticky", left: 0 }}
      >
        <TablePagination
          component="div"
          count={total}
          page={currentPage}
          rowsPerPage={defaultPageSize}
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </React.Fragment>
  );
};

DetailedDataTable.propTypes = {
  columns: PropTypes.array.isRequired,
  query: PropTypes.any.isRequired,
  dataExtractor: PropTypes.func,
  totalExtractor: PropTypes.func,
  searchTransformer: PropTypes.func,
  defaultSorting: PropTypes.array,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  fetchPolicy: PropTypes.string,
  where: PropTypes.object,
  getIsRowSelected: PropTypes.func,
  FilterComponent: PropTypes.any,
  ActionComponent: PropTypes.any,
  ViewsComponent: PropTypes.any,
  opVarStorageKey: PropTypes.any,
  location: PropTypes.any,
  fullWidth: PropTypes.bool,
  isModalTable: PropTypes.bool,
  aggregateName: PropTypes.string,
  name: PropTypes.string,
};

DetailedDataTable.defaultProps = {
  defaultSorting: [],
  onRowClick: () => {},
  onCellClick: () => {},
  dataExtractor: () => {},
  totalExtractor: () => {},
  searchTransformer: () => {},
  fetchPolicy: "cache-and-network",
  where: {},
  getIsRowSelected: () => false,
  FilterComponent: null,
  ActionComponent: null,
  ViewsComponent: null,
  opVarStorageKey: null,
  location: "",
  fullWidth: false,
  isModalTable: false,
  aggregateName: "",
  name: "",
};

export default DetailedDataTable;
