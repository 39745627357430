import { gql } from "@apollo/client";
// eslint-disable-next-line import/prefer-default-export
export const CREATE_MANY_CERTS = gql`
  mutation CreateManyCertifications($data: [CertificationsCreateManyInput!]!) {
    createManyCertifications(data: $data) {
      count
    }
  }
`;

export const CREATE_MANY_LICENSES = gql`
  mutation CreateManyLicenses($data: [LicensesCreateManyInput!]!) {
    createManyLicenses(data: $data) {
      count
    }
  }
`;

export const CREATE_MANY_SKILLS = gql`
  mutation CreateManySkills($data: [SkillsCreateManyInput!]!) {
    createManySkills(data: $data) {
      count
    }
  }
`;
export const CREATE_MANY_COURSES = gql`
  mutation CreateManySkills($data: [deccoUCoursesCreateManyInput!]!) {
    createManydeccoUCourses(data: $data) {
      count
    }
  }
`;
export const UPDATE_MANY_CERTS = gql`
  mutation UpdateManyCertifications(
    $data: CertificationsUpdateManyMutationInput!
    $where: CertificationsWhereInput
  ) {
    updateManyCertifications(data: $data, where: $where) {
      count
    }
  }
`;

export const UPDATE_MANY_COURSES = gql`
  mutation UpdateManyCourses(
    $data: deccoUCoursesUpdateManyMutationInput!
    $where: deccoUCoursesWhereInput
  ) {
    updateManydeccoUCourses(data: $data, where: $where) {
      count
    }
  }
`;
export const UPDATE_MANY_SKILLS = gql`
  mutation UpdateManySkills($data: SkillsUpdateManyMutationInput!) {
    updateManySkills(data: $data) {
      count
    }
  }
`;

export const UPDATE_MANY_LICENSES = gql`
  mutation UpdateManyLicenses($data: LicensesUpdateManyMutationInput!) {
    updateManyLicenses(data: $data) {
      count
    }
  }
`;
