const links = [
  {
    id: "link_#0",
    link: "http://deccoapproved.arborwear.com/",
    name: "Aborwear",
    description: "Online DECCO Store",
  },
  {
    id: "link_#1",
    link: "https://decco.myabsorb.com/#/login",
    name: "DECCO U",
    description: "DECCO U Access",
  },
];

export default links;
