import React from "react";
import PropTypes from "prop-types";
import { Field, Control, Label, Input, Checkbox } from "rbx";
import { FileUploader } from "../..";
import "./AddDocumentForm.scss";

const AddDocumentForm = ({ inputs, onChange, onFileUpload, onCheck }) => (
  <div className="document-form-container">
    {inputs.filePath && (
      <Field>
        
        <Field>
          <Control>
            <Label>Description</Label>
            <Input
              autoComplete="new"
              name="description"
              readOnly={inputs.contactInfo}
              size="small"
              type="text"
              value={inputs.description}
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Control>
        </Field>
        <Field>
          <Control>
            <Label>File</Label>
            <Input
              disabled
              autoComplete="new"
              name="description"
              size="small"
              type="text"
              value={inputs.fileName}
            />
          </Control>
        </Field>
      </Field>
    )}
    <Field>
      <Control>
        <Label>DECCO Cell Phone List (click before uploading pdf with `Choose File`)</Label>
        <Checkbox
          name="description"
          size="large"
          value={inputs.contactInfo}
          onChange={(e) => onCheck()}
        />
      </Control>
    </Field>
    <Field>
      <FileUploader
        showDownloadButton
        buttonLabel="Choose File"
        contactInfo={inputs.contactInfo}
        filePath={inputs.filePath}
        type="file"
        value={inputs.fileName}
        onChange={onFileUpload}
      />
    </Field>
  </div>
);

AddDocumentForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onFileUpload: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
};

export default AddDocumentForm;
