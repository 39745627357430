import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";

const AccordionDetailsItem = ({ label, value }) => (
  <Box display="flex" flexWrap="wrap" justifyContent="space-between">
    <Typography fontSize={16} fontWeight={600}>
      {label}:
    </Typography>
    <Typography color="primary.main" fontSize={16} variant="body1">
      {value}
    </Typography>
  </Box>
);

AccordionDetailsItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default AccordionDetailsItem;
