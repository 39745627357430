import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { addDays, format } from "date-fns/esm";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  Box,
  Button as MuiButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import { GET_EXPIRING_LICENSES } from "../../../../graphql/reports";
import {
  ReportsTable,
  AccordionItem,
  ReportSelectMUI,
  AccordionDetailsItem,
} from "../../../../components";
import { EXP_LICENSES } from "../../columns";
import Dropdown from "../../../../components/Dropdown";
import ReportHeader from "../ReportHeader";
import MobileTable from "../../../../components/ReportsTable/MobileTable";

import { downloadLocalCsv, customToast as toast } from "../../../../utils";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";

const DEFAULT_WHERE = {
  AND: [
    {
      expDate: {
        gte: new Date(),
      },
    },
    {
      users: {
        is: {
          employmentStatus: {
            equals: "active",
          },
        },
      },
    },
    {
      expDate: {
        lte: addDays(new Date(), 60),
      },
    },
  ],
};

const dropdownOptions = [
  { id: "60Days", label: "Next 60 Days" },
  { id: "90Days", label: "Next 90 Days" },
  { id: "expired", label: "Expired" },
];

const LicensesTable = ({ report, onReportChange, tabs, permissions }) => {
  const [where, setWhere] = useState(DEFAULT_WHERE);
  const [activeDropdownItem, setActiveDropdownItem] = useState(
    dropdownOptions[0]
  );

  const { loading, data: { findManyLicenses: expLicenses = [] } = {} } =
    useQuery(GET_EXPIRING_LICENSES, {
      variables: {
        where,
        orderBy: [
          {
            users: {
              firstName: "asc",
            }
          },
        ],
      },
    });

  const exportCSV = async () => {
    try {
      downloadLocalCsv(expLicenses, "Expiring Licenses", EXP_LICENSES);
    } catch (err) {
      toast.error("Error exporting CSV");
    }
  };

  const handleDropdownOptionClick = (item) => {
    if (item.id === "expired") {
      setWhere({
        expDate: {
          lt: new Date(),
        },
        users: {
          is: {
            employmentStatus: {
              equals: "active",
            },
          },
        },
      });
    } else if (item.id === "90Days") {
      setWhere({
        AND: [
          {
            expDate: {
              gte: new Date(),
            },
          },
          {
            users: {
              is: {
                employmentStatus: {
                  equals: "active",
                },
              },
            },
          },
          {
            expDate: {
              lte: addDays(new Date(), 90),
            },
          },
        ],
      });
    } else {
      setWhere(DEFAULT_WHERE);
    }
    setActiveDropdownItem(item);
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileTable
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <ReportSelectMUI value={report} onChange={onReportChange} />
            </Box>

            <Box mt={1} width="20%">
              <MuiButton
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => exportCSV()}
              >
                <FileDownload />
              </MuiButton>
            </Box>
          </React.Fragment>
        }
        loading={loading}
        title="Reports"
      >
        {loading ? (
          <BoxLoader />
        ) : (
          expLicenses?.map((item) => (
            <AccordionItem
              info={format(new Date(item?.expDate), "MM-dd-yyyy")}
              key={item?.licenseNumber}
              title={`${item?.users?.firstName} ${item?.users?.lastName}`}
            >
              <AccordionDetailsItem
                label="Title"
                value={item?.codes?.title}
              />
              <AccordionDetailsItem
                label="License #"
                value={item?.licenseNumber}
              />
              <AccordionDetailsItem
                label="Employee #"
                value={item?.users?.employeeNumber?.trim()}
              />
              <AccordionDetailsItem label="Email" value={item?.users?.email} />
            </AccordionItem>
          ))
        )}
      </MobileTable>
    );
  }

  return (
    <ReportsTable
      columns={EXP_LICENSES}
      data={expLicenses}
      loading={loading}
      saveQuery={GET_EXPIRING_LICENSES}
    >
      <div className="table-utils">
        <Dropdown
          activeItem={activeDropdownItem}
          handleOptionClick={handleDropdownOptionClick}
          options={dropdownOptions}
        />
        {(permissions.webEditor || permissions.superAdmin) && (
          <Button
            className="download-btn"
            disabled={loading}
            size="medium"
            title="Download CSV"
            onClick={exportCSV}
          >
            <Icon size="small">
              <FontAwesomeIcon icon="download" />
            </Icon>
          </Button>
        )}
      </div>
      <ReportHeader
        report={report}
        tabs={tabs}
        onReportChange={onReportChange}
      />
    </ReportsTable>
  );
};

LicensesTable.propTypes = {
  onReportChange: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default LicensesTable;
