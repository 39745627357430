/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { Table, Generic, Field, Control } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Pagination from "./Pagination";

import "./GenericTable.scss";

const renderFunc = (cell) => {
  if (cell.value === null) return null;
  if (cell.column.id === "profileImg") {
    return cell.value ? (
      <img alt="profileImg" className="cell-img-wrapper" src={cell.value} />
    ) : (
      <div className="user-icon-wrapper">
        <FontAwesomeIcon icon="user" />
      </div>
    );
  }
  if (typeof cell.value === "boolean") {
    return (
      <Generic textColor={cell.value ? "success" : "danger"}>
        {cell.value ? "Yes" : "No"}
      </Generic>
    );
  }
  return <div className="cell-style-wrapper"> {cell.render("Cell")} </div>;
};

const renderTableHeaderSort = (column) => {
  if (column.isSorted) {
    return column.isSortedDesc ? " ▼" : " ▲";
  }
  return "";
};

const defaultPropGetter = () => {};

function GenericTable({
  columns,
  data,
  loading = false,
  onRowClick = () => {},
  onCellClick = () => {},
  sortBy = [],
  isFixedWidth = true,
  getRowProps = defaultPropGetter,
  updateData,
  id,
  isHoverable,
  styleClass,
  isPaginated,
}) {
  const [, setDefaultPageSize] = useState(isPaginated ? 20 : data?.length || 1);

  const tableState = {
    pageIndex: 0,
    sortBy,
    pageSize: isPaginated ? 20 : data?.length || 1,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
      updateData,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const handleSetPageSize = (n) => {
    if (isPaginated) {
      setDefaultPageSize(n);
      setPageSize(n);
    } else {
      setDefaultPageSize(data?.length);
      setPageSize(data?.length);
    }
  };

  useEffect(() => {
    if (!isPaginated) {
      setDefaultPageSize(data?.length || 1);
      setPageSize(data?.length || 1);
    }
  }, [data, isPaginated, setDefaultPageSize, setPageSize]);

  const showPaginationControls =
    !!pageOptions?.length && pageOptions?.length > 1;

  return (
    <div className={styleClass || "generic-table-container"} id={id}>
      <div className="table-container t-container">
        <React.Fragment>
          <Table {...getTableProps()} className="generic-table">
            <Table.Head>
              {headerGroups.map((headerGroup) => (
                <Table.Row {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <Table.Heading
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <span>{renderTableHeaderSort(column)}</span>
                    </Table.Heading>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body className="tb" {...getTableBodyProps()}>
              {page.map(
                (row, i) =>
                  prepareRow(row) || (
                    <Table.Row
                      {...row.getRowProps(getRowProps(row))}
                      className={[isHoverable && "is-hoverable"]
                        .filter(Boolean)
                        .join(" ")}
                      onClick={() => onRowClick(row.original)}
                    >
                      {row.cells.map((cell, j) => (
                        <Table.Cell
                          {...cell.getCellProps()}
                          key={`row-${i}-cell-${j}`}
                          onClick={() => onCellClick(cell)}
                        >
                          {renderFunc(cell)}
                        </Table.Cell>
                      ))}
                    </Table.Row>
                  )
              )}
            </Table.Body>
          </Table>
        </React.Fragment>
      </div>
      {showPaginationControls && isPaginated && (
        <Field align="centered" className="pagination-field" kind="group">
          <Control>
            <Pagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              gotoPage={gotoPage}
              page={pageIndex + 1}
              pageSize={pageSize}
              pages={pageOptions.length}
              setPageSize={handleSetPageSize}
              onClickNext={nextPage}
              onClickPrev={previousPage}
            />
          </Control>
        </Field>
      )}
    </div>
  );
}

GenericTable.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  sortBy: PropTypes.array,
  isFixedWidth: PropTypes.bool,
  getRowProps: PropTypes.func,
  defaultColumn: PropTypes.object,
  updateData: PropTypes.func,
  defaultPageSize: PropTypes.number,
  id: PropTypes.string,
  isHoverable: PropTypes.bool,
  styleClass: PropTypes.string,
  isPaginated: PropTypes.bool,
  test: PropTypes.any,
};

GenericTable.defaultProps = {
  data: [],
  columns: [],
  loading: false,
  pageCount: 1,
  pageSize: 20,
  onRowClick: () => {},
  onCellClick: () => {},
  sortBy: [],
  isFixedWidth: true,
  getRowProps: () => {},
  defaultColumn: {},
  updateData: () => {},
  defaultPageSize: 20,
  isHoverable: false,
  id: "",
  styleClass: "",
  isPaginated: true,
  test: null,
};
export default GenericTable;
