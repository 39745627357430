import * as React from "react";
import PropTypes from "prop-types";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";

import { useModal } from "../../../context";
import omitBy from "../../../utils/omitBy";
import SearchFiltersForm from "../FilterForm";

function SearchFiltersModal({
  inputs: initialInputs = {},
  onComplete,
  location,
}) {
  const { setModalOpen } = useModal();

  const [inputs, setInputs] = React.useState(initialInputs);

  const handleChange = (name, value) => {
    setInputs((prev) => omitBy({ ...prev, [name]: value }, (x) => !x));
  };

  const handleClose = () => {
    setModalOpen(false, <span />);
  };

  const handleSubmit = () => {
    handleClose();
    onComplete({ ...inputs });
  };

  const handleReset = () => {
    handleClose();
    onComplete({});
  };

  return (
    <React.Fragment>
      <DialogTitle>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
        >
          <Typography component="span" textTransform="uppercase" variant="h4">
            Filters
          </Typography>
          <Button onClick={handleReset}>Reset</Button>
        </Stack>
      </DialogTitle>
      <DialogContent dividers sx={{ width: "100%", marginRight: "1rem" }}>
        <SearchFiltersForm
          inputs={inputs}
          location={location}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Stack direction="row">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>OK</Button>
        </Stack>
      </DialogActions>
    </React.Fragment>
  );
}

export default SearchFiltersModal;

SearchFiltersModal.propTypes = {
  inputs: PropTypes.any.isRequired,
  onComplete: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
};
