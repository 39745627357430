import React, { useState, useEffect, useMemo } from "react";
import ReactFileReader from "react-file-reader";

import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

// prop types
import PropTypes from "prop-types";

// imports from rbx
import { Field, Control, Label, Button, Select, Title } from "rbx";

import { FIND_MANY_CODES } from "../../../../graphql/codes/codes.queries";
import { MASS_UPDATE_EMPLOYEE_QUALIFICATIONS_MUTATION } from "../../../../graphql";
import GenericSelect from "../../../GenericSelect";

const BASE_COLUMNS = {
  base: [
    {
      Header: "Employee ID",
      accessor: "employeeID",
      example: "192828",
      isValid(value) {
        return value.length > 0;
      },
      toolTip: "Employee ID cannot be empty",
    },
    {
      Header: "Issue Date",
      accessor: "issueDate",
      example: "1/31/24",
      isValid(value) {
        const regex = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2})$/;
        return regex.test(value);
      },
      toolTip: "Issue Date must be in the format MM/DD/YY",
    },
  ],
  certifications: {
    Header: "Certification Number",
    accessor: "certificationNumber",
    example: "123456-p",
    isValid(value) {
      return true;
    },
  },

  licenses: {
    Header: "License Number",
    accessor: "licenseNumber",
    example: "123456-p",
    isValid(value) {
      return true;
    },
  },
  hasExpiration: {
    Header: "Expiration Date",
    accessor: "expDate",
    example: "1/31/24",
    isValid(value) {
      const regex = /^([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2})$/;
      return regex.test(value);
    },
    toolTip: "Expiration Date must be in the format MM/DD/YY",
  },
};

const MassUpdatesForm = ({ onComplete }) => {
  const [entitySelect, setEntitySelect] = useState(""); // licenses, certifications, skills, deccou courses
  const [qualificationSelect, setQualificationSelect] = useState(); // the id of the specific qualification
  const [tableData, setTableData] = useState([]); // the data from the csv file
  const [validationDisabled, setValidationDisabled] = useState(false);

  // reset the qualification select when the entity select changes
  useEffect(() => {
    setQualificationSelect("");
  }, [entitySelect]);

  const { data: { findManyCodes: options } = {} } = useQuery(FIND_MANY_CODES, {
    variables: {
      where: {
        entity: {
          equals: entitySelect,
          mode: "insensitive",
        },
      },
      orderBy: [
        {
          title: "asc",
        },
      ],
    },
  });

  // reset the table data when the entity select or qualification select changes
  useEffect(() => {
    setTableData([]);
  }, [entitySelect, qualificationSelect]);

  const [massUpdate] = useMutation(
    MASS_UPDATE_EMPLOYEE_QUALIFICATIONS_MUTATION,
    {
      onCompleted: () => {
        toast.success("Successfully updated qualifications");
      },
      onError: (e) => {
        toast.error(`${e}`, { autoClose: false});
      },
    }
  );

  const COLUMNS = useMemo(() => {
    if (!entitySelect || !qualificationSelect) {
      return [];
    }

    const baseColumns = [...BASE_COLUMNS.base] || [];

    const needsExpiration =
      options?.find((cert) => cert.id === qualificationSelect)
        ?.expirationType === "manual";

    if (needsExpiration) {
      baseColumns.push(BASE_COLUMNS.hasExpiration);
    }

    baseColumns.push(BASE_COLUMNS?.[entitySelect?.toLocaleLowerCase()]);

    return baseColumns.filter(Boolean);
  }, [entitySelect, qualificationSelect, options]);

  const handleChange = (e) => {
    setQualificationSelect(e?.target?.value);
  };

  const handleLicenseChange = (e, n, id) => {
    setQualificationSelect(id);
  };

  const handleSubmit = async (cancel) => {
    if (cancel) {
      onComplete();
      return;
    }
    try {
     await massUpdate({
        variables: {
          data: tableData?.map((row) => ({
            employeeNumber: row?.employeeID?.value,
            issueDate: row?.issueDate?.value,
            expDate: row?.expDate?.value,
            licenseNumber: row?.licenseNumber?.value,
            certificationNumber: row?.certificationNumber?.value,
          })),

          qualificationId: qualificationSelect,
          type:
            entitySelect === "DeccoUCourses"
              ? "deccoUCourses"
              : entitySelect?.toLocaleLowerCase(),
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      toast.error(`Error updating qualifications` );
    } finally {
      onComplete();
    }
  };

  const handleFiles = async (files) => {
    // setLoading(true);
    // setValidationDisabled(false);
    const reader = new FileReader();
    reader.onload = (e) => {
      const csv = e.target.result.replace(/\r/gm, "");
      const lines = csv.split("\n").map((line) => line.split(","));

      const result = [];

      const linesNoEmpty = lines?.filter((l) => l?.[0]);

      // convert to array of objects with Headers and Accessors to make a table
      // We get the headers from the COLUMNS array

      const accessor = COLUMNS?.map((col) => col?.accessor);

      linesNoEmpty?.forEach((line) => {
        const obj = {};
        line?.forEach((item, index) => {
          const isValid = COLUMNS?.[index]?.isValid(item?.trim());

          obj[accessor[index]] = {
            value: item?.trim(),
            isValid,
            toolTip: COLUMNS?.[index]?.toolTip,
          };
        });

        // if any item in the line is invalid, disable the validation button
        if (
          line?.some((item) => !COLUMNS?.[line?.indexOf(item)]?.isValid(item))
        ) {
          setValidationDisabled(true);
        } else {
          setValidationDisabled(false);
        }

        result.push(obj);
      });

      setTableData(result);
    };

    reader.readAsText(files[0]);
    // setLoading(false);
  };

  return (
    <React.Fragment>
      <div className="mass-inputs-form">
        <Title size={4}>Mass Updates</Title>
        <Field>
          {/* Step 1: Select what kind of qualification */}
          <Control style={{ marginBottom: "10px" }}>
            <Label>1. Select A Qualification Type</Label>
            <Select.Container fullwidth size="medium">
              <Select
                style={{
                  border: "",
                  borderRadius: "4px",
                  boxShadow: "",
                }}
                value={entitySelect}
                onChange={(e) => setEntitySelect(e?.target?.value)}
              >
                <Select.Option value="" />
                <Select.Option value="Licenses">License</Select.Option>
                <Select.Option value="Certifications">
                  Certification
                </Select.Option>
                <Select.Option value="Skills">Skill</Select.Option>
                <Select.Option value="DeccoUCourses">
                  DECCOU Course
                </Select.Option>
              </Select>
            </Select.Container>
          </Control>
          {/* Step 2: Select the specific qualification  */}
          {entitySelect && (
            <Control style={{ marginBottom: "10px" }}>
              <Control
                style={{
                  paddingBottom: "0.5rem",
                }}
              >
                <Label>2. Select a {entitySelect?.slice(0, -1)}</Label>
                {entitySelect === "Licenses" ? (
                  <GenericSelect
                    isLicense
                    required
                    showBlank
                    label="Title"
                    loading={!options?.length}
                    name="title"
                    options={options}
                    showPlaceholder={{ title: "Select a License" }}
                    size="medium"
                    value={
                      options?.find((item) => item.id === qualificationSelect)
                        ?.title
                    }
                    onChange={handleLicenseChange}
                  />
                ) : (
                  <Select.Container fullwidth size="medium">
                    <Select
                      style={{
                        border: "",
                        borderRadius: "4px",
                        boxShadow: "",
                      }}
                      value={qualificationSelect}
                      onChange={handleChange}
                    >
                      <Select.Option value="" />
                      {options?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.title}
                        </Select.Option>
                      ))}
                    </Select>
                  </Select.Container>
                )}
              </Control>
              {COLUMNS?.length > 0 && (
                <Control style={{ marginBottom: "10px" }}>
                  <Label>
                    3. Upload a csv with the data in the same order and format
                    as the example below. Do not include a header row.
                  </Label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      flexWrap: "wrap",
                      marginBottom: "1rem",
                    }}
                  >
                    {COLUMNS?.map((column) => (
                      <div
                        key={column?.accessor}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          flexWrap: "wrap",
                          border: "1px solid #dbdbdb",
                          padding: "0.5rem",
                        }}
                      >
                        <Label style={{ borderBottom: "1px solid #dbdbdb" }}>
                          {column?.Header}
                        </Label>
                        <Label>{column?.example}</Label>
                      </div>
                    ))}
                  </div>
                  <ReactFileReader
                    disabled={false}
                    fileTypes=".csv"
                    handleFiles={handleFiles}
                  >
                    <Button color="success" type="button">
                      <span>Upload</span>
                    </Button>
                  </ReactFileReader>
                </Control>
              )}
              {tableData?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginBottom: "1rem",
                    maxHeight: "400px",
                    overflow: "auto",
                  }}
                >
                  {COLUMNS?.map((column) => (
                    <div
                      key={column?.employeeID}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "stretch",
                        alignItems: "center",
                        flexWrap: "wrap",
                        width: "200px",
                      }}
                    >
                      <Label style={{ borderBottom: "1px solid #dbdbdb" }}>
                        {column?.Header}
                      </Label>
                      {tableData?.map((row) => (
                        <div
                          style={{
                            border: "1px solid #dbdbdb",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            minHeight: "50px",
                          }}
                        >
                          <Label
                            key={row?.employeeID}
                            style={{
                              padding: "0.5rem",
                              borderBottom:
                                row?.[column?.accessor]?.isValid ||
                                row?.[column?.accessor]?.isValid === undefined
                                  ? ""
                                  : "1px solid red",
                            }}
                            tooltip={
                              row?.[column?.accessor]?.isValid ||
                              row?.[column?.accessor]?.isValid === undefined
                                ? null
                                : row?.[column?.accessor]?.toolTip
                            }
                          >
                            {row?.[column?.accessor]?.value}
                          </Label>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              )}
            </Control>
          )}
        </Field>
      </div>
      <div style={{ display: "flex" }}>
        <Button
          color="danger"
          onClick={() => {
            handleSubmit(true);
          }}
        >
          Cancel
        </Button>
        {tableData?.length > 0 && (
          <Button
            color="primary"
            disabled={validationDisabled}
            onClick={() => {
              handleSubmit(false);
            }}
          >
            Submit
          </Button>
        )}
      </div>
    </React.Fragment>
  );
};

MassUpdatesForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};

export default MassUpdatesForm;
