/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const FIND_MANY_DOCUMENTS = gql`
  query FindManyDocuments(
    $where: DocumentsWhereInput
    $orderBy: [DocumentsOrderByWithRelationInput!]
  ) {
    findManyDocuments(where: $where, orderBy: $orderBy) {
      id
      createdAt
      createdBy
      description
      name
      path
    }
  }
`;

export const SIGNED_FILE_URL_QUERY = gql`
  query SIGNED_FILE_URL_QUERY($input: SignedFileUrlInput!) {
    signedFileUrl(input: $input)
  }
`;