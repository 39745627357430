import { gql } from "@apollo/client";

export const CREATE_PROJECT = gql`
  mutation CreateOneProject($data: ProjectCreateInput!) {
    createOneProject(data: $data) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateOneProject(
    $data: ProjectUpdateInput!
    $where: ProjectWhereUniqueInput!
  ) {
    updateOneProject(data: $data, where: $where) {
      id
    }
  }
`;

export const UNASSIGN_PROJECT = gql`
  mutation UnassignProject($projectId: String!) {
    unassignProject(projectId: $projectId) {
      id
    }
  }
`;
