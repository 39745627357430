import React from "react";
import PropTypes from "prop-types";
import { Title } from "rbx";
import GoalsListItem from "../GoalListItem";

;

const IncompleteGoalsList = ({
  goalsLength,
  handleClick,
  incompleteGoals,
  setGoals,
  handleOpenGoalAdd,
  showManagerReviews,
  isUsersOwnGoals
}) => (
    <React.Fragment>
      {incompleteGoals?.map((goal, index) => (
        <React.Fragment key={goal?.id}>
          <GoalsListItem
            goal={goal}
            goalIndex={index}
            handleClick={handleClick}
            handleOpenGoalAdd={handleOpenGoalAdd}
            isUsersOwnGoals={isUsersOwnGoals}
            showManagerReviews={showManagerReviews}
          />
        </React.Fragment>
      ))}
      {!incompleteGoals.length  && (
        <div className="centered-title">
          <Title size={6}>
            Nice! All your personal evaluations are completed
          </Title>
        </div>
      )}
    </React.Fragment>
  );

IncompleteGoalsList.propTypes = {
  goalsLength: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  incompleteGoals: PropTypes.array.isRequired,
  setGoals: PropTypes.func.isRequired,
  handleOpenGoalAdd: PropTypes.func.isRequired,
  showManagerReviews: PropTypes.bool,
  isUsersOwnGoals: PropTypes.bool
};

IncompleteGoalsList.defaultProps = {
  showManagerReviews: false,
  isUsersOwnGoals: false
};

export default IncompleteGoalsList;
