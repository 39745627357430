import React, { createContext, useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";

import { useHistory } from "react-router-dom";

import { useApolloClient, useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import Cookies from "js-cookie";
import {
  LOGIN_MUTATION,
  CHECK_AUTH_QUERY,
  SIGNUP_MUTATION,
  // FORGOT_PASSWORD_MUTATION,
  // COMPLETE_PASSWORD_RESET_MUTATION
} from "../graphql";

export const AuthContext = createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
  loginType: null,
  user: { employeeNumber: null, email: null, firstName: null, lastName: null },
};

export const AuthProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const [login] = useMutation(LOGIN_MUTATION);
  const history = useHistory();
  // const [requestPasswordReset] = useMutation(FORGOT_PASSWORD_MUTATION);
  // const [completePasswordReset] = useMutation(COMPLETE_PASSWORD_RESET_MUTATION);
  const client = useApolloClient();

  const handleLogin = async (email, password) => {
    try {
      setState((prev) => ({ ...prev, isLoginPending: true }));
      const {
        data: { login: loginData = { token: "" } },
      } = await login({
        variables: { input: { email, password } },
      });
      if (loginData.token) {
        Cookies.set("DECCO_TOKEN", loginData.token, { sameSite: true });

        setState((prev) => ({
          ...prev,
          user: {
            ...loginData.user,
            id: loginData.user?.id,
            employeeNumber: loginData.user?.employeeNumber,
            email: loginData.user?.email,
            firstName: loginData.user?.firstName,
            lastName: loginData.user?.lastName,
            userSecurities: [loginData.user?.userType],
            profileImage: loginData?.user?.profileImage,
          },
          isLoggedIn: true,
          isLoginPending: false,
        }));
      }

      history.push(`/baseball-card/${loginData.user?.employeeNumber.trim()}`);
    } catch (err) {
      toast.error(err.message);
      Cookies.remove("DECCO_TOKEN");
      setState((prev) => ({
        ...prev,
        loginError: err.message,
        isLoginPending: false,
      }));
    } finally {
      setState((prev) => ({ ...prev, isLoginPending: false }));
    }
  };

  const handleSignup = async (email) => {
    try {
      setState((prev) => ({
        ...prev,
        isLoggedIn: false,
        isLoginPending: true,
      }));

      const { data: signupUserRequestData } = await client.mutate({
        mutation: SIGNUP_MUTATION,
        variables: {
          input: {
            email,
            origin: window.location.origin,
          },
        },
      });

      // We don't want to be explicit about this error.
      if (!signupUserRequestData) throw new Error("Something went wrong.");

      toast.success(
        "Request Sign Up sent successfully. Please check your email."
      );

      setState((prev) => ({
        ...prev,
        isLoggedIn: false,
        isLoginPending: false,
      }));

      history.push("/");
    } catch (err) {
      toast.error(err.message);
      setState((prev) => ({
        ...prev,
        loginError: err.message,
        isLoginPending: false,
      }));
    }
  };

  const handleLogout = useCallback(() => {
    Cookies.remove("DECCO_TOKEN");
    sessionStorage.clear();
    setState((prev) => ({ ...prev, ...initialState }));
    history.push("/");
  }, [history]);

  const handleCheckAuth = useCallback(async () => {
    try {
      setState((prev) => ({ ...prev, isLoginPending: true }));
      const {
        data: { checkAuth = false },
      } = await client.query({
        query: CHECK_AUTH_QUERY,
        fetchPolicy: "network-only",
      });

      if (!checkAuth) {
        throw new Error(`Not authorized.`);
      }
      
      if (!!checkAuth.employeeNumber !== state.isLoggedIn) {
        setState((prev) => ({
          ...prev,
          isLoggedIn: !!checkAuth,
          user: {
            ...prev.user,
            id: checkAuth?.id,
            firstName: checkAuth?.firstName,
            lastName: checkAuth?.lastName, 
            employeeNumber: checkAuth?.employeeNumber,
            userSecurities: [checkAuth?.userType],
            profileImage: checkAuth?.profileImage,
          },
        }));
      }
    } catch (err) {
      if (state.isLoggedIn) {
        handleLogout();
      }
    } finally {
      setState((prev) => ({ ...prev, isLoginPending: false }));
    }
  }, [client, handleLogout, state.isLoggedIn]);

  return (
    <AuthContext.Provider
      value={{
        state,
        handleLogin,
        handleLogout,
        handleCheckAuth,
        setState,
        handleSignup,
        // handleUpdatePassword,
        // handleForgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
