import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button, Tab } from "rbx";

import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import {
  uppercaseFirstLetter,
  download,
  customToast as toast,
  generateCSV,
  parseQueryResultWithColumnsAlt,
} from "../../utils";
import {
  EMPLOYEE_COLUMNS,
  CONTRACTOR_COLUMNS,
  PROJECT_CSV_COLUMNS,
} from "./columns";

import { Confirmation } from "../../components";
import { useModal } from "../../context";

import { GET_PROJECT, UNASSIGN_PROJECT } from "../../graphql";
import GenericTable from "../../components/GenericTable";

import ProjectModal from "./components/ProjectModal/ProjectModal";

const Project = () => {
  const params = useParams();
  const { projectId } = params;

  const { setModalOpen } = useModal();

  const [loading, setLoading] = useState(false);
  const [tabState, setTabState] = useState("partners");

  const [unassignProject] = useMutation(UNASSIGN_PROJECT, {
    refetchQueries: [{ query: GET_PROJECT, variables: { projectId } }],
  });

  const { data: { getProject: projectData } = {} } = useQuery(GET_PROJECT, {
    variables: {
      projectId,
    },
  });

  const exportLocalCsv = async () => {
    setLoading(true);

    try {
      const project = { ...projectData };

      // only keep columns that are in the columns constant
      Object.keys(project).forEach((key) => {
        if (!PROJECT_CSV_COLUMNS.find((column) => column.accessor === key)) {
          delete project[key];
        }

        if (key === "notes") {
          project[key] = project[key].replace(/\n/g, " ");
        }
      });

      project.employees = `"${project.employees
        .map((employee) => {
          const name = `${uppercaseFirstLetter(employee.firstName).concat(
            " ",
            uppercaseFirstLetter(employee.lastName)
          )}`;
          const number = `${employee.employeeNumber.trim()}`;
          const title = `${employee.jobTitle.trim()}`;
          return `${name}, ${number}, ${title}`;
        })
        .join("\n")}"`;

      project.contractors = project.contractors
        .map(
          (contractor) =>
            `"Contractor_Name=${uppercaseFirstLetter(
              contractor.firstName
            )} ${uppercaseFirstLetter(contractor.lastName)} 
            "Contractor_Task=${contractor.task}         "
            " --------------"`
        )
        .join(",")
        .replace(/,/g, "                   ")
        .replace(/\n/g, " ");

      const result = [project];

      const parsedObj = parseQueryResultWithColumnsAlt(
        result[0],
        PROJECT_CSV_COLUMNS
      );

      const CSV = generateCSV(Object.keys(parsedObj), [parsedObj]);

      toast.success(`CSV exported successfully.`);
      return download(`employeeInfo.csv`, "csv", CSV);
    } catch (error) {
      return toast.error(`CSV export failed. Please contact an administrator.`);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProject = () => {
    setModalOpen(
      true,
      <ProjectModal
        where={{ projectId }}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const handleMakeProjectInactive = async (projectName) => {
    setModalOpen(
      true,
      <Confirmation
        message={`Are you sure you want to make ${projectName} inactive? This will unassign all employees and contractors from this project.`}
        onCancel={() => setModalOpen(false, "")}
        onConfirm={() => {
          setModalOpen(false, "");
          unassignProject({ variables: { projectId } });
        }}
      />
    );
  };

  const isProjectActive =
    projectData?.employees?.length || projectData?.contractors?.length;

  if (loading) return <p>loading</p>;

  return (
    <div className="project-card-container">
      <div className="bg-mask" />

      <div className="project-card-wrapper">
        <div className="left-section">
          <div className="buttons">
            <React.Fragment>
              <Button
                disabled={loading}
                onClick={() => {
                  handleEditProject();
                }}
              >
                Update Info
              </Button>

              <Button
                className="cancel-button"
                disabled={loading || !isProjectActive}
                onClick={() => {
                  handleMakeProjectInactive(projectData?.name);
                }}
              >
                Make Inactive
              </Button>
            </React.Fragment>
          </div>
        </div>
        <div className="top-section">
          <div className="project-name large-screen-title">
            <Button
              className="print-button fa-print"
              size="small"
              onClick={() => exportLocalCsv()}
            >
              <FontAwesomeIcon icon={faDownload} size="lg" />
            </Button>

            <span className="c-secondary">{`${projectData?.name} `} </span>
          </div>
          <div className="project-info">
            <div className="project-name small-screen-title">
              <span className="c-secondary">{`${projectData?.name} `}</span>

              <Button
                className="print-button fa-print"
                size="small"
                onClick={() => exportLocalCsv()}
              >
                <FontAwesomeIcon icon={faDownload} size="lg" />
              </Button>
            </div>
            <div className="project-info-item">
              <div>
                <span>Project # </span>
                <span>Supervisor </span>
                <span> Manager </span>
              </div>
              <div>
                <span>{projectData?.project_num || ""}</span>
                <span>
                  <span>
                    {uppercaseFirstLetter(projectData?.supervisor || "")}
                  </span>
                  <div>{uppercaseFirstLetter(projectData?.manager || "")}</div>
                </span>
              </div>
            </div>

            <div className="project-info-item">
              <div>
                <span>Address </span>
                <span>City </span>
                <span>State</span>
              </div>
              <div>
                <span>{projectData?.address || ""}</span>
                <span>
                  <span>{projectData?.city}</span>
                  <div>{projectData?.state}</div>
                </span>
              </div>
            </div>

            <div className="project-info-item">
              <div>
                <span>Zipcode</span>
                <span>Status</span>
              </div>
              <div>
                <span>{projectData?.zip}</span>
                <span>{isProjectActive ? "Active" : "Inactive"}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="center-section">
          <div className="center-table">
            <div className="center-table-wrapper">
              <h3 style={{ marginTop: "10px" }}>Project Assignments</h3>
              <Tab.Group align="centered">
                <Tab
                  active={tabState === "partners"}
                  onClick={() => setTabState("partners")}
                >
                  partners
                </Tab>
                <Tab
                  active={tabState === "contractors"}
                  onClick={() => setTabState("contractors")}
                >
                  contractors
                </Tab>
              </Tab.Group>
              {tabState === "partners" && (
                <div className="generic-table-wrapper">
                  <GenericTable
                    columns={EMPLOYEE_COLUMNS}
                    data={projectData?.employees}
                    loading={loading}
                  />
                </div>
              )}
              {tabState === "contractors" && (
                <div className="generic-table-wrapper">
                  <GenericTable
                    columns={CONTRACTOR_COLUMNS}
                    data={projectData?.contractors}
                    loading={loading}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="right-table">
            <div className="right-table-wrapper">
              <div className="header-wrapper">
                <h3 style={{ marginTop: "10px" }}>Notes</h3>
              </div>
              <div className="table-info">
                <div className="cell-wrapper">{projectData?.notes}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Project.propTypes = {};

export default Project;
