import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import { Button, Icon, Title } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faUserCheck } from "@fortawesome/free-solid-svg-icons";
import formatDate from "../../../../utils/formatDate";
import capitalizeEveryWord from "../../../../utils/capitalizeEveryWord";

const ReviewSummaryItem = ({ evaluation, handleClick, isTemplate }) => {
  const tooltipMsg =
    evaluation?.status === "COMPLETED"
      ? "Evaluation is completed"
      : "Evaluation has not been started";

  const writer = evaluation?.author?.length
    ? capitalizeEveryWord(evaluation?.author)
    : "Employee being reviewed";

  const manager = evaluation?.manager?.length
    ? capitalizeEveryWord(evaluation?.manager)
    : "Employees manager";

  const dueDate = evaluation?.dateToCompleteBy?.length
    ? formatDate(evaluation?.dateToCompleteBy)
    : "N/A";

  return (
    <div className="summary-item" key={evaluation?.id}>
      <div className="summary-row">
        <Tooltip arrow placement="right" title={!isTemplate && tooltipMsg}>
          <div className="summary-item-title">
            {evaluation?.status === "INCOMPLETE" && (
              <Icon className="not-started-icon">
                <FontAwesomeIcon icon={faTimesCircle} />
              </Icon>
            )}

            {evaluation?.status === "COMPLETED" && (
              <Icon className="completed-icon">
                <FontAwesomeIcon icon={faUserCheck} />
              </Icon>
            )}

            <p className="bold-text">{evaluation?.title}</p>
          </div>
        </Tooltip>
        <Button
          className="secondary-btn"
          type="button"
          onClick={() => handleClick(evaluation?.id)}
        >
          <Title size={6} textColor="white">
            View
          </Title>
        </Button>
      </div>
      <p>Writer: {writer}</p>
      <p>Manager: {manager}</p>
      <p>Due Date: {dueDate}</p>
    </div>
  );
};

ReviewSummaryItem.propTypes = {
  evaluation: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool,
};

ReviewSummaryItem.defaultProps = {
  isTemplate: false,
};

export default ReviewSummaryItem;
