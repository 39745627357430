/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image, Label } from "rbx";
import React, { useEffect } from "react";
import { GenericTable } from "../../../../components";
import "./PrintBaseballCardModal.scss";

import {
  DEFAULT_CERTIFICATION_COLUMNS_2,
  DEFAULT_LICENSE_COLUMNS,
  DEFAULT_DECCO_U_COLUMNS,
  DEFAULT_SKILLS_COLUMNS,
} from "../../columns";

const PrintBaseballCard = ({ employeeData, onComplete, profileImageS3 }) => {
  useEffect(() => {
    onComplete();
  }, [onComplete]);
  return (
    <div className="print-baseball-card-container">
      <div className="baseball-card-wrapper">
        <React.Fragment>
          <div className="top-section">
            <div className="image-box">
              {employeeData?.profileImage ? (
                <Image src={profileImageS3} />
              ) : (
                <FontAwesomeIcon icon="user" />
              )}
            </div>
            <div className="user-info">
              <div className="user-name small-screen-title">
                <span className="c-secondary">
                  {`${employeeData?.firstName} `}
                </span>
                <span>
                  {employeeData?.middleInitial &&
                    `${employeeData.middleInitial}.`}{" "}
                  {employeeData?.lastName}
                </span>
              </div>
              <div>
                <div className="user-yos">
                  <span>Years of Service </span>
                  <span className="c-secondary">
                    {employeeData?.totalYearsOfService || ""}
                  </span>
                  {employeeData?.yearsOfServiceNote && (
                    <div> Service Note: {employeeData.yearsOfServiceNote} </div>
                  )}
                </div>
              </div>

              <div className="user-other">
                <div>
                  <span>Group </span>
                  <span>Total Points </span>
                  <span> Average </span>
                </div>
                <div>
                  <span>{employeeData?.group || "Unknown"}</span>
                  <span>
                    <span className="c-secondary">
                      {employeeData?.points || 0}
                    </span>
                    <div>{employeeData?.averageCompanyPoints || 0}</div>
                  </span>
                </div>
              </div>

              <div className="user-other">
                <div>
                  <span>Employee # </span>
                  <span>Job Title </span>
                  <span>T-Shirt Size</span>
                </div>
                <div>
                  <span>{employeeData?.employeeNumber || "Unknown"}</span>
                  <span>{employeeData?.jobTitle || "Unknown"}</span>
                  <span>{employeeData?.tshirtSize || "Not Assigned"}</span>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
        <div className="generic-table-wrapper">
          <Label>Licenses</Label>
          <GenericTable
            columns={DEFAULT_LICENSE_COLUMNS}
            data={employeeData?.licenses || []}
            isPaginated={false}
            loading={false}
            styleClass="generic-table"
          />
        </div>
        <div className="generic-table-wrapper">
          <Label>Certifications</Label>
          <GenericTable
            columns={DEFAULT_CERTIFICATION_COLUMNS_2}
            data={employeeData?.certifications || []}
            isPaginated={false}
            loading={false}
            styleClass="generic-table"
          />
        </div>
        <div className="generic-table-wrapper">
          <Label>Skills</Label>
          <GenericTable
            columns={DEFAULT_SKILLS_COLUMNS}
            data={employeeData?.skills || []}
            isPaginated={false}
            loading={false}
            styleClass="generic-table"
          />
        </div>
        <div className="generic-table-wrapper">
          <Label>DECCO U COURSES</Label>
          <GenericTable
            columns={DEFAULT_DECCO_U_COLUMNS}
            data={employeeData?.deccoUCourses || []}
            isPaginated={false}
            loading={false}
            styleClass="generic-table"
          />
        </div>
      </div>
    </div>
  );
};
export default PrintBaseballCard;
