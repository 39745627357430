import React, {useState} from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimeInput.scss";

import { Label } from "rbx";
// date picker component without time picker and range picker
const DatePicker = ({
  id,
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  showBlank,
}) => {
   const [startDate, setStartDate] = useState(value?.gte ? new Date(value?.gte) : null);
  const [endDate, setEndDate] = useState(value?.lte ? new Date(value?.lte) : null);
  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    const startDay = new Date(start)?.toISOString().split("T")[0];
    const endDay = new Date(end)?.toISOString().split("T")[0];
    const formattedStartDay = `${startDay}T03:59:00.000Z`;
    const formattedEndDay = `${endDay}T03:59:00.000Z`;
    onChange(name, {gte: formattedStartDay, lte: formattedEndDay});
  };
  return(
  <React.Fragment>
    <Label>{label}</Label>
    <ReactDatePicker
      selectsRange
      className="textInputRow datePicker"
      dateFormat="MMM d, yyyy"
      endDate={endDate}
      name={name}
      placeholderText="Date"
      popperClassName="popper"
      popperPlacement="bottom"
      selected={startDate}
      startDate={startDate}
      onChange={handleChange}
    />
  </React.Fragment>
  )
};

DatePicker.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  id: PropTypes.string,
  showBlank: PropTypes.bool,
};

DatePicker.defaultProps = {
  value: "",
  name: "",
  label: "Date",
  size: undefined,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  id: "",
  showBlank: false,
};

export default DatePicker;
