import React, { useState } from "react";
import { Title } from "rbx";
import { useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { GET_EVALUATIONS, GET_EVALUATION_IDS } from "./graphql";
// import { customToast as toast } from "../../utils";
import "../Reviews/Reviews.scss";
// import { useModal } from "../../context";
import { Loader, DetailedDataTable, ModalWrapper } from "../../components";
import { EVALUATION_COLUMNS } from "./columns";
// import EvaluationsListContainer from "../Reviews/components/EvaluationsListContainer/EvaluationsListContainer";
import Details from "../Details/Details";
import { useModal } from "../../context";
import FilterComponent from "../../components/DetailedDataTable/SearchFiltersModal";
import checkMobile from "../../utils/checkForMobile";

const Evaluations = () => {
  const location = useLocation();
  const { setModalOpen } = useModal();
  const [paginationWhere, setPaginationWhere] = useState({}); // eslint-disable-line
  const {
    data: EvaluationData,
    loading,
    refetch,
  } = useQuery(GET_EVALUATION_IDS, {
    variables: {
      where: paginationWhere,
    },
    fetchPolicy: "cache-and-network",
  });
  const evaluations = EvaluationData?.evaluations || [];
  const isMobile = checkMobile();

  const handleRowClick = async (evaluation) => {
    setModalOpen(
      true,
      <ModalWrapper
        details={evaluation?.title}
        title="Evaluation Details"
        onComplete={() => setModalOpen(false)}
      >
        <Details
          isModalView
          evaluationId={evaluation.id}
          keyType="evaluation"
          refetchEvals={refetch}
        />
      </ModalWrapper>
    );
  };

  if (loading) {
    return (
      <div
        className={
          isMobile ? "workshop-container-mobile" : "workshop-container"
        }
      >
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (location.search.includes("evaluation")) {
    return <Details />;
  }

  if (!evaluations?.length > 0) {
    return (
      <div
        className={
          isMobile ? "workshop-container-mobile" : "workshop-container"
        }
      >
        <div className="workshop">
          <Title>No Forms Found</Title>
        </div>
      </div>
    );
  }

  return (
    <div
      className={isMobile ? "workshop-container-mobile" : "workshop-container"}
    >
      <div className="workshop">
        <Title>Forms</Title>

        <DetailedDataTable
          FilterComponent={FilterComponent}
          aggregateName="aggregateEvaluation"
          columns={EVALUATION_COLUMNS}
          dataExtractor={(data) => data?.evaluations}
          defaultSorting={[{ id: "owner", desc: false }]}
          location="evaluations"
          name="all-evals"
          query={GET_EVALUATIONS}
          searchTransformer={(search) => ({})}
          setPaginationWhere={setPaginationWhere}
          totalExtractor={(total) => total}
          onRowClick={(evaluation) => handleRowClick(evaluation)}
        />
      </div>
    </div>
  );
};

export default Evaluations;
