import { useState, useEffect } from "react";

import { useAuth } from "../context/AuthContext";

const useCurrentUser = () => {
  const { state: authState } = useAuth();
  const [user, setUser] = useState({});

  useEffect(() => {
    const userSecurities = authState?.user?.userSecurities;

    if (Array.isArray(userSecurities)) {
      setUser((prev) => {
        const updatedUser = prev;
        return updatedUser;
      });
    }
  }, [authState, user]);

  return authState?.user;
};

export default useCurrentUser;
