import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";

import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import ProjectForm from "../ProjectForm/ProjectForm";

import { UPDATE_PROJECT, GET_PROJECT } from "../../../../graphql";

const INITIAL_STATE = {
  name: "",
  project_num: "",
  manager: "",
  supervisor: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  notes: "",
  users: [],
  contractors: [],
};

class CustomProjectError extends Error {}

const ProjectModal = ({ where, onComplete }) => {
  const [project, setProject] = useState({ ...INITIAL_STATE });

  const { data: { getProject = {} } = {} } = useQuery(GET_PROJECT, {
    variables: { projectId: where.projectId },
  });

  const [editProject, { loading: isLoading }] = useMutation(UPDATE_PROJECT);

  const handleInputChange = (name, value) => {
    setProject((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setProject({ ...getProject });
  }, [getProject]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await editProject({
        variables: {
          where: {
            id: where?.projectId,
          },
          data: {
            name: { set: project.name },
            project_num: { set: project.project_num },
            manager: { set: project.manager },
            supervisor: { set: project.supervisor },
            address: { set: project.address },
            city: { set: project.city },
            state: { set: project.state },
            zip: { set: project.zip },
            phone: { set: project.phone },
            notes: { set: project.notes },
          },
        },
        refetchQueries: [
          {
            query: GET_PROJECT,
            variables: { projectId: where.projectId },
          },
        ],
      });

      toast.success(`Project updated successfully.`);
      onComplete();
    } catch (error) {
      const message =
        error instanceof CustomProjectError
          ? error.message
          : `Error updating project. Please contact support.`;
      toast.error(message);
    }
  };

  if (isLoading) return <Loader />;

  const isDisabled =
    !project.project_num ||
    !project.name ||
    !project.manager ||
    !project.supervisor ||
    !project.address ||
    !project.city ||
    !project.state ||
    project.state.length !== 2 ||
    !project.zip ||
    project.zip.length !== 5 ||                                                      
    !project.phone ||
    isLoading;

  return (
    <form id="project-form" onSubmit={handleSubmit}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>Edit Project</Title>
        </div>
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="project-form"
              size="small"
              state={isLoading ? "loading" : ""}
              type="submit"
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <ProjectForm project={project} onChange={handleInputChange} />
    </form>
  );
};

ProjectModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  where: PropTypes.object.isRequired,
};

export default ProjectModal;
