import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_EVALUATION_TEMPLATES = gql`
  query EvaluationTemplates(
    $where: EvaluationTemplateWhereInput
    $sectionsOrderBy: [EvaluationSectionTemplateOrderByWithRelationInput!]
    $questionsOrderBy: [EvaluationQuestionTemplateOrderByWithRelationInput!]
  ) {
    evaluationTemplates(where: $where) {
      id
      authorHidden
      needsAssignment
      title
      year
      sections(orderBy: $sectionsOrderBy) {
        _count {
          questions
        }
        id
        instructions
        title
        questions(orderBy: $questionsOrderBy) {
          answer
          answerOptions {
            id
            name
            value
          }
          answerType
          id
          text
          _count {
            answerOptions
          }
        }
      }
    }
  }
`;
