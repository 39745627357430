import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";

const BooleanInput = ({
  name,
  label,
  size,
  value,
  onChange,
  readOnly,
  disabled,
}) => {
  const handleChange = (e) => {
    onChange(e.target.name, e.target.checked);
  };

  return (
    <Checkbox
      checked={!!value}
      className="checkbox"
      disabled={disabled}
      id={name}
      label={label}
      name={name}
      readOnly={readOnly}
      size={size}
      onChange={handleChange}
    />
  );
};
BooleanInput.propTypes = {
  name: PropTypes.string,
  size: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

BooleanInput.defaultProps = {
  name: "",
  size: "",
  label: "",
  value: "",
  onChange: (e) => e,
  readOnly: false,
  disabled: false,
};

export default BooleanInput;
