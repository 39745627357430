/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from "react";

import "./BackgroundImage.scss";

const BackgroundImage = (props) => (
  <div
    className="background-img"
    style={{
      backgroundImage: "url('/background-logo.png')",
    }}
  >
    {props.children}
  </div>
);

export default BackgroundImage;
