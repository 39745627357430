import { gql } from "@apollo/client";

// also creates any employees that exist in foundation but not in mongo
export const GET_EMPLOYEES_DIRECTORY = gql`
  query GET_EMPLOYEES_DIRECTORY {
    getEmployeeDirectory {
      id
      employeeNumber
      firstName
      lastName
      fullName
      skills {
        id
        codes {
          title
        }
      }
      licenses {
        id
        codes {
          title
        }
      }
      certifications {
        id
        codes {
          title
        }
      }
      deccoUCourses {
        id
        codes {
          title
        }
      }
    }
  }
`;

// also creates any employees that exist in foundation but not in mongo
export const GET_EMPLOYEE_NUMBERS = gql`
  query GET_EMPLOYEE_NUMBERS {
    getEmployeeDirectory {
      employeeNumber
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query Employee($where: EmployeeWhereUniqueInput!) {
    employee(where: $where) {
      employeeNumber
      firstName
      lastName
      dateHired
      jobTitle
      middleInitial
    }
  }
`;

export const GET_EMPLOYEES_FOR_EXPORTING = gql`
  query PartnerServicesExport(
    $where: EmployeeWhereInput
    $mongoWhere: UserWhereInput
    $orderBy: [EmployeeOrderByInput!]
  ) {
    partnerServicesExport(
      where: $where
      mongoWhere: $mongoWhere
      orderBy: $orderBy
    ) {
      employeeNumber
      firstName
      lastName
      emailAddress
      jobTitle
      licenses
      certifications
      skills
      courses
    }
  }
`;

export const GET_EMPLOYEES = gql`
  query GetEmployees($where: UserWhereInput) {
    users(where: $where) {
      firstName
      lastName
      employeeNumber
      jobTitle
      middleInitial
      profileImage
      email
    }
  }
`;

export const GET_EMPLOYEES_MOBILE = gql`
  query GetEmployees(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
    $take: Int
  ) {
    users(where: $where, orderBy: $orderBy, take: $take) {
      firstName
      lastName
      employeeNumber
      jobTitle
      middleInitial
      profileImage
      email
      state
      deccoUCourses {
        issueDate
        codes {
          title
        }
      }
      skills {
        issueDate
        codes {
          title
        }
      }
      licenses {
        licenseNumber
        issueDate
        expDate
        codes {
          state
          level
          title
        }
      }
      certifications {
        id
        issueDate
        expDate
        certificationNumber
        codes {
          title
        }
      }
    }
  }
`;
