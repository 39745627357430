import React from "react";
import PropTypes from "prop-types";
import { GenericTable } from "../../../../components";

const Trainings = ({
  employeeData,
  editMode,
  handleUpdateDeccoUCourse,
  COLUMNS,
}) => (
  <div className="bball-card-trainings-container">
    {/* <div className="right-table"> */}
    {/* <div className="right-table-wrapper"> */}
    <div className="header-wrapper">
      <h3 style={{ marginTop: "10px" }}>Trainings Completed</h3>
    </div>
    <div className="table-info">
      <div className="cell-wrapper">
        <GenericTable
          columns={COLUMNS.deccoUCourses}
          data={employeeData?.deccoUCourses || []}
          isPaginated={false}
          loading={false}
          styleClass="styledTable"
          onRowClick={(row) =>
            editMode ? handleUpdateDeccoUCourse(row) : null
          }
        />
      </div>
    </div>
    {/* </div> */}
  </div>
  // </div>
);

Trainings.propTypes = {
  employeeData: PropTypes.object,
  editMode: PropTypes.bool,
  handleUpdateDeccoUCourse: PropTypes.func,
  COLUMNS: PropTypes.object,
};

Trainings.defaultProps = {
  employeeData: {},
  editMode: false,
  handleUpdateDeccoUCourse: () => null,
  COLUMNS: {},
};

export default Trainings;
