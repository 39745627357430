import React from "react";
import PropTypes from "prop-types";
import { Tab, Label } from "rbx";
import { format } from "date-fns/esm";
import {
  Box
} from "@mui/material";
import { 
  GenericTable,   
  AccordionItem,
  BBCardSelectMUI,
  AccordionDetailsItem
} from "../../../../components";
import MobileTable from "../../../../components/ReportsTable/MobileTable";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";


const Table = ({
  employeeData,
  editMode,
  permissionLevels,
  TAB_STATES,
  setTabState,
  COLUMNS,
  handleRowClick,
  tabState = "skills",
  mobile,
  loading
}) => mobile ? (
    <div className="bball-mobile-table" style={{width: "80%"}}>
        <br />
          <MobileTable
            bbCard
            showLoadingBar
            headerActions={
              <React.Fragment>
                <Box mt={1} width="90%">
                  <BBCardSelectMUI value={tabState} onChange={setTabState} />
                </Box>
              </React.Fragment>
            }
            loading={loading}
            title="Qualifications & Licenses"
          >
            {loading ? (
              <BoxLoader />
            ) : (
              <React.Fragment>
                {tabState === "skills" && ( 
                  employeeData[tabState]?.map((item) => (
                    <AccordionItem
                      key={item?.licenseNumber}
                      title={item?.codes?.title}
                      width="90%"
                    >
                      <AccordionDetailsItem
                        label="Points"
                        value={item?.codes?.points}
                      />
                      <AccordionDetailsItem
                        label="Issue Date"
                        value={format(new Date(item?.issueDate), "MM-dd-yyyy") || "--"}
                      />
                    </AccordionItem>
                  ))
                )}
                  {tabState === "licenses" && ( 
                  employeeData[tabState]?.map((item) => (
                    <AccordionItem
                      info={format(new Date(item?.expDate), "MM-dd-yyyy")}
                      key={item?.licenseNumber}
                      title={item?.codes?.title}
                      width="90%"
                    >
                      <AccordionDetailsItem
                        label="Level"
                        value={item?.codes?.level}
                      />
                        <AccordionDetailsItem
                        label="State"
                        value={item?.codes?.state}
                      />
                      <AccordionDetailsItem
                        label="Points"
                        value={item?.codes?.points}
                      />
                       <AccordionDetailsItem
                        label="License #"
                        value={item?.licenseNumber}
                      />
                      <AccordionDetailsItem
                        label="Issue Date"
                        value={format(new Date(item?.issueDate), "MM-dd-yyyy") || "--"}
                      />
                    </AccordionItem>
                  ))
                )}
                {tabState === "certifications" && ( 
                  employeeData[tabState]?.map((item) => (
                    <AccordionItem
                      info={format(new Date(item?.expDate), "MM-dd-yyyy")}
                      key={item?.licenseNumber}
                      title={item?.codes?.title}
                      width="90%"
                    >
                      <AccordionDetailsItem
                        label="Points"
                        value={item?.codes?.points}
                      />
                      <AccordionDetailsItem
                        label="Certifiacation #"
                        value={item?.certificationNumber}
                      />
                      <AccordionDetailsItem
                        label="Issue Date"
                        value={format(new Date(item?.issueDate), "MM-dd-yyyy") || "--"}
                      />
                    </AccordionItem>
                  ))
                )}
              </React.Fragment>
            )}
          </MobileTable>
        </div>
    ) : (
  <div className="bball-card-table-container">
    <div className="center-section">
      <div className="center-table">
        <div className="center-table-wrapper">
          <h3 style={{ marginTop: "10px" }}>Qualifications & Licenses</h3>

          <Tab.Group
            align="centered"
            style={{ marginBottom: 0, marginTop: "15px" }}
          >
            {/* supervisor can only edit skills */}
            {editMode && permissionLevels.fieldSupervisor ? (
              <Tab
                active
                key={TAB_STATES.skills}
                onClick={() => setTabState(TAB_STATES.skills)}
              >
                <Label className="modal-label">SKILLS</Label>
              </Tab>
            ) : (
              Object.keys(TAB_STATES).map((key) => (
                <Tab
                  active={tabState === TAB_STATES[key]}
                  key={key}
                  onClick={() => setTabState(TAB_STATES[key])}
                >
                  <Label className="modal-label">
                    {TAB_STATES[key].charAt(0).toUpperCase() +
                      TAB_STATES[key].slice(1)}
                  </Label>
                </Tab>
              ))
            )}
          </Tab.Group>

          
          <GenericTable
            columns={COLUMNS[tabState]}
            data={employeeData[tabState] || []}
            isPaginated={false}
            loading={false}
            onRowClick={(row) => (editMode ? handleRowClick(row) : null)}
          />
        </div>
      </div>
    </div>
  </div>
)


Table.propTypes = {
  employeeData: PropTypes.object,
  editMode: PropTypes.bool,
  permissionLevels: PropTypes.object,
  TAB_STATES: PropTypes.object,
  setTabState: PropTypes.func,
  COLUMNS: PropTypes.object,
  handleRowClick: PropTypes.func,
  tabState: PropTypes.string,
  mobile: PropTypes.bool,
  loading: PropTypes.bool
};

Table.defaultProps = {
  employeeData: {},
  editMode: false,
  permissionLevels: {},
  TAB_STATES: {},
  setTabState: () => null,
  COLUMNS: {},
  handleRowClick: () => null,
  tabState: "",
  mobile: false,
  loading: false
};

export default Table;
