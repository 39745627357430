import React, { useEffect, useState } from "react";
import { Button, Title } from "rbx";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_USERS} from "./graphql";
import { useModal } from "../../context";
import { Loader } from "../../components";
import IncompleteGoals from "./IncompleteGoals";
import CompletedGoals from "./CompletedGoals";
import "./Goals.scss";
import GoalFormModal from "./components/GoalFormModal/GoalFormModal";

const Goals = () => {
  const params = useParams();
  // id being employee number
  const { id } = params;

  // brings back all reviews where at least one authorEmployeeNumber matches their employee number
  const {
    data: userData,
    loading,
    refetch,
  } = useQuery(GET_USERS, {
    variables: {
      where: {
        employeeNumber: {contains: id}
      },
      fetchPolicy: "network-only",
    }
  });

  const { setModalOpen, isModalOpen } = useModal();
  const [goals, setGoals] = useState([]);
  const [isCompletedGoals, setIsCompletedGoals] = useState(false);

  useEffect(() => {
    if (userData?.users[0]?.goals) {
      setGoals(userData?.users[0]?.goals);
    }
  }, [userData, id, refetch, isModalOpen]);

  useEffect(() => {
    refetch();
  }, [refetch, id]);

  const handleComplete = async () => {
    refetch();
    setModalOpen(false);
  };

  const handleOpenGoalAdd = (goal, type) => {
    setModalOpen(
      true,
      <GoalFormModal editGoal={goal} type={type} userData={userData?.users[0]} onComplete={handleComplete}/>
    );
  };
  

  if (loading) {
    return (
      <div className="inner-container">
        <Loader />
      </div>
    );
  }

  if (!goals.length) {
    return (
      <div className="inner-container">
        <div className="reviews" style={{width:"70%", alignItems: "center"}}>
          <div className="add-button-container">
            <Button
              className="review-option-primary"
              style={{ marginBottom: "1.5rem", padding: "0.5rem"}}
              type="button"
              onClick={() => handleOpenGoalAdd()}
            >
              <Title size={6} textColor="white">
                Add Goal
              </Title>
            </Button> 
          </div>
          <Title className="centerTitle" >No Goals Found</Title>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="inner-container">
        <Button
          className={isCompletedGoals ? "unselectedBtn" : "selectedBtn"}
          type="button"
          onClick={() => setIsCompletedGoals(!isCompletedGoals)}
        >
          <p>Active</p>
        </Button>
        <Button
          className={isCompletedGoals ? "selectedBtn" : "unselectedBtn"}
          type="button"
          onClick={() => setIsCompletedGoals(!isCompletedGoals)}
        >
          <p>Completed</p>
        </Button>
     
      </div>
      {isCompletedGoals ? (
        <CompletedGoals
          employeeNumber={id}
          goals={goals}
          loading={loading}
          setGoals={setGoals}
          userData={userData?.users[0]}
        />
      ) : (
        <IncompleteGoals
          employeeNumber={id}
          goals={goals}
          handleOpenGoalAdd={handleOpenGoalAdd}
          loading={loading}
          refetchMyGoals={refetch}
          setGoals={setGoals}
          userData={userData?.users[0]}
        />
      )}
    </div>
  );
};

export default Goals;
