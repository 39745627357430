import React from "react";
import PropTypes from "prop-types";

import { Field, Control, Input, Label, Select, Icon } from "rbx";
import { useQuery } from "@apollo/client";
import { useTheme, useMediaQuery } from "@mui/material";
// question icon from font awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

import { GET_PROJECTS } from "../../../../graphql/project/project.queries";
import "./ContractorForm.scss";

const evaluations = [
  { id: 0, label: " " },
  { id: 1, label: "1" },
  { id: 2, label: "2" },
  { id: 3, label: "3" },
];

const statusOptions = [
  { id: 4, label: " " },
  { id: 5, label: "Active" },
  { id: 6, label: "Inactive" },
];

const ContractorForm = ({ contractor, onChange }) => {
  const { data: ProjectsData, loading: projectsDataLoading } = useQuery(
    GET_PROJECTS,
    {
      orderBy: [{ name: "asc" }],
      where: { id: {} },
    }
  );

  let projects = [{ id: 7, name: " " }];

  if (ProjectsData?.projects) {
    projects = [...projects, ...ProjectsData?.projects];
  }

  const handleInputChange = (item) => {
    switch (item.target.name) {
      case "project.name":
        onChange(
          "projectId",
          projects.find((project) => project.name === item.target.value)
        );
        break;

      case "contactStatus":
        onChange("status", item.target.value);
        break;

      default:
        onChange(item.target.name, item.target.value);
        break;
    }
  };

  const indicateRequiredProject =
    !contractor.projectId || contractor.project.name === " ";
  const indicateRequiredStatus =
    contractor.contractorStatus === " " ||
    contractor.contractorStatus === undefined;
  const indicateRequiredEvaluation =
    !contractor.evaluation || contractor.evaluation === " ";

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Field
      className={downSm ? "grid-override is-mobile" : "grid-override is-two"}
      kind="group"
    >
      <Control expanded>
        <Label>First Name</Label>
        <Input
          required
          autoComplete="off"
          maxLength={100}
          name="firstName"
          placeholder="First Name"
          type="text"
          value={contractor.firstName || ""}
          onChange={handleInputChange}
        />
      </Control>
      <Control expanded>
        <Label>Last Name</Label>
        <Input
          required
          autoComplete="off"
          maxLength={100}
          name="lastName"
          placeholder="Last Name"
          type="text"
          value={contractor.lastName || ""}
          onChange={handleInputChange}
        />
      </Control>
      <Control expanded>
        <Label>Staffing Agency</Label>
        <Input
          required
          autoComplete="off"
          maxLength={100}
          name="staffingAgency"
          placeholder="Staffing Agency"
          type="text"
          value={contractor.staffingAgency || ""}
          onChange={handleInputChange}
        />
      </Control>
      <Control expanded>
        <Label>Project</Label>
        <Select.Container
          state={
            projectsDataLoading || Array.isArray(projects) ? "" : "loading"
          }
        >
          <Select
            required
            className="select-btn"
            name="project.name"
            placeholder="Project"
            style={{
              border: indicateRequiredProject ? "1px #e63362 solid" : "",
              borderRadius: "4px",
              boxShadow: indicateRequiredProject
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            }}
            value={contractor.project?.name || ""}
            onChange={handleInputChange}
          >
            {projects?.map((option) => (
              <Select.Option key={option.id} value={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </Control>
      <Control expanded>
        <Label>Task</Label>
        <Input
          required
          autoComplete="off"
          maxLength={100}
          name="task"
          placeholder="Task"
          type="text"
          value={contractor.task || ""}
          onChange={handleInputChange}
        />
      </Control>
      <Control expanded>
        <Label>Status</Label>
        <Select.Container>
          <Select
            required
            className="select-btn"
            name="contactStatus"
            placeholder="Status"
            style={{
              border: indicateRequiredStatus ? "1px #e63362 solid" : "",
              borderRadius: "4px",
              boxShadow: indicateRequiredStatus
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            }}
            value={contractor.contractorStatus || ""}
            onChange={handleInputChange}
          >
            {statusOptions.map((option) => (
              <Select.Option key={option.id} value={option.label}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </Control>
      <Control expanded>
        <Label>Notes</Label>
        <Input
          required
          autoComplete="off"
          maxLength={100}
          name="notes"
          placeholder="Notes"
          type="text"
          value={contractor.notes || ""}
          onChange={handleInputChange}
        />
      </Control>
      <Control expanded>
        <Label>
          Evaluation{" "}
          <Icon
            tooltip={`1: Highly Recommended\n2: Neutral\n3: Less Recommended`}
          >
            <FontAwesomeIcon color="darkBlue" icon={faQuestionCircle} />
          </Icon>{" "}
        </Label>
        <Select.Container className="input-container">
          <Select
            required
            className="select-btn"
            name="evaluation"
            placeholder="Evaluation"
            style={{
              border: indicateRequiredEvaluation ? "1px #e63362 solid" : "",
              borderRadius: "4px",
              boxShadow: indicateRequiredEvaluation
                ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
                : "",
            }}
            value={contractor.evaluation || ""}
            onChange={handleInputChange}
          >
            {evaluations.map((option) => (
              <Select.Option key={option.id} value={option.label}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </Control>
    </Field>
  );
};

ContractorForm.propTypes = {
  contractor: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

ContractorForm.defaultProps = {
  contractor: {},
};

export default ContractorForm;
