/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_USER_PROJECT_HISTORY = gql`
  query ProjectHistoryEntries(
    $where: ProjectHistoryEntryWhereInput
    $orderBy: [ProjectHistoryEntryOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    projectHistoryEntries(
      where: $where
      orderBy: $orderBy
      take: $take
      skip: $skip
    ) {
      id
      projectId
      projectName
      updatedAt
      updatedBy
      updatedByFullName
      userId
      user {
        firstName
        lastName
      }
    }
  }
`;

export const GET_USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      firstName
      lastName
    }
  }
`;
