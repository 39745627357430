import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Select, Field, Icon, Control, Button } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Pagination({
  page,
  pages,
  onClickPrev,
  onClickNext,
  canNextPage,
  canPreviousPage,
  pageSize,
  setPageSize,
}) {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.keyCode === 39) {
        onClickNext();
      }
      if (e.keyCode === 37) {
        onClickPrev();
      }
    };
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onClickNext, onClickPrev]);

  return (
    <div className="data-table-pagination">
      <Field align="right" kind="group" size="small">
        <Control>
          <Button
            className="prev"
            color="primary"
            disabled={!canPreviousPage}
            role="button"
            size="small"
            onClick={onClickPrev}
          >
            <Icon>
              <FontAwesomeIcon icon="arrow-left" />
            </Icon>
          </Button>
        </Control>
        <Control>
          <Select.Container size="small">
            <Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 25, "All"].map((ps) => {
                const p = ps === "All" ? 10000 : ps;
                return (
                  <Select.Option key={ps} value={p}>
                    Show {ps}
                  </Select.Option>
                );
              })}
            </Select>
          </Select.Container>
        </Control>
        <Control>
          <Button disabled size="small">
            {page} / {pages}
          </Button>
        </Control>
        <Control>
          <Button
            className="next"
            color="primary"
            disabled={!canNextPage}
            role="button"
            size="small"
            onClick={onClickNext}
          >
            <Icon>
              <FontAwesomeIcon icon="arrow-right" />
            </Icon>
          </Button>
        </Control>
      </Field>
    </div>
  );
}

Pagination.propTypes = {
  pages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number,
  setPageSize: PropTypes.func,
};

Pagination.defaultProps = {
  pageSize: 20,
  setPageSize: "",
};
export default Pagination;
