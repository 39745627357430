import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Icon } from "rbx";
import {
  Button as MuiButton,
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from "prop-types";
import { FileDownload } from "@mui/icons-material";
import {
  ReportsTable,
  AccordionItem,
  AccordionDetailsItem,
  ReportSelectMUI,
} from "../../../../components";

import { GET_YEARS_OF_SERVICE_REPORT } from "../../../../graphql/yearsOfService/yearsOfService.queries";
import YOS_COLUMNS from "./columns";

import { customToast as toast, downloadLocalCsv } from "../../../../utils";
import ReportHeader from "../ReportHeader";
import { GET_EMPLOYEES_WITH_GROUP } from "../../../../graphql";
import MobileTable from "../../../../components/ReportsTable/MobileTable";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";

const YearsOfServiceTable = ({ tabs, onReportChange, report, permissions }) => {
  const [data, setData] = useState([]);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  // technically the where and order by are not applicatble. But it is handled server side. And this will remain as a placeholder
  const { loading } = useQuery(GET_EMPLOYEES_WITH_GROUP, {
    variables: {
      where: {
        employmentStatus: {
          equals: "active",
        },
      },
      orderBy: [
        {
          firstName: "asc",
        },
      ],
    },

    onCompleted: (d) => {
      // sort by the parse int years of service
      const arrToSort = [...d?.getEmployeesWithGroup];
      const sortedData = downSm ? arrToSort : arrToSort.sort((a, b) => {
        const aYos = parseFloat(a?.totalYearsOfService, 10);
        const bYos = parseFloat(b?.totalYearsOfService, 10);
        return aYos - bYos;
      });

      setData(sortedData);
    },
  });

  const exportCSV = async () => {
    try {
      const sortedData = data.sort((a, b) => {
        const aYos = parseFloat(a?.totalYearsOfService, 10);
        const bYos = parseFloat(b?.totalYearsOfService, 10);

        return bYos - aYos;
      });

      downloadLocalCsv(sortedData, "YearsOfService", YOS_COLUMNS);
      toast.success("CSV exported successfully");
    } catch (err) {
      toast.error("Error exporting CSV");
    }
  };


  if (downSm) {
    return (
      <MobileTable
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <ReportSelectMUI value={report} onChange={onReportChange} />
            </Box>

            <Box mt={1} width="20%">
              <MuiButton
                color="secondary"
                sx={{
                  color: "white",
                  width: "100%",
                }}
                variant="contained"
                onClick={() => exportCSV()}
              >
                <FileDownload />
              </MuiButton>
            </Box>
          </React.Fragment>
        }
        loading={loading}
        title="Reports"
      >
        {loading ? (
          <BoxLoader />
        ) : (
          data?.map((item) => (
            <AccordionItem
              info={item?.totalYearsOfService}
              key={item?.employeeNumber}
              title={`${item?.firstName} ${item?.lastName}`}
            >
              <AccordionDetailsItem
                label="Employee #"
                value={item?.employeeNumber}
              />
              <AccordionDetailsItem label="Email" value={item?.email} />
              <AccordionDetailsItem label="Cost Code" value={item?.costCode} />
              <AccordionDetailsItem label="Review Group" value={item?.group} />
              <AccordionDetailsItem
                label="Notes"
                value={item?.yearsOfServiceNote}
              />
            </AccordionItem>
          ))
        )}
      </MobileTable>
    );
  }

  return (
    <ReportsTable
      columns={YOS_COLUMNS}
      data={data}
      loading={loading}
      saveQuery={GET_YEARS_OF_SERVICE_REPORT}
      sortBy={[{ "Expiration Date": "desc" }]}
    >
      {(permissions.webEditor ||
        permissions.admin ||
        permissions.superAdmin) && (
        <div className="table-utils">
          <Button
            className="download-btn"
            disabled={loading}
            size="medium"
            title="Download CSV"
            onClick={exportCSV}
          >
            <Icon size="small">
              <FontAwesomeIcon icon="download" />
            </Icon>
          </Button>
        </div>
      )}
      <ReportHeader
        report={report}
        tabs={tabs}
        onReportChange={onReportChange}
      />
    </ReportsTable>
  );
};

YearsOfServiceTable.propTypes = {
  onReportChange: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default YearsOfServiceTable;
