const createComponents = (palette) => ({
  MuiSelect: {
    styleOverrides: {
      root: {
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: `${palette.primary.main} !important`,
        },
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      paper: {
        backgroundColor: "white",
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        "&.Mui-focused": {
          color: `${palette.primary.main} !important`,
        },
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: "#f5f5f5 !important",
      },
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        backgroundColor: "inherit !important",
      },
    },
  },
  MuiStack: {
    styleOverrides: {
      root: {
        backgroundColor: "inherit !important",
      },
    },
  },
});

export default createComponents;
