import { gql } from "@apollo/client";

// MONGODB
export const VALIDATE_PASSWORD_RESET_QUERY = gql`
  query ValidatePasswordResetToken($where: UserWhereInput!) {
    validatePasswordResetToken(where: $where) {
      id
      firstName
    }
  }
`;

export const FIND_FIRST_USER = gql`
  query FindFirstUser($where: UserWhereInput) {
    findFirstUser(where: $where) {
      id
      employeeNumber
      yearsOfServiceNote
      profileImage
      userType
      deccoUCourses {
        id
        issueDate
        codes {
          title
          points
          id
          expirationType
        }
      }
      certifications {
        id
        expDate
        issueDate
        certificationNumber
        codes {
          points
          title
          id
          expirationType
        }
      }
      licenses {
        id
        issueDate
        expDate
        licenseNumber
        codes {
          points
          title
          level
          state
          id
          expirationType
        }
      }
      skills {
        id
        issueDate
        codes {
          points
          title
          id
          expirationType
        }
      }
    }
  }
`;

export const FIND_MANY_CERTIFICATIONS = gql`
  query FindManyCertifications(
    $where: CertificationsWhereInput
    $orderBy: [CertificationsOrderByWithRelationInput!]
  ) {
    findManyCertifications(where: $where, orderBy: $orderBy) {
      id
      expDate
      users {
        email
        employeeNumber
      }
      userId
      codes {
        id
        entity
        points
        title
      }
    }
  }
`;

export const FIND_FIRST_CERTIFICATIONS = gql`
  query FindFirstCertifications($where: CertificationsWhereInput) {
    findFirstCertifications(where: $where) {
      id
      expDate
      codes {
        id
        entity
        points
        title
      }
    }
  }
`;

export const FIND_UNIQUE_CERTIFICATIONS = gql`
  query FindUniqueCertifications($where: CertificationsWhereUniqueInput!) {
    findUniqueCertifications(where: $where) {
      id
    }
  }
`;

export const FIND_MANY_LICENSES = gql`
  query FindManyLicenses(
    $where: LicensesWhereInput
    $orderBy: [LicensesOrderByWithRelationInput!]
  ) {
    findManyLicenses(where: $where, orderBy: $orderBy) {
      id
      expDate
      userId
      codes {
        id
        entity
        points
        title
        level
        state
      }
      users {
        id
        employeeNumber
      }
    }
  }
`;

export const FIND_MANY_SKILLS = gql`
  query FindManySkills(
    $where: SkillsWhereInput
    $orderBy: [SkillsOrderByWithRelationInput!]
  ) {
    findManySkills(where: $where, orderBy: $orderBy) {
      id
      userId
      codes {
        id
        entity
        points
        title
      }
      users {
        id
        employeeNumber
      }
    }
  }
`;

export const FIND_MANY_TECH_COURSES = gql`
  query FindManyTechCourses($where: deccoUCoursesWhereInput) {
    findManydeccoUCourses(where: $where) {
      id
      issueDate
      codes {
        id
        entity
        points
        title
      }
    }
  }
`;

// FOUNDATION
export const VALIDATE_PASSWORD_FOUNDATION = gql`
  query FindFirstEmployee($where: EmployeeWhereInput) {
    findFirstEmployee(where: $where) {
      emailAddress
      employeeNumber
      firstName
    }
  }
`;

export const GET_FOUNDATION_USER = gql`
  query GetFoundationUser($where: EmployeeWhereInput!) {
    getFoundationUser(where: $where) {
      employeeNumber
      firstName
      middleInitial
      lastName
      dateOfBirth
      dateTerminated
      dateRehired
      phone
      emailAddress
      dateHired
      departmentNumber
      jobTitle
      yearsOfService
      address
      city
      state
      costCode
      zip
    }
  }
`;
