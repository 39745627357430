import React, { useState } from "react";

import {
  Button,
  Box,
  LinearProgress,
  Collapse,
  Typography,
  useTheme,
} from "@mui/material";

import PropTypes from "prop-types";
import {
  Add,
  FileDownload,
  FilterAlt,
  FilterAltOff,
} from "@mui/icons-material";
import {
  ProjectSelect,
  AccordionItem,
  AccordionDetailsItem,
} from "../../components";

import ContractorFilters from "./components/ContractorFilters";

const MobileContractorList = ({
  inputs,
  loading,
  data,
  handleFiltersChange,
  handleProjectChange,
  handleDetailsClick,
  handleAddClick,
  onExportClick,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box padding="90px 0px 0px 0px" width="100%">
        {loading && <LinearProgress color="secondary" />}
      </Box>

      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100%"
        overflow="hidden"
        padding="20px 0px 0px 0px"
        width="100svw"
      >
        <Box display="inline-block" width="100%">
          <Box
            display="flex"
            gap={2}
            id="filters-container"
            justifyContent="center"
            mb={2}
            pl={2}
            pr={2}
            width="100%"
          >
            <Box pl={2} width="100%">
              <Button
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => onExportClick()}
              >
                <FileDownload />
              </Button>
            </Box>
            <Box pl={2} pr={2} width="100%">
              <Button
                color="primary"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              >
                {isFiltersOpen ? <FilterAltOff /> : <FilterAlt />}
              </Button>
            </Box>
            <Box pr={2} width="100%">
              <Button
                color="success"
                sx={{ width: "100%", color: theme.palette.common.white }}
                variant="contained"
                onClick={() => handleAddClick()}
              >
                <Add />
              </Button>
            </Box>
          </Box>
          <Collapse in={isFiltersOpen}>
            <Box mb={4}>
              <ContractorFilters
                inputs={inputs}
                onChange={handleFiltersChange}
              />
            </Box>
          </Collapse>
          {(data?.length === 0 || !data) && (
            <Box
              alignItems="center"
              bgcolor={theme.palette.grey[400]}
              display="flex"
              height="15rem"
              justifyContent="center"
              mt={2}
              width="100%"
            >
              <Typography variant="h7">No Contractors found</Typography>
            </Box>
          )}
          <Collapse in={!isFiltersOpen}>
            <Box
              display="inline-block"
              height="80svh"
              id="employees-container"
              maxHeight="38rem"
              pr={2}
              sx={{
                textTransform: "uppercase",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              width="100%"
            >
              {data?.map((item) => (
                <AccordionItem
                  key={item.id}
                  title={`${item.firstName} ${item?.lastName}`}
                >
                  <AccordionDetailsItem label="Task" value={item.task} />
                  <AccordionDetailsItem
                    label="Evaluation"
                    value={item.evaluation}
                  />
                  <AccordionDetailsItem
                    label="Staffing Agency"
                    value={item.staffingAgency}
                  />
                  <AccordionDetailsItem label="Status" value={item.status} />
                  <AccordionDetailsItem label="Notes" value={item.notes} />
                  <Box mt={2}>
                    <ProjectSelect
                      name="projectId"
                      value={item.projectId || ""}
                      onChange={(_, value) =>
                        handleProjectChange(item.id, value)
                      }
                    />
                  </Box>
                  <Box mt={2}>
                    <Button
                      fullWidth
                      color="secondary"
                      style={{ color: theme.palette.common.white }}
                      variant="contained"
                      onClick={() => handleDetailsClick(item)}
                    >
                      Edit
                    </Button>
                  </Box>
                </AccordionItem>
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </React.Fragment>
  );
};

MobileContractorList.propTypes = {
  loading: PropTypes.bool,
  inputs: PropTypes.object,
  data: PropTypes.array,
  handleProjectChange: PropTypes.func,
  handleFiltersChange: PropTypes.func,
  handleAddClick: PropTypes.func,
  onExportClick: PropTypes.func,
  handleDetailsClick: PropTypes.func,
};

MobileContractorList.defaultProps = {
  loading: false,
  data: [],
  inputs: {},
  handleProjectChange: () => {},
  handleFiltersChange: () => {},
  handleAddClick: () => {},
  onExportClick: () => {},
  handleDetailsClick: () => {},
};

export default MobileContractorList;
