/* eslint-disable react/prop-types */
import React from "react";
import { ProjectSelect } from "../../components";

const CONTRACTOR_COLUMNS = [
  {
    Header: "Full Name",
    id: "fullName",
    accessor: "fullName",
  },
  {
    Header: "Task",
    id: "task",
    accessor: "task",
  },
  {
    Header: "Evaluation",
    id: "evaluation",
    accessor: "evaluation",
  },
  {
    Header: "Staffing Agency",
    id: "staffingAgency",
    accessor: "staffingAgency",
  },
  {
    Header: "Status",
    id: "status",
    accessor: "status",
  },
  {
    Header: "Notes",
    id: "notes",
    accessor: "notes",
  },
];

export const generateColumns = (handleChange) => [
  ...CONTRACTOR_COLUMNS,
  {
    Header: "Project",
    accessor: "projectId",

    Cell: ({ cell, row }) => (
      <ProjectSelect
        name={row.original.id}
        value={cell.value || ""}
        onChange={handleChange}
      />
    ),
  },
];

export default CONTRACTOR_COLUMNS;
