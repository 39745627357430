import React from "react";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { FIND_MANY_CODES } from "../../graphql/codes/codes.queries";

const CodeSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  fullWidth,
  getValue,
  setValue,
  entity,
  addClass,
  addLabelClass,
}) => {
  const { data: { findManyCodes: options = [] } = {} } = useQuery(
    FIND_MANY_CODES,
    {
      variables: {
        where: {
          entity: {
            equals: entity,
          },
        },
        orderBy: [
          {
            title: "asc",
          },
        ],
      },
      skip: !entity,
    }
  );

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control className={addClass} size={size}>
      {label && <Label className={addLabelClass}>{label}</Label>}
      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {Array.isArray(options) &&
            options.map(({ id, title }) => (
              <Select.Option key={id} value={title}>
                {title}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

CodeSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  entity: PropTypes.string.isRequired,
  addClass: PropTypes.string,
  addLabelClass: PropTypes.string,
};

CodeSelect.defaultProps = {
  size: "",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
  addClass: "",
  addLabelClass: "",
};

export default CodeSelect;
