import React from "react";
import {
  Box,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import PropTypes from "prop-types";

const Table = ({
  columns,
  data,
  sorting,
  rowSelection,
  getIsRowSelected,
  onSortingChange,
  onRowSelectionChange,
  onRowClick,
  onCellClick,
  fullWidth,
}) => {
  const theme = useTheme();

  const table = useReactTable({
    data,
    columns,
    columnResizeMode: "onChange",
    manualSorting: true,
    manualPagination: true,
    enableRowSelection: true,
    state: {
      sorting,
      rowSelection,
    },
    getRowId: (originalRow, index) => originalRow.id || index,
    onRowSelectionChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleRowClick = (...args) => {
    if (typeof onRowClick === "function") {
      const sel = window?.getSelection()?.toString().length;
      if (sel) return;
      onRowClick(...args);
    }
  };

  const handleCellClick = (...args) => {
    const sel = window?.getSelection()?.toString().length;
    if (sel) return;
    onCellClick?.(...args);
  };
  const isHoverable = typeof onRowClick === "function";
  const width = fullWidth ? "100%" : table.getCenterTotalSize();

  return (
    <MuiTable stickyHeader sx={{ width }}>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <TableCell
                colSpan={header.colSpan}
                key={header.id}
                sx={{
                  whiteSpace: "nowrap",
                  width: header.getSize(),
                }}
              >
                {header.column.getCanSort() ? (
                  <TableSortLabel
                    active={!!header.column.getIsSorted()}
                    direction={header.column.getIsSorted() ? header.column.getIsSorted() : "asc"}
                    onClick={header.column.getToggleSortingHandler()}
                  >
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </TableSortLabel>
                ) : (
                  flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )
                )}
                {header.column.getCanResize() && (
                  <Box
                    sx={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      height: "100%",
                      width: "5px",
                      background: header.column.getIsResizing()
                        ? theme.palette.primary.main
                        : theme.palette.secondary.main,
                      cursor: "col-resize",
                      userSelect: "none",
                      touchAction: "none",
                      opacity: header.column.getIsResizing() ? 1 : 0,
                      "&:hover": {
                        opacity: 1,
                      },
                    }}
                    onMouseDown={header.getResizeHandler()}
                  />
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {table.getRowModel().rows.map((row) => {
          const isSelected = !!getIsRowSelected?.(row.original);
          return (
            <TableRow
              hover={isHoverable}
              key={row.id}
              selected={isSelected}
              onClick={(e) => {
                handleRowClick(row.original, e);
              }}
            >
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  sx={{ width: cell.column.getSize() }}
                  onClick={(e) => handleCellClick(cell, e)}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          );
        })}
      </TableBody>
    </MuiTable>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.any.isRequired,
  sorting: PropTypes.array,
  rowSelection: PropTypes.object,
  getIsRowSelected: PropTypes.func,
  onSortingChange: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  fullWidth: PropTypes.bool,
};

Table.defaultProps = {
  sorting: [],
  rowSelection: {},
  fullWidth: false,
  getIsRowSelected: () => false,
  onSortingChange: () => {},
  onRowSelectionChange: () => {},
  onRowClick: () => {},
  onCellClick: () => {},
};

export default Table;
