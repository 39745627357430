import React, { useState } from "react";
import { Input, Button } from "rbx";


import { useMutation } from "@apollo/client";
import { useHistory, Link } from "react-router-dom";
import { customToast as toast } from "../../utils";

import { FORGOT_PASSWORD_MUTATION } from "../../graphql";

const ForgotPassword = () => {
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const history = useHistory();

  const [requestPasswordReset, { loading: RequestPasswordResetLoading }] =
    useMutation(FORGOT_PASSWORD_MUTATION);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data: RequestPasswordResetData } = await requestPasswordReset({
        variables: {
          input: {
            email: forgotPasswordEmail,
            origin: window.location.origin,
          },
        },
      });

      if (!RequestPasswordResetData) {
        // We don't want to be explicit about this error.
        throw new Error("Something went wrong.");
      }
      toast.success(
        "Request Password Reset sent successfully. Please check your email."
      );
      history.push("/");
    } catch (err) {
      toast.error(`${err.message}`);
    }
  };

  const handleChange = (name, value) => {
    setForgotPasswordEmail((prev) => value);
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-wrapper">
        <div className="logo">
          <img alt="DECCO" className="decco-logo" src="/logo-white.png" />
        </div>
        <form className="form-box" onSubmit={handleSubmit}>
          <Input
            required
            className="ph-w input"
            disabled={RequestPasswordResetLoading}
            name="email"
            placeholder="DECCO Email"
            type="email"
            value={forgotPasswordEmail}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />

          <div>
            <Link to="/login">Back to Log In</Link>
          </div>

          <Button
            disabled={!forgotPasswordEmail || RequestPasswordResetLoading}
            state={RequestPasswordResetLoading ? "loading" : ""}
            type="submit"
          >
            Reset Password
          </Button>
        </form>
      </div>
    </div>
  );
};

ForgotPassword.propTypes = {};

export default ForgotPassword;
