import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import GoalSection from "./components/GoalSection/GoalSection";
import GoalForm from "./components/GoalForm/GoalForm";
import { useModal } from "../../context";
import { usePermissions } from "../../hooks";
import capitalizeEveryWord from "../../utils/capitalizeEveryWord";
import "./Goals.scss";
import checkMobile from "../../utils/checkForMobile";

const CompletedGoals = ({
  goals,
  employeeNumber,
  loading,
  setGoals,
  refetchMyGoals,
  userData,
}) => {
  const permissionLevels = usePermissions();
  const isMobile = checkMobile();
  const [permissions, setPermissions] = useState(null);

  const showManagerReviews =
    permissions?.webEditor ||
    permissions?.superAdmin ||
    permissions?.admin ||
    permissions?.fieldSupervisor;

  const { setModalOpen } = useModal();

  const handleComplete = async () => {
    setModalOpen(false);
    await refetchMyGoals();
  };

  const completeGoals = goals?.filter((goal) => goal.status === "COMPLETED");

  useEffect(() => {
    if (permissionLevels) {
      setPermissions(permissionLevels);
    }
  }, [permissionLevels]);

  const handleOpenGoal = (goal, goalIndex, isCompletedSection) => {
    setModalOpen(
      true,
      <GoalForm
        currentgoalId={goals?.[goalIndex]?.id}
        employeeNumber={employeeNumber}
        goal={goal}
        goals={goals}
        isCompletedSection={isCompletedSection}
        loading={loading}
        permissions={permissionLevels}
        refetchMyGoals={refetchMyGoals}
        onCanceled={() => setModalOpen(false)}
        onComplete={handleComplete}
      />
    );
  };

  return (
    <div
      className={
        showManagerReviews
          ? "reviews-outer-container-manager-goals"
          : "reviews-outer-container-goals"
      }
    >
      <div className="reviews-container-section">
        <div className="reviews">
          {completeGoals.length ? (
            <React.Fragment>
              <div className="centered-title" id="employee-reviews">
                <Typography variant={isMobile ? "h4" : "h3"}>
                  {capitalizeEveryWord(
                    `${userData?.firstName} ${userData?.lastName} Active Goals`
                  )}
                </Typography>
              </div>
              <GoalSection
                isCompletedSection
                goals={goals}
                handleClick={handleOpenGoal}
                sectionType="personal"
                setGoals={setGoals}
              />
            </React.Fragment>
          ) : (
            <div className="centered-title">
              <Typography variant={isMobile ? "h4" : "h3"}>
                Nice! All your goals are completed
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CompletedGoals.propTypes = {
  goals: PropTypes.array.isRequired,
  employeeNumber: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setGoals: PropTypes.func.isRequired,
  refetchMyGoals: PropTypes.func,
  userData: PropTypes.object.isRequired,
};

CompletedGoals.defaultProps = {
  refetchMyGoals: () => {},
};

export default CompletedGoals;
