import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CHECK_AUTH_QUERY = gql`
  query checkAuth {
    checkAuth {
      id
      employeeNumber
      userType
      profileImage
      firstName
      lastName
    }
  }
`;

export const IS_USER_PASSWORD_VALID = gql`
  query IS_USER_PASSWORD_VALID($input: AuthInput!) {
    isUserPasswordValid(input: $input) {
      isValid
    }
  }
`;
