import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_ONE_REVIEW = gql`
  mutation UpdateOneReview(
    $data: ReviewUpdateInput!
    $where: ReviewWhereUniqueInput!
  ) {
    updateOneReview(data: $data, where: $where) {
      id
      status
      manager
      templateVersionId
      title
      year
      userId
    }
  }
`;

export const UPDATE_ONE_EVALUATION = gql`
  mutation UpdateOneEvaluation(
    $data: EvaluationUpdateInput!
    $where: EvaluationWhereUniqueInput!
  ) {
    updateOneEvaluation(data: $data, where: $where) {
      id
      author
      title
    }
  }
`;

export const DELETE_ONE_EVALUATION = gql`
  mutation DeleteOneEvaluation(
    $where: EvaluationWhereUniqueInput!
  ) {
    deleteOneEvaluation(where: $where) {
      id
      author
      title
    }
  }
`;

export const CREATE_ONE_EVALUATION = gql`
  mutation CreateOneEvaluation($data: EvaluationCreateInput!) {
    createOneEvaluation(data: $data) {
      id
      author
      authorHidden
      manager
      templateVersionId
      title
      reviewId
    }
  }
`;

export const UPDATE_ONE_EVALUATION_QUESTION = gql`
  mutation UpdateOneEvaluationQuestion(
    $data: EvaluationQuestionUpdateInput!
    $where: EvaluationQuestionWhereUniqueInput!
  ) {
    updateOneEvaluationQuestion(data: $data, where: $where) {
      answer
      id
    }
  }
`;
