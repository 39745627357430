import { LinearProgress, linearProgressClasses, styled } from "@mui/material";

const LinearProgressBar = styled(LinearProgress)(() => ({
  height: 5,
  width: "100%",
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#dbdbdb",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#6981a8",
  },
}));

export default LinearProgressBar;
