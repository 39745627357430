import React, { useMemo } from "react";
import PropTypes from "prop-types";
import GenericTable from "../../../GenericTable";
import { useModal } from "../../../../context";
import { Loader } from "../../..";

import {
  generateCertificationColumns,
  generateLicenseColumns,
  generateSkillCoursesColumns,
} from "../../columns";
import QualificationCodesModal from "../QualificationCodesModal";

const QualificationsTable = ({ qualification, data, loading, refetch, isReadOnly }) => {
  const { setModalOpen } = useModal();

  const COLUMNS = useMemo(() => {
    switch (qualification) {
      case "certifications":
        return generateCertificationColumns();
      case "licenses":
        return generateLicenseColumns();
      default:
        return generateSkillCoursesColumns();
    }
  }, [qualification]);

  const handleRowClick = ({ id }) => {
    if (isReadOnly) return;
    setModalOpen(
      true,
      <QualificationCodesModal
        id={id}
        qualification={qualification}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
          width: "100%",
        }}
      >
        <Loader />;
      </div>
    );
  }

  return (
    <div className="generic-table-wrapper">
      <GenericTable
        isHoverable
        columns={COLUMNS}
        data={data}
        onRowClick={handleRowClick}
    />
    </div>
  );
};

QualificationsTable.propTypes = {
  qualification: PropTypes.string.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  refetch: PropTypes.func,
  isReadOnly: PropTypes.bool,
};
QualificationsTable.defaultProps = {
  data: [],
  loading: false,
  refetch: () => null,
  isReadOnly: false,
};

export default QualificationsTable;
