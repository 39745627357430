// eslint-disable-next-line import/prefer-default-export
export const INITIAL_CYCLE_INPUTS = {
  name: "",
  groupCycle: null,
  templateId: "",
  startDate: null,
};

export const REVIEW_CYCLE_GROUPS = [
  "D1 - January",
  "D1 - February",
  "D1 - March",
  "D1 - April",
  "D1 - May",
  "D1 - June",
  "D1 - July",
  "D1 - August",
  "D1 - September",
  "D1 - October",
  "D1 - November",
  "D1 - December",

  "D2 - January",
  "D2 - February",
  "D2 - March",
  "D2 - April",
  "D2 - May",
  "D2 - June",
  "D2 - July",
  "D2 - August",
  "D2 - September",
  "D2 - October",
  "D2 - November",
  "D2 - December",

  "D3",
  "D4 Field",
  "D5 (D4 Apprentices)",
];
