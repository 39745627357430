import React from "react";
import PropTypes from "prop-types";
import { Select, Textarea } from "rbx";
import { DateInput } from "../../../../components";

const EvaluationQuestion = ({
  question,
  questionIndex,
  sectionIndex,
  isEvaluationCompleted,
  handleInputChange,
}) => {
  if (question.answerType === "date") {
    return (
      <div className="flex-list-item">
        <p>
          {questionIndex + 1}. {question?.text}
        </p>

        <div className="dropdown-container">
          <DateInput
            required
            disabled={isEvaluationCompleted}
            label=""
            name={`${sectionIndex}-${questionIndex}`}
            value={question?.answer}
            onChange={handleInputChange}
          />
        </div>
      </div>
    );
  }


  if (question.answerType === "multi") {
    return (
      <div className="flex-list-item">
        <p>
          {questionIndex + 1}. {question?.text}
        </p>
        <Select.Container className="dropdown-container">
          <Select
            required
            className="dropdown"
            disabled={isEvaluationCompleted}
            name={`${sectionIndex}-${questionIndex}`}
            value={question?.answer || ""}
            onChange={handleInputChange}
          >
            <Select.Option value="" />
            {[...question?.answerOptions]?.sort((a,b) => a?.value - b?.value).map((option) => (
              <Select.Option key={option.id} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Select.Container>
      </div>
    );
  }

  return (
    <div className="text-input">
      <p>
        {questionIndex + 1}. {question?.text}
      </p>
      <div>
        <Textarea
          required
          disabled={isEvaluationCompleted}
          name={`${sectionIndex}-${questionIndex}`}
          placeholder="Enter response here"
          rows={2}
          value={question?.answer || ""}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

EvaluationQuestion.propTypes = {
  question: PropTypes.object.isRequired,
  questionIndex: PropTypes.number.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  isEvaluationCompleted: PropTypes.bool.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default EvaluationQuestion;
