import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ASSIGN_PROJECT = gql`
  mutation AssignEmployeeToProject(
    $projectId: String!
    $employeeNumber: String!
  ) {
    assignEmployeeToProject(
      projectId: $projectId
      employeeNumber: $employeeNumber
    ) {
      id
    }
  }
`;
