import { Image } from "rbx";
import React from "react";
import BackgroundImage from "../BackgroundImage";

const LoadingPage = () => (
  <div className="app-container">
    <div className="loader-nav">
      <Image
        alt="DECCO"
        className="loader-nav-logo"
        src="/logo-white.png"
        style={{ position: "relative", height: "68%", marginTop: "18px", 
        marginLeft: "22px", width: "auto"
   }}
      />
    </div>

    <BackgroundImage>
      <div className="loader-nav-expanded" />
    </BackgroundImage>
  </div>
);

export default LoadingPage;
