import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const CREATE_PROJECT_HISTORY_ENTRY = gql`
  mutation CreateOneProjectHistoryEntry(
    $data: ProjectHistoryEntryCreateInput!
  ) {
    createOneProjectHistoryEntry(data: $data) {
      projectId
      projectName
      updatedAt
      updatedBy
      updatedByFullName
      userId
    }
  }
`;
