import formatDate from "../../../utils/formatDate";

// eslint-disable-next-line import/prefer-default-export
export const REVIEW_CYCLE_COLUMNS = [
  {
    header: "Name",
    id: "name",
    accessorKey: "name",
  },
  {
    header: "Group Cycle",
    id: "groupCycle",
    accessorKey: "groupCycle",
  },
  {
    header: "Start Date",
    id: "startDate",
    cell: ({ row }) =>
      row?.original?.startDate && formatDate(row?.original?.startDate),
  },
  {
    header: "Assoviated Review Template",
    id: "templateId",
    accessorKey: "templateId",
  },
];
