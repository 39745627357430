import React, {useState} from "react";
import { Title } from "rbx";
import { useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { GET_ALL_EMPLOYEES } from "./graphql";
import "../Reviews/Reviews.scss";
import { Loader, DetailedDataTable} from "../../components";
import { GOALS_COLUMNS } from "./columns";
import checkMobile from "../../utils/checkForMobile";

const AllManagerGoals = () => {
  const params = useParams();
  const { id } = params;
  const [paginationWhere, setPaginationWhere] = useState({}); // eslint-disable-line
  const { data: employeeData, loading } = useQuery(GET_ALL_EMPLOYEES, {
    variables: {
      where: {
        supervisorObj: {
          is: {
            employeeNumber: {
              contains: id,
            },
          }
         },
      },
    },
    fetchPolicy: "cache-and-network",
  });


  const users = employeeData?.users || [];
  const isMobile = checkMobile();

  const history = useHistory();

  const handleRowClick = (row) => {
    history.push(`/goals/${row.employeeNumber}`);
  };
    

  if (loading) {
    return (
      <div className={isMobile ? "workshop-container-mobile": "workshop-container"}>
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (!users?.length > 0) {
    return (
     <div className={isMobile ? "workshop-container-mobile": "workshop-container"}>
        <div className="workshop">
          <Title>No Managed Partners Found</Title>
        </div>
      </div>
    );
  }

  return (
    <div className={isMobile ? "workshop-container-mobile": "workshop-container"}>
      <div className="workshop">
        <Title>Managed Partners</Title>
        <DetailedDataTable
          FilterComponent={null}
          aggregateName="aggregateGoal"
          columns={GOALS_COLUMNS}
          dataExtractor={(data) => data?.users}
          defaultSorting={[{ id: "firstName", desc: false }]}
          location="goals"
          name="all-goals"
          query={GET_ALL_EMPLOYEES}
          searchTransformer={(search) => ({})}
          setPaginationWhere={setPaginationWhere}
          totalExtractor={(total) => total}
          where={{
            supervisorObj: {
              is: {
                employeeNumber: {
                  contains: id,
                },
              }
            },
          }}
          onRowClick={(user) => handleRowClick(user)}
        />
      </div>
    </div>
  );
};

export default AllManagerGoals;
