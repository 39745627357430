import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Title } from "rbx";
import { useApolloClient, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { ReactSelect, Loader } from "../../../../components";
import {
  GET_REVIEWS,
  GET_USERS,
  UPDATE_ONE_EVALUATION,
  CREATE_ONE_EVALUATION,
  DELETE_ONE_EVALUATION,
} from "../../graphql";
import { useAuth } from "../../../../context";
import capitalizeEveryWord from "../../../../utils/capitalizeEveryWord";
import { deepOmit } from "../../../../utils";

const AssignEvaluationForm = ({ evaluation, reviewId, onComplete }) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [users, setUsers] = useState(null);

  const client = useApolloClient();

  const auth = useAuth();
  const activeEmployeeNumber = auth.state.user.employeeNumber;

  const isDisabled =
    selectedEmployees?.length === 0 || selectedEmployees?.length > 10;

  const isMultiSelect =
    (evaluation?.title?.includes("Client") &&
      (evaluation?.title?.includes("D1") ||
        evaluation?.title?.includes("D2") ||
        evaluation?.title?.includes("D3"))) ||
    evaluation?.title?.includes("PPA"); // handles D4 and D5

  const [updateOneEvaluation] = useMutation(UPDATE_ONE_EVALUATION, {
    refetchQueries: [
      {
        query: GET_REVIEWS,
        variables: {
          where: {
            evaluations: {
              some: {
                authorEmployeeNumber: {
                  contains: activeEmployeeNumber,
                },
              },
            },
          },
          orderBy: [
            {
              name: "asc",
            },
          ],
          sectionsOrderBy: [{ order: "asc" }],
          questionsOrderBy: [{ order: "asc" }],
        },
        fetchPolicy: "network-only",
        awaitRefetchQueries: true,
      },
    ],
  });

  const [deleteOneEvaluation] = useMutation(DELETE_ONE_EVALUATION, {
    refetchQueries: [
      {
        query: GET_REVIEWS,
        variables: {
          where: {
            evaluations: {
              some: {
                authorEmployeeNumber: {
                  contains: activeEmployeeNumber,
                },
              },
            },
          },
          orderBy: [
            {
              name: "asc",
            },
          ],
          sectionsOrderBy: [{ order: "asc" }],
          questionsOrderBy: [{ order: "asc" }],
        },
        fetchPolicy: "network-only",
        awaitRefetchQueries: true,
      },
    ],
  });

  const [createOneEvaluation] = useMutation(CREATE_ONE_EVALUATION);

  const fetchEmployeeData = async (variables) => {
    const result = await client.query({
      query: GET_USERS,
      variables,
    });

    setUsers(result?.data?.users);
  };

  useEffect(() => {
    if (evaluation) {
      const evaluationGroup = evaluation.title.split("- ")[1];
      const variables =
        evaluationGroup !== "PPA" && evaluationGroup
          ? {
              orderBy: [
                {
                  firstName: "asc",
                },
              ],
            }
          : { variables: {} };

      fetchEmployeeData(variables);
    } 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluation]);

  useEffect(() => {
    if (users) {
      // added filter condition on to remove former employees
      // changed from a name filter to using employeeNumber
      const usersWithoutCurrentOwner = users?.filter(
        (user) =>
          user?.employeeNumber.trim() !==
          evaluation?.ownerEmployeeNumber.trim() && user?.employmentStatus === "active"
      );

      const dropdownOptions = usersWithoutCurrentOwner?.map((user) => ({
        label: capitalizeEveryWord(
          `${user?.firstName?.trim()} ${user?.lastName?.trim()}`
        ),
        value: user?.employeeNumber,
      }));

      const sortedOptions = dropdownOptions.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setOptions(sortedOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const handleChange = (selected, n) => {
    setSelectedEmployees(selected);
  };

  const handleAssignEvaluation = async (e) => {
    e.preventDefault();
    if (isMultiSelect) {
      const evalCopy = evaluation;
      const omitted = deepOmit(evalCopy, ["__typename"]);

      // remove id and reviewId from omitted object
      delete omitted.id;
      delete omitted.reviewId;

      try {
        await Promise.all(
          selectedEmployees?.map(async (selectedEmployee) => {
            const CreateOneEvaluationInput = {
              variables: {
                data: {
                  ...omitted,
                  author: selectedEmployee?.label.toUpperCase(), // keep in line with other names
                  authorEmployeeNumber: selectedEmployee?.value,
                  formAuthor: selectedEmployee?.label.toUpperCase(),// assign writer one stage earlier
                  formAuthorEmployeeNumber: selectedEmployee?.value,
                  authorHidden: true,
                  needsAssignment: false,
                  review: {
                    connect: {
                      id: reviewId,
                    },
                  },
                  sections: {
                    create: omitted?.sections?.map((section) => ({
                      instructions: section.instructions,
                      title: section.title,
                      questions: {
                        create: section.questions.map((question) => ({
                          answerType: question.answerType,
                          text: question.text,
                          answerOptions: {
                            create: question.answerOptions.map(
                              (answerOption) => ({
                                name: answerOption.name,
                                value: answerOption.value,
                              })
                            ),
                          },
                        })),
                      },
                    })),
                  },
                },
              },
            };

            await createOneEvaluation(CreateOneEvaluationInput);

            // delete current evaluation
          })
        );

        const DeleteOneEvaluationInput = {
          variables: {
            where: {
              id: evaluation?.id,
            },
          },
        };

        await deleteOneEvaluation(DeleteOneEvaluationInput);

        toast.success("Successfully assigned evaluations");
      } catch (err) {
        toast.error("Error: Please contact support");
      }
    } else {
      try {
        const UpdateOneEvaluationInput = {
          variables: {
            data: {
              author: {
                set: selectedEmployees?.label,
              },
              authorEmployeeNumber: {
                set: selectedEmployees?.value,
              },
              authorHidden: {
                set: true,
              },
              needsAssignment: {
                set: false,
              },
            },
            where: {
              id: evaluation.id,
            },
          },
        };

        await updateOneEvaluation(UpdateOneEvaluationInput);

        toast.success("Successfully assigned evaluations");
      } catch (err) {
        toast.error("Error: Please contact support");
      }
    }

    onComplete();
  };

  const loadingAssignEvaluation = async (e) => {
    setLoading(true);
    await handleAssignEvaluation(e);
  };

  if (loading) {
    return (
      <div style={{ minHeight: "10rem" }}>
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <form id="assign-evals-form" onSubmit={(e) => loadingAssignEvaluation(e)}>
      <header className="modal-head">
        <div className="modal-head">
          <Title size={5}>Assign Evaluation</Title>
        </div>
        <div className="modal-head">
          <Button.Group hasAddons>
            <Button
              size="small"
              style={{ marginBottom: "0.5rem" }}
              type="button"
              onClick={() => onComplete("cancel")}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={isDisabled}
              size="small"
              style={{ marginBottom: "0.5rem" }}
              type="submit"
            >
              <span>Save and Close</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <span style={{ color: "red" }}>
        ***Please note that assigning peer evaluations is a one time action. Once you assign peer evaluations, you will not be able to change the assignees. Please make sure you have selected the correct assignees before clicking `Save and Close`.***
      </span>
      {selectedEmployees?.length > 10 && (
        <span style={{ color: "red" }}>
          Please select no more than 10 assignees
        </span>
      )}
      <ReactSelect
        closeMenuOnSelect={!isMultiSelect}
        isMulti={isMultiSelect}
        name="employees"
        options={
          options &&
          options?.map((option) => ({
            label: option?.label,
            value: option?.value,
          }))
        }
        placeholder="Select Employees"
        value={selectedEmployees}
        onChange={(e, n) => {
          handleChange(e, n);
        }}
      />
    </form>
  );
};

AssignEvaluationForm.propTypes = {
  evaluation: PropTypes.object.isRequired,
  reviewId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default AssignEvaluationForm;
