import React, { useContext, createContext, useMemo } from "react";

import {
  createTheme as createMuiTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material";
import PropTypes from "prop-types";
import { createComponents, createPalette, createTypography } from "../theme";

function createTheme() {
  return createMuiTheme({
    palette: createPalette(),
    components: createComponents(createPalette()),
    typography: createTypography(createPalette()),
  });
}

const ThemeContext = createContext();

export const useToggleTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const theme = useMemo(() => createTheme(), []);

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
};
