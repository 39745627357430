import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFile($file: Upload!, $contactInfo: Boolean!) {
    uploadFile(file: $file, contactInfo: $contactInfo) {
      encoding
      filename
      filepath
      mimetype
    }
  }
`;

export const UPLOAD_IMAGE_MUTATION = gql`
  mutation UPLOAD_FILE_MUTATION($file: Upload!) {
    uploadImage(file: $file) {
      filename
      mimetype
      encoding
      filepath
    }
  }
`;
