/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const ALL_BIRTHDAYS_QUERY = gql`
  query ALL_BIRTHDAYS_QUERY($where: BirthdayInput!) {
    birthdays(where: $where) {
      name
      birthday
    }
  }
`;
