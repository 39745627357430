import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateTimeInput.scss";

import { Label } from "rbx";

const DateTimeInput = ({
  id,
  value,
  name,
  label,
  size,
  onChange,
  disabled,
  getValue,
  setValue,
  showBlank,
}) => (
    <React.Fragment>
      <Label>{label}</Label>
     <ReactDatePicker
        showTimeSelect
        className="textInputRow datePicker"
        dateFormat="MMMM d, yyyy p"
        name={name}
        placeholderText="Date"
        popperClassName="popper"
        popperPlacement="bottom"
        selected={
          // eslint-disable-next-line no-nested-ternary
          value
            ? new Date(value)
            : showBlank ? null :new Date()
        }
        timeCaption="time"
        timeFormat="HH:mm"
        timeIntervals={15}
        onChange={date => {
           onChange(name, date)
         }
        }
      />
    </React.Fragment>
  );

DateTimeInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  id: PropTypes.string,
  showBlank: PropTypes.bool,
};

DateTimeInput.defaultProps = {
  value: "",
  name: "",
  label: "Date",
  size: undefined,
  onChange: () => null,
  disabled: false,
  getValue: (x) => x,
  setValue: (x) => x,
  id: "",
  showBlank: false,
};

export default DateTimeInput;
