/* eslint-disable react/prop-types */
import React from "react";
import { uppercaseFirstLetter } from "../../utils";
import { ProjectSelect } from "../../components";

const COLUMNS = [
  {
    Header: "Employee #",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "First Name",
    id: "firstName",
    accessor: "firstName",
    Cell: ({ cell }) => uppercaseFirstLetter(cell?.value) || "N/a",
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "lastName",
    Cell: ({ cell }) => uppercaseFirstLetter(cell?.value) || "N/a",
  },

  {
    Header: "Job Title",
    id: "jobTitle",
    accessor: "jobTitle",
  },
  {
    Header: "Supervisor",
    id: "supervisor",
    accessor: (row) =>
      row?.supervisor ? uppercaseFirstLetter(row.supervisor) : "N/a",
  },
  {
    Header: "Manager",
    id: "manager",
    accessor: (row) =>
      row?.manager ? uppercaseFirstLetter(row.manager) : "N/a",
  },
];

const generateColumns = (handleChange, isProjDistanceQuery) => {
  const base = [
    ...COLUMNS,
    {
      Header: "Project",
      accessor: "projectId",

      Cell: ({ cell }) => (
        <ProjectSelect
          name={cell.row.original.employeeNumber}
          value={cell.value || ""}
          onChange={handleChange}
        />
      ),
    },
  ];

  if (isProjDistanceQuery) {
    base.push({
      Header: "Project Distance",
      id: "distanceToProject",
      accessor: (row) =>
        row.distanceToProject && row.timeToProject
          ? `${row.distanceToProject}, ${row.timeToProject}`
          : "Not Available",
    });
  }

  return base;
};

export default generateColumns;
