import { darken, lighten } from "@mui/material";

const colors = {
  primary: {
    main: "#052e6f",
    light: lighten("#052e6f", 0.3),
    dark: darken("#052e6f", 0.3),
  },
  secondary: {
    main: "#fb7753",
    light: lighten("#fb7753", 0.3),
    dark: darken("#fb7753", 0.2),
  },
  info: {
    main: "#b0e8d0",
  },
  label: {
    main: "#363636",
  },
  background: {
    paper: "#2F293B",
    default: "#1D162A",
  },
  success: {
    main: "hsl(141, 56%, 43%)",
  },
  error: {
    main: "hsl(348, 100%, 61%)",
  },
  common: {
    white: "#fff",
    black: "hsl(0, 0%, 4%)",
  },
  grey: {
    900: "#808080",
    800: "hsl(0, 0%, 21%)",
    700: "#hsl(0, 0%, 29%)",
    600: "hsl(0, 0%, 29%)",
    500: "#7a7a7a",
    400: "#f7f7f7",
    300: "#dbdbdb",
    200: "#d9d9d9",
    100: "hsl(0, 0%, 98%)",
    50: "#f2f2f2",
  },
};

const createPalette = () => colors;

export default createPalette;
