import React from "react";
import PropTypes from "prop-types";
import { Box, LinearProgress, Typography } from "@mui/material";

const MobileTable = ({
  title,
  children,
  loading,
  showLoadingBar,
  headerActions,
  maxHeight,
  handleScrollChange,
  bbCard
}) => (
  <React.Fragment>
    <Box marginTop="-2.2rem" width="100%">
      {loading && showLoadingBar && <LinearProgress color="secondary" />}
    </Box>
    <Box display="grid" justifyItems="center" mb={1} mt={2}>
      <Typography variant="h4">{title}</Typography>
    </Box>
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      marginTop="1rem"
      overflow="hidden"
      width="100svw"
    >
      <Box display="inline-block" width="100%">
        <Box
          alignItems="center"
          display="flex"
          gap={2}
          height="100%"
          id="filters-container"
          justifyContent={bbCard ? "left" : "center"}
          mb={2}
          pl={bbCard? 0 : 2}
          pr={2}
          width="100%"
        >
          {headerActions}
        </Box>

        <Box
          display="inline-block"
          id="employees-container"
          maxHeight={maxHeight}
          pr={2}
          sx={{
            textTransform: "uppercase",
            overflowY: "auto",
            overflowX: "hidden",
          }}
          width="100%"
          onScroll={handleScrollChange}
        >
          {children}
        </Box>
      </Box>
    </Box>
  </React.Fragment>
);

MobileTable.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  headerActions: PropTypes.node,
  loading: PropTypes.bool,
  showLoadingBar: PropTypes.bool,
  maxHeight: PropTypes.string,
  handleScrollChange: PropTypes.func,
  bbCard: PropTypes.bool,
};

MobileTable.defaultProps = {
  loading: false,
  showLoadingBar: false,
  headerActions: null,
  maxHeight: "38rem",
  handleScrollChange: () => {},
  bbCard: false,
};

export default MobileTable;
