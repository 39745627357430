import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import PropTypes from "prop-types";
import {
  Box,
  Button as MuiButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { FileDownload } from "@mui/icons-material";
import {
  ReportsTable,
  ReportSelectMUI,
  AccordionItem,
  AccordionDetailsItem,
} from "../../../../components";

import { GET_POINTS_REPORT } from "../../../../graphql";
import { POINTS_COLUMNS } from "../../columns";

import { downloadLocalCsv, customToast as toast } from "../../../../utils";
import ReportHeader from "../ReportHeader";
import Dropdown from "../../../../components/Dropdown";
import MobileTable from "../../../../components/ReportsTable/MobileTable";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";

const PointsTable = ({ tabs, onReportChange, report, permissions }) => {
  const [activeDropdownItem, setActiveDropdownItem] = useState({
    id: "all",
    label: "All",
  });

  const [qualificationWhere, setQualificationWhere] = useState({});

  const { loading, data: { findManyCodes: pointsData = [] } = {} } = useQuery(
    GET_POINTS_REPORT,
    {
      variables: {
        where: qualificationWhere,
        orderBy: [
          {
            entity: "asc",
          },
          { title: "asc" },
          { level: "asc" },
        ],
      },
    }
  );

  const exportCSV = async () => {
    try {
      downloadLocalCsv(pointsData, "Points", POINTS_COLUMNS);
    } catch (err) {
      toast.error("Error exporting CSV");
    }
  };

  const handleDropdownOptionClick = (item) => {
    if (item.id === "all") {
      setQualificationWhere({});
    } else {
      setQualificationWhere({ entity: { equals: item.id } });
    }

    setActiveDropdownItem(item);
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileTable
        showExportButton
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <ReportSelectMUI value={report} onChange={onReportChange} />
            </Box>

            <Box mt={1} width="20%">
              <MuiButton
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => exportCSV()}
              >
                <FileDownload />
              </MuiButton>
            </Box>
          </React.Fragment>
        }
        loading={loading}
        title="Reports"
      >
        {loading ? (
          <BoxLoader />
        ) : (
          pointsData?.map((item) => (
            <AccordionItem
              info={item?.points}
              key={item?.description}
              title={item?.title}
            >
              <AccordionDetailsItem label="Type" value={item?.entity} />
              <AccordionDetailsItem
                label="State"
                value={item?.state || "N/A"}
              />
              <AccordionDetailsItem
                label="Level"
                value={item?.level || "N/A"}
              />
              <AccordionDetailsItem label="State" value={item?.description} />
              <AccordionDetailsItem
                label="Expiration Type"
                value={item?.expirationType}
              />
              <AccordionDetailsItem
                label="Description"
                value={item?.description}
              />
            </AccordionItem>
          ))
        )}
      </MobileTable>
    );
  }

  return (
    <ReportsTable
      columns={POINTS_COLUMNS}
      data={pointsData}
      loading={loading}
      saveQuery={GET_POINTS_REPORT}
    >
      <div className="table-utils">
        <Dropdown
          activeItem={activeDropdownItem}
          handleOptionClick={handleDropdownOptionClick}
          options={[
            { id: "all", label: "All" },
            { id: "licenses", label: "Licenses" },
            { id: "certifications", label: "Certifications" },
            { id: "skills", label: "Skills" },
            { id: "deccoUCourses", label: "DECCOU COURSES" },
          ]}
        />
        {(permissions?.webEditor || permissions.superAdmin) && (
          <Button
            className="download-btn"
            disabled={loading}
            size="medium"
            title="Download CSV"
            onClick={exportCSV}
          >
            <Icon size="small">
              <FontAwesomeIcon icon="download" />
            </Icon>
          </Button>
        )}
      </div>
      <ReportHeader
        report={report}
        tabs={tabs}
        onReportChange={onReportChange}
      />
    </ReportsTable>
  );
};

PointsTable.propTypes = {
  onReportChange: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default PointsTable;
