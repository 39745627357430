import React from "react";
import { Control, Field, Icon, Label, Radio } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { Box, TextField, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";
import {
  QualificationSelectMUI,
  EmployeeJobTitleSelectMUI,
  GenericSelectMUI,
  ProjectSelectMUI,
} from "../../components";

const EmployeeAssignmentFilters = ({ inputs, handleFiltersChange }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography fullWidth mb={2} ml={2} mr={2} mt={1} variant="h5">
        Employee Filters
      </Typography>
      <Box
        display="grid"
        gap={2}
        sx={{
          width: "100%",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "1fr 1fr",
          },
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
        }}
      >
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Employee Number"
          name="employeeNumber"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.employeeNumber}
          onChange={(e) =>
            handleFiltersChange("employeeNumber", e?.target?.value)
          }
        />

        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Name"
          name="fullName"
          size="medium"
          value={inputs?.fullName}
          onChange={(e) => handleFiltersChange("fullName", e?.target?.value)}
        />

        <EmployeeJobTitleSelectMUI
          showAllOption
          label="Job Title"
          name="jobTitle"
          value={inputs?.jobTitle}
          onChange={(name, value) => handleFiltersChange(name, value)}
        />

        <QualificationSelectMUI
          showAllOption
          entity="certifications"
          label="Certifications"
          name="certifications"
          value={inputs?.certifications}
          onChange={(name, value) => {
            handleFiltersChange(name, value);
          }}
        />

        <QualificationSelectMUI
          showAllOption
          entity="skills"
          label="Skills"
          name="skills"
          value={inputs?.skills}
          onChange={(name, value) => {
            handleFiltersChange(name, value);
          }}
        />

        <QualificationSelectMUI
          showAllOption
          entity="deccoUCourses"
          label="DECCOU Course"
          name="deccoUCourses"
          value={inputs?.deccoUCourses}
          onChange={(name, value) => {
            handleFiltersChange(name, value);
          }}
        />

        <QualificationSelectMUI
          showAllOption
          entity="licenses"
          label="License"
          name="licenses"
          value={inputs?.licenses}
          onChange={(name, value) => {
            handleFiltersChange(name, value);
          }}
        />

        <GenericSelectMUI
          showAllOption
          label="License State"
          name="licenseState"
          options={[
            { value: "MA", title: "MA", id: "MA" },
            { value: "NH", title: "NH", id: "NH" },
            { value: "RI", title: "RI", id: "RI" },
            { value: "CT", title: "CT", id: "CT" },
          ]}
          value={inputs?.licenseState}
          onChange={(name, value) => {
            handleFiltersChange(name, value);
          }}
        />

        <GenericSelectMUI
          showAllOption
          label="License Level"
          name="licenseLevel"
          options={[
            {
              value: "Apprentice",
              title: "Apprentice",
              id: "Apprentice",
            },
            {
              value: "Journeyman",
              title: "Journeyman",
              id: "Journeyman",
            },
            { value: "Master", title: "Master", id: "Master" },
          ]}
          value={inputs?.licenseLevel}
          onChange={(name, value) => {
            handleFiltersChange(name, value);
          }}
        />
      </Box>
      <Typography fullWidth m={2} variant="h5">
        Project Filters
      </Typography>
      <Box
        display="grid"
        gap={2}
        sx={{
          width: "100%",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "1fr 1fr",
          },
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
        }}
      >
        <ProjectSelectMUI
          showAllOption
          label="Project to check distance"
          name="projectId"
          value={inputs?.projectId}
          onChange={handleFiltersChange}
        />
        <Box display="grid">
          <Typography variant="h7">Filter Employees by Project</Typography>
          <Box>
            <Label>
              <Radio
                checked={inputs?.isFilterEmployee}
                name="isFilterEmployee"
                onChange={() =>
                  handleFiltersChange(
                    "isFilterEmployee",
                    !inputs?.isFilterEmployee
                  )
                }
              />{" "}
              Yes
            </Label>
            <Label>
              <Radio
                checked={!inputs?.isFilterEmployee}
                name="isFilterEmployee"
                onChange={() =>
                  handleFiltersChange(
                    "isFilterEmployee",
                    !inputs?.isFilterEmployee
                  )
                }
              />{" "}
              No
            </Label>
          </Box>
        </Box>
        <Box display="grid">
          <Field marginless paddingless kind="group">
            <Typography variant="h7"> Get Project Distance</Typography>
            <Icon
              tooltip={`1. Select a Project
2. Select No for Filter Employees by Project 
3. Select Yes for Get Project Distance `}
            >
              <FontAwesomeIcon color="darkBlue" icon={faQuestionCircle} />
            </Icon>
          </Field>
          <Box>
            <Control expanded>
              <Label>
                <Radio
                  checked={inputs?.isGetDistanceQuery}
                  name="isGetDistanceQuery"
                  onChange={() =>
                    handleFiltersChange(
                      "isGetDistanceQuery",
                      !inputs?.isGetDistanceQuery
                    )
                  }
                />{" "}
                Yes
              </Label>

              <Label>
                <Radio
                  checked={!inputs?.isGetDistanceQuery}
                  name="isGetDistanceQuery"
                  onChange={() =>
                    handleFiltersChange(
                      "isGetDistanceQuery",
                      !inputs?.isGetDistanceQuery
                    )
                  }
                />{" "}
                No
              </Label>
            </Control>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
};

EmployeeAssignmentFilters.propTypes = {
  inputs: PropTypes.object.isRequired,
  handleFiltersChange: PropTypes.func.isRequired,
};

export default EmployeeAssignmentFilters;
