import React from "react";
import { Button, Icon, Title } from "rbx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useModal } from "../../context";

// import {
//   AddCertificationModal,
//   AddLicenseModal,
//   AddSkillModal,
// } from "./components";
// import AddCourseModal from "./components/AddCourseModal";
import MassUpdatesForm from "./components/MassUpdatesForm";

const MassUpdates = () => {
  const { setModalOpen } = useModal();

  // const onAddLicenseClick = () => {
  //   setModalOpen(
  //     true,
  //     <AddLicenseModal onComplete={() => setModalOpen(false, "")} />
  //   );
  // };

  // const onAddCertificationClick = () => {
  //   setModalOpen(
  //     true,
  //     <AddCertificationModal onComplete={() => setModalOpen(false, "")} />
  //   );
  // };

  // const onAddSkillClick = () => {
  //   setModalOpen(
  //     true,
  //     <AddSkillModal onComplete={() => setModalOpen(false, "")} />
  //   );
  // };

  // const onAddCourseClick = () => {
  //   setModalOpen(
  //     true,
  //     <AddCourseModal onComplete={() => setModalOpen(false, "")} />
  //   );
  // };

  const onCsvClick = () => {
    setModalOpen(
      true,
      <MassUpdatesForm onComplete={() => setModalOpen(false, "")} />
    );
  };

  return (
    <div className="mass-updates-container">
      <Title>Mass Updates</Title>

      <Button.Group>
        {/* <Button color="primary" type="button" onClick={onAddLicenseClick}>
          <Icon>
            <FontAwesomeIcon icon="plus" />
          </Icon>
          <span>Licenses</span>
        </Button>
        <Button color="primary" type="button" onClick={onAddCertificationClick}>
          <Icon>
            <FontAwesomeIcon icon="plus" />
          </Icon>
          <span>Certifications</span>
        </Button>
        <Button color="primary" type="button" onClick={onAddSkillClick}>
          <Icon>
            <FontAwesomeIcon icon="plus" />
          </Icon>
          <span>Skills</span>
        </Button>
        <Button color="primary" type="button" onClick={onAddCourseClick}>
          <Icon>
            <FontAwesomeIcon icon="plus" />
          </Icon>
          <span>DECCOU Courses</span>
        </Button> */}
        <Button color="primary" type="button" onClick={onCsvClick}>
          <Icon>
            <FontAwesomeIcon icon="plus" />
          </Icon>
          <span>CSV</span>
        </Button>
      </Button.Group>
    </div>
  );
};

MassUpdates.propTypes = {};

export default MassUpdates;
