import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_REVIEW_TEMPLATES = gql`
  query ReviewTemplates($where: ReviewTemplateWhereInput) {
    reviewTemplates(where: $where) {
      id
      year
      title
    }
  }
`;
