import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Title, Button, Input, Field, Control, Label } from "rbx";
import { toast } from "react-toastify";

import {
  FIND_MANY_CAL_EVENTS_QUERY,
  FIND_UNIQUE_CAL_EVENT_QUERY,
  CREATE_CAL_EVENT_MUTATION,
  UPDATE_CAL_EVENT_MUTATION,
  DELETE_CAL_EVENT_MUTATION,
} from "../../../../graphql";

import DateTimeInput from "../../../DateTimeInput";
import Confirmation from "../../../Confirmation";

import { useModal } from "../../../../context";

const INITIAL_STATE = {
  title: "",
  date: "",
};

class CustomCalendarEventsError extends Error {}

const CalendarEventsModal = ({ onComplete, id }) => {
  const [inputs, setInputs] = useState({ ...INITIAL_STATE });

  const { setModalOpen } = useModal({allowOverflow: true});

  const [createCalEvent, { loading: createLoading }] = useMutation(
    CREATE_CAL_EVENT_MUTATION
  );
  const [updateCalEvent, { loading: updateLoading }] = useMutation(
    UPDATE_CAL_EVENT_MUTATION
  );
  const [deleteCalEvent, { loading: deleteLoading }] = useMutation(
    DELETE_CAL_EVENT_MUTATION
  );

  const [findCalendarEvent, { data: calendarEventData }] = useLazyQuery(
    FIND_UNIQUE_CAL_EVENT_QUERY
  );

  useEffect(() => {
    if (id) {
      findCalendarEvent({
        variables: { where: { id } },
      });
    }
  }, [id, findCalendarEvent]);

  useEffect(() => {
    const calendarEvent = calendarEventData?.findUniqueCalEvents;
    if (calendarEvent) {
      setInputs((prev) => ({
        ...prev,
        title: calendarEvent.title || "",
        date: calendarEvent.date ? calendarEvent.date : "",
      }));
    }
  }, [calendarEventData]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const isAdding = !id;

      if (isAdding) {
        await createCalEvent({
          variables: {
            data: {
              date: inputs.date,
              title: inputs.title,
            },
          },
          refetchQueries: [
            {
              query: FIND_MANY_CAL_EVENTS_QUERY,
              variables: {
                orderBy: { date: "asc" },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      } else {
        await updateCalEvent({
          variables: {
            data: {
              date: { set: inputs.date },
              title: { set: inputs.title },
            },
            where: { id },
          },
          refetchQueries: [
            {
              query: FIND_MANY_CAL_EVENTS_QUERY,
              variables: {
                orderBy: { date: "asc" },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
      }
      toast.success(
        `Calendar Event ${isAdding ? "created" : "edited"} successfully.`
      );
      onComplete();
    } catch (err) {
      const message =
        err instanceof CustomCalendarEventsError
          ? err.message
          : `Error ${
              id ? "updating" : "creating"
            } calendar event. Please contact support.`;
      toast.error(message);
    }
  };

  const handleDelete = async () => {
    const performDelete = async () => {
      try {
        await deleteCalEvent({
          variables: {
            where: { id },
          },
          refetchQueries: [
            {
              query: FIND_MANY_CAL_EVENTS_QUERY,
              variables: {
                orderBy: { date: "asc" },
              },
              fetchPolicy: "network-only",
            },
          ],
        });
        toast.success("Calendar Event deleted successfully.");
        onComplete();
      } catch (err) {
        toast.error("Error deleting Calendar Event.");
      }
    };
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this calendar event?"
        onCancel={() =>
          setModalOpen(
            true,
            <CalendarEventsModal id={id} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isLoading = createLoading || updateLoading || deleteLoading;

  const isDisabled = !inputs.title || !inputs.date || isLoading;

  return (
    <form id="company-form" onSubmit={handleSave}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>Add Event</Title>
        </div>
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={isLoading}
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isLoading || isDisabled}
              form="company-form"
              size="small"
              state={isLoading ? "loading" : ""}
              type="submit"
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field kind="group">
        <Control expanded>
          <Label>Event</Label>
          <Input
            autoComplete="off"
            name="title"
            placeholder="Enter Event Name"
            type="text"
            value={inputs.title}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
        </Control>
        <Control expanded>
          <DateTimeInput
            name="date"
            value={inputs.date}
            onChange={handleChange}
          />
        </Control>
      </Field>
      {id && (
        <Button
          color="danger"
          disabled={isLoading}
          size="small"
          state={isLoading ? "loading" : ""}
          type="button"
          onClick={handleDelete}
        >
          Delete
        </Button>
      )}
    </form>
  );
};

CalendarEventsModal.propTypes = {
  onComplete: PropTypes.func,
  id: PropTypes.string,
};

CalendarEventsModal.defaultProps = {
  onComplete: () => {},
  id: null,
};

export default CalendarEventsModal;
