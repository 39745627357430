import React from "react";
import { Button, Dropdown, Icon } from "rbx";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const CustomDropdown = ({ options, handleOptionClick, activeItem }) => (
  <Dropdown>
    <Dropdown.Trigger>
      <Button>
        <span>{activeItem.label}</span>
        <Icon size="small">
          <FontAwesomeIcon icon={faAngleDown} />
        </Icon>
      </Button>
    </Dropdown.Trigger>
    <Dropdown.Menu>
      <Dropdown.Content>
        {options.map((item) => (
          <Dropdown.Item
            active={activeItem.id === item.id}
            key={item.id}
            onClick={() => handleOptionClick(item)}
          >
            {item.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Content>
    </Dropdown.Menu>
  </Dropdown>
);

CustomDropdown.propTypes = {
  options: PropTypes.array,
  handleOptionClick: PropTypes.any,
  activeItem: PropTypes.object,
};

CustomDropdown.defaultProps = {
  options: [],
  handleOptionClick: () => null,
  activeItem: {},
};

export default CustomDropdown;
