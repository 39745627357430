import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const MASS_UPDATE_EMPLOYEE_QUALIFICATIONS_MUTATION = gql`
  mutation MassUpdateWithCsv($data: [CsvMassUpdatesInput!]! $qualificationId: String! $type: String!) {
    massUpdateWithCsv(data: $data, qualificationId: $qualificationId, type: $type)
  }
`;

export const MASS_GOAL_ADD_MUTATION = gql`
  mutation MassGoalAdd($data: [CsvMassUpdatesGoalsInput!]!) {
    massGoalAdd(data: $data)
  }
`;
