import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import {
  Button,
  Box,
  LinearProgress,
  Collapse,
  Typography,
  useTheme,
} from "@mui/material";

import PropTypes from "prop-types";
import {
  Add,
  FileDownload,
  FilterAlt,
  FilterAltOff,
} from "@mui/icons-material";
import { AccordionItem, AccordionDetailsItem } from "../../components";
import ProjectFilters from "./components/ProjectFilters/ProjectFilters";
import { GET_PROJECT_LIST } from "../../graphql";

const DEFAULT_PROJECTS_WHERE = {
  AND: [
    {
      name: {
        notIn: ["Disability", "Vacation", "Not Assigned"],
      },
    },
    { project_num: {} },
    { name: {} },
    { manager: {} },
    { supervisor: {} },
  ],
};

const DEFAULT_PROJECT_STATUS_FILTER = {
  isFilter: false,
};

const MobileProjectList = ({
  handleDetailsClick,
  handleAddClick,
  onExportClick,
  totalResults,
}) => {
  const theme = useTheme();

  const [data, setData] = useState([]);
  const [take, setTake] = useState(20);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [where, setWhere] = useState(DEFAULT_PROJECTS_WHERE);
  const [projectStatusFilter, setProjectStatusFilter] = useState(
    DEFAULT_PROJECT_STATUS_FILTER
  );

  const { data: projectList, loading: loadingProjects } = useQuery(
    GET_PROJECT_LIST,
    {
      variables: {
        take,
        orderBy: [{ name: "asc" }],
        where: {
          AND: [where],
        },
      },
    }
  );

  useEffect(() => {
    if (projectList?.length) {
      setData(projectList);
    }
  }, [projectList]);

  useEffect(() => {
    if (projectList?.projects && !loadingProjects) {
      const rows = projectList.projects.map((project) => {
        const isActive =
          project.users?.length > 0 || project.contractors?.length > 0;

        const status = isActive ? "Active" : "Inactive";

        return { ...project, status };
      });

      if (projectStatusFilter.isFilter) {
        const status = projectStatusFilter.isActive ? "Active" : "Inactive";
        setData(rows.filter((row) => row.status === status));
      } else {
        setData(rows);
      }
    }
  }, [projectList, loadingProjects, projectStatusFilter]);

  const handleFiltersChange = (name, value) => {
    switch (name) {
      case "project_num":
        setWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            {
              project_num: value
                ? { mode: "insensitive", contains: value }
                : {},
            },
            { name: prev.AND[2].name },
            { manager: prev.AND[3].manager },
            { supervisor: prev.AND[4].supervisor },
          ],
        }));
        break;

      case "name":
        setWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            { project_num: prev.AND[1].project_num },
            {
              name: value ? { mode: "insensitive", contains: value } : {},
            },
            { manager: prev.AND[3].manager },
            { supervisor: prev.AND[4].supervisor },
          ],
        }));
        break;

      case "manager":
        setWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            { project_num: prev.AND[1].project_num },
            { name: prev.AND[2].name },
            {
              manager: value ? { mode: "insensitive", contains: value } : {},
            },
            { supervisor: prev.AND[4].supervisor },
          ],
        }));
        break;

      case "supervisor":
        setWhere((prev) => ({
          AND: [
            { id: prev.AND[0].id },
            { project_num: prev.AND[1].project_num },
            { name: prev.AND[2].name },
            { manager: prev.AND[3].manager },
            {
              supervisor: value ? { mode: "insensitive", contains: value } : {},
            },
          ],
        }));
        break;

      case "status":
        setProjectStatusFilter({ isFilter: value !== 2, isActive: value });
        break;

      default:
        break;
    }
  };

  const handleScrollChange = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && take < totalResults) {
      setTake(take + 20);
    }
  };

  return (
    <React.Fragment>
      <Box padding="90px 0px 0px 0px" width="100%">
        {loadingProjects && <LinearProgress color="secondary" />}
      </Box>

      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100%"
        overflow="hidden"
        padding="20px 0px 0px 0px"
        width="100svw"
      >
        <Box display="inline-block" width="100%">
          <Box
            display="flex"
            gap={2}
            id="filters-container"
            justifyContent="center"
            mb={2}
            pl={2}
            pr={2}
            width="100%"
          >
            <Box pl={2} width="100%">
              <Button
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => onExportClick()}
              >
                <FileDownload />
              </Button>
            </Box>
            <Box pl={2} pr={2} width="100%">
              <Button
                color="primary"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              >
                {isFiltersOpen ? <FilterAltOff /> : <FilterAlt />}
              </Button>
            </Box>
            <Box pr={2} width="100%">
              <Button
                color="success"
                sx={{ width: "100%", color: theme.palette.common.white }}
                variant="contained"
                onClick={() => handleAddClick()}
              >
                <Add />
              </Button>
            </Box>
          </Box>
          <Collapse in={isFiltersOpen}>
            <Box mb={4}>
              <ProjectFilters inputs={where} onChange={handleFiltersChange} />
            </Box>
          </Collapse>
          {(data?.length === 0 || !data) && (
            <Box
              alignItems="center"
              bgcolor={theme.palette.grey[400]}
              display="flex"
              height="15rem"
              justifyContent="center"
              mt={2}
              sx={{
                overflowX: "hidden",
              }}
              width="100%"
            >
              <Typography variant="h7">No Projects found</Typography>
            </Box>
          )}
          <Collapse in={!isFiltersOpen}>
            <Box
              display="inline-block"
              height="80svh"
              id="project-container"
              maxHeight="38rem"
              pr={2}
              sx={{
                textTransform: "uppercase",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              width="100%"
              onScroll={handleScrollChange}
            >
              {data?.map((item) => (
                <AccordionItem key={item.id} title={item?.name}>
                  <AccordionDetailsItem label="Manager" value={item.manager} />
                  <AccordionDetailsItem
                    label="Supervisor"
                    value={item.supervisor}
                  />
                  <AccordionDetailsItem label="Status" value={item.status} />
                  <AccordionDetailsItem label="Address" value={item.address} />
                  <Box mt={2}>
                    <Button
                      fullWidth
                      color="secondary"
                      style={{ color: theme.palette.common.white }}
                      variant="contained"
                      onClick={() => handleDetailsClick(item)}
                    >
                      Edit
                    </Button>
                  </Box>
                </AccordionItem>
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </React.Fragment>
  );
};

MobileProjectList.propTypes = {
  handleAddClick: PropTypes.func,
  onExportClick: PropTypes.func,
  handleDetailsClick: PropTypes.func,
  totalResults: PropTypes.number.isRequired,
};

MobileProjectList.defaultProps = {
  handleAddClick: () => {},
  onExportClick: () => {},
  handleDetailsClick: () => {},
};

export default MobileProjectList;
