import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_EVALUATIONS = gql`
  query Evaluations(
    $where: EvaluationWhereInput
    $orderBy: [EvaluationOrderByWithRelationInput!]
    $take: Int
    $skip: Int
  ) {
    evaluations(where: $where, orderBy: $orderBy, take: $take, skip: $skip) {
      id
      title
      dateCompleted
      dateToCompleteBy
      assignmentDate
      manager
      managerEmployeeNumber
      templateVersionId
      owner
      author
      authorEmployeeNumber
      authorHidden
      needsAssignment
      status
      reviewId
      signedDate
      signedUserId #this is an employee number not a uuid
      signerName
      formAuthor
      formAuthorEmployeeNumber
      sections {
        id
        title
        instructions
        questions {
          id
          text
          answer
          answerType
          answerOptions {
            id
            value
            name
          }
        }
      }
    }
    aggregateEvaluation(where: $where) {
      _count {
        _all
      }
    }
  }
`;

export const GET_EVALUATION_IDS = gql`
  query Evaluations($where: EvaluationWhereInput) {
    evaluations(where: $where) {
      id
    }
  }
`;

export const GET_REVIEW_TITLE = gql`
  query Review($where: ReviewWhereInput) {
    reviews(where: $where) {
      id
      title
    }
  }
`;
export const GET_ALL_EMPLOYEES = gql`
  query GetEmployees($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!]) {
    users(where: $where, orderBy: $orderBy) {
      firstName
      lastName
      employeeNumber
      jobTitle
      middleInitial
      profileImage
      email
      group
      supervisorObj {
        employeeNumber
      }
    }
  }
`;
