import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import AssignReviewForm from "../AssignReviewForm/AssignReviewForm";

import { ASSIGN_REVIEW_TO_USER } from "../../../graphql";

const INITIAL_STATE = {
  employeeNumber: "",
  templateVersionId: "",
  dateToCompleteBy: "",
};

class CustomProjectError extends Error {}

const AssignReview = ({ where, onComplete }) => {
  const [review, setReview] = useState({ ...INITIAL_STATE });

  const [assignReview, { loading: loadingAssignReview }] = useMutation(
    ASSIGN_REVIEW_TO_USER
  );

  const handleInputChange = (name, value) => {
    setReview((prev) => ({ ...prev, [name]: value }));
  };

  const AssignReviewInput = {
    variables: {
      employeeNumber: review.employeeNumber,
      templateVersionId: review.templateVersionId,
      dateToCompleteBy: review.dateToCompleteBy,
    },
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await assignReview(AssignReviewInput);
      toast.success(`Successfully assigned Review.`);
      onComplete();
    } catch (error) {
      const message =
        error instanceof CustomProjectError
          ? error.message
          : `Error creating review. Please contact support.`;
      toast.error(message);
    }
  };

  if (loadingAssignReview) {
    return (
      <div style={{ minHeight: "10rem" }}>
        <Loader />
      </div>
    );
  }

  const isDisabled =
    !review.employeeNumber ||
    !review.templateVersionId ||
    !review.dateToCompleteBy;

  return (
    <div style={{ height: "100%" }}>
      <form id="project-form" onSubmit={handleSubmit}>
        <header className="modal-head">
          <div className="modal-head-start">
            <Title size={5}>Create Review</Title>
          </div>
          <div className="modal-head-end">
            <Button.Group hasAddons>
              <Button
                size="small"
                type="button"
                onClick={() => onComplete(false)}
              >
                <span>Cancel</span>
              </Button>
              <Button
                color="primary"
                disabled={isDisabled}
                form="project-form"
                size="small"
                state={loadingAssignReview ? "loading" : ""}
                type="submit"
              >
                <span>Save</span>
              </Button>
            </Button.Group>
          </div>
        </header>
        <hr />
        <AssignReviewForm review={review} onChange={handleInputChange} />
      </form>
    </div>
  );
};

AssignReview.propTypes = {
  onComplete: PropTypes.func.isRequired,
  where: PropTypes.object.isRequired,
};

export default AssignReview;
