import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export


export const GET_USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      group
      firstName
      lastName
      supervisorObj {
        jobTitle
      }
      goals {
        id
        title
        description
        status
        startDate
        dueDate
        currentProgress
        createdBy
        createDate
        updatedAt
        updatedBy
        updatedByFullName
        evaluationSectionId
      }
    }
  }
`;

export const GET_USERS = gql`
  query Users(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
  ) {
    users(where: $where, orderBy: $orderBy) {
      id
      employeeNumber
      firstName
      jobTitle
      lastName
      group
      goals {
        id
        title
        description
        status
        startDate
        dueDate
        currentProgress
        createdBy
        createDate
        updatedAt
        updatedBy
        updatedByFullName
        evaluationSectionId
      }
    }
  }
`;

export const GET_REVIEWS = gql`
  query Users(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
  ) {
    users(where: $where, orderBy: $orderBy) {
      id
      employeeNumber
      firstName
      jobTitle
      lastName
      group
      goals {
        id
        title
        description
        status
        startDate
        dueDate
        currentProgress
        createdBy
        createDate
        updatedAt
        updatedBy
        updatedByFullName
        evaluationSectionId
      }
    }
  }
`;