import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import EmployeeRelationForm from "../EmployeeRelationForm";

import {
  SINGLE_EMPLOYEE_RELATION_QUERY,
  UPDATE_EMPLOYEE_RELATION_MUTATION,
  ALL_EMPLOYEE_RELATIONS_QUERY,
  FIND_FIRST_EMPLOYEE_RELATION_QUERY,
} from "../../../../graphql";

const INITIAL_STATE = {
  group: "",
  title: "",
  supervisor: "",
};

class CustomEmployeeRelationError extends Error {}
const EmployeeRelationModal = ({ id, onComplete }) => {
  const client = useApolloClient();

  const [inputs, setInputs] = useState({ ...INITIAL_STATE });

  const [updateEmployeeRelations, { loading: UpdateEmployeeRelationLoading }] =
    useMutation(UPDATE_EMPLOYEE_RELATION_MUTATION);

  const [
    findUniqueEmployeeRelations,
    { data: EmployeeRelationData, loading: FindUniqueEmployeeeRelationLoading },
  ] = useLazyQuery(SINGLE_EMPLOYEE_RELATION_QUERY);

  useEffect(() => {
    if (id) {
      findUniqueEmployeeRelations({
        variables: { where: { id } },
      });
    }
  }, [id, findUniqueEmployeeRelations]);

  useEffect(() => {
    const employeeRelation = EmployeeRelationData?.findUniqueEmployeeRelations;
    if (employeeRelation) {
      setInputs((prev) => ({
        ...prev,
        group: employeeRelation.group || "",
        title: employeeRelation.title || "",
        supervisor: employeeRelation.supervisor || "",
      }));
    }
  }, [EmployeeRelationData]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();

      const isAdding = !id;

      const { data: FindFirstEmployeeRelationData } = await client.query({
        query: FIND_FIRST_EMPLOYEE_RELATION_QUERY,
        variables: {
          where: {
            group: { equals: inputs.group },
            title: { equals: inputs.title },
            supervisor: { equals: inputs.supervisor },
          },
        },
      });

      const existingEmployeeRelation =
        FindFirstEmployeeRelationData?.findFirstEmployeeRelations;

      if (existingEmployeeRelation && existingEmployeeRelation.id !== id) {
        throw new CustomEmployeeRelationError(
          "This combination of group, title, and supervisor already exists."
        );
      }

      await updateEmployeeRelations({
        variables: {
          group: inputs.group,
          jobTitle: inputs.title,
          supervisor: inputs.supervisor,
        },
        refetchQueries: [
          {
            query: ALL_EMPLOYEE_RELATIONS_QUERY,
            variables: { orderBy: [{ group: "asc" }] },
          },
        ],
      });

      toast.success(
        `Employee Relation ${isAdding ? "created" : "edited"} successfully.`
      );
      onComplete();
    } catch (err) {
      const message =
        err instanceof CustomEmployeeRelationError
          ? err.message
          : `Error ${
              id ? "updating" : "creating"
            } employeee relation. Please contact support.`;
      toast.error(message);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isLoading =
    FindUniqueEmployeeeRelationLoading || UpdateEmployeeRelationLoading;
  // DeleteCompanyLoading;

  const isDisabled = useMemo(
    () => isLoading || Object.keys(inputs).some((key) => !inputs[key].length),
    [inputs, isLoading]
  );

  return (
    <form id="company-form" onSubmit={handleSave}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>
            {[id ? "Edit" : "Create", "Employee Relation"].join(" ")}
          </Title>
        </div>
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isLoading || isDisabled}
              form="company-form"
              size="small"
              state={isLoading ? "loading" : ""}
              type="submit"
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <EmployeeRelationForm
        disabled={isLoading}
        inputs={inputs}
        onChange={handleChange}
      />
    </form>
  );
};

EmployeeRelationModal.propTypes = {
  id: PropTypes.string,
  onComplete: PropTypes.func,
};

EmployeeRelationModal.defaultProps = {
  id: "",
  onComplete: () => {},
};

export default EmployeeRelationModal;
