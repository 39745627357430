/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const SINGLE_SUPERVISOR_QUERY = gql`
  query SINGLE_SUPERVISOR_QUERY($title: String!) {
    supervisor(title: $title) {
      supervisor
      group
    }
  }
`;
