import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

const GenericSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  getValue,
  setValue,
  showBlank,
  showAll,
  options,
  addClass,
  addLabelClass,
  isLicense,
  expanded,
}) => {
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    const id = options?.find((x) => x.title === e.target.value)?.id;
    onChange(e.target.name, setValue(e.target.value), id);
  };

  return (
    <Control className={addClass} expanded={expanded} size={size}>
      {label && (
        <Label className={addLabelClass || "filter-label"} size="medium">
          {label}
        </Label>
      )}
      <Select.Container fullwidth size={size}>
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {showAll && <Select.Option value="">All</Select.Option>}

          {isLicense
            ? Array.isArray(options) &&
              options.map(({ id, title, level, state }) => (
                <Select.Option key={id} value={title}>
                  {`${title} ${state || ""} ${level || ""}`}
                </Select.Option>
              ))
            : Array.isArray(options) &&
              options.map(({ id, title }) => (
                <Select.Option key={id} value={title}>
                  {title}
                </Select.Option>
              ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

GenericSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
  showAll: PropTypes.bool,
  addClass: PropTypes.string,
  addLabelClass: PropTypes.string,
  isLicense: PropTypes.bool,
  expanded: PropTypes.bool,
};

GenericSelect.defaultProps = {
  size: "normal",
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  showBlank: false,
  showAll: false,
  options: [],
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
  addClass: "",
  addLabelClass: "",
  isLicense: false,
  expanded: false,
};

export default GenericSelect;
