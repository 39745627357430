import { useState, useEffect } from "react";

import { useAuth } from "../context/AuthContext";

const usePermissions = () => {
  const { state: authState } = useAuth();
  const [permissions, setPermissions] = useState({
    user: true,
    fieldSupervisor: false,
    admin: false,
    superAdmin: false,
    webEditor: false,
  });

  useEffect(() => {
    const userSecurities = authState?.user?.userSecurities;

    if (Array.isArray(userSecurities)) {
      setPermissions((prev) => {
        const updatedPermissons = prev;
        updatedPermissons[userSecurities[0]] = true;
        return updatedPermissons;
      });
    }
  }, [authState, permissions]);

  return permissions;
};

export default usePermissions;
