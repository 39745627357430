import React from "react";
import PropTypes from "prop-types";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconButton.scss";

const IconButton = ({ onClick, icon }) => (
  <Button color="primary" id="icon-button" onClick={onClick}>
    <Icon>
      <FontAwesomeIcon icon={icon} />
    </Icon>
  </Button>
);

IconButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.string.isRequired,
};

IconButton.defaultProps = {
  onClick: (e) => e,
};

export default IconButton;
