/* eslint-disable react/prop-types */
import { Control } from "rbx";
import React from "react";
import { GenericSelect } from "../../../../components";

const tshirtOptions = [
  { id: "notAssigned", title: "Not Assigned" },
  { id: "SM", title: "SM" },
  { id: "MD", title: "MD" },
  { id: "LG", title: "LG" },
  { id: "XL", title: "XL" },
  { id: "XL2", title: "2XL" },
  { id: "XL3", title: "3XL" },
  { id: "XL4", title: "4XL" },
];

const COLUMNS = [
  {
    Header: "Employee Number",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "First Name",
    id: "firstName",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "lastName",
  },
  {
    Header: "Project",
    id: "project",
    accessor: "project",
  },
];

export const generateColumns = (handleUpdateUser) => [
  ...COLUMNS,
  {
    Header: "T-shirt Size",
    id: "tshirtSize",
    accessor: "tshirtSize",
    Cell: ({ row }) => (
      <Control>
        <GenericSelect
          addClass="is-fullwidth"
          name="tshirtSize"
          options={tshirtOptions}
          showBlank={false}
          value={row.original.tshirtSize}
          onChange={(n, v) => {
            handleUpdateUser(row.original.id, v);
          }}
        />
      </Control>
    ),
  },
];

export const EXPORT_COLUMNS = [
  {
    Header: "Employee #",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "First Name",
    id: "firstName",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "lastName",
  },
  {
    Header: "Project",
    id: "project",
    accessor: "project",
  },
  {
    Header: "T-shirt Size",
    id: "tshirtSize",
    accessor: "tshirtSize",
  },
];

export default generateColumns;
