import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

import LEVELS from "./constants";

const LevelSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  id,
  fullWidth,
  getValue,
  setValue,
}) => {
  useEffect(() => {
    if (value === "") {
      setValue(LEVELS[0]);
    }
  }, [setValue, value]);
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control expanded size={size}>
      {label && <Label>{label}</Label>}
      <Select.Container fullwidth={fullWidth} size={size}>
        <Select
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {LEVELS.map((key) => (
            <Select.Option key={key} value={key}>
              {key}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

LevelSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
};

LevelSelect.defaultProps = {
  size: "",
  fullWidth: false,
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  id: null,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => null,
};

export default LevelSelect;
