const createTypography = (palette) => ({
  fontFamily: ["EurostileExtended", "sans-serif", "Avenir", "Roboto"].join(","),
  h1: {
    fontWeight: 700,
    fontSize: 60,
    color: palette.primary.main,
    fontFamily: ["EurostileExtended", "sans-serif"].join(","),
  },
  h2: {
    fontWeight: 400,
    fontSize: 48,
    color: palette.primary.main,
    fontFamily: ["EurostileExtended", "sans-serif"].join(","),
  },
  h3: {
    fontWeight: 600,
    fontSize: 38,
    color: palette.primary.main,
    fontFamily: ["EurostileExtended", "sans-serif"].join(","),
  },
  h4: {
    fontWeight: 400,
    fontSize: 28,
    color: palette.primary.main,
    fontFamily: ["EurostileExtended", "sans-serif"].join(","),
  },
  h5: {
    fontWeight: 400,
    fontSize: 16,
    color: palette.primary.main,
    fontFamily: ["EurostileExtended", "sans-serif"].join(","),
    "@media (min-width: 600px)": {
      fontSize: 22,
    },
    "@media (min-width: 900px)": {
      fontSize: 22,
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: 18,
    color: palette.primary.main,
    fontFamily: ["EurostileExtended", "sans-serif"].join(","),
  },
  h7: {
    fontWeight: 600,
    fontSize: 16,
    color: palette.primary.main,
    fontFamily: "'Avenir', 'Roboto', sans-serif",
  },
  h8: {
    fontWeight: 600,
    fontSize: 16,
    color: palette.primary.main,
    textTransform: "none",
    fontFamily: ["EurostileExtended", "sans-serif"].join(","),
  },
  label: {
    fontWeight: 700,
    fontSize: "1.25rem",
    color: palette.label.main,
    fontFamily: "'Avenir', 'Roboto', sans-serif",
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: 18,
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
  },
  subtitle2: {
    fontWeight: 500,
    fontSize: 14,
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
  },
  subtitle3: {
    fontWeight: 600,
    fontSize: 16,
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
    color: palette.secondary.main,
  },
  body1: {
    fontWeight: 400,
    fontSize: 16,
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
  },
  body2: {
    fontWeight: 400,
    fontSize: 14,
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
  },
  body3: {
    fontWeight: 600,
    fontSize: 14,
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
    color: palette.primary.main,
  },
  button: {
    fontWeight: 600,
    fontSize: 14,
    textTransform: "uppercase",
    color: "d9d9d9",
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
  },
  caption: {
    fontWeight: 400,
    fontSize: 12,
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
  },
  overline: {
    fontWeight: 400,
    fontSize: 10,
    textTransform: "uppercase",
    fontFamily: ["Roboto", "Avenir", "sans-serif"],
  },
});

export default createTypography;
