import styled from "styled-components";

const StyledLabel = styled.label.attrs({ className: "label" })`
  width: ${(props) => props.width};
  input:not([type="checkbox"]),
  .textarea,
  .select {
    margin-top: 4px;
  }
`;

export default StyledLabel;
