import React, { useMemo } from "react";

import { Control, Field, Title, Button, Icon } from "rbx";

import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GenericTable from "../GenericTable";
import { useModal } from "../../context";
import { usePermissions } from "../../hooks";

import {
  ALL_EMPLOYEE_RELATIONS_QUERY,
  DELETE_EMPLOYEE_RELATION_MUTATION,
} from "../../graphql";

import { generateColumns } from "./columns";
import { Confirmation } from "..";
import EmployeeRelationModal from "./components/EmployeeRelationModal/EmployeeRelationModal";

import "./employeeRelations.scss";

const EmployeeRelations = () => {
  const { setModalOpen } = useModal();
  const [deleteEmployeeRelation] = useMutation(
    DELETE_EMPLOYEE_RELATION_MUTATION
  );
  const permissionLevels = usePermissions();

  const { data: EmployeeRelationsData, loading: EmployeeRelationsLoading } =
    useQuery(ALL_EMPLOYEE_RELATIONS_QUERY, {
      variables: { orderBy: [{ group: "asc" }] },
    });

  const COLUMNS = useMemo(() => {
    const handleDelete = async (title) => {
      const performDelete = async () => {
        try {
          await deleteEmployeeRelation({
            variables: {
              jobTitle: title,
            },
            refetchQueries: [
              {
                query: ALL_EMPLOYEE_RELATIONS_QUERY,
                variables: { orderBy: [{ group: "asc" }] },
              },
            ],
          });
          toast.success("Employee relation removed successfully.");
        } catch (err) {
          toast.error(
            "There was an error deleting employee relation. Please contact support."
          );
        } finally {
          setModalOpen(false, "");
        }
      };
      setModalOpen(
        true,
        <Confirmation
          message="Are you sure you want to delete this Employee Relation?"
          onCancel={() => setModalOpen(false, "")}
          onConfirm={performDelete}
        />
      );
    };
    return generateColumns(handleDelete);
  }, [deleteEmployeeRelation, setModalOpen]);

  const handleAddClick = () => {
    setModalOpen(
      true,
      <EmployeeRelationModal onComplete={() => setModalOpen(false, "")} />
    );
  };

  const handleRowClick = ({ id }) => {
    setModalOpen(
      true,
      <EmployeeRelationModal
        id={id}
        onComplete={() => setModalOpen(false, "")}
      />
    );
  };

  const employeeRelations =
    EmployeeRelationsData?.findManyEmployeeRelations || [];

  return (
    <div className="employee-relations-container">
      <div className="section-header">
        <Title>Employee Relations</Title>
        {(permissionLevels.webEditor) && (
          <Field>
            <Control>
                <Button color="primary" onClick={handleAddClick}>
                  <Icon>
                    <FontAwesomeIcon icon="plus" />
                  </Icon>
                  <span>Add</span>
                </Button>
            </Control>
          </Field>
        )}
      </div>
      <div className="generic-table-wrapper">
        <GenericTable
          isHoverable
          columns={COLUMNS}
          data={employeeRelations}
          loading={EmployeeRelationsLoading}
          onRowClick={handleRowClick}
        />
      </div>
    </div>
  );
};

EmployeeRelations.propTypes = {};
EmployeeRelations.defaultProps = {};

export default EmployeeRelations;
