/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { Title, Button, Field, Icon, Control } from "rbx";
import format from "date-fns/format";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useQuery } from "@apollo/client";

import { CalendarEventsTable, CalendarEventsModal } from "./components";

import { FIND_MANY_CAL_EVENTS_QUERY } from "../../graphql/calendar/calendar.queries";

import { useModal } from "../../context";

const CalendarEvents = () => {
  const { setModalOpen } = useModal();

  const [state, setState] = useState({
    data: [],
    currentPageData: [],
    recordCount: 0,
    pageCount: 0,
  });

  const [, setLoading] = useState(false);

  const { data: findManyCalEventsData } = useQuery(FIND_MANY_CAL_EVENTS_QUERY, {
    variables: { orderBy: { date: "asc" } },
  });

  useEffect(() => {
    try {
      setLoading(true);

      const result = [];

      if (findManyCalEventsData?.findManyCalEvents.length) {
        findManyCalEventsData.findManyCalEvents.forEach((event) => {
          // updated teh date adder to be the correct timezone so this should now work going forward
          const date = format(new Date(event.date), "y-MM-dd");
          const time = format(new Date(event.date), "p");
          const timeStamp = event.date.split(".")[0]; // this seems wrong but not sure it is used anywhere

          result.push({
            title: event.title,
            date,
            time,
            id: event.id,
            timeStamp,
          });
        });

        setState((prev) => ({
          ...prev,
          data: result,
          currentPageData: result.slice(0, 10),
          recordCount: result.length,
          pageCount: result.length < 10 ? 1 : Math.ceil(result.length / 10),
        }));
      }
    } catch (err) {
      toast.error("Error. Please refresh your page");
    } finally {
      setLoading(false);
    }
  }, [findManyCalEventsData]);

  const handleEventsPaginationChange = (currentPage) => {
    const paginated = [...state.data].splice(currentPage * 10, 10);
    setState((prev) => ({ ...prev, currentPageData: paginated }));
  };

  const handleRowClick = ({ id }) => {
    setModalOpen(
      true,
      <CalendarEventsModal id={id} onComplete={() => setModalOpen(false, "")} />
    );
  };

  const handleAddClick = () => {
    setModalOpen(
      true,
      <CalendarEventsModal
        onComplete={() => {
          setModalOpen(false, "");
        }}
      />
    );
  };

  return (
    <div className="calendar-events-container">
      <div className="section-header">
        <Title>Calendar Events</Title>
        <Field>
          <Control>
            <Button color="primary" onClick={handleAddClick}>
              <Icon>
                <FontAwesomeIcon icon="plus" />
              </Icon>
              <span>Add</span>
            </Button>
          </Control>
        </Field>
      </div>
      {!!state.data.length && (
        <CalendarEventsTable
          data={state.currentPageData}
          pageCount={state.pageCount}
          recordCount={state.recordCount}
          onPaginationChange={handleEventsPaginationChange}
          onRowClick={handleRowClick}
        />
      )}
    </div>
  );
};

CalendarEvents.propTypes = {};

export default CalendarEvents;
