import React from "react";
import { Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import PropTypes from "prop-types";
import { DetailedDataTable } from "../../components";
import { PROJECT_HISTORY_COLUMNS } from "./columns";
import { GET_USER_PROJECT_HISTORY } from "./graphql";

const ProjectAssignmentHistory = ({ userId }) => {
  const where = {
    userId: {
      equals: userId,
    },
  };

  const { data: ProjectHistoryData } = useQuery(GET_USER_PROJECT_HISTORY, {
    variables: {
      where,
    },
    fetchPolicy: "network-only",
  });

  return (
    <Box className="assignment-history-table" sx={{ height: "35rem" }}>
      <DetailedDataTable
        fullWidth
        isModalTable
        columns={PROJECT_HISTORY_COLUMNS}
        dataExtractor={(data) => data?.projectHistoryEntries}
        defaultSorting={[{ id: "updatedAt", desc: true }]}
        location="projectAssignmentHistory"
        name="assignment-history"
        query={GET_USER_PROJECT_HISTORY}
        searchTransformer={(search) => ({})}
        totalExtractor={() => ProjectHistoryData?.projectHistoryEntries.length}
        where={where}
        // eslint-disable-next-line no-console
        onRowClick={() => console.log("row clicked")}
      />
    </Box>
  );
};

ProjectAssignmentHistory.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ProjectAssignmentHistory;
