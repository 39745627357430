import React, { useEffect, useState } from "react";
import { Title } from "rbx";
import PropTypes from "prop-types";
import { useTheme, useMediaQuery } from "@mui/material";
import ReviewSection from "./components/ReviewSection/ReviewSection";
import EvaluationForm from "./components/EvaluationForm/EvaluationForm";
import { useModal } from "../../context";
import "./Reviews.scss";
import { usePermissions } from "../../hooks";
import { REVIEW_SECTIONS } from "./constants";

const CompletedReviews = ({ reviews, employeeNumber, loading, setReviews }) => {
  const { setModalOpen } = useModal();

  const permissionLevels = usePermissions();

  const [permissions, setPermissions] = useState(null);

  const showManagerReviews =
    permissions?.webEditor ||
    permissions?.superAdmin ||
    permissions?.admin ||
    permissions?.fieldSupervisor;

  useEffect(() => {
    if (permissionLevels) {
      setPermissions(permissionLevels);
    }
  }, [permissionLevels]);

  const handleOpenReview = (evaluation) => {
    const currentReview = reviews.filter(
      (review) => review.id === evaluation?.reviewId
    );

    setModalOpen(
      true,
      <EvaluationForm
        currentReviewId={evaluation?.reviewId}
        employeeNumber={employeeNumber}
        evaluation={evaluation}
        evaluations={currentReview?.evaluations}
        key={evaluation?.id}
        loading={loading}
        reviews={reviews}
        onCanceled={() => setModalOpen(false)}
        onComplete={() => setModalOpen(false)}
      />
    );
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const className = showManagerReviews
    ? `reviews-outer-container-manager ${downSm && "grid-one-fr"}`
    : `reviews-outer-container ${downSm && "grid-one-fr"}`;

  return (
    <div className={className}>
      {REVIEW_SECTIONS.map(
        (section) =>
          ((showManagerReviews && section.sectionType === "Manager") ||
            section.sectionType !== "Manager") && (
            <div className="reviews-container-section">
              <div className="reviews">
                <div className="centered-title" id="employee-reviews">
                  <Title size={4}>{section.title}</Title>
                </div>
                {reviews.length ? (
                  <ReviewSection
                    isCompletedSection
                    handleClick={handleOpenReview}
                    reviews={reviews}
                    sectionType={section.sectionType}
                    setReviews={setReviews}
                  />
                ) : (
                  <div className="centered-title">
                    <Title size={6}>
                      No completed {section.sectionType.toLowerCase()}{" "}
                      evaluations found
                    </Title>
                  </div>
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};

CompletedReviews.propTypes = {
  reviews: PropTypes.array.isRequired,
  employeeNumber: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setReviews: PropTypes.func.isRequired,
};

export default CompletedReviews;
