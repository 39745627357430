/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTable, usePagination, useSortBy, useRowSelect } from "react-table";
import { Table, Field, Control } from "rbx";
import  {Loader}  from "..";

import Pagination from "./Pagination";

import "./ReportsTable.scss";

const renderFunc = (cell) => {
  if (cell.value === null) return null;

  return <div className="cell-style-wrapper"> {cell.render("Cell")} </div>;
};

const renderTableHeaderSort = (column) => {
  if (column.isSorted) {
    return column.isSortedDesc ? " ▼" : " ▲";
  }
  return "";
};

const defaultPropGetter = () => {};

function ReportsTable({
  columns,
  children,
  data,
  loading = false,
  onRowClick = () => {},
  onCellClick = () => {},
  sortBy = [],
  getRowProps = defaultPropGetter,
  updateData,
  id,
}) {
  const [, setDefaultPageSize] = useState(10);

  const tableState = {
    pageIndex: 0,
    sortBy,
    pageSize: 10,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
      updateData,
    },
    useSortBy,
    usePagination,
    useRowSelect
  );

  const handleSetPageSize = (n) => {
    setDefaultPageSize(n);
    setPageSize(n);
  };

  return (
    <div className="report-container" id={id}>
      {children}

      <div className="report-wrapper ">
        {loading ? (
          <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "400px"}}> <Loader /> </div>

        ) : (
          <React.Fragment>
            <Table fullwidth className="report-table" {...getTableProps()}>
              <Table.Head>
                {headerGroups.map((headerGroup) => (
                  <Table.Row {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Table.Heading
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>{renderTableHeaderSort(column)}</span>
                      </Table.Heading>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body className="tb" {...getTableBodyProps()}>
                {page.map(
                  (row, i) =>
                    prepareRow(row) || (
                      <Table.Row
                        {...row.getRowProps(getRowProps(row))}
                        onClick={() => onRowClick(row.original)}
                      >
                        {row.cells.map((cell, j) => (
                          <Table.Cell
                            {...cell.getCellProps()}
                            key={`row-${i}-cell-${j}`}
                            onClick={() => onCellClick(cell)}
                          >
                            {renderFunc(cell)}
                          </Table.Cell>
                        ))}
                      </Table.Row>
                    )
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </div>

      <Field align="centered" className="pagination-field" kind="group">
        <Control>
          <Pagination
            canNextPage={canNextPage}
            canPreviousPage={canPreviousPage}
            gotoPage={gotoPage}
            page={pageIndex + 1}
            pageSize={pageSize}
            pages={pageOptions.length}
            setPageSize={handleSetPageSize}
            onClickNext={nextPage}
            onClickPrev={previousPage}
          />
        </Control>
      </Field>
    </div>
  );
}

ReportsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  onCellClick: PropTypes.func,
  sortBy: PropTypes.array,
  isFixedWidth: PropTypes.bool,
  getRowProps: PropTypes.func,
  defaultColumn: PropTypes.object,
  updateData: PropTypes.func,
  defaultPageSize: PropTypes.number,
  id: PropTypes.string,

  saveQuery: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func,
  ]),
};

ReportsTable.defaultProps = {
  data: [],
  loading: false,
  pageCount: 1,
  pageSize: 20,
  onRowClick: () => {},
  onCellClick: () => {},
  sortBy: [],
  isFixedWidth: true,
  getRowProps: () => {},
  defaultColumn: {},
  updateData: () => {},
  defaultPageSize: 20,
  id: "",
  saveQuery: {},
  children: null,
};
export default ReportsTable;
