import React, { useState, useEffect } from "react";

import Select from "react-select";

import PropTypes from "prop-types";

import { format } from "date-fns";

import { useQuery } from "@apollo/client";

import { Title, Button, Field, Control, Label, Content, Input } from "rbx";
import { initialValue } from "rbx/components/dropdown/dropdown-context";

import { DateInput, Loader } from "../../../../components";
import { FIND_MANY_CODES } from "../../../../graphql/codes/codes.queries";

const CertificationForm = ({ onComplete, onCancel, initialInputs = {}, employeeData = {}, mobile }) => {
  const [inputs, setInputs] = useState({});
  const [employeeCertifications, setEmployeeCertifications] = useState([]);
  const [repeatCertification, setRepeatCertification] = useState(null);
  const { data: { findManyCodes: options } = {}, loading } = useQuery(
    FIND_MANY_CODES,
    {
      variables: {
        where: {
          entity: {
            equals: "certifications",
          },
        },
        orderBy: [
          {
            title: "asc",
          },
        ],
      },
    }
  );


  useEffect(() => {
    if (employeeData?.certifications?.length > 0) {
      const certificationIds = employeeData?.certifications?.map((s) => ({certificationId: s?.id, codeId: s?.codes?.id}));
      setEmployeeCertifications(certificationIds);
    }
  }, [employeeData]);

  useEffect(() => {
    if (inputs?.value && employeeCertifications?.length > 0) {
      const oldCertification = employeeCertifications.find((s) => (s?.codeId === inputs?.value));
      if (oldCertification) {
        setRepeatCertification(oldCertification?.certificationId);
      } else {
        setRepeatCertification(null);
      }
    }
  }, [inputs, inputs.value, employeeCertifications]);

  useEffect(() => {
    if (initialInputs?.id) {
      setInputs({
        expDate: initialInputs?.expDate || format(new Date(), "yyyy-MM-dd"),
        issueDate: initialInputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
        certificationNumber: initialInputs?.certificationNumber || "",
        title: initialInputs?.title || "",
        expirationType: initialInputs?.expirationType || "manual",
      });
    }
  }, [initialInputs]);

  const handleChange = (e) => {
    const name = e?.target?.name || e?.name;
    const value = e?.target?.value || e?.value;
    setInputs({ ...inputs, [name]: value });
  };

  const handleCertificationChange = (e) => {
    setInputs({ ...inputs, ...e });
  };

  const handleBuildNewCertification = () => {

    if(repeatCertification) {
        // treat as update
      const updateData = {
        certifications: {
          update: [
            {
              data: {
                issueDate: {
                  set: inputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
                },
              },
              where: {
                id: repeatCertification,
              },
            },
          ],
        },
      };
      if (inputs?.expirationType === "manual") {
        updateData.certifications.update[0].data.expDate = {
          set: inputs?.expDate || format(new Date(), "yyyy-MM-dd"),
        };
    }
      return updateData;
    } 
    const data = {
      certifications: {
        create: [
          {
            codes: {
              connect: {
                id: inputs?.value,
              },
            },
            issueDate: inputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
            certificationNumber: inputs?.certificationNumber || "",
          },
        ],
      },
    };

    if (inputs?.expirationType === "manual") {
      data.certifications.create[0].expDate =
        inputs?.expDate || format(new Date(), "yyyy-MM-dd");
    }
    return data;
  };

  const handleUpdateCertification = () => {
    const d = {
      certifications: {
        update: [
          {
            data: {
              issueDate: {
                set: inputs?.issueDate || format(new Date(), "yyyy-MM-dd"),
              },
              certificationNumber: {
                set: inputs?.certificationNumber || "",
              },
            },
            where: {
              id: initialInputs?.id,
            },
          },
        ],
      },
    };

    if (inputs?.expirationType === "manual") {
      d.certifications.update[0].data.expDate = {
        set: inputs?.expDate || format(new Date(), "yyyy-MM-dd"),
      };
    }

    return d;
  };

  const handleComplete = () => {
    if (initialInputs?.id) {
      onComplete(handleUpdateCertification());
    } else {
      onComplete(handleBuildNewCertification());
    }
  };

  if (loading && options?.length) return <Loader />;

  return (
    <div
      id="CertificationForm"
      style={{
        minHeight: "60vh",
        overflow: "auto",
      }}
    >
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>
            {initialInputs?.id ? "Update" : "Create"} Certification
          </Title>
        </div>
         {mobile && (
          <br />
        )}
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              disabled={loading}
              size="small"
              type="button"
              onClick={() => onCancel()}
            >
              <span>Cancel</span>
            </Button>

            <Button
              color="primary"
              disabled={!inputs?.value && !initialInputs?.id}
              form="company-form"
              size="small"
              onClick={handleComplete}
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <Field
        style={{
          display: "flex",
          flexDirection: "column",
          justifContent: "stretch",
          alignItems: "stretch",
        }}
      >
        <Control>
          <Label textSize={5}>Title:</Label>
          {initialInputs?.id ? (
            <Content size="medium">{`${inputs?.title}`}</Content>
          ) : (
            <div style={{ width: "100%" }}>
              {repeatCertification && (
                <Content size="medium" textColor="danger">
                  This certification is already assigned to this employee. Adding another will replace the old certification.
                </Content>
              )}
              <Select
                options={options?.map((o) => ({
                  value: o?.id,
                  label: `${o?.title}`,
                  ...o,
                }))}
                value={inputs || initialValue}
                onChange={(e) => {
                  handleCertificationChange(e);
                }}
              />
            </div>
          )}
        </Control>
        <Field
          style={{
            display: "flex",
            flexDirection: mobile ? "column": "row",
            justifyContent: "flex-start",
            gap: "1rem",
          }}
        >
          <Control>
            <Label textSize={5}>Certification Number</Label>
            <Input
              autoComplete="off"
              maxLength={100}
              name="certificationNumber"
              placeholder="ID"
              type="text"
              value={inputs?.certificationNumber || ""}
              onChange={(e) => handleChange(e)}
            />
          </Control>

          <Control>
            <Label textSize={5}>Issue Date:</Label>
            <DateInput
              name="issueDate"
              type="date"
              value={inputs?.issueDate || format(new Date(), "yyyy-MM-dd")}
              onChange={(e) => handleChange(e)}
            />
          </Control>

          {(inputs?.certification?.expirationType === "manual" ||
            inputs?.expirationType === "manual") && (
            <Control>
              <Label textSize={5}>Expiration Date:</Label>
              <DateInput
                name="expDate"
                type="date"
                value={inputs.expDate || format(new Date(), "yyyy-MM-dd")}
                onChange={(e) => handleChange(e)}
              />
            </Control>
          )}
        </Field>
      </Field>
    </div>
  );
};

CertificationForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  initialInputs: PropTypes.object,
  employeeData: PropTypes.object,
  mobile: PropTypes.bool,
};

CertificationForm.defaultProps = {
  initialInputs: {},
  employeeData: {},
  mobile: false,
};

export default CertificationForm;
