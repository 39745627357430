import React from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { REVIEW_CYCLE_GROUPS } from "../../pages/ReviewCycle/constants"; // replace with query once in db

const GroupCycleSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  getValue,
  setValue,
  showBlank,
  showAll,
  filterHeight,
}) => {
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  return (
    <Control size={size}>
      {label && <Label>{label}</Label>}
      <Select.Container
        fullwidth
        size={size}
        // state={
        //   reviewTemplateDataLoading || Array.isArray(reviewTemplates)
        //     ? ""
        //     : "loading"
        // }
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
            height: filterHeight ? "36px" : "",
            fontSize: filterHeight ? "1rem" : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {showAll && <Select.Option value="">All</Select.Option>}
          {Array.isArray(REVIEW_CYCLE_GROUPS) &&
            REVIEW_CYCLE_GROUPS.map((cycleGroup) => (
              <Select.Option key={cycleGroup} value={cycleGroup}>
                {cycleGroup}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

GroupCycleSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
  showAll: PropTypes.bool,
  filterHeight: PropTypes.bool,
};

GroupCycleSelect.defaultProps = {
  size: "normal",
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  showBlank: false,
  showAll: false,
  filterHeight: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};

export default GroupCycleSelect;
