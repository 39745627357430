import React from "react";
import PropTypes from "prop-types";
import { useTheme, useMediaQuery } from "@mui/material";
import { Button, Title } from "rbx";
import capitalizeEveryWord from "../../../../utils/capitalizeEveryWord";

const EvaluationListItem = ({ e, title, isPersonalEval, handleClick }) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const className = downSm ? "review-item-mobile" : "review-item";

  // if it is a PPA or internal client and does not need assignment, show the form author
  const listItemTitle = ((e?.title?.includes("Internal Client") || e?.title?.includes("Periodic Performance")) && !e?.needsAssignment) ? `${capitalizeEveryWord(e.owner)}: ${e.title} - Assigned To ${capitalizeEveryWord(e.formAuthor)}`: `${capitalizeEveryWord(e.owner)} - ${e.title}`;

  if (title === "Sign Off") {
    return (
      <li key={e.id}>
        <div className={className} key={e.id}>
          <p className="review-title">
            {listItemTitle}
          </p>
          <Button
            className={`review-option-sign-off ${downSm && "option-mobile"}`}
            type="button"
            onClick={() => handleClick(e, true)}
          >
            <Title size={6} textColor="white">
              {title}
            </Title>
          </Button>
        </div>
      </li>
    );
  }

  if (title === "Assign") {
    return (
      <li key={e.id}>
        <div className={className} key={e.id}>
          <p className="review-title">
            {listItemTitle}
          </p>
          <Button
            className={`review-option-primary ${downSm && "option-mobile"}`}
            type="button"
            onClick={(event) => handleClick(event, e)}
          >
            <Title size={6} textColor="white">
              {title}
            </Title>
          </Button>
        </div>
      </li>
    );
  }

  if (title === "Resume") {
    return (
      <li key={e.id}>
        <div className={className} key={e.id}>
          <p className="review-title">
            {listItemTitle}
          </p>
          <Button
            className={`review-option-sign-off ${downSm && "option-mobile"}`}
            type="button"
            onClick={() => handleClick(e, false)}
          >
            <Title size={6} textColor="white">
              {title}
            </Title>
          </Button>
        </div>
      </li>
    );
  }

  if (title === "View") {
    return (
      <li key={e.id}>
        <div className={className}>
          <p className="review-title">
            {!isPersonalEval ? listItemTitle : e.title}
            
          </p>
          <Button
            className={`review-option-secondary ${downSm && "option-mobile"}`}
            type="button"
            onClick={() => handleClick(e, false)}
          >
            <Title size={6} textColor="white">
              {title}
            </Title>
          </Button>
        </div>
      </li>
    );
  }

  return (
    <li key={e.id}>
      <div className={className} key={e.id}>
        <p className="review-title">
          {!isPersonalEval ? listItemTitle : e.title}
        </p>
        <Button
          className={`review-option-secondary ${downSm && "option-mobile"}`}
          type="button"
          onClick={() => handleClick(e, false)}
        >
          <Title size={6} textColor="white">
            {title}
          </Title>
        </Button>
      </div>
    </li>
  );
};

EvaluationListItem.propTypes = {
  e: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isPersonalEval: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

EvaluationListItem.defaultProps = {
  isPersonalEval: false,
};

export default EvaluationListItem;
