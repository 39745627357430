import formatDate from "../../../utils/formatDate";

export const REVIEW_COLUMNS = [
  {
    header: "id",
    id: "id",
    accessorKey: "id",
  },
  {
    header: "Manager",
    id: "manager",
    accessorKey: "manager",
  },
  {
    header: "Title",
    id: "title",
    accessorKey: "title",
  },
  {
    header: "Year",
    id: "year",
    accessorKey: "year",
  },
  {
    header: "templateVersionId",
    id: "templateVersionId",
    accessorKey: "templateVersionId",
  },
  {
    header: "dateCompleted",
    id: "dateCompleted",
    accessorKey: "dateCompleted",
  },
  {
    header: "dateToCompleteBy",
    id: "dateToCompleteBy",
    accessorKey: "dateToCompleteBy",
  },
  {
    header: "status",
    id: "status",
    accessorKey: "status",
  },
  {
    header: "userId",
    id: "userId",
    accessorKey: "userIds",
  },
];

export const EVALUATION_COLUMNS = [
  {
    header: "Status",
    id: "status",
    accessorKey: "status",
  },
  {
    header: "Title",
    id: "title",
    accessorKey: "title",
  },
  {
    header: "Manager",
    id: "manager",
    accessorKey: "manager",
  },
  {
    header: "Manager Employee Number",
    id: "managerEmployeeNumber",
    accessorKey: "managerEmployeeNumber",
  },
  {
    header: "Writer",
    id: "formAuthor",
    accessorKey: "formAuthor", // transition to more accurate field
  },
  {
    header: "Writer Employee Number",
    id: "formAuthorEmployeeNumber",
    accessorKey: "formAuthorEmployeeNumber", // transition to more accurate field
  },
  {
    header: "Subject",
    id: "owner",
    accessorKey: "owner",
  },
  {
    header: "Current Author",
    id: "author",
    accessorKey: "author",
  },
  {
    header: "Current Author Employee Number",
    id: "authorEmployeeNumber",
    accessorKey: "authorEmployeeNumber",
  },
  {
    header: "Needs Assignment",
    id: "needsAssignment",
    accessorFn: (row) => row?.needsAssignment ? "Yes" : "No",
  },
  {
    header: "Assignment Date",
    id: "assignmentDate",
    accessorFn: (row) => row?.assignmentDate && formatDate(row?.assignmentDate),
  },
  {
    header: "Due Date",
    id: "dateToCompleteBy",
    accessorFn: (row) =>
      row?.dateToCompleteBy && formatDate(row?.dateToCompleteBy),
  },
  {
    header: "Overdue",
    id: "overdue",
    accessorFn: (row) =>
      row?.dateToCompleteBy &&
      new Date(row?.dateToCompleteBy) < new Date() &&
      !row?.dateCompleted
        ? "Yes"
        : "No",
  },
  {
    header: "Date Completed",
    id: "dateCompleted",
    accessorFn: (row) => row?.dateCompleted && formatDate(row?.dateCompleted),
  },
  {
    header: "Signer",
    id: "signer", // an evaluation that is signed(which is every that is completed after 8/22/2023) will have the author as the signer when it is signed
    accessorFn: (row) => (row?.signedDate ? row?.signerName : ""),
  },
  {
    header: "Date Signed",
    id: "signedDate",
    accessorFn: (row) => row?.signedDate && formatDate(row?.signedDate),
  },
  {
    header: "Template Version Id",
    id: "templateVersionId",
    accessorKey: "templateVersionId",
  },
];
