import React, { useState } from "react";
import { Input, Button } from "rbx";
import { Link } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";

const LoginPage = () => {
  const { handleLogin, state: authState } = useAuth();

  const [state, setState] = useState({ username: "", password: "" });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleLogin(state.username, state.password);
  };
  const handleChange = (name, value) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="login-container">
      <form className="login-wrapper" onSubmit={handleSubmit}>
        <div className="logo">
          <img alt="DECCO" className="decco-logo" src="/logo-white.png" />
        </div>
        <div className="form-box">
          <Input
            required
            className="ph-w"
            disabled={authState.isLoginPending}
            name="username"
            placeholder="Username"
            type="text"
            value={state.username}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <Input
            required
            className="ph-w"
            disabled={authState.isLoginPending}
            name="password"
            placeholder="Password"
            type="password"
            value={state.password}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
          />
          <div className="links-box">
            <Link to="/forgot">Forgot Password</Link>
            <Link to="/signup">Sign Up</Link>
          </div>
          <Button
            disabled={
              !state.username || !state.password || authState.isLoginPending
            }
            state={authState.isLoginPending ? "loading" : ""}
            type="submit"
          >
            Log in
          </Button>
        </div>
      </form>
    </div>
  );
};

LoginPage.propTypes = {};

export default LoginPage;
