import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const FIND_MANY_CODES = gql`
  query FindManyCodes(
    $where: CodesWhereInput
    $orderBy: [CodesOrderByWithRelationInput!]
  ) {
    findManyCodes(where: $where, orderBy: $orderBy) {
      id
      entity
      expirationType
      title
      state
      level
    }
  }
`;
