import React, { useState } from "react";
import { Input, Button } from "rbx";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

const SignUpPage = () => {
    const [email, setEmail] = useState("");

    const { handleSignup, isLoginPending } = useAuth();

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSignup(email);
    };

    const handleChange = ({ target }) => {
        setEmail(target.value);
    };

    return (
        <div className="signup-container">

            <div className="signup-wrapper">
                <div className="logo">
                    <img alt="DECCO" className="decco-logo" src="/logo-white.png" />
                </div>

                <form className="form-box" onSubmit={handleSubmit}>
                    <Input
                        required
                        className="ph-w input"
                        name="email"
                        placeholder="DECCO Email"
                        type="email"
                        value={email}
                        onChange={handleChange}
                    />

                    <div>
                        <Link to="/login">Back to Log In</Link>
                    </div>

                    <Button
                        disabled={isLoginPending}
                        type="submit"
                    >
                        Sign up
                    </Button>
                </form>
            </div>
        </div>
    );
}

SignUpPage.propTypes = {};

export default SignUpPage;