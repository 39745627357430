import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_EXPIRING_LICENSES = gql`
  query FindManyLicenses(
    $where: LicensesWhereInput
    $orderBy: [LicensesOrderByWithRelationInput!]
  ) {
    findManyLicenses(where: $where, orderBy: $orderBy) {
      codes {
        title
        state
        points
        level
        expirationType
      }
      expDate
      licenseNumber
      users {
        firstName
        employeeNumber
        lastName
        email
      }
    }
  }
`;

export const GET_EXPIRING_CERTIFICATIONS = gql`
  query FindManyCertifications(
    $orderBy: [CertificationsOrderByWithRelationInput!]
    $where: CertificationsWhereInput
  ) {
    findManyCertifications(orderBy: $orderBy, where: $where) {
      expDate
      codes {
        title
        expirationType
      }
      users {
        firstName
        lastName
        email
        employeeNumber
      }
    }
  }
`;

export const GET_TSHIRT_REPORT = gql`
  query Users($where: UserWhereInput, $orderBy: [UserOrderByWithRelationInput!]) {
    users(where: $where, orderBy: $orderBy) {
      firstName
      lastName
      employeeNumber
      tshirtSize
      projectId
      id
      project {
        name
      }
    }
  }
`;

export const GET_QUALIFICATION_CERTS = gql`
  query GetQualCerts(
    $where: CertificationsWhereInput
    $orderBy: [CertificationsOrderByWithRelationInput!]
  ) {
    findManyCertifications(where: $where, orderBy: $orderBy) {
      users {
        employeeNumber
        firstName
        lastName
        middleInitial
      }
      codes {
        title
        expirationType
      }
      issueDate
      certificationNumber
      expDate
    }
  }
`;

export const GET_QUALIFICATION_LICENSES = gql`
  query GetQualLicenses(
    $where: LicensesWhereInput
    $orderBy: [LicensesOrderByWithRelationInput!]
    $distinct: [LicensesScalarFieldEnum!]
  ) {
    findManyLicenses(where: $where, orderBy: $orderBy, distinct: $distinct) {
      users {
        employeeNumber
        firstName
        lastName
        middleInitial
      }
      codes {
        title
        level
        state
        expirationType
      }
      issueDate
      licenseNumber
      expDate
    }
  }
`;

export const GET_QUALIFICATION_SKILLS = gql`
  query GetQualSkills(
    $where: SkillsWhereInput
    $orderBy: [SkillsOrderByWithRelationInput!]
  ) {
    findManySkills(where: $where, orderBy: $orderBy) {
      users {
        employeeNumber
        firstName
        lastName
        middleInitial
      }
      codes {
        title
      }
      issueDate
    }
  }
`;

export const GET_QUALIFICATION_DECCOU = gql`
  query FindManyDeccoUCourses(
    $where: DeccoUCoursesWhereInput
    $orderBy: [DeccoUCoursesOrderByWithRelationInput!]
  ) {
    findManyDeccoUCourses(where: $where, orderBy: $orderBy) {
      issueDate
      codes {
        title
      }
      users {
        firstName
        lastName
        employeeNumber
        middleInitial
      }
    }
  }
`;
