import React from "react";

import Select from "react-select";

import PropTypes from "prop-types";

const ReactSelect = ({
  options,
  onChange,
  isMulti,
  value,
  name,
  closeMenuOnSelect,
}) => {
  const handleChange = (e) => {
    onChange(e, name);
  };
  return (
    <Select
      cacheOptions
      defaultOptions
      isClearable
      isSearchable
      closeMenuOnSelect={closeMenuOnSelect}
      isMulti={isMulti}
      options={options}
      value={value}
      onChange={handleChange}
    />
  );
};

ReactSelect.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  name: PropTypes.string.isRequired,
  closeMenuOnSelect: PropTypes.bool,
};

ReactSelect.defaultProps = {
  isMulti: false,
  onChange: () => {},
  value: null,
  closeMenuOnSelect: false,
};

export default ReactSelect;
