import React from "react";
import PropTypes from "prop-types";

const NumberCell = ({ value }) => (
  <div style={{ width: "100%", textAlign: "right" }}>{value}</div>
);

NumberCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
NumberCell.defaultProps = {
  value: "'",
};
export default NumberCell;
