import React from "react";
import { Box, CircularProgress } from "@mui/material";

function BoxLoader() {
  return (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
    >
      <CircularProgress />
    </Box>
  );
}

export default BoxLoader;
