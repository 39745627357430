/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FIND_FIRST_USER } from "../../graphql/user";
import { customToast as toast } from "../../utils";
import GenericTable from "../../components/GenericTable";
import {
  certColumns,
  courseColumns,
  licenseColumns,
  skillColumns,
} from "./constants";
import { client } from "../../graphql";

const SubRowAsync = ({ row, rowProps, visibleColumns }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    licenses: [],
    certs: [],
    skills: [],
    deccoUCourses: [],
  });

  useEffect(() => {
    const getUserData = async () => {
      if (row?.original.employeeNumber) {
        setLoading(true);
        try {
          const {
            data: { findFirstUser },
          } = await client.query({
            query: FIND_FIRST_USER,
            variables: {
              where: {
                employeeNumber: {
                  contains: row.original.employeeNumber,
                },
              },
            },
            fetchPolicy: "network-only",
          });
          if (findFirstUser) {
            setData({
              deccoUCourses: findFirstUser.deccoUCourses || [],
              licenses: findFirstUser.licenses || [],
              certs: findFirstUser.certifications || [],
              skills: findFirstUser.skills || [],
            });
          }
        } catch (err) {
          toast.error("An error has occured, please try again later");
        } finally {
          setLoading(false);
        }
      }
    };
    getUserData();
  }, [row]);

  if (loading) {
    return (
      <tr>
        <td />
        <td colSpan={visibleColumns.length - 1}>Loading...</td>
      </tr>
    );
  }

  if (
    !data.licenses.length &&
    !data.certs.length &&
    !data.deccoUCourses.length &&
    !data.skills.length
  ) {
    return (
      <tr>
        <td />
        <td colSpan={visibleColumns.length - 1}>No Data to Show...</td>
      </tr>
    );
  }

  return (
    <React.Fragment>
      <tr className="sub-row" {...rowProps}>
        <td />
        <td />
        <td className="sub-cell">
          <h3>Skills</h3>
          <GenericTable
            columns={skillColumns}
            data={data.skills}
            isPaginated={false}
            loading={loading}
          />
        </td>
        <td className="sub-cell">
          <h3>Licenses</h3>
          <GenericTable
            columns={licenseColumns}
            data={data.licenses}
            isPaginated={false}
            loading={loading}
          />
        </td>
        <td className="sub-cell">
          <h3>Certifications</h3>
          <GenericTable
            columns={certColumns}
            data={data.certs}
            isPaginated={false}
            loading={loading}
          />
        </td>
        <td className="sub-cell">
          <h3>DECCOU Courses</h3>
          <GenericTable
            columns={courseColumns}
            data={data.deccoUCourses}
            isPaginated={false}
            loading={loading}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

SubRowAsync.propTypes = {
  row: PropTypes.object,
  rowProps: PropTypes.object,
  visibleColumns: PropTypes.array,
};

SubRowAsync.defaultProps = {
  row: {},
  rowProps: {},
  visibleColumns: [],
};

export default SubRowAsync;
