import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import { Tooltip } from "@mui/material";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import {
  DELETE_REVIEW_AND_EVALS,
} from "./graphql";
import ReviewSummaryList from "../ReviewSummaryList/ReviewSummaryList";
import LinearProgressBar from "../../components/LinearProgressBar";
import Details from "../Details";
import { useModal } from "../../context";
import Confirmation from "../../components/Confirmation";

const ReviewSummary = ({ review, refetchReviewList }) => {
  const [progress, setProgress] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedEvalId, setSelectedEvalId] = useState("");
  const { setModalOpen } = useModal();
  const [deleteReviewAndEvals] = useMutation(
    DELETE_REVIEW_AND_EVALS
  );

  const completedEvals = review?.evaluations?.filter(
    (evaluation) => evaluation?.status === "COMPLETED"
  );

  const tooltipMsg =
    review?.status === "COMPLETED"
      ? "Review is completed"
      : "Review is not completed";

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((completedEvals.length / review?.evaluations?.length) * 100);
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, [completedEvals.length, review?.evaluations?.length]);

  const handleShowDetails = (id) => {
    setSelectedEvalId(id);
    setShowDetails(!showDetails);
  };

  const handleDeleteReviewAndEvals = async () => {
    const performDelete = async () => {
      try {
        await deleteReviewAndEvals({
          variables: {
            reviewId: review?.id,
          },
        });
        toast.success("Review Deleted Successfully");
      } catch (error) {
        toast.error(error.message);
      } finally {
        refetchReviewList();
        setModalOpen(false, "");
      }
    }
      setModalOpen(
        true,
        <Confirmation
          message={`Are you sure you want to delete ${review?.user?.firstName} ${review?.user?.lastName}'s ${review.title}?`}
          onCancel={() => setModalOpen(false, "")}
          onConfirm={ () => performDelete()}
        />
      );
    };

  if (showDetails) {
    return (
      <div className="show-details-container">
        <Button
          className="back-btn"
          type="button"
          onClick={() => setShowDetails(false)}
        >
          <Icon className="arrow-icon">
            <FontAwesomeIcon icon="arrow-left" />
          </Icon>
          <p>Back</p>
        </Button>
        <Details
          isModalView
          evaluationId={selectedEvalId}
          keyType="evaluation"
        />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="progress-bar-container" style={{justifyContent: "center"}}>
        <Button
          id="danger-btn"
          type="button"
          onClick={() => handleDeleteReviewAndEvals()}
        >
          <p>Delete Review</p>
        </Button>
      </div>
      <div className="progress-bar-container">
        <Tooltip arrow placement="top" title={tooltipMsg}>
          <LinearProgressBar value={progress} variant="determinate" />
        </Tooltip>

        {review?.status === "COMPLETED" ? (
          <Icon className="completed-icon">
            <FontAwesomeIcon icon={faCheckCircle} />
          </Icon>
        ) : (
          <Icon className="not-started-icon">
            <FontAwesomeIcon icon={faTimesCircle} />
          </Icon>
        )}
      </div>
      <ReviewSummaryList handleClick={handleShowDetails} review={review} />
    </React.Fragment>
  );
};

ReviewSummary.propTypes = {
  review: PropTypes.object.isRequired,
  refetchReviewList: PropTypes.func.isRequired,
};

export default ReviewSummary;
