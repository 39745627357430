import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_CONTRACTORS = gql`
  query GET_CONTRACTORS(
    $where: ContractorWhereInput
    $orderBy: [ContractorOrderByWithRelationInput!]
  ) {
    contractors(where: $where, orderBy: $orderBy) {
      id
      evaluation
      firstName
      lastName
      notes
      task
      staffingAgency
      status
      projectId
      project {
        name
      }
    }
  }
`;

export const GET_CONTRACTOR = gql`
  query GET_CONTRACTOR($where: ContractorWhereUniqueInput!) {
    contractor(where: $where) {
      id
      evaluation
      firstName
      lastName
      notes
      task
      staffingAgency
      status
      projectId
      project {
        name
      }
    }
  }
`;

export const GET_CONTRACTORS_EXPORT = gql`
  query GET_CONTRACTORS_EXPORT(
    $where: ContractorWhereInput
    $orderBy: [ContractorOrderByWithRelationInput!]
  ) {
    contractorExport(where: $where, orderBy: $orderBy) {
      id
      evaluation
      firstName
      lastName
      notes
      task
      staffingAgency
      contractorStatus
      projectId
      projectName
    }
  }
`;
