/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Notification,
  Table,
  Generic,
  Field,
  Control,
  Image,
} from "rbx";

import {
  useTable,
  usePagination,
  useSortBy,
  useRowSelect,
  useExpanded,
} from "react-table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Pagination from "./Pagination";
import Loading from "../../../../components/Loader";

import "./ContractorTable.scss";

const renderFunc = (cell) => {
  if (cell.value === null) return null;
  if (cell.column.id === "profileImg") {
    return cell.value ? (
      <div className="image-icon-wrapper">
        <Image alt="profileImg" className="cell-img-wrapper" src={cell.value} />
      </div>
    ) : (
      <div className="user-icon-wrapper">
        <FontAwesomeIcon icon="user" />
      </div>
    );
  }
  if (typeof cell.value === "boolean") {
    return <Generic>{cell.value ? "Active" : "Inactive"}</Generic>;
  }
  return (
    <div
      className={
        cell.column.Header === "Project"
          ? "project-cell-style-wrapper"
          : "cell-style-wrapper"
      }
    >
      {" "}
      {cell.render("Cell")}{" "}
    </div>
  );
};

const renderTableHeaderSort = (column) => {
  if (column.isSorted) {
    return column.isSortedDesc ? " ▼" : " ▲";
  }
  return "";
};

const defaultPropGetter = () => {};
function ContractorTable({
  columns,
  data,
  loading = false,
  onRowClick = () => {},
  sortBy = [],
  isFixedWidth = true,
  getRowProps = defaultPropGetter,
  updateData,
  id,
}) {
  const [, setDefaultPageSize] = useState(20);

  const tableState = {
    pageIndex: 0,
    sortBy,
    pageSize: 20,
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
      updateData,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const handleSetPageSize = (n) => {
    setDefaultPageSize(n);
    setPageSize(n);
  };

  const showPaginationControls = !!pageOptions.length && pageOptions.length > 1;

  if (!data.length) {
    return (
      <Container className="no-contractors-found-wrapper">
        <Notification className="contractor-notification">
          No Contractors Found
        </Notification>
      </Container>
    );
  }
  return (
    <div className="data-table-container" id={id}>
      <div className="table-container t-contractor-container">
        {loading ? (
          <Loading.Loading />
        ) : (
          <React.Fragment>
            <Table {...getTableProps()}>
              <Table.Head>
                {headerGroups.map((headerGroup) => (
                  <Table.Row {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <Table.Heading
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        <span>{renderTableHeaderSort(column)}</span>
                      </Table.Heading>
                    ))}
                  </Table.Row>
                ))}
              </Table.Head>
              <Table.Body className="tb" {...getTableBodyProps()}>
                {page.map(
                  (row, i) =>
                    prepareRow(row) || (
                      <React.Fragment key={i}>
                        <Table.Row
                          {...row.getRowProps(getRowProps(row))}
                          // onClick={() => onRowClick(row)}
                        >
                          {row.cells.map((cell, j) => (
                            <Table.Cell
                              {...cell.getCellProps()}
                              key={`row-${i}-cell-${j}`}
                              onClick={() =>
                                cell.column.Header === "Project"
                                  ? null
                                  : onRowClick(row)
                              }
                            >
                              {renderFunc(cell)}
                            </Table.Cell>
                          ))}
                        </Table.Row>
                      </React.Fragment>
                    )
                )}
              </Table.Body>
            </Table>
          </React.Fragment>
        )}
      </div>
      {showPaginationControls && (
        <Field align="centered" className="pagination-field" kind="group">
          <Control>
            <Pagination
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
              gotoPage={gotoPage}
              page={pageIndex + 1}
              pageSize={pageSize}
              pages={pageOptions.length}
              setPageSize={handleSetPageSize}
              onClickNext={nextPage}
              onClickPrev={previousPage}
            />
          </Control>
        </Field>
      )}
    </div>
  );
}

ContractorTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  loading: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  onRowClick: PropTypes.func,
  sortBy: PropTypes.array,
  isFixedWidth: PropTypes.bool,
  getRowProps: PropTypes.func,
  defaultColumn: PropTypes.object,
  updateData: PropTypes.func,
  defaultPageSize: PropTypes.number,
  id: PropTypes.string,
};

ContractorTable.defaultProps = {
  data: [],
  loading: false,
  pageCount: 1,
  pageSize: 20,
  onRowClick: () => {},
  sortBy: [],
  isFixedWidth: true,
  getRowProps: () => {},
  defaultColumn: {},
  updateData: () => {},
  defaultPageSize: 20,
  id: "",
};
export default ContractorTable;
