import React from "react";
import Modal from "react-modal";
import PropTypes from "prop-types";

const customStyles = {
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-37%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    overflowY: "auto",
    minWidth: "25%",
  },
};

Modal.setAppElement("#root");

function CustomModal({ open, closeModal, children }) {
  return (
    <Modal isOpen={open} style={customStyles} onRequestClose={closeModal}>
      {children}
    </Modal>
  );
}

CustomModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.any,
  children: PropTypes.node,
};

CustomModal.defaultProps = {
  open: false,
  closeModal: () => null,
  children: null,
};

export default CustomModal;
