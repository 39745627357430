import React from "react";

import { Control, Input, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

import { debounce } from "../../utils";

const Searchbar = ({ onChange, placeholder, disabled }) => (
  <div className="searchbar-container">
    <Control>
      <Input
        autoComplete="off"
        className="ph-grey"
        disabled={disabled}
        id="search-input"
        placeholder={placeholder}
        onChange={debounce(({ target: { value } }) => {
          onChange(value);
        }, 500)}
      />
      <Icon align="right">
        <FontAwesomeIcon icon="search" />
      </Icon>
    </Control>
  </div>
);

Searchbar.propTypes = {
  onChange: PropTypes.any,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
};

Searchbar.defaultProps = {
  onChange: () => null,
  placeholder: "Search...",
  disabled: false,
};

export default Searchbar;
