import { isValid } from "date-fns";
import format from "date-fns/format";
import React from "react";

const BASE_COLUMNS_START = [
  {
    Header: "Employee Number",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "First name",
    id: "firstName",
    accessor: "firstName",
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "lastName",
  },
];

const BASE_COLUMNS_CENTER = [
  {
    Header: "Issue Date",
    id: "issueDate",
    accessor: "issueDate",
  },
];

export const CERTIFICATION_COLS = [
  ...BASE_COLUMNS_START,
  {
    Header: "Certification",
    id: "title",
    accessor: "title",
  },
  {
    Header: "Certification#",
    id: "certificationNumber",
    accessor: "certificationNumber",
  },
  ...BASE_COLUMNS_CENTER,
   {
    id: "expDate",
    Header: "Expiration Date",
    accessor: "expDate",
    accessorFn: (row) => {

      if (!row?.expDate || row?.codes?.expirationType === "none") return "--";

      if (row?.expDate && isValid(row.expDate) && row.expDate < new Date()) {
        return (
          <span style={{ color: "red" }}>
            {format(new Date(row?.expDate), "MM/dd/yy")}
          </span>
        );
      }

      return format(new Date(row?.expDate), "MM/dd/yy");
    },
  },
  {
    Header: "Is Expired",
    id: "isExpired",
    accessor: "isExpired",
    accessorFn: (row) => {
      if (!row?.expDate || row?.codes?.expirationType === "none") return "";

      return row?.expDate && isValid(row.expDate) && row.expDate < new Date() ? "Expired" : "";
    }
  },
];

export const SKILL_COLS = [
  ...BASE_COLUMNS_START,
  {
    Header: "Skill",
    id: "title",
    accessor: "title",
  },
  ...BASE_COLUMNS_CENTER,
];

export const LICENSE_COLS = [
  ...BASE_COLUMNS_START,
  {
    Header: "License",
    id: "title",
    accessor: "title",
  },
  {
    Header: "License State",
    id: "state",
    accessor: "state",
  },
  {
    Header: "License Level",
    id: "level",
    accessor: "level",
    Cell: ({ cell }) => (cell?.value ? cell.value : "N/A"),
  },
  {
    Header: "License Number",
    id: "licenseNumber",
    accessor: "licenseNumber",
    Cell: ({ cell }) => (cell?.value ? cell.value : "N/A"),
  },
  ...BASE_COLUMNS_CENTER,
   {
    id: "expDate",
    Header: "Expiration Date",
    accessor: "expDate",
    accessorFn: (row) => {

      if (!row?.expDate || row?.codes?.expirationType === "none") return "--";

      if (row?.expDate && isValid(row.expDate) && row.expDate < new Date()) {
        return (
          <span style={{ color: "red" }}>
            {format(new Date(row?.expDate), "MM/dd/yy")}
          </span>
        );
      }

      return format(new Date(row?.expDate), "MM/dd/yy");
    },
  },
  {
    Header: "Is Expired",
    id: "isExpired",
    accessor: "isExpired",
    accessorFn: (row) => {
      if (!row?.expDate || row?.codes?.expirationType === "none") return "";

      return row?.expDate && isValid(row.expDate) && row.expDate < new Date() ? "Expired" : "";
    }
  },
];

export const DECCOU_COLS = [
  ...BASE_COLUMNS_START,
  {
    Header: "DECCOU Course",
    id: "title",
    accessor: "title",
  },
  ...BASE_COLUMNS_CENTER,
];
