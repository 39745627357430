import React from "react";
import { useQuery } from "@apollo/client";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button as MuiButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import PropTypes from "prop-types";
import { FileDownload } from "@mui/icons-material";
import { GET_ALPHA_LIST } from "../../../../graphql";
import {
  ReportSelectMUI,
  AccordionItem,
  AccordionDetailsItem,
  ReportsTable,
} from "../../../../components";
import { COST_CODE_COLUMNS } from "../../columns";

import ReportHeader from "../ReportHeader";

import { downloadLocalCsv, customToast as toast } from "../../../../utils";
import MobileTable from "../../../../components/ReportsTable/MobileTable";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";

const AlphaListTable = ({ report, onReportChange, tabs, permissions }) => {
  const { data: { users: employees = [] } = {}, loading } = useQuery(
    GET_ALPHA_LIST,
    {
      variables: {
        where: {
          employmentStatus: {
            equals: "active",
          },
        },
        orderBy: [
          {
            lastName: "asc",
          },
        ],
      },
    }
  );

  const exportCSV = async () => {
    try {
      downloadLocalCsv(employees, "Alpha List Report", COST_CODE_COLUMNS);
    } catch (err) {
      toast.error("Error exporting CSV");
    }
  };

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm) {
    return (
      <MobileTable
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <ReportSelectMUI value={report} onChange={onReportChange} />
            </Box>

            <Box mt={1} width="20%">
              <MuiButton
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => exportCSV()}
              >
                <FileDownload />
              </MuiButton>
            </Box>
          </React.Fragment>
        }
        loading={loading}
        report={report}
        title="Reports"
        onExportClick={exportCSV}
        onReportChange={onReportChange}
      >
        {loading ? (
          <BoxLoader />
        ) : (
          employees?.map((item) => (
            <AccordionItem
              key={item?.employeeNumber}
              title={`${item?.lastName}, ${item?.firstName}`}
            >
              <AccordionDetailsItem
                label="Employee #"
                value={item?.employeeNumber}
              />
              <AccordionDetailsItem label="Cost Code" value={item?.costCode} />
              <AccordionDetailsItem label="Job Title" value={item?.jobTitle} />
              <AccordionDetailsItem label="Review Group" value={item?.group} />
            </AccordionItem>
          ))
        )}
      </MobileTable>
    );
  }

  return (
    <ReportsTable
      columns={COST_CODE_COLUMNS}
      data={employees}
      loading={loading}
      onExportClick={exportCSV}
    >
      {(permissions.webEditor ||
        permissions.superAdmin ||
        permissions.admin) && (
        <div className="table-utils">
          <Button
            className="download-btn"
            disabled={loading}
            size="medium"
            title="Download CSV"
            onClick={exportCSV}
          >
            <Icon size="small">
              <FontAwesomeIcon icon="download" />
            </Icon>
          </Button>
        </div>
      )}
      <ReportHeader
        report={report}
        tabs={tabs}
        onReportChange={onReportChange}
      />
    </ReportsTable>
  );
};

AlphaListTable.propTypes = {
  onReportChange: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default AlphaListTable;
