import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

import BaseballCardAvatar from "../BaseballCardAvatar";

const Header = ({
  employeeData,
  exportBBCardPermissions,
  projectName,
  handlePrintClick,
  profileImageS3,
  mobile
}) => {
  const headerClassName = mobile ? "bball-header-title-mobile" : "bball-header-title";
return (
  <Grid container className="bball-header-container">
    <Grid container columnSpacing={1} p={1}>
      <Grid
        item
        sm={3}
        sx={{
          display: { xs: "block", md: "none" },
          marginBottom: { md: 0, xs: 1 },
        }}
        xs={4}
      >
        <BaseballCardAvatar
          employeeData={employeeData}
          profileImageS3={profileImageS3}
        />
      </Grid>
      <Grid item className={headerClassName} md={12} sm={9} xs={8}>
        {exportBBCardPermissions && (
          <Button
            className="print-button fa-print"
            size="large"
            onClick={() => handlePrintClick()}
          >
            <Icon>
              <FontAwesomeIcon icon={faPrint} />
            </Icon>
          </Button>
        )}
        <React.Fragment>
          <Typography
            className="c-secondary"
            component="span"
            fontSize={mobile ? "1rem" : "2rem"}
            textTransform="uppercase"
          >
            {`${employeeData?.firstName} `}
            {employeeData?.middleInitial && `${employeeData.middleInitial}. `}
          </Typography>
          <Typography
            className="c-white"
            component="span"
            fontSize={mobile ? "1rem" : "2rem"}
            textTransform="uppercase"
          >
            {`${employeeData?.lastName}`}
          </Typography>
        </React.Fragment>
      </Grid>
      <Grid item xs={12}>
        <Grid container columns={{ xs: 4, sm: 8, md: 13 }}>
          <Grid item md={1} xs={0} />
          <Grid item xs={4}>
            <Grid container>
              <Grid item display="flex" xs={12}>
                <Typography fontSize="1.1rem" fontWeight="bold" mr={1}>
                  Years of Service
                </Typography>
                <Typography
                  className="c-secondary"
                  fontSize="1.1rem"
                  fontWeight="bold"
                >
                  {employeeData?.totalYearsOfService || ""}
                </Typography>
              </Grid>
              {employeeData?.yearsOfServiceNote ? (
                <Grid item display="flex" xs={12}>
                  <Typography mr={1}>Service Note:</Typography>
                  <Typography>{employeeData.yearsOfServiceNote}</Typography>
                </Grid>
              ) : null}
              <Grid item display="flex" xs={12}>
                <Typography mr={1}>Project Assignment:</Typography>
                <Typography>{projectName}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ display: { xs: "block", md: "block" } }} xs={4}>
            <Grid container>
              <Grid item display="flex" xs={12}>
                <Typography mr={1}>Review Group:</Typography>
                <Typography>{employeeData?.group || "Not Assigned"}</Typography>
              </Grid>
              <Grid item display="flex" xs={12}>
                <Typography mr={1}>Total Points:</Typography>
                <Typography className="c-secondary">
                  {employeeData?.points || 0}
                </Typography>
              </Grid>
              <Grid item display="flex" xs={12}>
                <Typography mr={1}>Average Company Points:</Typography>
                <Typography>
                  {employeeData?.averageCompanyPoints || 0}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ display: { xs: "block", md: "block" } }} xs={4}>
            <Grid container>
              <Grid item display="flex" xs={12}>
                <Typography align="right" mr={1}>
                  Employee #:
                </Typography>
                <Typography>
                  {employeeData?.employeeNumber || "Unknown"}
                </Typography>
              </Grid>
              <Grid item display="flex" xs={12}>
                <Typography align="right" mr={1}>
                  Job Title:
                </Typography>
                <Typography>{employeeData?.jobTitle || "Unknown"}</Typography>
              </Grid>
              <Grid item display="flex" xs={12}>
                <Typography align="right" mr={1}>
                  T-Shirt Size:
                </Typography>
                <Typography>
                  {employeeData?.tshirtSize || "Not Assigned"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);
}

Header.propTypes = {
  employeeData: PropTypes.object,
  exportBBCardPermissions: PropTypes.bool,
  projectName: PropTypes.string,
  handlePrintClick: PropTypes.func,
  profileImageS3: PropTypes.string,
  mobile: PropTypes.bool,
};

Header.defaultProps = {
  employeeData: {},
  exportBBCardPermissions: false,
  projectName: "",
  handlePrintClick: () => null,
  profileImageS3: "",
  mobile: false,
};

export default Header;
