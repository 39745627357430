import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import {
  useTheme,
  useMediaQuery,
  Box,
  Button as MuiButton,
} from "@mui/material";
import Loader from "../../../../components/Loader";
import ProjectForm from "../ProjectForm/ProjectForm";
import { CREATE_PROJECT, GET_PROJECT_LIST } from "../../../../graphql";

const INITIAL_STATE = {
  name: "",
  project_num: "",
  manager: "",
  supervisor: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  notes: "",
  users: [],
  contractors: [],
};

class CustomProjectError extends Error {}

const ProjectModal = ({ where, onComplete }) => {
  const [project, setProject] = useState({ ...INITIAL_STATE });

  const [createProject, { loading: loadingCreateProject }] =
    useMutation(CREATE_PROJECT);

  const handleInputChange = (name, value) => {
    setProject((prev) => ({ ...prev, [name]: value }));
  };

  const CREATE_NEW_PROJECT = {
    variables: {
      data: {
        name: project.name,
        project_num: project.project_num,
        manager: project.manager,
        supervisor: project.supervisor,
        address: project.address,
        city: project.city,
        state: project.state,
        zip: project.zip,
        phone: project.phone,
        notes: project.notes,
        users: [],
        contractors: [],
      },
    },
    refetchQueries: [
      {
        query: GET_PROJECT_LIST,
        variables: {
          orderBy: [{ name: "asc" }],
          where,
        },
        fetchPolicy: "network-only",
      },
    ],
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await createProject(CREATE_NEW_PROJECT);
      toast.success(`Project created successfully.`);
      onComplete();
    } catch (error) {
      const message =
        error instanceof CustomProjectError
          ? error.message
          : `Error creating project. Please contact support.`;
      toast.error(message);
    }
  };

  const isLoading = loadingCreateProject;

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (isLoading) return <Loader />;

  const isDisabled =
    !project.project_num ||
    !project.name ||
    !project.manager ||
    !project.supervisor ||
    !project.address ||
    !project.city ||
    !project.state ||
    !project.zip;

  return (
    <form id="project-form" onSubmit={handleSubmit}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>Create Project</Title>
        </div>
        {!downSm && (
          <div className="modal-head-end">
            <Button.Group hasAddons>
              <Button
                size="small"
                type="button"
                onClick={() => onComplete(false)}
              >
                <span>Cancel</span>
              </Button>
              <Button
                color="primary"
                disabled={isDisabled}
                form="project-form"
                size="small"
                state={isLoading ? "loading" : ""}
                type="submit"
              >
                <span>Save</span>
              </Button>
            </Button.Group>
          </div>
        )}
      </header>
      <hr />
      <ProjectForm project={project} onChange={handleInputChange} />
      {downSm && (
        <Box
          alignContent="center"
          display="flex"
          gap={2}
          justifyContent="space-between"
          p={2}
          width="100%"
        >
          <MuiButton
            fullWidth
            color="secondary"
            sx={{ color: theme.palette.common.white }}
            variant="contained"
            onClick={() => onComplete(false)}
          >
            Cancel
          </MuiButton>
          <MuiButton
            fullWidth
            color="primary"
            disabled={isDisabled}
            variant="contained"
            onClick={(e) => handleSubmit(e)}
          >
            Save
          </MuiButton>
        </Box>
      )}
    </form>
  );
};

ProjectModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  where: PropTypes.object.isRequired,
};

export default ProjectModal;
