import React from "react";
import PropTypes from "prop-types";
import { Checkbox, Control } from "rbx";
import "./LockReviewsCheckbox.scss";
import { Tooltip } from "@mui/material";

const LockReviewsCheckbox = ({ value, onChange, disabled }) => (
  <Control expanded>
    <div className="review-cycle-row">
      <p className="bold-text">Lock Reviews from Non-Admins</p>
      <Tooltip
        arrow
        placement="right"
        title={
          <p className="tooltip">
            Selecting this box will only allow admins to make process changes
            such as adding forms, changing authors/signers, modifying visibility
            and updating due dates for these reviews.
          </p>
        }
      >
        <Checkbox
          checked={value}
          className="margin-left admin-checkbox"
          disabled={disabled}
          name="lockReviewsFromNonAdmins"
          size="large"
          onChange={onChange}
        />
      </Tooltip>
    </div>
  </Control>
);

LockReviewsCheckbox.propTypes = {
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

LockReviewsCheckbox.defaultProps = {
  disabled: false,
};

export default LockReviewsCheckbox;
