/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@apollo/client";
import { TextField, Autocomplete } from "@mui/material";
import { GET_PROJECTS } from "../../graphql";

const ProjectSelectMUI = ({
  name,
  value,
  label,
  placeholder,
  disabled,
  loading,
  required,
  showAllOption,
  getValue,
  setValue,
  onChange,
  filterEmploymentStatus,
}) => {
  const [formattedOptions, setFormattedOptions] = useState([]);

  const where = filterEmploymentStatus
    ? {
        AND: [
          {
            name: {
              notIn: ["Disability", "Vacation", "Not Assigned"],
            },
          },
        ],
      }
    : {};

  const { data: { projects = [] } = {} } = useQuery(GET_PROJECTS, {
    variables: {
      orderBy: [{ name: "asc" }],
      where,
    },
  });

  useEffect(() => {
    if (projects.length) {
      const fOptions = projects.map((code) => ({
        value: code.id,
        label: code?.name,
      }));

      if (showAllOption) {
        fOptions.unshift({ value: "", label: "All" });
      }

      setFormattedOptions(fOptions);
    }
  }, [projects, showAllOption]);

  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) =>
        option?.label ??
        formattedOptions.find((opt) => opt.value === value)?.label
      }
      isOptionEqualToValue={(o, v) => o.value === v}
      isRequired={required}
      loading={loading}
      options={formattedOptions}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      size="medium"
      value={getValue(value)}
      onChange={(_, option) => {
        onChange(name, setValue(option?.value));
      }}
    />
  );
};

ProjectSelectMUI.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  showAllOption: PropTypes.bool,
  options: PropTypes.array,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
  filterEmploymentStatus: PropTypes.bool,
};

ProjectSelectMUI.defaultProps = {
  name: "",
  placeholder: "",
  label: "",
  disabled: false,
  loading: false,
  required: false,
  showAllOption: false,
  filterEmploymentStatus: false,
  options: [],
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};

export default ProjectSelectMUI;
