import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_REVIEW_TEMPLATES = gql`
  query ReviewTemplates(
    $where: ReviewTemplateWhereInput
    $take: Int
    $skip: Int
    $orderBy: [ReviewTemplateOrderByWithRelationInput!]
    $sectionsOrderBy: [EvaluationSectionTemplateOrderByWithRelationInput!]
    $questionsOrderBy: [EvaluationQuestionTemplateOrderByWithRelationInput!]
  ) {
    reviewTemplates(
      where: $where
      take: $take
      skip: $skip
      orderBy: $orderBy
    ) {
      id
      year
      title
      _count {
        evaluationTemplates
      }
      evaluationTemplates {
        title
        year
        id
        sections(orderBy: $sectionsOrderBy) {
          title
          id
          questions(orderBy: $questionsOrderBy) {
            id
            answer
            answerType
            answerOptions {
              name
              id
            }
            text
          }
        }
      }
    }
    aggregateReviewTemplate(where: $where) {
      _count {
        _all
      }
    }
  }
`;
