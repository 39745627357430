import React from "react";
import { ExpandMore as ExpandMoreIcon, Person } from "@mui/icons-material";
import { useApolloClient } from "@apollo/client";
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";
import {
  SIGNED_FILE_URL_QUERY,
} from "../../graphql";

const AccordionItem = ({
  title = "",
  width,
  paddingLeft,
  paddingRight,
  info,
  img = "",
  children,
  onChange,
}) => {
  const theme = useTheme();
  const client = useApolloClient();
  const [profileImageS3, setProfileImageS3] = React.useState("");
    React.useEffect(() => {
    if (img) {
      const handleDownloadProfilePicture = async (image) => {
        const profileImage = image;
        const path = profileImage.split("/")[3];
        const { data: signedFileUrlData } = await client.query({
          query: SIGNED_FILE_URL_QUERY,
          variables: {
            input: {
              objectKey: path,
            },
            fetchPolicy: "network-only",
          },
        });
        const { signedFileUrl } = signedFileUrlData;
        setProfileImageS3(signedFileUrl);
      };
      handleDownloadProfilePicture(img);
    }
  }, [img, client]);
  return (
    <Box pl={paddingLeft} pr={paddingRight} width={width}>
      <Accordion
        sx={{
          backgroundColor: theme.palette.grey[400],
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        }}
        onChange={onChange}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            width="100%"
          >
            {!!img && profileImageS3?.length > 0 && (
              <Box
                alignItems="center"
                border={`1px solid ${theme.palette.secondary.main}`}
                borderRadius="70%"
                display="flex"
                height="3rem"
                justifyContent="center"
                minWwidth="3rem"
                mr="1rem"
                overflow="hidden"
                width="3rem"
              >
                <Box
                  alt="Profile-Image"
                  backgroundSize="cover"
                  component="img"
                  src={profileImageS3}
                />
              </Box>
            )}
            {img?.length > 1 && profileImageS3?.length < 1 && (
              <Box
                alignItems="center"
                bgcolor={theme.palette.secondary.main}
                borderRadius="70%"
                display="flex"
                height="3rem"
                justifyContent="center"
                minWidth="3rem"
                mr="1rem"
                overflow="hidden"
                width="3rem"
              >
                <Person
                  sx={{ color: "#fff", width: "2.5rem", height: "2.5rem" }}
                />
              </Box>
            )}
            <Typography maxWidth={info?.length ? "65%" : "100%"} variant="h7">
              {title}
            </Typography>
            <Box ml="auto">
              <Typography variant="subtitle3">{info}</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

AccordionItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  info: PropTypes.any,
  width: PropTypes.string,
  paddingLeft: PropTypes.number,
  paddingRight: PropTypes.number,
  img: PropTypes.string,
  onChange: PropTypes.func,
};

AccordionItem.defaultProps = {
  img: null,
  info: "",
  width: "100svw",
  paddingLeft: 2,
  paddingRight: 2,
  onChange: () => {},
};

export default AccordionItem;
