import React from "react";
import PropTypes from "prop-types";
import ReviewSummaryItem from "./components/ReviewSummaryItem";
import "./ReviewSummaryList.scss";

const ReviewSummaryList = ({ review, isTemplate, handleClick }) => (
  <div className="summary-container">
    {review?.evaluations?.map((evaluation) => (
      <ReviewSummaryItem
        evaluation={evaluation}
        handleClick={handleClick}
        isTemplate={isTemplate}
      />
    ))}
  </div>
);

ReviewSummaryList.propTypes = {
  review: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isTemplate: PropTypes.bool,
};

ReviewSummaryList.defaultProps = {
  isTemplate: false,
};

export default ReviewSummaryList;
