import React, { useState } from "react";
import { Title } from "rbx";
import { useApolloClient, useQuery } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { GET_REVIEWS, GET_USER, GET_REVIEW_IDS } from "./graphql";
import { Loader, DetailedDataTable, ModalWrapper } from "../../components";
import { REVIEW_COLUMNS } from "./columns";
import Details from "../Details/Details";
import { useModal } from "../../context";
import ReviewSummary from "../ReviewSummary";
import capitalizeEveryWord from "../../utils/capitalizeEveryWord";
import FilterComponent from "../../components/DetailedDataTable/SearchFiltersModal";
import checkMobile from "../../utils/checkForMobile";
import "../Reviews/Reviews.scss";

const Reviews = () => {
  const { setModalOpen } = useModal();
  const client = useApolloClient();
  const [paginationWhere, setPaginationWhere] = useState({}); // eslint-disable-line
  const isMobile = checkMobile();

  const {
    data: ReviewData,
    loading,
    refetch,
  } = useQuery(GET_REVIEW_IDS, {
    variables: {
      where: paginationWhere,
    },
    fetchPolicy: "cache-and-network",
  });

  const location = useLocation();

  const reviews = ReviewData?.reviews || [];

  const getUserInfo = async (userId) => {
    const { data: userInfo } = await client.query({
      query: GET_USER,
      variables: {
        where: {
          id: userId,
        },
      },
    });

    return userInfo?.user;
  };

  const handleRowClick = async (review) => {
    const userData = await getUserInfo(review.userId);

    const fullNameFormatted = capitalizeEveryWord(
      `${userData?.firstName} ${userData?.lastName}`
    );

    setModalOpen(
      true,
      <ModalWrapper
        details={review?.title}
        title={fullNameFormatted}
        onComplete={() => setModalOpen(false)}
      >
        <ReviewSummary refetchReviewList={refetch} review={review} />
      </ModalWrapper>
    );
  };

  if (loading) {
    return (
      <div
        className={
          isMobile ? "workshop-container-mobile" : "workshop-container"
        }
      >
        <div className="loader-container">
          <Loader />
        </div>
      </div>
    );
  }

  if (location.search.includes("review")) {
    return <Details />;
  }

  if (!reviews?.length > 0) {
    return (
      <div
        className={
          isMobile ? "workshop-container-mobile" : "workshop-container"
        }
      >
        <div className="workshop">
          <Title>No Reviews Found</Title>
        </div>
      </div>
    );
  }

  return (
    <div
      className={isMobile ? "workshop-container-mobile" : "workshop-container"}
    >
      <div className="workshop">
        <Title>Reviews</Title>
        <DetailedDataTable
          FilterComponent={FilterComponent}
          aggregateName="aggregateReview"
          columns={REVIEW_COLUMNS}
          dataExtractor={(data) => data?.reviews}
          defaultSorting={[{ id: "subject", desc: false }]}
          location="reviews"
          name="all-reviews"
          query={GET_REVIEWS}
          searchTransformer={(search) => ({})}
          setPaginationWhere={setPaginationWhere}
          totalExtractor={(total) => total}
          onRowClick={(review) => handleRowClick(review)}
        />
      </div>
    </div>
  );
};

export default Reviews;
