import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const SETTINGS_SYNC = gql`
  mutation employeeSync {
    employeeSync {
      employeesCreated
      employeesUpdated
      message
    }
  }
`;
