import React, { useEffect } from "react";

import PropTypes from "prop-types";
import { Field, Icon, Control, Button, Select } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Pagination({
  page,
  pages,
  onClickPrev,
  onClickNext,
  canNextPage,
  canPreviousPage,
  pageSize,
  setPageSize,
  hidePageSizeSelector,
}) {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (canNextPage && e.keyCode === 39) {
        onClickNext();
      }
      if (canPreviousPage && e.keyCode === 37) {
        onClickPrev();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [canNextPage, canPreviousPage, onClickNext, onClickPrev]);

  return (
    <div className="data-table-pagination">
      <Field align="right" kind="group" size="small">
        <Control>
          <Button
            className="prev"
            disabled={!canPreviousPage}
            role="button"
            size="small"
            onClick={onClickPrev}
          >
            <Icon>
              <FontAwesomeIcon icon="arrow-left" />
            </Icon>
          </Button>
        </Control>
        {!hidePageSizeSelector && (
          <Control>
            <Select.Container size="small">
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[25, 50].map((ps) => (
                  <Select.Option key={ps} value={ps}>
                    Show {ps}
                  </Select.Option>
                ))}
              </Select>
            </Select.Container>
          </Control>
        )}
        <Control>
          <Button disabled size="small">
            {page} of {pages}
          </Button>
        </Control>
        <Control>
          <Button
            className="next"
            disabled={!canNextPage}
            role="button"
            size="small"
            onClick={onClickNext}
          >
            <Icon>
              <FontAwesomeIcon icon="arrow-right" />
            </Icon>
          </Button>
        </Control>
      </Field>
    </div>
  );
}

Pagination.propTypes = {
  pages: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  page: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickPrev: PropTypes.func.isRequired,
  canNextPage: PropTypes.bool.isRequired,
  canPreviousPage: PropTypes.bool.isRequired,
  pageSize: PropTypes.number.isRequired,
  setPageSize: PropTypes.func.isRequired,
  hidePageSizeSelector: PropTypes.bool,
};

Pagination.defaultProps = {
  hidePageSizeSelector: false,
};

export default Pagination;
