/* eslint-disable react/destructuring-assignment */
import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import format from "date-fns/format";

export const DEFAULT_COLUMNS = [
  {
    accessor: "name",
    Header: "Name",
  },
  {
    accessor: "description",
    Header: "Description",
  },
  {
    accessor: "createdAt",
    Header: "Created At",
    Cell: ({ value }) => (value ? format(new Date(value), "MM-dd-y ") : "--"),
  },
];

export const generateColumns = (handleDelete, handleDownload) => [
  ...DEFAULT_COLUMNS,
  {
    Header: "Download",
    id: "download",
    Cell: (cell) => (
      <Generic
        style={{
          textAlign: "center",
          cursor: "pointer",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        title="Download"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDownload(cell.row.original);
        }}
      >
        <Icon color="danger" style={{ maxHeight: "10px" }}>
          <FontAwesomeIcon icon="download" />
        </Icon>
      </Generic>
    ),
  },
];

export default DEFAULT_COLUMNS;
