import React, { useState } from "react";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";

import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import Loader from "../../../../components/Loader";
import GoalAddEditForm from "../GoalAddEditForm/GoalAddEditForm";
import { useCurrentUser } from "../../../../hooks";
import {CREATE_ONE_USER_GOAL } from "../../../../graphql";
import { UPDATE_ONE_USER_GOAL } from "../../graphql";

const INITIAL_STATE = {
  title: "",
  startDate: "",
  dueDate: "",
  description: "",
};

class CustomProjectError extends Error {}

const GoalFormModal = ({ onComplete, userData, type, editGoal }) => {
  const [goal, setGoal] = useState(editGoal?.title ? {title: editGoal?.title, startDate: new Date(editGoal?.startDate), dueDate: new Date(editGoal?.dueDate), description: editGoal?.description }: { ...INITIAL_STATE });

  const [createOneUserGoal, { loading: loadingCreateUserGoal }] =
    useMutation(CREATE_ONE_USER_GOAL);

  const handleInputChange = (name, value) => {
    setGoal((prev) => ({ ...prev, [name]: value }));
  };

  const user = useCurrentUser();

  const CREATE_NEW_GOAL = {
    variables: {
      data: {
        title: goal.title,
        description: goal.description,
        status: "Active",
        startDate: goal.startDate,
        dueDate: goal.dueDate,
        currentProgress: 0,
        createdBy: `${user?.firstName} ${user?.lastName}`,
        createDate: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        updatedBy: user?.employeeNumber,
        updatedByFullName: `${user?.firstName} ${user?.lastName}`,
        user: { connect : { id: userData?.id } },
      },
    }
  };

  const EDIT_NEW_GOAL = {
    variables: {
      where: {
        id: editGoal?.id,
      },
      data: {
        title: { set: goal.title,},
        description: { set: goal.description,},
        startDate: { set: goal.startDate,},
        dueDate: { set: goal.dueDate,},
        updatedAt: { set: new Date().toISOString(),},
        updatedBy: { set: user?.employeeNumber,},
        updatedByFullName: { set: `${user?.firstName} ${user?.lastName}`,},
      },
    }
  };

  const handleCreate = async (e) => {
    try {
      e.preventDefault();
      await createOneUserGoal(CREATE_NEW_GOAL);
      toast.success(`Goal created successfully.`);
      onComplete();
    } catch (error) {
      const message =
        error instanceof CustomProjectError
          ? error.message
          : `Error creating goal. Please contact support.`;
      toast.error(message);
    }
  };

    // eslint-disable-next-line no-unused-vars
  const [updateOneUserGoal, { loading: loadingGoal }] = useMutation(
    UPDATE_ONE_USER_GOAL
  );

  const handleEdit = async (e) => {
    try {
      e.preventDefault();
      await updateOneUserGoal(EDIT_NEW_GOAL);

      toast.success("Successfully updated goal");
      onComplete();
    } catch (err) {
      toast.error("Error saving goal, please contact support.");
    }
  };

  const handleSubmit = async (e) => {
    if(type === "Create") {
      await handleCreate(e);
    } else {
      await handleEdit(e);
    }
  }


  const isLoading = loadingCreateUserGoal;

  if (isLoading) return <Loader />;

  const isDisabled =
    !goal.title ||
    !goal.startDate ||
    !goal.dueDate ||
    !goal.description

  return (
    <form id="project-form" onSubmit={handleSubmit}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>{type === "Edit" ? "Edit Goal":"Create Goal"}</Title>
        </div>
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isDisabled}
              form="project-form"
              size="small"
              state={isLoading ? "loading" : ""}
              type="submit"
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <GoalAddEditForm goal={goal} onChange={handleInputChange} />
    </form>
  );
};

GoalFormModal.propTypes = {
  onComplete: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
  type: PropTypes.string,
  editGoal: PropTypes.object
};

GoalFormModal.defaultProps = {
  editGoal: {},
  type: "Create"
};

export default GoalFormModal;
