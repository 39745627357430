/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import format from "date-fns/format";
import { Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const columns = [
  {
    accessor: "profileImg",
    Header: "",
    id: "profileImg",
    disableSortBy: true,
  },
  {
    Header: () => null,
    id: "expander",
    Cell: ({ row }) => (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <Icon>
            <FontAwesomeIcon icon="caret-down" />
          </Icon>
        ) : (
          <Icon>
            <FontAwesomeIcon icon="caret-right" />
          </Icon>
        )}
      </span>
    ),
    SubCell: () => null,
  },
  {
    accessor: "employeeNumber",
    Header: "Employee #",
    Cell: ({ cell }) => <span>{cell.value?.trim()}</span>,
  },
  {
    id: "firstName",
    accessor: "firstName",
    Header: "First Name",
  },
  {
    id: "lastName",
    accessor: "lastName",
    Header: "Last Name",
  },
  {
    accessor: "title",
    Header: "Job Title",
  },
  {
    accessor: "email",
    Header: "Email",
  },
];

export const licenseColumns = [
  {
    accessor: "codes.title",
    Header: "Title",
  },
  {
    accessor: "codes.level",
    Header: "Level",
  },
  {
    accessor: "codes.state",
    Header: "State",
  },
  {
    accessor: "licenseNumber",
    Header: "State ID",
  },
  {
    accessor: "issueDate",
    Header: "Issued",
    Cell: ({ value }) => (value ? format(new Date(value), "y-MM-dd") : "--"),
  },
  {
    accessor: "expDate",
    Header: "Expires",
    Cell: ({ value }) => (value ? format(new Date(value), "y-MM-dd") : "--"),
  },
];

export const certColumns = [
  {
    accessor: "codes.title",
    Header: "Title",
  },
  {
    accessor: "certificationNumber",
    Header: "State ID",
  },
  {
    accessor: "issueDate",
    Header: "Issued",
    Cell: ({ value }) => (value ? format(new Date(value), "y-MM-dd") : "--"),
  },
  {
    accessor: "expDate",
    Header: "Expires",
    Cell: ({ value }) => (value ? format(new Date(value), "y-MM-dd") : "--"),
  },
];

export const skillColumns = [
  {
    accessor: "codes.title",
    Header: "Title",
  },
  {
    accessor: "issueDate",
    Header: "Issued",
    Cell: ({ value }) => (value ? format(new Date(value), "y-MM-dd") : "--"),
  },
];

export const courseColumns = [
  {
    accessor: "codes.title",
    Header: "Title",
  },
  {
    accessor: "issueDate",
    Header: "Completed",
    Cell: ({ value }) => (value ? format(new Date(value), "y-MM-dd") : "--"),
  },
];

export default columns;
