import { format } from "date-fns";
import { uppercaseFirstLetter } from "../../utils";

export const EXP_LICENSES = [
  {
    Header: "Expiring",
    id: "expDate",
    accessor: "expDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell?.value), "MM/dd/yy") : "--",
  },
  {
    Header: "Title",
    id: "title",
    accessor: "codes.title",
    // title, state, level
    Cell: ({ cell }) => {
      const { codes } = cell?.row?.original;
      return `${codes?.title} - ${codes?.level} - ${codes?.state} `;
    },
  },
  {
    Header: "License Number",
    id: "licenseNumber",
    accessor: "licenseNumber",
  },
  {
    Header: "Employee Number",
    id: "employeeNumber",
    accessor: "users.employeeNumber",
  },
  {
    Header: "First Name",
    id: "firstName",
    accessor: "users.firstName",
    Cell: ({ cell }) => {
      const { users } = cell?.row?.original;
      return `${uppercaseFirstLetter(users?.firstName)}`;
    },
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "users.lastName",
    Cell: ({ cell }) => {
      const { users } = cell?.row?.original;
      return `${uppercaseFirstLetter(users?.lastName)} `;
    },
  },

  {
    Header: "Email",
    id: "email",
    accessor: "users.email",
    Cell: ({ cell }) => (cell?.value ? cell?.value.toLowerCase() : ""),
  },
];

export const EXP_CERTIFICATIONS = [
  {
    Header: "Expiring",
    id: "expDate",
    accessor: "expDate",
    Cell: ({ cell }) =>
      cell?.value ? format(new Date(cell?.value), "MM/dd/yy") : "--",
  },
  {
    Header: "Title",
    id: "title",
    accessor: "codes.title",
  },
  {
    Header: "First Name",
    id: "FirstName",
    accessor: "users.firstName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Last Name",
    id: "LastName",
    accessor: "users.lastName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Email",
    id: "email",
    accessor: "users.email",
    Cell: ({ cell }) => (cell?.value ? cell?.value.toLowerCase() : ""),
  },
  {
    Header: "Employee #",
    id: "employeeNumber",
    accessor: "users.employeeNumber",
  },
];

export const POINTS_COLUMNS = [
  {
    Header: "Type",
    id: "entity",
    accessor: "entity",
    Cell: ({ cell }) =>
      cell?.value === "deccoUCourses"
        ? "DECCOU Courses"
        : uppercaseFirstLetter(cell?.value),
  },
  {
    Header: "Title",
    id: "title",
    accessor: "title",
  },
  {
    Header: "State",
    id: "state",

    Cell: ({ cell }) =>
      cell?.value ? uppercaseFirstLetter(cell?.value) : "N/A",
  },
  {
    Header: "Level",
    id: "level",
    accessor: "level",
    Cell: ({ cell }) =>
      cell?.value ? uppercaseFirstLetter(cell?.value) : "N/A",
  },
  {
    Header: "Description",
    id: "description",
    accessor: "description",
  },

  {
    Header: "Points",
    id: "points",
    accessor: "points",
  },
  {
    Header: "Expiration Type",
    id: "expirationType",
    accessor: "expirationType",
    Cell: ({ cell }) =>
      cell?.value ? uppercaseFirstLetter(cell?.value) : "None",
  },
];

export const COST_CODE_COLUMNS = [
  {
    Header: "Employee Number",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "First Name",
    id: "firstName",
    accessor: "firstName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "lastName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Cost Code",
    id: "costCode",
    accessor: "costCode",
  },
  {
    Header: "Job Title",
    id: "jobTitle",
    accessor: "jobTitle",
  },
  {
    Header: "Review Group",
    id: "group",
    accessor: "group",
  },
];

export const EMPLOYEE_PERMISSIONS_COLUMNS = [
  {
    Header: "Employee Number",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "First Name",
    id: "firstName",
    accessor: "firstName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "lastName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Permission Level",
    id: "userType",
    accessor: "userType",
  },
];

export const EMPLOYEE_POINTS_COLUMNS = [
  {
    Header: "First Name",
    id: "firstName",
    accessor: "firstName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Last Name",
    id: "lastName",
    accessor: "lastName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Em ID",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "Group",
    id: "group",
    accessor: "group",
  },
  {
    Header: "Lic",
    id: "licPoints",
    accessor: "licPoints",
  },
  {
    Header: "Cert",
    id: "cerPoints",
    accessor: "cerPoints",
  },
  {
    Header: "Skill",
    id: "skillPoints",
    accessor: "skillPoints",
  },
  {
    Header: "Total",
    id: "points",
    accessor: "points",
  },
];

