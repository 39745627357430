import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_REVIEWS = gql`
  query Reviews(
    $where: ReviewWhereInput
    $take: Int
    $skip: Int
    $orderBy: [ReviewOrderByWithRelationInput!]
    $sectionsOrderBy: [EvaluationSectionOrderByWithRelationInput!]
    $questionsOrderBy: [EvaluationQuestionOrderByWithRelationInput!]
  ) {
    reviews(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      manager
      title
      year
      templateVersionId
      dateCompleted
      dateToCompleteBy
      status
      userId
      user {
        id
        firstName
        lastName
      }
      evaluations {
        id
        title
        dateCompleted
        dateToCompleteBy
        assignmentDate
        manager
        templateVersionId
        owner
        author
        authorHidden
        needsAssignment
        status
        reviewId
        formAuthor
        formAuthorEmployeeNumber
        sections(orderBy: $sectionsOrderBy) {
          id
          title
          instructions
          order
          questions(orderBy: $questionsOrderBy) {
            id
            text
            order
            answer
            answerType
            answerOptions {
              id
              value
              name
            }
          }
        }
      }
    }
    aggregateReview(where: $where) {
      _count {
        _all
      }
    }
  }
`;

export const GET_REVIEW_IDS = gql`
  query Reviews($where: ReviewWhereInput) {
    reviews(where: $where) {
      id
    }
  }
`;

export const GET_USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      firstName
      lastName
    }
  }
`;
