import { uppercaseFirstLetter } from "../../../../utils";

const YOS_COLUMNS = [
  {
    Header: "Employee #",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "Last Name",
    id: "LastName",
    accessor: "lastName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "First Name",
    id: "FirstName",
    accessor: "firstName",
    Cell: ({ cell }) => (cell?.value ? uppercaseFirstLetter(cell?.value) : ""),
  },
  {
    Header: "Email",
    id: "email",
    accessor: "email",
  },
  {
    Header: "Years of Service",
    id: "totalYearsOfService",
    accessor: "totalYearsOfService",
  },
  {
    Header: "Notes",
    id: "yearsOfServiceNote",
    accessor: "yearsOfServiceNote",
  },
  {
    Header: "Cost Code",
    id: "costCode",
    accessor: "costCode",
  },
  {
    Header: "Review Group",
    id: "group",
    accessor: "group",
  },
];

export default YOS_COLUMNS;
