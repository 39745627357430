import { gql } from "@apollo/client";

export const SINGLE_EMPLOYEE_RELATION_QUERY = gql`
  query SINGLE_EMPLOYEE_RELATION_QUERY(
    $where: EmployeeRelationsWhereUniqueInput!
  ) {
    findUniqueEmployeeRelations(where: $where) {
      id
      group
      title
      supervisor
    }
  }
`;
export const FIND_FIRST_EMPLOYEE_RELATION_QUERY = gql`
  query FIND_FIRST_EMPLOYEE_RELATION_QUERY(
    $where: EmployeeRelationsWhereInput!
  ) {
    findFirstEmployeeRelations(where: $where) {
      id
      group
      title
      supervisor
    }
  }
`;

export const ALL_EMPLOYEE_RELATIONS_QUERY = gql`
  query ALL_EMPLOYEE_RELATIONS_QUERY(
    $where: EmployeeRelationsWhereInput
    $orderBy: [EmployeeRelationsOrderByWithRelationInput!]
    $cursor: EmployeeRelationsWhereUniqueInput
    $take: Int
    $skip: Int
    $distinct: [EmployeeRelationsScalarFieldEnum!]
  ) {
    findManyEmployeeRelations(
      where: $where
      orderBy: $orderBy
      cursor: $cursor
      take: $take
      skip: $skip
      distinct: $distinct
    ) {
      id
      group
      title
      supervisor
    }
  }
`;
