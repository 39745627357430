import React, { useEffect } from "react";

import { Switch, Route, Redirect } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";

import Navigation from "../components/Navigation";

import { useAuth } from "../context";
import { ModalProvider } from "../context/ModalContext";

import BaseballCard from "./BaseballCard";
import Dashboard from "./Dashboard";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import Login from "./Login";
import PartnerServices from "./PartnerServices";
import Settings from "./Settings";
import EmailConfirmation from "./EmailConfirmation";
import BackgroundImage from "./BackgroundImage";
import ProtectedRoute from "../components/ProtectedRoutes/ProtectedRoutes";
import Projects from "./Projects";
import Project from "./Project";
import EmployeeAssignments from "./EmployeeAssignments";
import Contractors from "./Contractors";
import Signup from "./Signup";
import LoadingPage from "./LoadingPage";
import Workshop from "./Workshop";
import Reviews from "./Reviews";
import Details from "./Details";
import AllReviews from "./AllReviews";
import AllEvaluations from "./AllEvaluations";
import ReviewCycle from "./ReviewCycle";
import Goals from "./Goals";
import AllGoals from "./AllGoals";
import AllWebEditorGoals from "./AllGoals/AllWebEditorGoals";

const { REACT_APP_ENV } = process.env;

// eslint-disable-next-line no-console
console.log(REACT_APP_ENV);

// leave out of stage and prod for now
// const isProduction =
//   REACT_APP_ENV === "production" || REACT_APP_ENV === "stage";

const Pages = () => {
  const { state: authState, handleLogout, handleCheckAuth } = useAuth();

  useEffect(() => {

    const handleCheckAuthFunc = async () => {
      await handleCheckAuth();
    };
    handleCheckAuthFunc();
  }, [handleCheckAuth]);

  useIdleTimer({
    timeout: 1000 * 60 * 60,
    debounce: 500,
    onIdle: handleLogout,
  });

  if (authState?.isLoginPending) {
    return <LoadingPage />;
  }

  return authState.isLoggedIn ? (
    <div className="app-container">
      <ModalProvider>
        <Navigation />
        <main className="main-container">
          <Switch>
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="/password-confirmation">
              <EmailConfirmation />
            </Route>

            <BackgroundImage>
              <Route path="/dashboard">
                <Dashboard />
              </Route>
              <ProtectedRoute
                allowedRoles={[
                  "fieldSupervisor",
                  "admin",
                  "superAdmin",
                  "webEditor",
                ]}
                component={PartnerServices}
                path="/partner-services"
              />
              <ProtectedRoute
                allowedRoles={[
                  "fieldSupervisor",
                  "admin",
                  "superAdmin",
                  "webEditor",
                  "user",
                ]}
                component={BaseballCard}
                path="/baseball-card/:id"
              />
              <ProtectedRoute
                allowedRoles={["superAdmin", "webEditor"]}
                component={Settings}
                path="/settings"
              />
              <ProtectedRoute
                allowedRoles={["superAdmin", "webEditor"]}
                component={Projects}
                path="/projects"
              />
              <ProtectedRoute
                allowedRoles={["superAdmin", "webEditor"]}
                component={Project}
                path="/project/:projectId"
              />
              <ProtectedRoute
                allowedRoles={["superAdmin", "webEditor"]}
                component={EmployeeAssignments}
                path="/partner-assignments"
              />
              <ProtectedRoute
                allowedRoles={["superAdmin", "webEditor"]}
                component={Contractors}
                path="/contractor-assignments"
              />
              <React.Fragment>
                <Route path="/reviews">
                  <Reviews employeeNumber={authState?.user?.employeeNumber} />
                </Route>
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor"]}
                  component={Workshop}
                  path="/workshop"
                />
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor"]}
                  component={AllEvaluations}
                  path="/navigator/forms"
                />
                {/* evaluation summary page */}
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor"]}
                  component={Workshop}
                  path="/navigator/forms/:id"
                />
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor"]}
                  component={AllReviews}
                  path="/navigator/reviews"
                />
                {/* review summary page */}
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor"]}
                  component={Workshop}
                  path="/navigator/reviews/:id"
                />
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor"]}
                  component={Details}
                  path="/details"
                />
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor"]}
                  component={ReviewCycle}
                  path="/review-cycle"
                />
              </React.Fragment>
              {/* Goals */}
              <React.Fragment>
                <Route path="/goals/:id">
                  <Goals employeeNumber={authState?.user?.employeeNumber} />
                </Route>
                <ProtectedRoute
                  allowedRoles={["superAdmin", "webEditor", "admin", "fieldSupervisor"]}
                  component={AllGoals}
                  path="/partner-goals/:id"
                />
                <ProtectedRoute
                  allowedRoles={["webEditor"]}
                  component={AllWebEditorGoals}
                  path="/allUsersGoals"
                />
              </React.Fragment>
            </BackgroundImage>
          </Switch>
        </main>
      </ModalProvider>
    </div>
  ) : (
    <Switch>
      <Route path="/reset/:token">
        <ResetPassword />
      </Route>
      <Route path="/forgot">
        <ForgotPassword />
      </Route>
      <Route path="/signup">
        <Signup />
      </Route>
      <Route path="*">
        <Login />
      </Route>
    </Switch>
  );
};

Pages.propTypes = {};

export default Pages;
