import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import QualificationCodesForm from "../QualificationCodesForm";

import {
  CREATE_SYSTEM_CODE_MUTATION,
  UPDATE_SYSTEM_CODE_MUTATION,
  FIND_MANY_CODES_QUERY,
  FIND_FIRST_CODE,
  FIND_UNIQUE_CODE,
} from "../../../../graphql";

import { convertInputToVariables } from "../../../../utils";

const INITIAL_STATE = {
  title: "",
  points: "",
  expirationType: "none",
  entity: "",
  description: "",
  state: "",
  lookupCode: "",
  level: "",
};

const globalDisabledKeys = ["title", "points", "expirationType"];

class CustomQualificationCodeError extends Error {}
const QualificationCodeModal = ({ id, onComplete, qualification }) => {
  const client = useApolloClient();

  const [inputs, setInputs] = useState({ ...INITIAL_STATE });
  const [emplouyeesUsingCode, setEmployeesUsingCode] = useState([]);

  useEffect(() => {
    setInputs((prev) => ({ ...prev, entity: qualification }));
  }, [qualification]);

  const [createQualificationCode, { loading: CreateEmployeeRelationLoading }] =
    useMutation(CREATE_SYSTEM_CODE_MUTATION);
  const [updateQualificationCode, { loading: UpdateEmployeeRelationLoading }] =
    useMutation(UPDATE_SYSTEM_CODE_MUTATION);

  const [
    findUniqueQualificationCode,
    {
      data: QualificationCodeData,
      loading: FindUniqueEmployeeeRelationLoading,
    },
  ] = useLazyQuery(FIND_UNIQUE_CODE);

  useEffect(() => {
    if (QualificationCodeData?.findUniqueCodes) {
      // entity is licenses, skills, certifications, or deccoUCourses
      const entity = QualificationCodeData?.findUniqueCodes?.entity;

      if (entity) {
        const empsUsingCode = QualificationCodeData?.findUniqueCodes?.[entity]
          ?.map((qual) => qual?.users?.employeeNumber?.trim())
          .filter(Boolean);

        setEmployeesUsingCode(empsUsingCode);
      }
    }
  }, [QualificationCodeData]);

  useEffect(() => {
    if (id) {
      findUniqueQualificationCode({
        variables: { where: { id } },
      });
    }
  }, [id, findUniqueQualificationCode]);

  useEffect(() => {
    const qualificationCode = QualificationCodeData?.findUniqueCodes;

    if (qualificationCode) {
      setInputs((prev) => ({
        ...prev,
        points: qualificationCode.points || "",
        title: qualificationCode.title || "",
        expirationType: qualificationCode.expirationType || "",
        description: qualificationCode.description || "",
        state: qualificationCode.state || "",
        lookupCode: qualificationCode.lookupCode || "",
        level: qualificationCode.level || "",
      }));
    }
  }, [QualificationCodeData]);

  const handleSave = async (e) => {
    try {
      e.preventDefault();
      const isAdding = !id;

      if(inputs.entity === "licenses") {
        const { data: findFirstCode } = await client.query({
          query: FIND_FIRST_CODE,
          variables: {
            where: {
              title: { equals: inputs.title },
              level: { equals: inputs.level },
              state: { equals: inputs.state },
            },
          },
        });

      const existingQualificationCode = findFirstCode?.findFirstCodes;

      if (existingQualificationCode && existingQualificationCode.id !== id) {
        throw new CustomQualificationCodeError(
          "This qualification already exists."
        );
      }
    } else {
      const { data: findFirstCode } = await client.query({
        query: FIND_FIRST_CODE,
        variables: {
          where: {
            title: { equals: inputs.title },
          },
        },
      });

      const existingQualificationCode = findFirstCode?.findFirstCodes;

      if (existingQualificationCode && existingQualificationCode.id !== id) {
        throw new CustomQualificationCodeError(
          "This qualification already exists."
        );
      }
    }

      const data = convertInputToVariables(
        inputs,
        Object.keys(INITIAL_STATE),
        isAdding
      );

      if (isAdding) {
        data.title = data.title.trim();
        await createQualificationCode({
          variables: {
            data: {
              ...data,
            },
          },
          refetchQueries: [
            {
              query: FIND_MANY_CODES_QUERY,
              variables: {
                orderBy: [
                  {
                    entity: "asc",
                  },
                ],
                where: {
                  entity: {
                    equals: qualification,
                  },
                },
              },
            },
          ],
        });
      } else {
        data.title.set = data.title.set.trim();
        await updateQualificationCode({
          variables: {
            where: { id },
            data: {
              ...data,
            },
          },
          refetchQueries: [
            {
              query: FIND_MANY_CODES_QUERY,
              variables: {
                orderBy: [
                  {
                    entity: "asc",
                  },
                ],
                where: {
                  entity: {
                    equals: qualification,
                  },
                },
              },
            },
          ],
        });
      }

      toast.success(
        `${qualification} ${isAdding ? "created" : "edited"} successfully.`
      );
      onComplete();
    } catch (err) {
      const message =
        err instanceof CustomQualificationCodeError
          ? err.message
          : `Error ${
              id ? "updating" : "creating"
            } employeee relation. Please contact support.`;
      toast.error(message);
    }
  };

  const handleChange = (name, value) => {
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const isLoading =
    FindUniqueEmployeeeRelationLoading ||
    CreateEmployeeRelationLoading ||
    UpdateEmployeeRelationLoading;

  const isDisabled = useMemo(
    () =>
      isLoading ||
      Object.keys(inputs).some(
        (key) => globalDisabledKeys.includes(key) && inputs[key] === ""
      ),

    [inputs, isLoading]
  );

  return (
    <form id="company-form" onSubmit={handleSave}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title className="qual-form-title" size={5}>
            {[
              id ? "Edit" : "Create",
              qualification === "deccoUCourses"
                ? "COURSES Code"
                : `${qualification.toUpperCase()} Code`,
            ].join(" ")}
          </Title>
        </div>
        <div className="modal-head-end">
          <Button.Group hasAddons>
            <Button
              size="small"
              type="button"
              onClick={() => onComplete(false)}
            >
              <span>Cancel</span>
            </Button>
            <Button
              color="primary"
              disabled={isLoading || isDisabled}
              form="company-form"
              size="small"
              state={isLoading ? "loading" : ""}
              type="submit"
            >
              <span>Save</span>
            </Button>
          </Button.Group>
        </div>
      </header>
      <hr />
      <QualificationCodesForm
        disabled={isLoading}
        empsUsingCode={emplouyeesUsingCode}
        inputs={inputs}
        isAdding={!id}
        qualification={qualification}
        onChange={handleChange}
      />
    </form>
  );
};

QualificationCodeModal.propTypes = {
  id: PropTypes.string,
  onComplete: PropTypes.func,
  qualification: PropTypes.string.isRequired,
};

QualificationCodeModal.defaultProps = {
  id: "",
  onComplete: () => {},
};

export default QualificationCodeModal;
