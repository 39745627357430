import { gql } from "@apollo/client";

export const CREATE_DOCUMENT_MUTATION = gql`
  mutation CreateManyCertifications($data: DocumentsCreateInput!) {
    createOneDocuments(data: $data) {
      id
    }
  }
`;

export const DELETE_DOCUMENT_MUTATION = gql`
  mutation CreateManyCertifications($where: DocumentsWhereUniqueInput!) {
    deleteOneDocuments(where: $where) {
      id
    }
  }
`;
