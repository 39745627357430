import React, { useEffect, useState } from "react";
import { Title, Button } from "rbx";
import PropTypes from "prop-types";
import { Typography } from "@mui/material";
import GoalSection from "./components/GoalSection/GoalSection";
import GoalForm from "./components/GoalForm/GoalForm";
import { useModal, useAuth } from "../../context";
import { usePermissions } from "../../hooks";
import capitalizeEveryWord from "../../utils/capitalizeEveryWord";
import "./Goals.scss";

import checkMobile from "../../utils/checkForMobile";

const IncompleteGoals = ({
  goals,
  employeeNumber,
  loading,
  setGoals,
  refetchMyGoals,
  userData,
  handleOpenGoalAdd,
}) => {
  const permissionLevels = usePermissions();
  const isMobile = checkMobile();
  const [permissions, setPermissions] = useState(null);

  const auth = useAuth();
  const activeUserEmployeeNumber = auth?.state?.user?.employeeNumber;

  const isUsersOwnGoals =
    activeUserEmployeeNumber.trim() === employeeNumber.trim();

  const showManagerReviews =
    permissions?.webEditor ||
    permissions?.superAdmin ||
    permissions?.admin ||
    permissions?.fieldSupervisor;

  const { setModalOpen } = useModal();

  const handleComplete = async () => {
    setModalOpen(false);
    await refetchMyGoals();
  };

  const incompleteGoals = goals.filter((goal) => goal.status === "Active");

  useEffect(() => {
    if (permissionLevels) {
      setPermissions(permissionLevels);
    }
  }, [permissionLevels]);

  const handleOpenGoal = (
    goal,
    goalIndex,
    isCompletedSection,
    type = "view"
  ) => {
    setModalOpen(
      true,
      <GoalForm
        currentGoalId={goal?.id}
        employeeNumber={employeeNumber}
        goal={goal}
        goals={goals}
        isCompletedSection={isCompletedSection}
        loading={loading}
        permissions={permissionLevels}
        refetchMyGoals={refetchMyGoals}
        type={type}
        onCanceled={() => setModalOpen(false)}
        onComplete={handleComplete}
      />
    );
  };

  return (
    <div
      className={
        showManagerReviews
          ? "reviews-outer-container-manager-goals"
          : "reviews-outer-container-goals"
      }
    >
      <div className="reviews-container-section">
        <div className="reviews">
          <div className="add-button-container">
            <Button
              className="review-option-primary"
              style={{ marginBottom: "1.5rem", padding: "0.5rem" }}
              type="button"
              onClick={() => handleOpenGoalAdd()}
            >
              <Title size={6} textColor="white">
                Add Goal
              </Title>
            </Button>
          </div>
          {incompleteGoals.length ? (
            <React.Fragment>
              <div className="centered-title" id="employee-reviews">
                <Typography variant={isMobile ? "h4" : "h3"}>
                  {capitalizeEveryWord(
                    `${userData?.firstName} ${userData?.lastName} Active Goals`
                  )}
                </Typography>
              </div>
              <GoalSection
                goals={goals}
                handleClick={handleOpenGoal}
                handleOpenGoalAdd={handleOpenGoalAdd}
                isUsersOwnGoals={isUsersOwnGoals}
                sectionType="personal"
                setGoals={setGoals}
                showManagerReviews={showManagerReviews}
              />
            </React.Fragment>
          ) : (
            <div className="centered-title">
              <Typography variant={isMobile ? "h4" : "h3"}>
                Nice! All your goals are completed
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

IncompleteGoals.propTypes = {
  goals: PropTypes.array.isRequired,
  employeeNumber: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  setGoals: PropTypes.func.isRequired,
  refetchMyGoals: PropTypes.func,
  userData: PropTypes.object.isRequired,
  handleOpenGoalAdd: PropTypes.func.isRequired,
};

IncompleteGoals.defaultProps = {
  refetchMyGoals: () => {},
};

export default IncompleteGoals;
