import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Box, Paper, Typography, useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import PartnerServicesRowDetails from "./PartnerServicesRowDetails";

import {
  certColumns,
  courseColumns,
  licenseColumns,
  skillColumns,
} from "./constants";

const sections = ["Skills", "Licences", "Certs", "DECCOU"];

const MobilePartnerServicesCard = ({ data }) => {
  const theme = useTheme();

  const [activeSection, setActiveSection] = useState(0);

  const handleNext = () => {
    if (activeSection + 1 > sections.length - 1) {
      setActiveSection(0);
    } else {
      setActiveSection((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeSection - 1 < 0) {
      setActiveSection(sections.length - 1);
    } else {
      setActiveSection((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getActiveTable = (title, item) => {
    if (title === "Skills") {
      return (
        <PartnerServicesRowDetails
          columns={skillColumns}
          data={item?.skills}
          title={title}
        />
      );
    }
    if (title === "Licences") {
      return (
        <PartnerServicesRowDetails
          columns={licenseColumns}
          data={item?.licenses}
          title={title}
        />
      );
    }
    if (title === "Certs") {
      return (
        <PartnerServicesRowDetails
          columns={certColumns}
          data={item?.certifications}
          title={title}
        />
      );
    }
    if (title === "DECCOU") {
      return (
        <PartnerServicesRowDetails
          columns={courseColumns}
          data={item?.deccoUCourses}
          title={title}
        />
      );
    }
    return null;
  };

  return (
    <Box mt="1rem" sx={{ maxWidth: "100%", flexGrow: 1 }}>
      <Paper
        square
        elevation={4}
        sx={{
          textAlign: "center",
          bgcolor: theme.palette.grey[400],
          paddingTop: "0.5rem",
        }}
      >
        <Box alignItems="center" display="flex" justifyContent="center">
          <Typography variant="subtitle3">{sections[activeSection]}</Typography>
        </Box>

        <Box
          sx={{
            height: "17rem",
            maxWidth: 400,
            width: "100%",
            p: 2,
          }}
        >
          {getActiveTable(sections[activeSection], data)}
        </Box>
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          pb={2}
          pl={2}
          pr={2}
        >
          <Button size="small" style={{width: "45%", marginRight: "5%"}} variant="outlined" onClick={handleBack}>
            {theme.direction === "rtl" ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            {activeSection - 1 < 0
              ? sections[sections.length - 1]
              : sections[activeSection - 1]}
          </Button>
          <Button size="small" style={{width: "45%", marginLeft: "5%"}} variant="outlined" onClick={handleNext}>
            {activeSection + 1 >= sections.length
              ? sections[0]
              : sections[activeSection + 1]}
            {theme.direction === "rtl" ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

MobilePartnerServicesCard.propTypes = {
  data: PropTypes.object,
};

MobilePartnerServicesCard.defaultProps = {
  data: {},
};

export default MobilePartnerServicesCard;
