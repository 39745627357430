import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const ALL_EMPLOYEE_TITLES_QUERY = gql`
  query ALL_EMPLYEE_TITLES_QUERY(
    $distinct: [EmployeeScalarFieldEnum!]
    $orderBy: [EmployeeOrderByWithRelationInput!]
  ) {
    employees(distinct: $distinct, orderBy: $orderBy) {
      jobTitle
    }
  }
`;

export const ALL_EMPLOYEE_JOB_TITLES_QUERY = gql`
  query ALL_EMPLOYEE_JOB_TITLES_QUERY(
    $where: UserWhereInput
    $distinct: [UserScalarFieldEnum!]
    $orderBy: [UserOrderByWithRelationInput!]
  ) {
    users(where: $where, distinct: $distinct, orderBy: $orderBy) {
      jobTitle
    }
  }
`;
