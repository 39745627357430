import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_REVIEWS = gql`
  query ReviewTemplates($where: ReviewTemplateWhereInput) {
    reviewTemplates(where: $where) {
      id
      year
      title
      _count {
        evaluationTemplates
      }
      evaluationTemplates {
        title
        year
        id
        sections {
          title
          id
          order
          questions {
            id
            order
            answer
            answerType
            answerOptions {
              name
              id
            }
            text
          }
        }
      }
    }
  }
`;

export const GET_ALL_REVIEW_CYCLES = gql`
  query ReviewCycles(
    $where: ReviewCycleWhereInput
    $take: Int
    $skip: Int
    $orderBy: [ReviewCycleOrderByWithRelationInput!]
  ) {
    reviewCycles(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      groupCycle
      id
      name
      startDate
      templateId
    }
    aggregateReviewCycle(where: $where) {
      _count {
        _all
      }
    }
  }
`;
