import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";
import { useQuery } from "@apollo/client";
import { GET_EMPLOYEES_WITH_GROUP } from "../../graphql";
import { uppercaseFirstLetter } from "../../utils";

const EmployeeSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  getValue,
  setValue,
  showBlank,
  showAll,
}) => {
  const { data: employeesData, loading: employeeDataLoading } = useQuery(
    GET_EMPLOYEES_WITH_GROUP,
    {
      variables: {},
    }
  );

  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);

  const employees = employeesData?.getEmployeesWithGroup;

  const handleChange = (e) => {
    onChange(e.target.name, setValue(e.target.value));
  };

  useEffect(() => {
    if (
      employees &&
      employees?.length > 0 &&
      !value &&
      !showBlank &&
      !showAll
    ) {
      onChange(name, `${employees[0]?.firstName} ${employees[0]?.lastName}`);
    }
  }, [
    employees,
    employees?.length,
    getValue,
    name,
    onChange,
    showAll,
    showBlank,
    value,
  ]);

  return (
    <Control size={size}>
      {label && (
        <Label>
          {`${label}`}
          {required && `*`}
        </Label>
      )}
      <Select.Container
        fullwidth
        size={size}
        state={employeeDataLoading || Array.isArray(employees) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={getValue(value)}
          onChange={handleChange}
        >
          {showBlank && <Select.Option value="" />}
          {showAll && <Select.Option value="">All</Select.Option>}
          {Array.isArray(employees) &&
            employees.map(({ employeeNumber, firstName, lastName }) => (
              <Select.Option
                key={employeeNumber}
                value={`${firstName} ${lastName}`}
              >
                {`${uppercaseFirstLetter(firstName)} ${uppercaseFirstLetter(
                  lastName
                )}`}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

EmployeeSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
  showAll: PropTypes.bool,
};

EmployeeSelect.defaultProps = {
  size: "normal",
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  showBlank: false,
  showAll: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};

export default EmployeeSelect;
