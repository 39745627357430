import { gql } from "@apollo/client";

export const CREATE_SYSTEM_CODE_MUTATION = gql`
  mutation CreateOneCodes($data: CodesCreateInput!) {
    createOneCodes(data: $data) {
      id
    }
  }
`;

export const UPDATE_SYSTEM_CODE_MUTATION = gql`
  mutation UpdateOneCodes(
    $data: CodesUpdateInput!
    $where: CodesWhereUniqueInput!
  ) {
    updateOneCodes(data: $data, where: $where) {
      id
    }
  }
`;

export const DELETE_SYSTEM_CODE_MUTATION = gql`
  mutation DeleteOneCodes($where: CodesWhereUniqueInput!) {
    deleteOneCodes(where: $where) {
      id
    }
  }
`;
