import React from "react";

import PropTypes from "prop-types";
import { Box, TextField, useTheme } from "@mui/material";
import {
  EmployeeJobTitleSelectMUI,
  GenericSelectMUI,
  QualificationSelectMUI,
} from "../../../components";
import { debounce } from "../../../utils";
import capitalizeEveryWord from "../../../utils/capitalizeEveryWord";

const PartnerFilters = ({ where, setWhere }) => {
  const handleChange = (name, value) => {
    if (
      !value &&
      name !== "licenses" &&
      name !== "licenseState" &&
      name !== "licenseLevel"
    ) {
      if (name === "name") {
        setWhere((prev) => {
          const { OR, ...rest } = prev;

          return rest;
        });
      } else {
        setWhere((prev) => {
          const { [name]: _, ...rest } = prev;

          return rest;
        });
      }
    } else if (name === "employmentStatus") {
      setWhere((prev) => ({
        ...prev,
        employmentStatus: {
          equals: value,
        },
      }));
    } else if (name === "name") {
      setWhere((prev) => ({
        ...prev,
        OR: [
          {
            firstName: {
              startsWith: value,
              mode: "insensitive",
            },
          },
          {
            lastName: {
              startsWith: value,
              mode: "insensitive",
            },
          },
        ],
      }));
    } else if (name === "jobTitle") {
      setWhere((prev) => ({
        ...prev,
        jobTitle: {
          startsWith: value,
          mode: "insensitive",
        },
      }));
    } else if (name === "employeeNumber") {
      setWhere((prev) => ({
        ...prev,
        employeeNumber: {
          contains: value,
        },
      }));
    } else if (
      name === "skills" ||
      name === "certifications" ||
      name === "deccoUCourses"
    ) {
      setWhere((prev) => ({
        ...prev,
        [name]: {
          some: {
            codes: {
              is: {
                title: {
                  startsWith: value,
                  mode: "insensitive",
                },
              },
            },
          },
        },
      }));
    } else if (
      name === "licenses" ||
      name === "licenseState" ||
      name === "licenseLevel"
    ) {
      const licenseAnd = [
        {
          title: {
            startsWith:
              where?.licenses?.some?.codes?.is?.AND[0]?.title?.startsWith || "",
            mode: "insensitive",
          },
        },
        {
          state: {
            startsWith:
              where?.licenses?.some?.codes?.is?.AND[1]?.state?.startsWith || "",
            mode: "insensitive",
          },
        },
        {
          level: {
            startsWith:
              where?.licenses?.some?.codes?.is?.AND[2]?.level?.startsWith || "",
            mode: "insensitive",
          },
        },
      ];

      if (name === "licenses") {
        licenseAnd[0].title.startsWith = value || "";
      } else if (name === "licenseState") {
        licenseAnd[1].state.startsWith = value || "";
      } else if (name === "licenseLevel") {
        licenseAnd[2].level.startsWith = value || "";
      }

      setWhere((prev) => ({
        ...prev,
        licenses: {
          some: {
            codes: {
              is: {
                AND: licenseAnd,
              },
            },
          },
        },
      }));
    }
  };

  const theme = useTheme();

  return (
    <Box
      display="grid"
      gap={2}
      sx={{
        width: "100%",
        paddingLeft: "2rem",
        paddingRight: "2rem",
        [theme.breakpoints.down("sm")]: {
          gridTemplateColumns: "1fr",
        },
        [theme.breakpoints.up("sm")]: {
          gridTemplateColumns: "1fr 1fr",
        },
        [theme.breakpoints.up("md")]: {
          gridTemplateColumns: "1fr 1fr 1fr",
        },
      }}
    >
      <GenericSelectMUI
        showAllOption
        getValue={(x) =>
          x?.label === "All"
            ? { value: "", label: x }
            : { label: capitalizeEveryWord(x), value: x }
        }
        label="Employment Status"
        name="employmentStatus"
        options={[
          { value: "active", title: "Active", id: "active" },
          { value: "former", title: "Former", id: "former" },
        ]}
        value={where?.employmentStatus?.equals}
        onChange={(name, value) => {
          handleChange(name, value);
        }}
      />

      <TextField
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        label="Employee Number"
        name="employeeNumber"
        size="medium"
        value={where?.employeeNumber?.startsWith}
        onChange={debounce(({ target: { name, value } }) => {
          handleChange(name, value);
        }, 500)}
      />

      <TextField
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        label="Name"
        name="name"
        size="medium"
        value={where?.name?.startsWith}
        onChange={debounce(({ target: { name, value } }) => {
          handleChange(name, value);
        }, 500)}
      />

      <EmployeeJobTitleSelectMUI
        showAllOption
        label="Job Title"
        name="jobTitle"
        value={where?.jobTitle?.startsWith}
        onChange={(name, value) => handleChange(name, value)}
      />

      <QualificationSelectMUI
        showAll
        entity="certifications"
        label="Certification"
        name="certifications"
        placeholder="certifications"
        size="medium"
        value={where?.certifications?.some?.codes?.is?.title?.startsWith}
        onChange={(name, value) => {
          handleChange(name, value);
        }}
      />
      <QualificationSelectMUI
        showAll
        entity="skills"
        label="Skill"
        name="skills"
        placeholder="skills"
        size="medium"
        value={where?.skills?.some?.codes?.is?.title?.startsWith}
        onChange={(name, value) => {
          handleChange(name, value);
        }}
      />

      <QualificationSelectMUI
        showAll
        entity="deccoUCourses"
        label="DECCOU Course"
        name="deccoUCourses"
        placeholder="DECCOU Courses"
        size="medium"
        value={where?.deccoUCourses?.some?.codes?.is?.title?.startsWith}
        onChange={(name, value) => {
          handleChange(name, value);
        }}
      />

      <QualificationSelectMUI
        showAll
        entity="licenses"
        label="License"
        name="licenses"
        size="medium"
        value={where?.licenses?.some?.codes?.is?.AND?.[0]?.title?.startsWith}
        onChange={(name, value) => {
          handleChange(name, value);
        }}
      />

      <GenericSelectMUI
        showAll
        label="License State"
        name="licenseState"
        options={[
          { value: "MA", title: "MA", id: "MA" },
          { value: "NH", title: "NH", id: "NH" },
          { value: "RI", title: "RI", id: "RI" },
          { value: "CT", title: "CT", id: "CT" },
        ]}
        size="medium"
        value={where?.licenses?.some?.codes?.is?.AND?.[1].state?.startsWith}
        onChange={(name, value) => {
          handleChange(name, value);
        }}
      />

      <GenericSelectMUI
        showAll
        label="License Level"
        name="licenseLevel"
        options={[
          { value: "Apprentice", title: "Apprentice", id: "Apprentice" },
          { value: "Journeyman", title: "Journeyman", id: "Journeyman" },
          { value: "Master", title: "Master", id: "Master" },
        ]}
        value={where?.licenses?.some?.codes?.is?.AND?.[2].level?.startsWith}
        onChange={(name, value) => {
          handleChange(name, value);
        }}
      />
    </Box>
  );
};

PartnerFilters.propTypes = {
  setWhere: PropTypes.func,
  where: PropTypes.object,
};

PartnerFilters.defaultProps = {
  setWhere: () => null,
  where: {},
};

export default PartnerFilters;
