import React, { useEffect, useState } from "react";
import {
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  CalendarEvents,
  Resources,
  EmployeeRelations,
  MassUpdates,
  // MassUpdatesGoals,
  SettingsSync,
} from "../../components";
import { usePermissions } from "../../hooks";
import QualificationCodes from "../../components/QualificationCodes";

const Settings = () => {
  const [isAdmin, setIsAdmin] = useState(false);
  const permissionLevels = usePermissions();
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const sectionName = downSm ? "section-mobile" : "section";
  useEffect(() => {
    if (permissionLevels.webEditor) {
      setIsAdmin(true);
    }
  }, [permissionLevels, downSm]);
  return (
    <div className="settings-container">
      {isAdmin && (
        <React.Fragment>
          <SettingsSync />
          <div className={sectionName}>
            <CalendarEvents />
          </div>
          <div className={sectionName}>
            <Resources />
          </div>
          <div className={sectionName}>
            <EmployeeRelations />
          </div>
        </React.Fragment>
      )}
      {/* superAdmin can see qual codes */}
      <div className={sectionName}>
        <QualificationCodes isAdmin={isAdmin} />
      </div>
      {isAdmin && (
        <div className={sectionName}>
          <MassUpdates />
        </div>
      )}
      {/* {isAdmin && (
        <div className="section">
          <MassUpdatesGoals />
        </div>
      )} */}
    </div>
  );
};

Settings.propTypes = {};

export default Settings;
