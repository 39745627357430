import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const UPDATE_ONE_USER_GOAL = gql`
  mutation UpdateOneUserGoal(
    $data: UserGoalUpdateInput!
    $where: UserGoalWhereUniqueInput!
  ) {
    updateOneUserGoal(data: $data, where: $where) {
      id          
      title               
      description         
      status              
      startDate           
      dueDate             
      currentProgress  
      createdBy           
      createDate          
      updatedAt           
      updatedBy           
      updatedByFullName   
      evaluationSectionId              
      assignedUserId     
    }
  }
`;

export const DELETE_ONE_USER_GOAL = gql`
  mutation DeleteOneUserGoal(
    $where: UserGoalWhereUniqueInput!
  ) {
    deleteOneUserGoal(where: $where) {
      id           
    }
  }
`;

export const CREATE_ONE_USER_GOAL = gql`
  mutation CreateOneUserGoal($data: UserGoalCreateInput!) {
    createOneUserGoal(data: $data) {
      id          
      title               
      description         
      status              
      startDate           
      dueDate             
      currentProgress  
      createdBy           
      createDate          
      updatedAt           
      updatedBy           
      updatedByFullName   
      evaluationSectionId              
      assignedUserId      
    }
  }
`;
