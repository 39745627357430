import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_FULL_NAME = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      firstName
      lastName
    }
  }
`;
