/* eslint-disable no-plusplus */
import { toast } from "react-toastify";
import QRCode from "qrcode";

export { default as STATES } from "./states";

export const customToast = {
  success: (message) => {
    toast.success(message, { autoClose: 4000, pauseOnHover: false });
  },
  error: (message) => {
    toast.error(message, { autoClose: 4000 });
  },
  warning: (message) => {
    toast.warning(message, { autoClose: 4000 });
  },
};

export const omit = (obj, arr) =>
  Object.keys(obj)
    .filter((k) => !arr.includes(k))

    .reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});

export const isNull = (val) => val === null;
export const isUndefined = (val) => val === undefined;

export const isNil = (val) => isUndefined(val) || isNull(val);

export const convertInputToVariables = (
  variables,
  keys,
  adding = false,
  connectFields = []
) =>
  omit(
    keys.reduce((acc, curr) => {
      if (!isNil(variables[curr])) {
        acc[curr] = adding ? variables[curr] : { set: variables[curr] };
      }
      return acc;
    }, {}),
    connectFields
  );
export const formatCurrency = (n) => {
  if (n) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(n);
  }
  return "$0.00";
};

export const unmaskCurrency = (maskedValue = "") =>
  parseFloat(maskedValue.replace(/\D/g, "") || 0, 10) / 100;

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function foo(...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const deepOmit = (obj, keys) =>
  Array.isArray(obj)
    ? obj.map((val) => deepOmit(val, keys))
    : Object.keys(obj).reduce((acc, key) => {
        if (!keys.includes(key)) {
          const val = obj[key];
          acc[key] =
            typeof val === "object" && val !== null ? deepOmit(val, keys) : val;
        }
        return acc;
      }, {});

export const flattenObject = (obj, prefix = "") =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}.` : "";
    if (obj[k] !== null && typeof obj[k] === "object")
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

export const parseQueryResultWithColumns = (x, columns) => {
  const obj = flattenObject(x);
  return columns.reduce((acc, curr) => {
    if (
      obj[`${curr.accessor}`] === null ||
      typeof obj[`${curr.accessor}`] === "undefined" ||
      (typeof obj[`${curr.accessor}`] === "string" &&
        !obj[`${curr.accessor}`].length)
    ) {
      acc[curr.Header] = "";
    } else if (curr.Cell) {
      // refernece to row original fails so custom logic to support address export
      if(curr.accessor === "address"){
        acc[curr?.Header] = `${obj?.address}, ${obj?.state}, ${obj?.city}, ${obj?.zip}`;
      } else {
        
        const result = curr.Cell({
          cell: { value: obj[`${curr.accessor}`], row: { original: x } },
        });
  
        acc[curr.Header] =
          // if Cell fn returns a react element
          result && typeof result.type === "function"
            ? result.props.value
            : result;
      }
    } else if (
      typeof obj[`${curr.accessor}`] === "string" &&
      obj[`${curr.accessor}`].includes(",")
    ) {
      acc[curr.Header] = String(obj[`${curr.accessor}`]);
    } else {
      acc[curr.Header] = obj[`${curr.accessor}`];
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key] && typeof obj[key] === "string") {
        obj[key] = obj[key]
          .replace(/{|}|\[|]|"|'/g, "")
          .replace(/,/g, "\u2028")
          .replace(/:/g, ": ");
      }
    });

    return acc;
  }, {});
};

export const parseQueryResultWithColumnsAlt = (x, columns) => {
  const obj = flattenObject(x);
  return columns.reduce((acc, curr) => {
    if (
      obj[`${curr.accessor}`] === null ||
      typeof obj[`${curr.accessor}`] === "undefined" ||
      (typeof obj[`${curr.accessor}`] === "string" &&
        !obj[`${curr.accessor}`].length)
    ) {
      acc[curr.Header] = "";
    } else if (curr.Cell) {
      // refernece to row original fails so custom logic to support address export
      if(curr.accessor === "address"){
        acc[curr.Header] = `${obj.address}, ${obj.state}, ${obj.city}, ${obj.zip}`;
      } else {
        
        const result = curr.Cell({
          cell: { value: obj[`${curr.accessor}`], row: { original: x } },
        });
  
        acc[curr.Header] =
          // if Cell fn returns a react element
          result && typeof result.type === "function"
            ? result.props.value
            : result;
      }
    } else if (
      typeof obj[`${curr.accessor}`] === "string" &&
      obj[`${curr.accessor}`].includes(",")
    ) {
      acc[curr.Header] = obj[`${curr.accessor}`];
    } else {
      acc[curr.Header] = obj[`${curr.accessor}`];
    }

    Object.keys(obj).forEach((key) => {
      if (obj[key]) {
        obj[key] = obj[key]
          .replace(/{|}|\[|]|"|'/g, "")
          .replace(/\n/g, "\u2028")
          .replace(/:/g, ": ");
      }
    });

    return acc;
  }, {});
};

export const download = (filename, type, content) => {
  const el = document.createElement("a");
  el.setAttribute(
    "href",
    `data:text/${type};charset=utf-8,${encodeURIComponent(content)}`
  );
  el.setAttribute("download", `${filename}`);
  el.style.display = "none";
  document.body.appendChild(el);
  el.click();
  document.body.removeChild(el);
};

export const generateCSV = (headers, data) =>
  `${headers.join(",")}\n${data
    .map((d) =>
      headers
        .map((h) =>
          h.toLowerCase().includes("zip")
            ? `"=""${d[h]}"""`
            : JSON.stringify(d[h])
        )
        .join(",")
    )
    .join("\n")}`;

export const downloadCSV = async (
  client,
  { query, where, mongoWhere, orderBy },
  dataName,
  columns
) => {
  const { data } = await client.query({
    query,
    variables: {
      where,
      mongoWhere,
      orderBy,
      first: null,
    },
    fetchPolicy: "network-only",
  });
  const items = data[dataName].Payload || data[dataName];

  const result =
    items && items.map((x) => parseQueryResultWithColumns(x, columns));
  if (result && result[0]) {
    const CSV = generateCSV(Object.keys(result[0]), result);
    return download(`${dataName}.csv`, "csv", CSV);
  }
  toast.error("No Data to Export");
  return null;
};

export const downloadLocalCsv = async (items, dataName, columns) => {

  let sortedItems = [];
  if(dataName === "Expiring Licenses" || dataName === "Expiring Certifications"){
    sortedItems = [...items].sort((a,b) => b?.expDate.localeCompare(a?.expDate));
  } 
  else if(dataName === "Points" || dataName === "YearsOfService"){
    sortedItems = [...items];
  }
  else if(dataName === "Projects"){
    sortedItems = [...items].sort((a,b) => a?.name.localeCompare(b?.name));
  }
  else{
    sortedItems = [...items].sort((a,b) => a?.lastName.localeCompare(b?.lastName));
  }

  const result =
    sortedItems && sortedItems.map((x) => parseQueryResultWithColumns(x, columns));
  if (result && result[0]) {
    const CSV = generateCSV(Object.keys(result[0]), result);
    return download(`${dataName}.csv`, "csv", CSV);
  }
  toast.error("No Data to Export");
  return null;
};

export const downloadLocalCsvQualCodes = async (items, dataName, columns) => {

  const  sortedItems = [...items].sort((a,b) => a?.title.localeCompare(b?.title));

  const result =
    sortedItems && sortedItems.map((x) => parseQueryResultWithColumns(x, columns));
  if (result && result[0]) {
    const CSV = generateCSV(Object.keys(result[0]), result);
    return download(`${dataName}.csv`, "csv", CSV);
  }
  toast.error("No Data to Export");
  return null;
};
export const clampNumber = (num, min, max) =>
  Math.max(Math.min(num, Math.max(min, max)), Math.min(min, max));

export const getDaysDiffBetweenDates = (dateInitial, dateFinal) =>
  Math.round((dateFinal - dateInitial) / (1000 * 3600 * 24));

export const throttle = (fn, wait) => {
  let inThrottle;
  let lastFn;
  let lastTime;
  return function innerFn(...args) {
    const context = this;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(() => {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};

export const deepFlatten = (arr) =>
  [].concat(...arr.map((v) => (Array.isArray(v) ? deepFlatten(v) : v)));

export const generateQRCode = (value) => {
  let imgUrl = "";
  QRCode.toDataURL(value, (err, url) => {
    imgUrl = url;
  });
  return imgUrl;
};

export const formatPhoneNumber = (phoneNumber) => {
  const cleaned =
    typeof phoneNumber === "string"
      ? phoneNumber.replace(/\D/g, "")
      : (phoneNumber && phoneNumber[1]?.replace(/\D/g, "")) || "";
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [
      true,
      [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(""),
    ];
  }
  return [
    false,
    typeof phoneNumber === "string"
      ? phoneNumber
      : (phoneNumber && phoneNumber[1]) || "",
  ];
};

export const uppercaseFirstLetter = (string) =>
  typeof string !== "string"
    ? ""
    : string.charAt(0).toUpperCase() + string?.slice(1)?.toLowerCase();

export const employeeNameSort = array =>
  array.sort((a, b) => {
    const aVal = a.firstName.toLowerCase() + a.lastName.toLowerCase();
    const bVal = b.firstName.toLowerCase() + b.lastName.toLowerCase();
    if (aVal > bVal) return 1;
    if (bVal > aVal) return -1;
    return 0;
  });

export const evalEmployeeNameSort = array =>
  array.sort((a, b) => {
    const aVal = a.owner.toLowerCase();
    const bVal = b.owner.toLowerCase();
    if (aVal > bVal) return 1;
    if (bVal > aVal) return -1;
    return 0;
  });

export const makeID = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const getDateEST = (
  options = { timeZone: "America/New_York", hour12: false }
) => new Date().toLocaleString("en-US", options);

export const removeLocalTimeZoneOffset = (date) => {
  const localDate = new Date(date);
  localDate.setTime(
    localDate.getTime() + localDate.getTimezoneOffset() * 60 * 1000
  );
  return localDate;
};

export const stripTimeZone = (dt) => {
  // a function that removes the time from a date and returns a new date object
  // if its a date object, make a copy then turn it into a string

  let d;

  if (dt instanceof Date) {
    d = new Date(dt).toISOString();
  } else if (typeof dt === "string") {
    d = dt;
  } else {
    return null;
  }

  // remove the time from the date string
  const date = d.split("T")[0];

  // return a new date object with the time set at utc 1pm which will never be affected by timezones
  return new Date(`${date}T13:00:00.000Z`);
};
