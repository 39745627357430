import React from "react";
import PropTypes from "prop-types";

import { Control, Field } from "rbx";
import {
  EmployeeSelectAlt,
  ReviewTemplateSelect,
  DatePicker,
} from "../../../components";

import "./AssignReviewForm.scss";

const AssignReviewForm = ({ review, onChange }) => {
  const handleDateInputChange = (name, date) => {
    onChange(name, date);
  };

  const handleEmployeeSelectChange = (name, value) => {
    onChange(name, value);
  };

  return (
    <Field className="grid-override is-two" kind="group">
      <Control expanded>
        <EmployeeSelectAlt
          required
          showBlank
          label="Employee"
          name="employeeNumber"
          placeholder="Employee"
          value={review.employeeNumber || ""}
          onChange={handleEmployeeSelectChange}
        />
      </Control>
      <Control expanded>
        <ReviewTemplateSelect
          required
          showBlank
          label="Template"
          name="templateVersionId"
          placeholder="Template"
          value={review.templateVersionId || ""}
          onChange={handleEmployeeSelectChange}
        />
      </Control>
      <Control expanded>
        <DatePicker
          showBlank
          label="Due Date"
          name="dateToCompleteBy"
          value={review.dateToCompleteBy}
          onChange={handleDateInputChange}
        />
      </Control>
    </Field>
  );
};

AssignReviewForm.propTypes = {
  review: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

AssignReviewForm.defaultProps = {
  review: {},
};

export default AssignReviewForm;
