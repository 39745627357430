import React, { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Button, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {
  Box,
  Button as MuiButton,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";

import { FileDownload } from "@mui/icons-material";
import { GET_TSHIRT_REPORT } from "../../../../graphql/reports";
import {
  ReportsTable,
  ReportSelectMUI,
  AccordionItem,
  AccordionDetailsItem,
} from "../../../../components";
import Dropdown from "../../../../components/Dropdown";
import ReportHeader from "../ReportHeader";
import { generateColumns, EXPORT_COLUMNS } from "./columns";

import { TotalTshirts } from "..";
import { GET_PROJECTS, UPDATE_EMPLOYEE_MUTATION } from "../../../../graphql";

import {
  downloadLocalCsv,
  customToast as toast,
  uppercaseFirstLetter,
} from "../../../../utils";
import MobileTable from "../../../../components/ReportsTable/MobileTable";
import BoxLoader from "../../../../components/DetailedDataTable/BoxLoader/BoxLoader";

const TshirtsTable = ({ report, onReportChange, tabs, permissions }) => {
  const [tshirtSizes, setTshirtSizes] = useState([]);
  const [where, setWhere] = useState({
    employmentStatus: {
      equals: "active",
    },
  });
  const [employeeData, setEmployeeData] = useState([]);

  const { loading, refetch } = useQuery(GET_TSHIRT_REPORT, {
    variables: {
      where,
      orderBy: [{ firstName: "asc" }],
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      const formatted = data.users.map((user) => ({
        employeeNumber: user?.employeeNumber?.trim(),
        firstName: `${uppercaseFirstLetter(user?.firstName)}`,
        lastName: `${uppercaseFirstLetter(user?.lastName)}`,
        project: user?.project?.name,
        tshirtSize: user?.tshirtSize,
        id: user?.id,
      }));

      setEmployeeData(formatted);
    },
  });



  const { data: { projects = [] } = {} } = useQuery(GET_PROJECTS, {
    variables: {
      orderBy: [{ name: "asc" }],
      where: {
        AND: [
          {
            name: {
              notIn: ["Disability", "Vacation", "Not Assigned"],
            },
          },
        ],
      },
    },
  });

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE_MUTATION);

  const [activeDropdownItem, setActiveDropdownItem] = useState({
    id: "all",
    label: "All Projects",
  });

  useEffect(() => {
    if (Array.isArray(employeeData) && employeeData?.length > 0) {
      const newTshirtSizes = [
        { id: "notAssigned", label: "Not Assigned", value: 0 },
        { id: "SM", label: "SM", value: 0 },
        { id: "MD", label: "MD", value: 0 },
        { id: "LG", label: "LG", value: 0 },
        { id: "XL", label: "XL", value: 0 },
        { id: "2XL", label: "2XL", value: 0 },
        { id: "3XL", label: "3XL", value: 0 },
        { id: "4XL", label: "4XL", value: 0 },
      ];
      employeeData?.forEach((employee) => {
        const { tshirtSize } = employee;
        const index = newTshirtSizes.findIndex(
          (tshirtSizeItem) => tshirtSizeItem.id === tshirtSize
        );
        if (index !== -1) {
          newTshirtSizes[index].value += 1;
        }
      });
      setTshirtSizes(newTshirtSizes);
    }
  }, [employeeData, employeeData?.length]);

  const exportCSV = async () => {
    try {
      downloadLocalCsv(employeeData, "TShirts Report", EXPORT_COLUMNS);
    } catch (err) {
      toast.error("Error exporting CSV");
    }
  };

  const handleDropdownOptionClick = async (item) => {
    if (item.id === "all") {
      setWhere({
        employmentStatus: {
          equals: "active",
        },
      });

      const {
        data: { users },
      } = await refetch({
        where: {
          employmentStatus: {
            equals: "active",
          },
        },
      });

      const formatted = users.map((user) => ({
        employeeNumber: user?.employeeNumber?.trim(),
        firstName: `${uppercaseFirstLetter(user?.firstName)}`,
        lastName: `${uppercaseFirstLetter(user?.lastName)}`,
        project: user?.project?.name,
        tshirtSize: user?.tshirtSize,
      }));

      setEmployeeData(formatted);
    } else {
      const {
        data: { users: usersByProject },
      } = await refetch({
        where: {
          AND: [
            {
              projectId: {
                equals: item.id,
              },
            },
            {
              employmentStatus: {
                equals: "active",
              },
            },
          ],
        },
      });
      const formatted = usersByProject.map((user) => ({
        employeeNumber: user?.employeeNumber?.trim(),
        firstName: `${uppercaseFirstLetter(user?.firstName)}`,
        lastName: `${uppercaseFirstLetter(user?.lastName)}`,
        project: user?.project?.name,
        tshirtSize: user?.tshirtSize,
      }));

      setEmployeeData(formatted);
    }

    setActiveDropdownItem(item);
  };

  const COLUMNS = useMemo(() => {
    const update = async (id, tshirtSize) => {
      const performUpdate = async () => {
        try {
          await updateEmployee({
            variables: {
              where: {
                id,
              },
              data: {
                tshirtSize: {
                  set: tshirtSize,
                },
              },
            },

            refetchQueries: [
              {
                query: GET_TSHIRT_REPORT,
                variables: {
                  where,
                },
              },
            ],
          });

          toast.success("Tshirt Size updated succesfully.");
        } catch (err) {
          toast.error(
            "There was an error updating Tshirt Size. Please contact support."
          );
        }
      };
      await performUpdate();
    };
    return generateColumns(update);
  }, [updateEmployee, where]);

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (downSm && employeeData) {
    return (
      <MobileTable
        showLoadingBar
        headerActions={
          <React.Fragment>
            <Box mt={1} width="100%">
              <ReportSelectMUI value={report} onChange={onReportChange} />
            </Box>

            <Box mt={1} width="20%">
              <MuiButton
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => exportCSV()}
              >
                <FileDownload />
              </MuiButton>
            </Box>
          </React.Fragment>
        }
        loading={loading}
        title="Reports"
      >
        <Box mb={2} pl={2} pr={2} width="100svw">
          <Box display="grid" justifyItems="center">
            <Typography variant="subtitle3">Totals</Typography>
          </Box>

          {loading ? (
            <BoxLoader />
          ) : (
            tshirtSizes.map((size) => (
              <React.Fragment>
                <AccordionDetailsItem label={size.label} value={size.value} />
                <Divider
                  sx={{
                    marginTop: ".2rem",
                    backgroundColor: theme.palette.primary.main,
                  }}
                />
              </React.Fragment>
            ))
          )}
        </Box>

        {employeeData?.map((item) => (
          <AccordionItem
            info={item?.tshirtSize}
            key={item?.employeeNumber}
            title={`${item?.firstName} ${item?.lastName}`}
          >
            <AccordionDetailsItem
              label="Employee #"
              value={item?.employeeNumber}
            />
            <AccordionDetailsItem label="Project" value={item?.project} />
          </AccordionItem>
        ))}
      </MobileTable>
    );
  }

  return (
    <ReportsTable
      columns={COLUMNS}
      data={employeeData || []}
      loading={loading}
      saveQuery={GET_TSHIRT_REPORT}
    >
      <div className="table-utils">
        <Dropdown
          activeItem={activeDropdownItem}
          handleOptionClick={handleDropdownOptionClick}
          options={[
            { id: "all", label: "All Projects" },
            ...projects?.map((project) => ({
              id: project.id,
              label: project.name,
            })),
          ]}
          size="small"
        />
        {permissions?.webEditor && (
          <Button
            className="download-btn"
            disabled={loading}
            size="medium"
            title="Download CSV"
            onClick={exportCSV}
          >
            <Icon size="small">
              <FontAwesomeIcon icon="download" />
            </Icon>
          </Button>
        )}
      </div>
      <ReportHeader
        report={report}
        tabs={tabs}
        onReportChange={onReportChange}
      />
      <TotalTshirts tshirtSizes={tshirtSizes} />
    </ReportsTable>
  );
};

TshirtsTable.propTypes = {
  onReportChange: PropTypes.func.isRequired,
  report: PropTypes.string.isRequired,
  tabs: PropTypes.array.isRequired,
  permissions: PropTypes.object.isRequired,
};

export default TshirtsTable;
