import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLazyQuery, useMutation } from "@apollo/client";

import { Title, Button } from "rbx";
import { toast } from "react-toastify";
import {
  useTheme,
  useMediaQuery,
  Box,
  Button as MuiButton,
} from "@mui/material";
import ContractorForm from "../ContractorForm/ContractorForm";
import Loader from "../../../../components/Loader";
import Confirmation from "../../../../components/Confirmation";
import { useModal } from "../../../../context";

import {
  GET_CONTRACTOR,
  GET_CONTRACTORS,
  CREATE_CONTRACTOR,
  UPDATE_CONTRACTOR,
  DELETE_CONTRACTOR,
} from "../../../../graphql";

const INITIAL_STATE = [
  {
    id: "",
    firstName: "",
    lastName: "",
    projectId: "",
    task: "",
    evaluation: "",
    notes: "",
    project: { name: "" },
    staffingAgency: "",
    status: "",
  },
];

class CustomContractorError extends Error {}

const ContractorModal = ({ id, onComplete, where }) => {
  const adding = !id;

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [contractor, setContractor] = useState({ ...INITIAL_STATE });
  const [updated, setUpdated] = useState([]);

  const { setModalOpen } = useModal();

  const [
    getContractor,
    { data: ContractorData, loading: ContractorDataLoading },
  ] = useLazyQuery(GET_CONTRACTOR);

  const [createContractor, { loading: ContractorCreateLoading }] = useMutation(
    CREATE_CONTRACTOR,
    {
      refetchQueries: [
        {
          query: GET_CONTRACTORS,
        },
      ],
    }
  );

  const [updateContractor, { loading: ContractorUpdateLoading }] =
    useMutation(UPDATE_CONTRACTOR);

  const [deleteContractor, { loading: ContractorDeleteLoading }] =
    useMutation(DELETE_CONTRACTOR);

  useEffect(() => {
    if (id) {
      getContractor({
        variables: { where: { id } },
      });
    }
  }, [id, getContractor]);

  useEffect(() => {
    if (ContractorData?.contractor) {
      const contractorStatus = ContractorData.contractor.status
        ? "Active"
        : "Inactive";
      const newInputs = { ...ContractorData.contractor, contractorStatus };
      setContractor(newInputs);
    }
  }, [ContractorData]);

  const handleChange = (name, value) => {
    switch (name) {
      case "projectId":
        setContractor((prev) => ({
          ...prev,
          [name]: value.id,
        }));

        setContractor((prev) => ({
          ...prev,
          project: { name: value.name },
        }));
        break;

      case "status":
        setContractor((prev) => ({
          ...prev,
          [name]: value === "Active",
        }));

        setContractor((prev) => ({
          ...prev,
          contractorStatus: value,
        }));
        break;

      default:
        setContractor((prev) => ({
          ...prev,
          [name]: value,
        }));
        break;
    }

    if (!adding) {
      setUpdated((prev) => (prev.includes(name) ? prev : [...prev, name]));
    }
  };

  const CREATE_NEW_CONTRACTOR = {
    variables: {
      data: {
        firstName: contractor.firstName,
        lastName: contractor.lastName,
        task: contractor.task,
        evaluation: contractor.evaluation,
        notes: contractor.notes,
        staffingAgency: contractor.staffingAgency,
        status: contractor.status,
        project: { connect: { id: contractor.projectId } },
      },
    },
    refetchQueries: [
      {
        query: GET_CONTRACTORS,
        variables: {
          orderBy: [{ firstName: "asc" }, { lastName: "asc" }],
          where,
        },
        fetchPolicy: "network-only",
      },
    ],
  };

  const UPDATE_CONTRACTOR_FIELDS = {
    variables: {
      data: {
        firstName: { set: contractor.firstName },
        lastName: { set: contractor.lastName },
        task: { set: contractor.task },
        evaluation: { set: contractor.evaluation },
        notes: { set: contractor.notes },
        staffingAgency: { set: contractor.staffingAgency },
        status: { set: contractor.status },
        project: { connect: { id: contractor.projectId } },
      },
      where: { id: contractor.id },
    },
    refetchQueries: [
      {
        query: GET_CONTRACTORS,
        variables: { orderBy: [{ firstName: "asc" }, { lastName: "asc" }] },
        fetchPolicy: "network-only",
      },
    ],
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (adding) {
        await createContractor(CREATE_NEW_CONTRACTOR);
      } else {
        await updateContractor(UPDATE_CONTRACTOR_FIELDS);
      }
      toast.success(
        `Contractor ${adding ? "created" : "edited"} successfully.`
      );
      onComplete();
    } catch (error) {
      const message =
        error instanceof CustomContractorError
          ? error.message
          : `Error ${
              id ? "updating" : "creating"
            } contractor. Please contact support.`;
      toast.error(message);
    }
  };

  const performDelete = async () => {
    try {
      await deleteContractor({
        variables: { where: { id } },
        refetchQueries: [
          {
            query: GET_CONTRACTORS,
            variables: {
              orderBy: [{ firstName: "asc" }, { lastName: "asc" }],
              where,
            },
            fetchPolicy: "network-only",
          },
        ],
      });
      toast.success("Contractor deleted successfully.");
      onComplete();
    } catch (error) {
      toast.error("Error deleting Contractor.");
    }
  };

  const handleDelete = async () => {
    setModalOpen(
      true,
      <Confirmation
        message="Are you sure you want to delete this Contractor?"
        onCancel={() =>
          setModalOpen(
            true,
            <ContractorModal id={id} onComplete={onComplete} />
          )
        }
        onConfirm={performDelete}
      />
    );
  };

  const isLoading =
    ContractorDataLoading ||
    ContractorCreateLoading ||
    ContractorUpdateLoading ||
    ContractorDeleteLoading;

  if (isLoading) return <Loader />;

  const isDisabled =
    !contractor.firstName ||
    !contractor.lastName ||
    !contractor.projectId ||
    contractor.projectId === 7 ||
    !contractor.task ||
    !contractor.evaluation ||
    contractor.evaluation === " " ||
    !contractor.notes ||
    !contractor.staffingAgency ||
    contractor.contractorStatus === " " ||
    (!adding && !updated?.length);

  return (
    <form id="contractor-form" onSubmit={handleSubmit}>
      <header className="modal-head">
        <div className="modal-head-start">
          <Title size={5}>
            {[adding ? "Create" : "Edit", "Contractor"].join(" ")}
          </Title>
        </div>
        {!downSm && (
          <div className="modal-head-end">
            <Button.Group hasAddons>
              <Button
                size="small"
                type="button"
                onClick={() => onComplete(false)}
              >
                <span>Cancel</span>
              </Button>
              <Button
                color="primary"
                disabled={isDisabled}
                form="contractor-form"
                size="small"
                state={isLoading ? "loading" : ""}
                type="submit"
              >
                <span>Save</span>
              </Button>
            </Button.Group>
          </div>
        )}
      </header>
      <hr />
      <ContractorForm contractor={contractor} onChange={handleChange} />
      {downSm && (
        <Box
          alignContent="center"
          display="flex"
          gap={2}
          justifyContent="space-between"
          p={2}
          width="100%"
        >
          <MuiButton
            fullWidth
            color="secondary"
            sx={{ color: theme.palette.common.white }}
            variant="contained"
            onClick={() => onComplete(false)}
          >
            Cancel
          </MuiButton>
          <MuiButton
            fullWidth
            color="error"
            variant="contained"
            onClick={handleDelete}
          >
            Delete
          </MuiButton>
          <MuiButton
            fullWidth
            color="primary"
            disabled={isDisabled}
            variant="contained"
            onClick={(e) => handleSubmit(e)}
          >
            Save
          </MuiButton>
        </Box>
      )}
      {!adding && !downSm && (
        <Button
          color="danger"
          size="small"
          state={ContractorDeleteLoading ? "loading" : ""}
          type="button"
          onClick={handleDelete}
        >
          Delete
        </Button>
      )}
    </form>
  );
};

ContractorModal.propTypes = {
  id: PropTypes.string,
  onComplete: PropTypes.func,
  where: PropTypes.object,
};

ContractorModal.defaultProps = {
  id: "",
  onComplete: () => {},
  where: {},
};

export default ContractorModal;
