import { uppercaseFirstLetter } from "../../../utils";

export const EMPLOYEE_COLUMNS = [
  {
    Header: "Employee Number",
    id: "employeeNumber",
    accessor: "employeeNumber",
  },
  {
    Header: "Employee Name",
    id: "employee_name",
    accessor: (row) =>
      `${uppercaseFirstLetter(row.firstName)} ${uppercaseFirstLetter(
        row.lastName
      )} `,
  },
  {
    Header: "Job Title",
    id: "jobTitle",
    accessor: "jobTitle",
  },
];

export const CONTRACTOR_COLUMNS = [
  {
    Header: "Contractor Name",
    id: "contractor_name",
    accessor: (row) =>
      `${uppercaseFirstLetter(row.firstName)}  ${uppercaseFirstLetter(
        row.lastName
      )} `,
  },
  {
    Header: "Task",
    id: "task",
    accessor: "task",
  },
  {
    Header: "Staffing Agency",
    id: "staffingAgency",
    accessor: "staffingAgency",
  },
];

export const PROJECT_CSV_COLUMNS = [
  {
    Header: "Project #",
    accessor: "project_num",
  },
  {
    Header: "Project Name",
    accessor: "name",
  },

  {
    Header: "Manager",
    accessor: "manager",
  },
  {
    Header: "Supervisor",
    accessor: "supervisor",
  },
  {
    Header: "Phone",
    accessor: "phone",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  { Header: "City", accessor: "city" },
  { Header: "State", accessor: "state" },
  { Header: "Zip", accessor: "zip" },
  { Header: "Notes", accessor: "notes" },
  { Header: "Partners", accessor: "employees" },
  { Header: "Contractors", accessor: "contractors" },
];
