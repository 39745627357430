import React from "react";
import PropTypes from "prop-types";

import { Control, Field, Input, Label, Textarea } from "rbx";
import { DateInput } from "../../../../components";

import "./GoalAddEditForm.scss";
import checkMobile from "../../../../utils/checkForMobile";

const GoalAddEditForm = ({ goal, onChange }) => {
  const handleInputChange = (item) => {
    onChange(item.target.name, item.target.value);
  };

  const isMobile = checkMobile();

  function isDateValid(dateStr) {
    return !isNaN(new Date(dateStr));
  }

  return (
    <Field
      className={isMobile ? "grid-override-mobile" : "grid-override is-two"}
      kind="group"
    >
      <Control expanded>
        <Label>Title</Label>
        <Input
          required
          autoComplete="off"
          maxLength={50}
          name="title"
          placeholder="Title"
          type="text"
          value={goal.title || ""}
          onChange={handleInputChange}
        />
      </Control>
      <Control expanded>
        <Label>Start Date</Label>
        <div className="dropdown-container">
          <DateInput
            required
            label=""
            name="startDate"
            value={goal?.startDate}
            onChange={handleInputChange}
          />
        </div>
      </Control>
      <Control expanded>
        <Label>Due Date</Label>
        <div className="dropdown-container">
          <DateInput
            required
            label=""
            name="dueDate"
            value={isDateValid(goal?.dueDate) ? goal?.dueDate : goal?.startDate}
            onChange={handleInputChange}
          />
        </div>
      </Control>
      <Control expanded>
        <Label>Description</Label>
        <Textarea
          autoComplete="off"
          maxLength={1000}
          name="description"
          placeholder="Description"
          type="text"
          value={goal.description || ""}
          onChange={handleInputChange}
        />
      </Control>
    </Field>
  );
};

GoalAddEditForm.propTypes = {
  goal: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

GoalAddEditForm.defaultProps = {
  goal: {},
};

export default GoalAddEditForm;
