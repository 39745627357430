/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { TextField, Autocomplete } from "@mui/material";
import { usePermissions } from "../../hooks";

const HEADER_TABS = [
 "licenses",
 "certifications",
 "skills",
];


const ReportSelectMUI = ({
  name,
  value,
  label,
  placeholder,
  disabled,
  required,
  getValue,
  setValue,
  onChange,
}) => {
  const [formattedOptions, setFormattedOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const permissionLevels = usePermissions();

  const handleChange = (val) => {
    if(val?.length > 0){
      onChange(setValue(val));
    }
  };

  useEffect(() => {
    if (HEADER_TABS) {
      setLoading(true);
        setFormattedOptions(
          HEADER_TABS.map((opt) => ({
            value: opt,
            label: opt,
          }))
        );
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissionLevels]);

  return (
    <Autocomplete
      fullWidth
      ListboxProps={{
        sx: { "&": { padding: 0, margin: 0, maxHeight: 200 } },
      }}
      disabled={disabled}
      getOptionLabel={(option) =>
        option.label ??
        formattedOptions.find((opt) => opt.value === value)?.label
      }
      isOptionEqualToValue={(o, v) => o.value === v}
      isRequired={required}
      loading={loading}
      name={name}
      options={formattedOptions}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      size="small"
      value={getValue(value)}
      onChange={(_, option) => {
        handleChange(option?.value);
      }}
    />
  );
};

ReportSelectMUI.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
};

ReportSelectMUI.defaultProps = {
  name: "report",
  placeholder: "Select a report",
  label: "Report",
  disabled: false,
  required: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};

export default ReportSelectMUI;
