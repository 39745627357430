import React from "react";
import PropTypes from "prop-types";
import GoalsListContainer from "../GoalsListContainer";

const GoalSection = ({
  sectionType,
  goals,
  isCompletedSection,
  handleClick,
  setGoals,
  handleOpenGoalAdd,
  isUsersOwnGoals,
  showManagerReviews
}) => (
  <GoalsListContainer
    goals={goals}
    handleClick={handleClick}
    handleOpenGoalAdd={handleOpenGoalAdd}
    isCompletedSection={isCompletedSection}
    isUsersOwnGoals={isUsersOwnGoals}
    sectionType={sectionType}
    setGoals={setGoals}
    showManagerReviews={showManagerReviews}
  />
);

GoalSection.propTypes = {
  handleClick: PropTypes.func.isRequired,
  goals: PropTypes.array,
  sectionType: PropTypes.string,
  setGoals: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool,
  handleOpenGoalAdd: PropTypes.func.isRequired,
  isUsersOwnGoals: PropTypes.bool,
  showManagerReviews: PropTypes.bool
};

GoalSection.defaultProps = {
  goals: [],
  sectionType: "",
  isCompletedSection: false,
  isUsersOwnGoals: false,
  showManagerReviews: false
};

export default GoalSection;
