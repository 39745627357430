import React from "react";
import { Button, Title } from "rbx";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
// import { TextField } from "@mui/material";
// import EvaluationSection from "../EvaluationSection";
import {
  UPDATE_ONE_USER_GOAL,
  GET_USER,
  DELETE_ONE_USER_GOAL
} from "../../graphql";

import { Loader } from "../../../../components";
import formatDate from "../../../../utils/formatDate";
import { useAuth } from "../../../../context";
import capitalizeEveryWord from "../../../../utils/capitalizeEveryWord";
import "./GoalForm.scss";

const GoalForm = ({
  currentGoalId,
  employeeNumber,
  goal,
  goals,
  loading,
  permissions,
  onComplete,
  refetchMyGoals,
  onCanceled,
  isCompletedSection = false,
  type,
}) => {

  const auth = useAuth();

  const activeUserEmployeeNumber = auth?.state?.user?.employeeNumber;
  const activeUserFullName = `${auth?.state?.user?.firstName} ${auth?.state?.user?.lastName}`;

  // eslint-disable-next-line no-unused-vars
  const [updateOneUserGoal, { loading: loadingGoal }] = useMutation(
    UPDATE_ONE_USER_GOAL
  );

    const [deleteOneUserGoal, { loading: loadingDeleteGoal }] = useMutation(
    DELETE_ONE_USER_GOAL
  );

  // eslint-disable-next-line no-unused-vars
  const { data: UserData, loadingUserData } = useQuery(GET_USER, {
    variables: {
      where: {
        employeeNumber,
      },
    },
  });

  const handleGoalCompletion = async (e) => {
    try {
      e.preventDefault();

      const timeStamp = new Date().toISOString();
        // completing decco leadership -> Molly for signing
      await updateOneUserGoal({
        variables: {
          where: {
            id: currentGoalId,
          },
          data: {
            status: {
              set: "COMPLETED",
            },
            updatedBy: {
              set: activeUserEmployeeNumber,
            },
            updatedByFullName: {
              set: activeUserFullName,
            },
            updatedAt: {
              set: timeStamp,
            }
          },
        },
      });

      await setTimeout(1000);

      toast.success("Successfully updated goal");
      onComplete();
    } catch (err) {
      toast.error("Error saving goal, please contact support.");
    }
  };

    const handleDeleteGoal = async (e) => {
    try {
      e.preventDefault();

      // Mongo taking years to remove records so updating to remove from lists first
       await updateOneUserGoal({
        variables: {
          where: {
            id: currentGoalId,
          },
          data: {
            status: {
              set: "TO_BE_DELETED",
            },
          }
        }
      });

      await deleteOneUserGoal({
        variables: {
          where: {
            id: currentGoalId,
          },
        },
      });

      toast.success("Successfully deleted goal");
      onComplete();
    } catch (err) {
      toast.error("Error deleting goal, please contact support.");
    }
  };

  if (loading || loadingUserData || loadingDeleteGoal) {
    return (
      <div style={{ minHeight: "10rem" }}>
        <Loader />
      </div>
    );
  }

  return (
    <form id="evaluation-form" onSubmit={handleGoalCompletion}>
      <div className="evaluation-container">
        <header className="modal-head" style={{}}>
          <div className="modal-head">
            <Title size={5}>{goal.title}</Title>
          </div>
            <div className="modal-head">
              <Button.Group hasAddons>
                <Button
                  size="small"
                  style={{ marginBottom: "0.5rem" }}
                  onClick={onCanceled}
                  >
                  <span>Cancel</span>
                </Button>
                {!isCompletedSection && activeUserEmployeeNumber.trim() !== employeeNumber.trim() && type === "delete" && (
                  <React.Fragment>
                    <Button
                      color="danger"
                      size="small"
                      style={{ marginBottom: "0.5rem", marginLeft: "0.5rem", marginRight: "0.2rem" }}
                      onClick={handleDeleteGoal}
                    >
                      <span>Delete</span>
                    </Button>
                    <Button
                      color="primary"
                      form="evaluation-form"
                      size="small"
                      style={{ marginBottom: "0.5rem" }}
                      type="submit"
                    >
                      <span>Complete</span>
                    </Button>
                  </React.Fragment>
              )}
              </Button.Group>
            </div>
        </header>
        <div className="modal-head-details">
 
  {/* className="highlighted-details-text" */}

          <p><b>Status:</b> {capitalizeEveryWord(goal?.status)}</p>

          <p><b>Start Date:</b> {formatDate(goal?.startDate)}</p>
          {/* <p><b>Current Progress:</b> {` ${goal?.currentProgress} / 100`}</p> */}

          <p><b>Created By:</b>{` ${capitalizeEveryWord(goal?.createdBy)}`}</p>

          <p><b>Create Date:</b> {formatDate(goal?.createDate)}</p>

          <p><b>Updated By:</b>{` ${capitalizeEveryWord(goal?.updatedByFullName)}`}</p>
          <p><b>Updated Date:</b>{` ${formatDate(goal?.updatedAt)}`}</p>
          <p><b>Due Date:</b> {formatDate(goal?.dueDate)}</p>

        </div>
        <hr />
        {/* <Button.Group hasAddons>
          <Button
            size="small"
            style={{ marginBottom: "0.5rem" }}
            onClick={onCanceled}
          >
            <span>Cancel</span>
          </Button>
          <Button
            color="primary"
            form="evaluation-form"
            size="small"
            style={{ marginBottom: "0.5rem" }}
            onClick={handleEditGoal}
          >
            <span>Edit Goal</span>
          </Button>
        </Button.Group> */}
      </div>

      <p>
        <b>Description:</b> {` ${goal?.description}`}
      </p>
    </form>
  );
};

GoalForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  employeeNumber: PropTypes.string.isRequired,
  goal: PropTypes.object.isRequired,
  goals: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  currentGoalId: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  refetchMyGoals: PropTypes.func.isRequired,
  onCanceled: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool,
  type: PropTypes.string,
};

GoalForm.defaultProps = {
  isCompletedSection: false,
  type: "view",
};

export default GoalForm;
