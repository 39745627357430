import React from "react";
import PropTypes from "prop-types";

import { Button, Title } from "rbx";
import { Paper } from "@mui/material";
import capitalizeEveryWord from "../../../../utils/capitalizeEveryWord";

const GoalEvalListItem = ({
  goal,
  goalIndex,
  handleClick,
  isCompletedSection = false,
  handleComplete,
  handleReassign,
  evaluationSectionId,
  readOnlyParent
}) => (
    <li >
      <Paper elevation={4} style={{padding: "1rem", marginBottom: "1rem"}}>
        <div className="review-item" key={goal.id} style={{flexDirection: "column"}}>
          <p>
            <h3>{`${capitalizeEveryWord(goal.title)}`}</h3>
          </p>
          <p style={{textAlign: "left"}}>
            <b>Description: </b>{`${goal?.description}`}
          </p>
          <hr />
          {!isCompletedSection && !readOnlyParent &&(
            <div style={{display: "flex", flexDirection: "row"}}>
              <Button
                  className="review-option-primary"
                  style={{ marginBottom: "0.5rem", padding: "0.5rem", marginRight: "0.5rem"}}
                  type="button"
                  onClick={(e) => handleReassign(e, goal?.id)}
                >
                <Title size={6} textColor="white">
                  Repeat
                </Title>
              </Button>
              <Button
                className="review-option-secondary"
                style={{ marginBottom: "0.5rem", padding: "0.5rem"}}
                type="button"
                onClick={(e) => handleComplete(e, goal?.id, evaluationSectionId)}
              >
                <Title size={6} textColor="white">
                  Complete
                </Title>
              </Button>
            </div> 
          )}
        </div>
      </Paper>
    </li>
  );

GoalEvalListItem.propTypes = {
  goal: PropTypes.object.isRequired,
  goalIndex: PropTypes.number.isRequired,
  handleClick: PropTypes.func.isRequired,
  isCompletedSection: PropTypes.bool,
  handleComplete: PropTypes.func.isRequired,
  handleReassign: PropTypes.func.isRequired,
  evaluationSectionId: PropTypes.string.isRequired,
  readOnlyParent: PropTypes.bool
};

GoalEvalListItem.defaultProps = {
  isCompletedSection: false,
  readOnlyParent: false
};

export default GoalEvalListItem;
