import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faBroom,
  faUser,
  faAngleDown,
  faSignOutAlt,
  faHome,
  faCogs,
  faBuilding,
  faFile,
  faList,
  faBriefcaseMedical,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faUsers,
  faPlus,
  faPhone,
  faAt,
  faAddressBook,
  faSync,
  faSyncAlt,
  faFilter,
  faPrint,
  faSearch,
  faCalendarTimes,
  faCopy,
  faEdit,
  faUpload,
  faExternalLinkAlt,
  faDesktop,
  faVials,
  faUserMd,
  faFlask,
  faDollarSign,
  faMoneyBill,
  faVial,
  faCircle,
  faEye,
  faEyeSlash,
  faFileExport,
  faCog,
  faPercentage,
  faTrashAlt,
  faClone,
  faEnvelope,
  faArrowUp,
  faArrowDown,
  faShoppingCart,
  faDownload,
  faCaretDown,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faBroom,
  faSync,
  faUser,
  faAngleDown,
  faSignOutAlt,
  faSyncAlt,
  faHome,
  faCogs,
  faBuilding,
  faFile,
  faBriefcaseMedical,
  faList,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faArrowRight,
  faTimes,
  faUsers,
  faPlus,
  faPhone,
  faAt,
  faAddressBook,
  faFilter,
  faPrint,
  faSearch,
  faCalendarTimes,
  faCopy,
  faEdit,
  faUpload,
  faExternalLinkAlt,
  faDesktop,
  faVials,
  faUserMd,
  faFlask,
  faDollarSign,
  faMoneyBill,
  faVial,
  faCircle,
  faEye,
  faEyeSlash,
  faFileExport,
  faPercentage,
  faTrashAlt,
  faClone,
  faCog,
  faEnvelope,
  faArrowUp,
  faArrowDown,
  faShoppingCart,
  faDownload,
  faCaretDown,
  faCaretRight
);
