import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query GET_PROJECTS(
    $orderBy: [ProjectOrderByWithRelationInput!]
    $where: ProjectWhereInput
  ) {
    projects(orderBy: $orderBy, where: $where) {
      id
      name
    }
  }
`;

export const GET_PROJECT = gql`
  query GET_PROJECT($projectId: String!) {
    getProject(projectId: $projectId) {
      id
      address
      city
      manager
      name
      notes
      phone
      project_num
      state
      supervisor
      zip
      contractors {
        id
        evaluation
        firstName
        lastName
        notes
        task
        staffingAgency
        status
      }
      employees {
        firstName
        lastName
        jobTitle
        employeeNumber
      }
    }
  }
`;

export const GET_PROJECT_LIST = gql`
  query GET_PROJECT_LIST(
    $orderBy: [ProjectOrderByWithRelationInput!]
    $where: ProjectWhereInput
    $take: Int
  ) {
    projects(orderBy: $orderBy, where: $where, take: $take) {
      id
      project_num
      name
      manager
      supervisor
      address
      city
      state
      phone
      notes
      zip
      users {
        id
        employeeNumber
        email
      }
      contractors {
        id
        firstName
        lastName
        task
      }
    }
  }
`;

export const CREATE_ONE_USER_GOAL = gql`
  mutation CreateOneUserGoal($data: UserGoalCreateInput!) {
    createOneUserGoal(data: $data) {
      id
      title
      description
      status
      startDate
      dueDate
      currentProgress
      createdBy
      createDate
      updatedAt
      updatedBy
      updatedByFullName
      evaluationSectionId
      assignedUserId
    }
  }
`;
