import { gql } from "@apollo/client";

export const FIND_MANY_CAL_EVENTS_QUERY = gql`
  query FindManyCalEvents(
    $orderBy: [CalEventsOrderByWithRelationInput!]
    $where: CalEventsWhereInput
  ) {
    findManyCalEvents(orderBy: $orderBy, where: $where) {
      id
      title
      date
    }
  }
`;

export const FIND_UNIQUE_CAL_EVENT_QUERY = gql`
  query FindUniqueCalEvents($where: CalEventsWhereUniqueInput!) {
    findUniqueCalEvents(where: $where) {
      id
      date
      title
    }
  }
`;
