import PropTypes from "prop-types";

const getButtonType = (e, activeEmployeeNumber, sectionType) => {
  if (
    sectionType === "Manager" &&
    e.needsSignature &&
    e.status === "COMPLETED" &&
    e.authorEmployeeNumber === activeEmployeeNumber &&
    e.ownerEmployeeNumber !== activeEmployeeNumber
  ) {
    return "Sign Off";
  }

  if (
    sectionType === "Personal" &&
    e.needsSignature &&
    e.status === "COMPLETED" &&
    e.authorEmployeeNumber === activeEmployeeNumber &&
    e.title.includes("Annual Performance") &&
    e.ownerEmployeeNumber === activeEmployeeNumber
  ) {
    return "Sign Off";
  }

  // Molly Only x2 // sign off on her own decco leadership
  if (
    e.title.includes("DECCO Leadership") &&
    sectionType === "Personal" &&
    e.needsSignature &&
    e.status === "COMPLETED" &&
    activeEmployeeNumber === "     11888" &&
    e.ownerEmployeeNumber === activeEmployeeNumber
  ) {
    return "Sign Off";
  }

  // For molly only
  if (
    e.title.includes("DECCO Leadership") &&
    sectionType === "Manager" &&
    e.needsSignature &&
    e.status === "COMPLETED" &&
    activeEmployeeNumber === "     11888" &&
    e.ownerEmployeeNumber !== activeEmployeeNumber
  ) {
    return "Sign Off";
  }

  if (
    e.status === "INCOMPLETE" &&
    e.needsAssignment &&
    (e.title.includes("Internal Client") ||
      e.title.includes("Periodic Performance")) &&
    e.managerEmployeeNumber === activeEmployeeNumber
  ) {
    return "Assign";
  }

  if (
    e.status === "SAVE_FOR_LATER" &&
    e.authorEmployeeNumber === activeEmployeeNumber
  ) {
    return "Resume";
  }

  if (
    e.status === "INCOMPLETE" &&
    e.authorEmployeeNumber === activeEmployeeNumber
  ) {
    return "Start";
  }
  return null;
};

getButtonType.propTypes = {
  e: PropTypes.object.isRequired,
  activeEmployeeNumber: PropTypes.bool.isRequired,
  sectionType: PropTypes.string.isRequired,
};

export default getButtonType;
