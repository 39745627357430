import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_USER_REVIEWS = gql`
  query User(
    $where: UserWhereUniqueInput!
    $sectionsOrderBy: [EvaluationSectionTemplateOrderByWithRelationInput!]
    $questionsOrderBy: [EvaluationQuestionTemplateOrderByWithRelationInput!]
  ) {
    user(where: $where) {
      jobTitle
      employeeNumber
      firstName
      lastName
      reviews {
        dateCompleted
        dateToCompleteBy
        manager
        managerEmployeeNumber
        id
        status
        title
        year
        evaluations {
          id
          author
          authorEmployeeNumber
          owner
          manager
          managerEmployeeNumber
          dateCompleted
          dateToCompleteBy
          assignmentDate
          needsAssignment
          status
          title
          needsSignature
          signedDate
          signedUserId
          signerName
          formAuthor
          formAuthorEmployeeNumber
          sections(orderBy: $sectionsOrderBy) {
            id
            title
            instructions
            order
            questions(orderBy: $questionsOrderBy) {
              id
              answerOptions {
                id
                value
                name
              }
              answerType
              answer
              text
              order
            }
          }
        }
      }
    }
  }
`;

export const GET_REVIEWS = gql`
  query Reviews(
    $where: ReviewWhereInput
    $orderBy: [EvaluationAnswerOptionOrderByWithRelationInput!]
    $sectionsOrderBy: [EvaluationSectionOrderByWithRelationInput!]
    $questionsOrderBy: [EvaluationQuestionOrderByWithRelationInput!]
  ) {
    reviews(where: $where) {
      dateCompleted
      dateToCompleteBy
      id
      manager
      managerEmployeeNumber
      authorEmployeeNumber
      status
      templateVersionId
      title
      userId
      year
      evaluations {
        assignmentDate
        author
        authorHidden
        dateCompleted
        dateToCompleteBy
        id
        manager
        managerEmployeeNumber
        authorEmployeeNumber
        needsAssignment
        needsSignature
        signedDate
        signedUserId
        signerName
        owner
        ownerEmployeeNumber
        status
        templateVersionId
        title
        formAuthor
        formAuthorEmployeeNumber
        reviewId
        sections(orderBy: $sectionsOrderBy) {
          id
          instructions
          title
          order
          questions(orderBy: $questionsOrderBy) {
            answer
            answerType
            id
            text
            order
            answerOptions(orderBy: $orderBy) {
              id
              name
              value
              evaluationQuestionId
            }
          }
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query User($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      group
      firstName
      lastName
      supervisorObj {
        jobTitle
      }
    }
  }
`;

export const GET_USERS = gql`
  query Users(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
  ) {
    users(where: $where, orderBy: $orderBy) {
      id
      employeeNumber
      employmentStatus
      firstName
      jobTitle
      lastName
      group
    }
  }
`;

export const GET_REVIEW = gql`
  query Review($where: ReviewWhereUniqueInput!) {
    review(where: $where) {
      dateCompleted
      dateToCompleteBy
      id
      manager
      managerEmployeeNumber
      authorEmployeeNumber
      status
      templateVersionId
      title
      userId
      year
      evaluations {
        assignmentDate
        author
        authorHidden
        dateCompleted
        dateToCompleteBy
        id
        manager
        managerEmployeeNumber
        authorEmployeeNumber
        needsAssignment
        needsSignature
        signedDate
        signedUserId
        signerName
        owner
        ownerEmployeeNumber
        status
        templateVersionId
        title
        formAuthor
        formAuthorEmployeeNumber
        reviewId
      }
    }
  }
`;
