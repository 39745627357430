import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($input: AuthInput!) {
    login(input: $input) {
      user {
        id
        employeeNumber
        userType
        profileImage
        firstName
        lastName
        email
      }
      token
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation requestPasswordReset($input: RequestPasswordResetInput!) {
    requestPasswordReset(input: $input)
  }
`;

export const COMPLETE_PASSWORD_RESET_MUTATION = gql`
  mutation completePasswordReset($input: CompletePasswordResetInput!) {
    completePasswordReset(input: $input)
  }
`;

export const SIGNUP_MUTATION = gql`
  mutation signup($input: SignUpInput!) {
    signup(input: $input)
  }
`;