import React, { useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Control, Field, Input, Button, Help, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { COMPLETE_PASSWORD_RESET_MUTATION } from "../../graphql";

import { customToast as toast } from "../../utils";

class CustomResetError extends Error {}

const ResetPassword = () => {
  const { token } = useParams();

  const history = useHistory();

  const [inputs, setInputs] = useState({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const [completePasswordReset, { loading: CompletePasswordResetLoading }] =
    useMutation(COMPLETE_PASSWORD_RESET_MUTATION);

  const handleChange = (name, value) => {
    setInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const toggleShowPassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!inputs.newPassword) {
        throw new CustomResetError("New Password is required.");
      }
      if (!inputs.confirmNewPassword) {
        throw new CustomResetError("Confirm New Password is required.");
      }
      if (inputs.newPassword !== inputs.confirmNewPassword) {
        throw new CustomResetError(
          "New Password and Confirm New Password must be identical."
        );
      }
      const { data: CompletePasswordResetData } = await completePasswordReset({
        variables: {
          input: {
            newPassword: inputs.newPassword,
            confirmNewPassword: inputs.confirmNewPassword,
            resetPasswordToken: token,
          },
        },
      });
      if (!CompletePasswordResetData) {
        // We don't want to be explicit about this error.
        throw new Error("Something went wrong.");
      }
      toast.success("Password Reset Completed Successfully. Please Login.");
      history.push("/");
    } catch (err) {
      const message =
        err instanceof CustomResetError
          ? err.message
          : "Error Completing Password Reset. Please contact support.";
      toast.error(message);
    }
  };

  const isValidPassword = useMemo(
    () =>
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$/.test(
        inputs.newPassword
      ),
    [inputs.newPassword]
  );
  const arePasswordsMatching = useMemo(
    () => inputs.newPassword === inputs.confirmNewPassword,
    [inputs.newPassword, inputs.confirmNewPassword]
  );

  const isDisabled =
    !inputs.newPassword ||
    !inputs.confirmNewPassword ||
    !isValidPassword ||
    !arePasswordsMatching ||
    CompletePasswordResetLoading;

  return (
    <div className="forgot-password-container">
      <form className="forgot-password-wrapper" onSubmit={handleSubmit}>
        <div className="logo">
          <img alt="DECCO" className="decco-logo" src="/logo-white.png" />
        </div>
        <Field className="form-box">
          <Control>
            <Input
              className="ph-w"
              disabled={CompletePasswordResetLoading}
              name="newPassword"
              placeholder="New Password"
              type={showPassword ? "text" : "password"}
              value={inputs.newPassword}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <Icon
              className="password-visibility"
              style={{ top: 5, color: "white" }}
              onClick={toggleShowPassword}
            >
              <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
            </Icon>
            <Help color="danger" style={{boxShadow: "none"}}>
              {isValidPassword ? (
                <span>&nbsp;</span>
              ) : (
                <span style={{ whiteSpace: "nowrap" }}>
                  Must be at least 8 characters and contain 1 uppercase, 1
                  lowercase, and 1 symbol.
                </span>
              )}
            </Help>
          </Control>
        </Field>
        <Field className="form-box">
          <Control>
            <Input
              className="ph-w"
              disabled={CompletePasswordResetLoading}
              name="confirmNewPassword"
              placeholder="Confirm New password"
              type={showPassword ? "text" : "password"}
              value={inputs.confirmNewPassword}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
            />
            <Icon
              className="password-visibility"
              style={{ top: 5, color: "white" }}
              onClick={toggleShowPassword}
            >
              <FontAwesomeIcon icon={showPassword ? "eye" : "eye-slash"} />
            </Icon>
            <Help color="danger" style={{boxShadow: "none"}}>
              {arePasswordsMatching ? (
                <span>&nbsp;</span>
              ) : (
                <span>Password and Confirm Password must be identical.</span>
              )}
            </Help>
          </Control>
        </Field>
        <div className="form-box">
          <Button
            disabled={isDisabled}
            state={CompletePasswordResetLoading ? "loading" : ""}
            type="submit"
          >
            Confirm
          </Button>
        </div>
      </form>
    </div>
  );
};
export default ResetPassword;
