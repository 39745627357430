import { gql } from "@apollo/client";

export const FIND_MANY_CODES_QUERY = gql`
  query FIND_MANY_CODES(
    $where: CodesWhereInput
    $orderBy: [CodesOrderByWithRelationInput!]
    $distinct: [CodesScalarFieldEnum!]
  ) {
    findManyCodes(where: $where, orderBy: $orderBy, distinct: $distinct) {
      points
      title
      id
      entity
      expirationType
      description
      state
      level
      lookupCode
      certifications {
        users {
          employeeNumber
        }
      }
      licenses {
        users {
          employeeNumber
        }
      }
      skills {
        users {
          employeeNumber
        }
      }
      deccoUCourses {
        users {
          employeeNumber
        }
      }
    }
  }
`;

export const FIND_MANY_CODES_FOR_SELECT = gql`
  query FIND_MANY_CODES_FOR_SELECT(
    $where: CodesWhereInput
    $orderBy: [CodesOrderByWithRelationInput!]
    $distinct: [CodesScalarFieldEnum!]
  ) {
    findManyCodes(where: $where, orderBy: $orderBy, distinct: $distinct) {
      title
      state
      level
      id
    }
  }
`;

export const FIND_FIRST_CODE = gql`
  query FIND_FIRST_CODE($where: CodesWhereInput) {
    findFirstCodes(where: $where) {
      entity
      points
      title
      description
      expirationType
      id
      state
      level
      lookupCode
    }
  }
`;

export const FIND_UNIQUE_CODE = gql`
  query FIND_UNIQUE_CODE($where: CodesWhereUniqueInput!) {
    findUniqueCodes(where: $where) {
      entity
      points
      title
      id
      description
      expirationType
      level
      state
      lookupCode
      certifications {
        users {
          employeeNumber
        }
      }
      licenses {
        users {
          employeeNumber
        }
      }
      skills {
        users {
          employeeNumber
        }
      }
      deccoUCourses {
        users {
          employeeNumber
        }
      }
    }
  }
`;

export const FIND_MANY_CODES_BY_ENTITY = gql`
  query FindManyCodesByEntity($where: CodesWhereInput) {
    findManyCodes(where: $where) {
      title
      state
      level
      id
    }
  }
`;
