import * as React from "react";
import { Grid, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
// import { isValid, startOfDay, endOfDay } from "date-fns";
import PropTypes from "prop-types";
import { Input } from "rbx";
// import { styled } from '@mui/material/styles';
// import { useAuth } from "../../../context";
// import { Role } from "../../generated/graphql";
// import CodeSelect from "../../CodeSelect";
// import DateInput from "../../DateInput";
import EmployeeSelectAlt from "../../EmployeeSelectAlt";
// import StatusSelect from "../../StatusSelect";
// import LevelSelect from "../../LevelSelect";
// import ProjectSelect from "../../ProjectSelect";
// import GenericSelect from "../../GenericSelect/GenericSelect";
import GroupCycleSelect from "../../GroupCycleSelect/GroupCycleSelect";
import DateRangePicker from "../../DateRangePicker/DateRangePicker";
import checkMobile from "../../../utils/checkForMobile";

function SearchFiltersForm({ inputs, onChange, location, setFilters }) {
  // TO DO replace with dynamic options
  const yearOptions = [
    "2023",
    "2024",
    "2025",
    "2026",
    "2027",
    "2028",
    "2029",
    "2030",
  ];


  const checkNeedsAssignment = () => {
    if (typeof inputs?.needsAssignment === "object") {
      return inputs?.needsAssignment?.equals ? "Yes" : "No";
    } if (typeof inputs?.needsAssignment === "string") {
      return inputs?.needsAssignment;
    } 
      return "";
    
  };

  const needsAssignment = checkNeedsAssignment();
  // TO DO replace with dynamic options
  const reviewStatusOptions = ["INCOMPLETE", "COMPLETED", "SAVE_FOR_LATER"];

  const overdueOptions = ["Yes", "No"];
  const needsAssignmentOptions = ["Yes", "No"];
  const isMobile = checkMobile();

  const convertEnum = (value) =>
    value
      .toLowerCase()
      .split("_")
      .map((x) => `${x.charAt(0).toUpperCase()}${x.slice(1)}`)
      .join(" ");

  if (location === "workshop") {
    return (
      <Grid container columnSpacing={1} rowSpacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginTop: "8px" }}
          >
            Year
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <Select
              fullWidth
              SelectDisplayProps={{
                style: { padding: "6.5px 10px" },
                width: "200px",
              }}
              name="year"
              sx={{ m: 1, minWidth: 120 }}
              value={inputs?.year?.equals || inputs?.year || ""}
              variant="outlined"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <MenuItem key="_blank" value="" />
              {Array.isArray(yearOptions) &&
                yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", margin: " 8px 0px" }}
          >
            Title
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200 }}>
            <Input
              fullWidth
              label="Title"
              value={inputs?.title?.contains || inputs?.title || ""}
              onChange={(e) => onChange("title", e.target.value)}
            />
            {/*  User */}
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  if (location === "reviews") {
    return (
      <Grid container columnSpacing={1} rowSpacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginTop: "8px" }}
          >
            Status
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <Select
              fullWidth
              SelectDisplayProps={{
                style: { padding: "6.5px 10px" },
                width: "200px",
              }}
              name="status"
              sx={{ m: 1, minWidth: 120 }}
              value={inputs?.status?.equals || inputs?.status || ""}
              variant="outlined"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <MenuItem key="_blank" value="" />
              {Array.isArray(reviewStatusOptions) &&
                reviewStatusOptions.map((status) => (
                  <MenuItem key={status} value={status}>
                    {convertEnum(status)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginTop: "8px" }}
          >
            Overdue
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <Select
              fullWidth
              SelectDisplayProps={{
                style: { padding: "6.5px 10px" },
                width: "200px",
              }}
              name="overdue"
              sx={{ m: 1, minWidth: 120 }}
              value={inputs?.overdue?.equals || inputs?.overdue || ""}
              variant="outlined"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <MenuItem key="_blank" value="" />
              {Array.isArray(overdueOptions) &&
                overdueOptions.map((overdue) => (
                  <MenuItem key={overdue} value={overdue}>
                    {overdue}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", margin: " 8px 0px" }}
          >
            Title
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200 }}>
            <Input
              fullWidth
              label="Title"
              value={inputs?.title?.contains || inputs?.title || ""}
              onChange={(e) => onChange("title", e.target.value)}
            />
            {/*  User */}
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useName
              label="Manager"
              name="manager"
              placeholder="Manager"
              value={inputs?.manager?.equals || inputs?.manager || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <DateRangePicker
            label="Date Completed"
            name="dateCompleted"
            value={inputs?.dateCompleted}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <DateRangePicker
            label="Date Due"
            name="dateToCompleteBy"
            value={inputs?.dateToCompleteBy}
            onChange={onChange}
          />
        </Grid>

        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginTop: "8px" }}
          >
            Year
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <Select
              fullWidth
              SelectDisplayProps={{
                style: { padding: "6.5px 10px" },
                width: "200px",
              }}
              name="year"
              sx={{ m: 1, minWidth: 120 }}
              value={inputs?.year?.equals || inputs?.year || ""}
              variant="outlined"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <MenuItem key="_blank" value="" />
              {Array.isArray(yearOptions) &&
                yearOptions.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  if (location === "evaluations") {
    return (
      <Grid container columnSpacing={1} rowSpacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <DateRangePicker
            label="Date Completed"
            name="dateCompleted"
            value={inputs?.dateCompleted}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <DateRangePicker
            label="Date Due"
            name="dateToCompleteBy"
            value={inputs?.dateToCompleteBy}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <DateRangePicker
            label="Assignment Date"
            name="assignmentDate"
            value={inputs?.assignmentDate}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useName
              label="Manager"
              name="manager"
              placeholder="Manager"
              value={inputs?.manager?.equals || inputs?.manager || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useName
              label="Subject"
              name="owner"
              placeholder="Subject"
              value={inputs?.owner?.equals || inputs?.owner || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useName
              label="Writer"
              name="formAuthor" // for data accuracy, the author field will refer to the writer
              placeholder="Writer"
              value={inputs?.formAuthor?.equals || inputs?.formAuthor || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useName
              label="Signer"
              name="signerName" // THE SIGNER WILL BE THE AUTHOR AT THE END
              placeholder="Signer"
              value={inputs?.signerName?.equals || inputs?.signerName || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useName
              label="Current Author"
              name="author" 
              placeholder="Author"
              value={inputs?.author?.equals || inputs?.author || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useEmployeeNumber
              label="Manager Employee Number"
              name="managerEmployeeNumber"
              placeholder="Manager Employee Number"
              value={
                inputs?.managerEmployeeNumber?.equals ||
                inputs?.managerEmployeeNumber ||
                ""
              }
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useEmployeeNumber
              label="Writer Employee Number"
              name="formAuthorEmployeeNumber"
              placeholder="Writer Employee Number"
              value={
                inputs?.formAuthorEmployeeNumber?.equals ||
                inputs?.formAuthorEmployeeNumber ||
                ""
              }
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl
            sx={{ m: 0, minWidth: 120, marginTop: "8px" }}
            variant="standard"
          >
            <EmployeeSelectAlt
              showBlank
              useEmployeeNumber
              label="Author Employee Number"
              name="authorEmployeeNumber"
              placeholder="Current Author Employee Number"
              value={
                inputs?.authorEmployeeNumber?.equals ||
                inputs?.authorEmployeeNumber ||
                ""
              }
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginTop: "8px" }}
          >
            Status
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <Select
              fullWidth
              SelectDisplayProps={{
                style: { padding: "6.5px 10px" },
                width: "200px",
              }}
              name="status"
              sx={{ m: 1, minWidth: 120 }}
              value={inputs?.status?.equals || inputs?.status || ""}
              variant="outlined"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <MenuItem key="_blank" value="" />
              {Array.isArray(reviewStatusOptions) &&
                reviewStatusOptions.map((status) => (
                  <MenuItem key={status} value={status}>
                    {convertEnum(status)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginTop: "8px" }}
          >
            Overdue
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <Select
              fullWidth
              SelectDisplayProps={{
                style: { padding: "6.5px 10px" },
                width: "200px",
              }}
              name="overdue"
              sx={{ m: 1, minWidth: 120 }}
              value={inputs?.overdue?.equals || inputs?.overdue || ""}
              variant="outlined"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <MenuItem key="_blank" value="" />
              {Array.isArray(overdueOptions) &&
                overdueOptions.map((overdue) => (
                  <MenuItem key={overdue} value={overdue}>
                    {overdue}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginTop: "8px" }}
          >
            Needs Assignment
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <Select
              fullWidth
              SelectDisplayProps={{
                style: { padding: "6.5px 10px" },
                width: "200px",
              }}
              name="needsAssignment"
              sx={{ m: 1, minWidth: 120 }}
              value={needsAssignment}
              variant="outlined"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            >
              <MenuItem key="_blank" value="" />
              {Array.isArray(needsAssignmentOptions) &&
                needsAssignmentOptions.map((assignment) => (
                  <MenuItem key={assignment} value={assignment}>
                    {assignment}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", margin: " 8px 0px" }}
          >
            Title
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200 }}>
            <Input
              fullWidth
              label="Title"
              value={inputs?.title?.contains || inputs?.title || ""}
              onChange={(e) => onChange("title", e.target.value)}
            />
            {/*  User */}
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  if (location === "reviewCycles") {
    return (
      <Grid container columnSpacing={1} rowSpacing={2}>
        <Grid item xs={isMobile ? 12 : 4}>
          <InputLabel
            sx={{ fontWeight: "bold", color: "black", marginBottom: "8px" }}
          >
            Name
          </InputLabel>
          <FormControl sx={{ m: 0, width: 200 }}>
            <Input
              fullWidth
              label="Name"
              value={inputs?.name?.contains || inputs?.name || ""}
              onChange={(e) => onChange("name", e.target.value)}
            />
            {/*  User */}
          </FormControl>
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <DateRangePicker
            label="Start Date"
            name="startDate"
            value={inputs?.startDate}
            onChange={onChange}
          />
        </Grid>
        <Grid item xs={isMobile ? 12 : 4}>
          <FormControl sx={{ m: 0, width: 200, marginLeft: "-8px" }}>
            <GroupCycleSelect
              filterHeight
              showBlank
              label="Group Cycle"
              name="groupCycle"
              size="medium"
              sx={{ height: "36px" }}
              value={inputs?.groupCycle?.equals || inputs?.groupCycle || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container columnSpacing={1} rowSpacing={2}>
      <Grid item xs={4}>
        <span>No Filters</span>
      </Grid>
    </Grid>
  );
}

SearchFiltersForm.propTypes = {
  inputs: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default SearchFiltersForm;
