/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, Autocomplete } from "@mui/material";

const statusOptions = [
  { value: "Active", label: "Active" },
  { value: "Inactive", label: "Inactive" },
];
const ContractorStatusSelectMUI = ({
  name,
  value,
  label,
  placeholder,
  disabled,
  loading,
  required,
  showAllOption,
  getValue,
  setValue,
  onChange,
}) => {
  const [formattedOptions, setFormattedOptions] = useState([]);
  useEffect(() => {
    if (statusOptions.length) {
      const fOptions = statusOptions.map((opt) => ({
        value: opt.value,
        label: opt.label,
      }));
      if (showAllOption) {
        fOptions.unshift({ value: "all", label: "All" });
      }
      setFormattedOptions(fOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusOptions, showAllOption]);
  return (
    <Autocomplete
      fullWidth
      disabled={disabled}
      getOptionLabel={(option) =>
        option?.label ??
        formattedOptions.find((opt) => opt.value === value)?.label
      }
      isOptionEqualToValue={(o, v) => o.value === v}
      isRequired={required}
      loading={loading}
      options={formattedOptions}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder={placeholder} />
      )}
      size="medium"
      value={getValue(value)}
      onChange={(_, option) => {
        onChange(name, setValue(option?.value));
      }}
    />
  );
};
ContractorStatusSelectMUI.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  showAllOption: PropTypes.bool,
  options: PropTypes.array,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
};
ContractorStatusSelectMUI.defaultProps = {
  name: "",
  placeholder: "",
  label: "",
  disabled: false,
  loading: false,
  required: false,
  showAllOption: false,
  options: [],
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};
export default ContractorStatusSelectMUI;