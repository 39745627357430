import React from "react";
import PropTypes from "prop-types";

// components
import { Field } from "rbx";
import EmployeeTitleSelect from "../../../EmployeeTitleSelect";
import GenericSelect from "../../../GenericSelect";
import SupervisorTitleSelect from "../../../SupervisorTitleSelect";

const GROUPS = [
  { id: "D1", title: "D1" },
  { id: "D2", title: "D2" },
  { id: "D3", title: "D3" },
  { id: "D4", title: "D4" },
  { id: "D5", title: "D5" },
];

const EmployeeRelationForm = ({ inputs, disabled, onChange }) => (
  <Field className="grid-override is-three" kind="group">
    <GenericSelect
      required
      showBlank
      disabled={disabled}
      label="Group"
      name="group"
      options={GROUPS}
      placeholder="Group"
      size="medium"
      value={inputs.group || ""}
      onChange={onChange}
    />

    <EmployeeTitleSelect
      required
      disabled={disabled}
      label="Title"
      name="title"
      placeholder="Title"
      size="medium"
      value={inputs.title || ""}
      onChange={onChange}
    />
    <SupervisorTitleSelect
      required
      disabled={disabled}
      label="Supervisor"
      name="supervisor"
      placeholder="Supervisor"
      size="medium"
      value={inputs.supervisor || ""}
      onChange={onChange}
    />
  </Field>
);
EmployeeRelationForm.propTypes = {
  inputs: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EmployeeRelationForm.defaultProps = {
  disabled: false,
};

export default EmployeeRelationForm;
