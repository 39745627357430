/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../context";

function Dropdown({ open, options }) {
  const history = useHistory();
  const { handleLogout } = useAuth();

  const handleClick = (item) => {
    if (item.logout) {
      handleLogout();
    } else {
      history.push(item.to);
    }
  };

  return (
    <div className="nav-dropdown">
      <div className={`nav-dropdown-content ${open && "show"}`}>
        {options.map((item) => (
          <div
            key={item.id}
            role="button"
            tabIndex={0}
            onClick={() => handleClick(item)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  open: PropTypes.bool,
  options: PropTypes.array,
};

Dropdown.defaultProps = {
  open: false,
  options: [],
};

export default Dropdown;
