import React, { useState } from "react";

import { Button, Box, LinearProgress, Collapse } from "@mui/material";

import PropTypes from "prop-types";
import { FileDownload, FilterAlt, FilterAltOff } from "@mui/icons-material";
import {
  ProjectSelect,
  AccordionItem,
  AccordionDetailsItem,
} from "../../components";

import EmployeeAssignmentFilters from "./EmployeeAssignmentFilters";

const MobileEmployeeAssignments = ({
  inputs,
  loading,
  employeeAssignmentData,
  handleFiltersChange,
  handleProjectChange,
  onExportClick,
}) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  return (
    <React.Fragment>
      <Box padding="90px 0px 0px 0px" width="100%">
        {loading && <LinearProgress color="secondary" />}
      </Box>

      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="100%"
        overflow="hidden"
        padding="20px 0px 0px 0px"
        width="100svw"
      >
        <Box display="inline-block" width="100%">
          <Box
            display="flex"
            gap={2}
            id="filters-container"
            justifyContent="center"
            mb={2}
            pl={2}
            pr={2}
            width="100%"
          >
            <Box pl={2} width="100%">
              <Button
                color="secondary"
                sx={{ color: "white", width: "100%" }}
                variant="contained"
                onClick={() => onExportClick()}
              >
                <FileDownload />
              </Button>
            </Box>
            <Box pr={2} width="100%">
              <Button
                color="primary"
                sx={{ width: "100%" }}
                variant="contained"
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
              >
                {isFiltersOpen ? <FilterAltOff /> : <FilterAlt />}
              </Button>
            </Box>
          </Box>
          <Collapse in={isFiltersOpen}>
            <Box mb={4}>
              <EmployeeAssignmentFilters
                handleFiltersChange={handleFiltersChange}
                inputs={inputs}
              />
            </Box>
          </Collapse>
          <Collapse in={!isFiltersOpen}>
            <Box
              display="inline-block"
              height="80svh"
              id="employees-container"
              maxHeight="38rem"
              pr={2}
              sx={{
                textTransform: "uppercase",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              width="100%"
            >
              {employeeAssignmentData?.map((item) => (
                <AccordionItem
                  info={item?.projectName}
                  key={item.id}
                  title={`${item.firstName} ${item?.lastName}`}
                >
                  <AccordionDetailsItem
                    label="Employee #"
                    value={item.employeeNumber}
                  />
                  <AccordionDetailsItem
                    label="Job Title"
                    value={item.jobTitle}
                  />
                  <AccordionDetailsItem
                    label="Supervisor"
                    value={item.supervisor}
                  />
                  <AccordionDetailsItem label="Manager" value={item.manager} />
                  <Box mt={2}>
                    <ProjectSelect
                      name={item.employeeNumber}
                      value={item.projectId || ""}
                      onChange={(_, value, other) =>
                        handleProjectChange(item.employeeNumber, value, other)
                      }
                    />
                  </Box>
                </AccordionItem>
              ))}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </React.Fragment>
  );
};

MobileEmployeeAssignments.propTypes = {
  loading: PropTypes.bool,
  inputs: PropTypes.object,
  employeeAssignmentData: PropTypes.array,
  handleProjectChange: PropTypes.func,
  handleFiltersChange: PropTypes.func,
  onExportClick: PropTypes.func,
};

MobileEmployeeAssignments.defaultProps = {
  loading: false,
  employeeAssignmentData: [],
  inputs: {},
  handleProjectChange: () => {},
  handleFiltersChange: () => {},
  onExportClick: () => {},
};

export default MobileEmployeeAssignments;
