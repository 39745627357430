import { uppercaseFirstLetter } from "../../../utils";
import formatDate from "../../../utils/formatDate";

export const REVIEW_COLUMNS = [
  {
    header: "Status",
    id: "status",
    accessorKey: "status",
  },
  {
    header: "Title",
    id: "title",
    accessorKey: "title",
  },
  {
    header: "Year",
    id: "year",
    accessorKey: "year",
  },
  {
    header: "Manager",
    id: "manager",
    accessorKey: "manager",
  },
  {
    header: "Subject",
    id: "subject",
    accessorFn: (row) =>
      `${uppercaseFirstLetter(row.user?.firstName)} ${uppercaseFirstLetter(
        row.user?.lastName
      )}`,
  },
  {
    header: "Due Date",
    id: "dateToCompleteBy",
    accessorFn: (row) =>
      row?.dateToCompleteBy && formatDate(row?.dateToCompleteBy),
  },
  {
    header: "Overdue",
    id: "overdue",
    accessorFn: (row) =>
      row?.dateToCompleteBy &&
      new Date(row?.dateToCompleteBy) < new Date() &&
      !row?.dateCompleted
        ? "Yes"
        : "No",
  },
  {
    header: "Date Completed",
    id: "dateCompleted",
    accessorFn: (row) => row?.dateCompleted && formatDate(row?.dateCompleted),
  },
  {
    header: "Template Version Id",
    id: "templateVersionId",
    accessorKey: "templateVersionId",
  },
];

export const CONTRACTOR_COLUMNS = [
  {
    Header: "Contractor Name",
    id: "contractor_name",
    accessor: (row) =>
      `${uppercaseFirstLetter(row.firstName)}  ${uppercaseFirstLetter(
        row.lastName
      )} `,
  },
  {
    Header: "Task",
    id: "task",
    accessor: "task",
  },
  {
    Header: "Staffing Agency",
    id: "staffingAgency",
    accessor: "staffingAgency",
  },
];
