/* eslint-disable react/prop-types */
import React from "react";
import { Generic, Icon } from "rbx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DEFAULT_COLUMNS = [
  {
    accessor: "group",
    Header: "Group",
  },
  {
    accessor: "title",
    Header: "Title",
  },
  {
    accessor: "supervisor",
    Header: "Supervisor",
  },
];

export const generateColumns = (handleDelete) => [
  {
    Header: "",
    accessor: "id",
    Cell: ({ cell }) => (
      <Generic
        style={{ textAlign: "center", cursor: "pointer" }}
        title="Delete"
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cell?.row?.values?.title);
        }}
      >
        <Icon color="danger">
          <FontAwesomeIcon icon="times" />
        </Icon>
      </Generic>
    ),
  },
  ...DEFAULT_COLUMNS,
];
