import React from "react";
import { Tab, Title } from "rbx";
import PropTypes from "prop-types";
import "../../Dashboard.scss";

const ReportHeader = ({ tabs, report, onReportChange }) => (
  <div className="report-header">
    <Title>Reports</Title>

    {tabs && tabs?.length && (
      <Tab.Group align="centered">
        {tabs.map((tab) => (
          <Tab
            active={report === tab}
            key={tab}
            onClick={() => onReportChange(tab)}
          >
            {tab}
          </Tab>
        ))}
      </Tab.Group>
    )}
  </div>
);

ReportHeader.propTypes = {
  tabs: PropTypes.array.isRequired,
  report: PropTypes.string.isRequired,
  onReportChange: PropTypes.func.isRequired,
};
export default ReportHeader;
