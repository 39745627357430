import { gql } from "@apollo/client";

export const GET_POINTS_REPORT = gql`
  query getPointsReport(
    $where: CodesWhereInput
    $orderBy: [CodesOrderByWithRelationInput!]
  ) {
    findManyCodes(where: $where, orderBy: $orderBy) {
      points
      title
      entity
      state
      lookupCode
      level
      expirationType
      description
    }
  }
`;

export const GET_EMPLOYEE_POINTS = gql`
  query getEmployeePoints($where: UserWhereUniqueInput!) {
    user(where: $where) {
      id
      deccoUCourses {
        codes {
          points
        }
      }
      skills {
        codes {
          points
        }
      }
      licenses {
        codes {
          points
        }
      }
      certifications {
        codes {
          points
        }
      }
    }
  }
`;

export const GET_ALL_USER_POINTS = gql`
  query getAllUserPoints {
    users {
      certifications {
        codes {
          points
        }
      }
      skills {
        codes {
          points
        }
      }
      deccoUCourses {
        codes {
          points
        }
      }
      licenses {
        codes {
          points
        }
      }
    }
  }
`;
