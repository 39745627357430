import React from "react";
import PropTypes from "prop-types";

import { TextField, Typography, Box, useTheme } from "@mui/material";
import { StatusSelectMUI, ProjectSelectMUI } from "../../../../components";

const ContractorFilters = ({ onChange, inputs }) => {
  const theme = useTheme();

  const handleChange = (name, value) => {
    onChange(name, value);
  };

  return (
    <React.Fragment>
      <Typography fullWidth mb={2} ml={2} mr={2} mt={1} variant="h5">
        Employee Filters
      </Typography>
      <Box
        display="grid"
        gap={2}
        sx={{
          width: "100%",
          paddingLeft: "2rem",
          paddingRight: "2rem",
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
          [theme.breakpoints.up("sm")]: {
            gridTemplateColumns: "1fr 1fr",
          },
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr 1fr",
          },
        }}
      >
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Name"
          name="fullName"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.name}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Task"
          name="task"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.task}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Evaluation"
          name="evaluation"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.evaluation}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <TextField
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          label="Staffing Agency"
          name="staffingAgency"
          size="medium"
          sx={{ marginBottom: "1rem" }}
          value={inputs?.staffingAgency}
          onChange={(e) => handleChange(e?.target?.name, e?.target?.value)}
        />
        <ProjectSelectMUI
          showAllOption
          label="Project"
          name="project"
          value={inputs?.project}
          onChange={handleChange}
        />
        <StatusSelectMUI
          showAllOption
          label="Status"
          name="status"
          value={inputs?.status}
          onChange={handleChange}
        />
      </Box>
    </React.Fragment>
  );
};

ContractorFilters.propTypes = {
  onChange: PropTypes.func,
  inputs: PropTypes.object,
};

ContractorFilters.defaultProps = {
  onChange: () => null,
  inputs: {},
};

export default ContractorFilters;
