import React, { useState } from "react";
import { Table } from "rbx";
import PropTypes from "prop-types";

import Pagination from "../../../Pagination";

const CalendarEventsTable = ({
  data,
  onPaginationChange,
  pageCount,
  recordCount,
  onRowClick,
}) => {
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <div className="ce-table-container">
      <div className="ce-table-wrapper">
        <Table fullwidth hoverable className="ce-table">
          <Table.Head>
            <Table.Row>
              <Table.Heading>Event</Table.Heading>
              <Table.Heading>Date</Table.Heading>
              <Table.Heading>Time</Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {data.map((item) => (
              <Table.Row
                key={item.id}
                selected={item.selected}
                onClick={() => onRowClick(item)}
              >
                <Table.Cell>{item.title}</Table.Cell>
                <Table.Cell>{item.date}</Table.Cell>
                <Table.Cell>{item.time}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>

        {recordCount > 10 && (
          <Pagination
            hidePageSizeSelector
            canNextPage={currentPage + 1 < pageCount}
            canPreviousPage={currentPage + 1 > 1}
            page={currentPage + 1}
            pageSize={10}
            pages={pageCount}
            recordCount={recordCount}
            setPageSize={() => null}
            onClickNext={() => {
              onPaginationChange(currentPage + 1);

              setCurrentPage((prev) => prev + 1);
            }}
            onClickPrev={() => {
              onPaginationChange(currentPage - 1);

              setCurrentPage((prev) => prev - 1);
            }}
          />
        )}
      </div>
    </div>
  );
};

CalendarEventsTable.propTypes = {
  data: PropTypes.array,
  onPaginationChange: PropTypes.any,
  pageCount: PropTypes.number,
  recordCount: PropTypes.number,
  onRowClick: PropTypes.any,
};

CalendarEventsTable.defaultProps = {
  data: [],
  onPaginationChange: () => null,
  pageCount: 1,
  recordCount: 0,
  onRowClick: () => null,
};

export default CalendarEventsTable;
