import React from "react";
import PropTypes from "prop-types";

const TotalTshirts = ({ tshirtSizes }) => (
  <div className="total-tshirts-container">
    <p className="total-tshirts-title">Totals:</p>
    {tshirtSizes.map((tshirtSize) => (
      <div key={tshirtSize.label}>
        <span className="left-side-label">{tshirtSize.label}:</span>
        <span>{tshirtSize.value}</span>
      </div>
    ))}
  </div>
);

TotalTshirts.propTypes = {
  tshirtSizes: PropTypes.array,
};

TotalTshirts.defaultProps = {
  tshirtSizes: [],
};

export default TotalTshirts;
