import { gql } from "@apollo/client";

// FOUNDATION
// eslint-disable-next-line import/prefer-default-export
export const SINGLE_YEARS_OF_SERVICE_QUERY = gql`
  query SINGLE_YEARS_OF_SERVICE_QUERY(
    $orderBy: YearsOfServiceOrderByWithRelationInput!
    $where: YearsOfServiceWhereInput!
  ) {
    singleYearsOfService(where: $where, orderBy: $orderBy) {
      employeeNumber
      yearsOfService
    }
  }
`;

// MONGODB
export const GET_YEARS_OF_SERVICE_REPORT = gql`
  query FindManyUsers(
    $where: UserWhereInput
    $orderBy: [UserOrderByWithRelationInput!]
  ) {
    users(where: $where, orderBy: $orderBy) {
      email
      firstName
      lastName
      yearsOfServiceNote
      totalYearsOfService
      employeeNumber
    }
  }
`;
