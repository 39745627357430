import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Control, Label, Select } from "rbx";

import { useQuery } from "@apollo/client";
import { GET_PROJECTS } from "../../graphql";

const ProjectSelect = ({
  label,
  size,
  name,
  onChange,
  disabled,
  value,
  required,
  getValue,
  setValue,
  showBlank,
  showAll,
  filterEmploymentStatus,
}) => {
  const where = filterEmploymentStatus
    ? {
        AND: [
          {
            name: {
              notIn: ["Disability", "Vacation", "Not Assigned"],
            },
          },
        ],
      }
    : {};

  const { data: { projects = [] } = {} } = useQuery(GET_PROJECTS, {
    variables: {
      orderBy: [{ name: "asc" }],
      where,
    },
  });
  const indicateRequired =
    required && (!value || value === "" || value === "0" || value === 0);
  const [initialValue, setInitialValue] = useState("");

  const handleChange = (e) => {
    const selected = projects.filter(
      (project) => project.id === e.target.value
    );

    setInitialValue(e.target.value);
    onChange(e.target.name, e.target.value, selected[0]?.name);
  };

  useEffect(() => {
    if (value && !initialValue) {
      setInitialValue(value);
    }
  }, [initialValue, value]);

  return (
    <Control size={size}>
      {label && (
        <Label className="project-label" size={size}>
          {label}
        </Label>
      )}
      <Select.Container
        fullwidth
        size={size}
        state={projects || Array.isArray([]) ? "" : "loading"}
      >
        <Select
          disabled={disabled}
          name={name}
          required={required}
          style={{
            border: indicateRequired ? "1px #e63362 solid" : "",
            borderRadius: "4px",
            boxShadow: indicateRequired
              ? "0 0 0 0.125em rgba(230, 51, 98, 0.25)"
              : "",
          }}
          value={initialValue}
          onChange={(e) => handleChange(e)}
        >
          {showBlank && <Select.Option value="" />}
          {showAll && <Select.Option value="">All</Select.Option>}
          {Array.isArray(projects) &&
            projects.map((project) => (
              <Select.Option key={project?.name} value={project?.id}>
                {project?.name}
              </Select.Option>
            ))}
        </Select>
      </Select.Container>
    </Control>
  );
};

ProjectSelect.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  getValue: PropTypes.func,
  setValue: PropTypes.func,
  showBlank: PropTypes.bool,
  showAll: PropTypes.bool,
  filterEmploymentStatus: PropTypes.bool,
};

ProjectSelect.defaultProps = {
  size: "normal",
  disabled: false,
  value: "",
  name: "",
  label: "",
  required: false,
  showBlank: false,
  showAll: false,
  filterEmploymentStatus: false,
  getValue: (x) => x,
  setValue: (x) => x,
  onChange: () => {},
};

export default ProjectSelect;
