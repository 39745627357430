import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Button, Control, Field, Icon, Title } from "rbx";
import { toast } from "react-toastify";

import { useMutation } from "@apollo/client";

import { SETTINGS_SYNC } from "../../graphql";

const SettingsSync = () => {
  const [syncFoundation] = useMutation(SETTINGS_SYNC);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);
      const {
        data: { employeeSync = {} },
      } = await syncFoundation();

      toast.success(
        `${employeeSync.message}, ${employeeSync.employeesCreated} employees created, ${employeeSync.employeesUpdated} employees updated.`
      );
    } catch (error) {
      toast.error("Something went wrong syncing employees. Please contact support" );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="section">
      <div className="section-header">
        <Title>Foundation Sync</Title>
        <Field>
          <Control>
            <Button
              color="primary"
              disabled={loading}
              onClick={() => handleClick()}
            >
              <Icon>
                <FontAwesomeIcon icon="sync" />
              </Icon>
              <span>Sync</span>
            </Button>
          </Control>
        </Field>
      </div>
    </div>
  );
};

SettingsSync.propTypes = {};

export default SettingsSync;
